import React from "react";
import { Link } from "react-router-dom";
import { Celebration } from "./calendar/ordinariate-calendar";
import { BACKGROUND_COLOR, PRAY_PATH, RUBRIC_COLOR } from "./tokens";

export const CelebrationList: React.FC<{
    year: number;
    month: number;
    date: number;
    options: Celebration[];
    outranked: Celebration[];
    isFirstEvensong: string[];
    isEvening: boolean;
}> = (props) => {
    const {
        year,
        month,
        date,
        options,
        outranked,
        isFirstEvensong,
        isEvening,
    } = props;

    const isCurrentTimeOfDay = isEvening
        ? new Date().getHours() > 12
        : new Date().getHours() < 12;

    const isCurrentDate =
        date === new Date().getDate() && month === new Date().getMonth() + 1;

    const isCurrent = isCurrentTimeOfDay && isCurrentDate;

    const topOption =
        options.find(
            (celebration) => !celebration.optional && !celebration.regionNote
        ) ?? options[0];

    const finalOptions = [topOption].concat(
        options.filter((celebration) => celebration !== topOption)
    );

    return (
        <>
            <div style={{}}>
                {finalOptions.map((celebration, celebrationIndex) => {
                    let liturgicalColor = "#4d9c2c"; // green
                    if (
                        celebration.title.includes("Advent") ||
                        celebration.title.includes("Lent") ||
                        celebration.title.includes("Palm Sunday") ||
                        celebration.title.includes("gesima") ||
                        celebration.title.includes("Passion") ||
                        celebration.title === "Holy Saturday" ||
                        celebration.title === "Good Friday" ||
                        celebration.title === "Maundy Thursday" ||
                        celebration.title.includes("Ash Wednesday") ||
                        celebration.title === "ANZAC Day" ||
                        celebration.title.includes("December")
                    ) {
                        liturgicalColor = "#6f3eb0"; // purple
                    }
                    if (
                        celebration.type === "memorial" ||
                        celebration.title.includes("Easter") ||
                        celebration.title.includes("Christmas") ||
                        celebration.title === "Rogation Monday" ||
                        celebration.title === "Rogation Tuesday" ||
                        celebration.title === "Rogation Wednesday" ||
                        celebration.title === "Rogation Thursday" ||
                        celebration.title === "Rogation Friday" ||
                        celebration.title === "Rogation Saturday" ||
                        celebration.title.includes("Ascension")
                    ) {
                        liturgicalColor = "#ffffff"; // white
                    }
                    if (
                        celebration.type === "feast" ||
                        celebration.type === "solemnity"
                    ) {
                        liturgicalColor = "#fdde6c"; // gold
                    }
                    if (
                        celebration.subtitle
                            ?.toLocaleLowerCase()
                            .includes("martyr") ||
                        celebration.title
                            .toLocaleLowerCase()
                            .includes("martyr") ||
                        celebration.subtitle?.includes("Apostle") ||
                        celebration.subtitle?.includes("Evangelist") ||
                        celebration.title.includes("Good Friday") ||
                        celebration.title.includes("Whit") ||
                        celebration.title.includes("Pentecost")
                    ) {
                        liturgicalColor = "#c23b1d"; // red
                    }
                    if (celebration.title.includes("Holy Week")) {
                        liturgicalColor = "#6f3eb0"; // purple
                    }
                    if (celebration.title === "All Souls") {
                        liturgicalColor = "#000000";
                    }
                    if (celebration.title.includes("before Advent")) {
                        liturgicalColor = "#4d9c2c"; // green
                    }
                    if (
                        celebration.shortTitle === "Advent III" ||
                        celebration.shortTitle === "Lent IV"
                    ) {
                        liturgicalColor = "#ff91b0"; // rose
                    }
                    if (celebration.title === "Saint John") {
                        liturgicalColor = "#ffffff"; // white
                    }
                    if (
                        celebration.collect ===
                        "O God, who by the leading of a star didst manifest thy Only Begotten Son to the Gentiles: mercifully grant that we, who know thee now by faith, may be led onward through this earthly life, until we see the vision of thy heavenly glory; through the same Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen."
                    ) {
                        liturgicalColor = "#ffffff"; // white
                    }

                    let officeLabel = "Mattins";

                    if (isEvening) {
                        if (
                            celebration.type === "sunday" ||
                            celebration.type === "solemnity" ||
                            celebration.type === "feast"
                        ) {
                            if (
                                isFirstEvensong.find(
                                    (ife) => ife === celebration.label
                                )
                            ) {
                                officeLabel = "First Evensong";
                            } else {
                                officeLabel = "Second Evensong";
                            }
                        } else {
                            officeLabel = "Evensong";
                        }
                    }

                    const hymnLookup: { [id: string]: string | undefined } = {
                        "First Evensong": celebration.ep1HymnId,
                        Mattins: celebration.mpHymnId,
                        Evensong: celebration.ep2HymnId,
                        "Second Evensong": celebration.ep2HymnId,
                    };

                    const vAndRLookup: { [id: string]: string | undefined } = {
                        "First Evensong": celebration.ep1VandR?.join(" / "),
                        Mattins: celebration.mpVandR?.join(" / "),
                        Evensong: celebration.ep2VandR?.join(" / "),
                        "Second Evensong": celebration.ep2VandR?.join(" / "),
                    };

                    const antLookup: { [id: string]: string | undefined } = {
                        "First Evensong": celebration.ep1MagId,
                        Mattins: celebration.mpBenId,
                        Evensong: celebration.ep2MagId,
                        "Second Evensong": celebration.ep2MagId,
                    };

                    const isSuggested =
                        isCurrent &&
                        !celebration.optional &&
                        !celebration.regionNote;

                    return (
                        <>
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    lineHeight: 1.2,
                                    paddingTop: 6,
                                    paddingBottom: 6,
                                }}
                            >
                                <div
                                    style={{
                                        alignSelf: "stretch",
                                        width: 8,
                                        minWidth: 8,
                                        backgroundColor: liturgicalColor,
                                        borderRadius: 2,
                                        boxShadow:
                                            "inset 0 0 0 1px rgba(0,0,0,0.2)",
                                    }}
                                ></div>
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "flex-start",
                                        paddingLeft: 8,
                                        flexGrow: 1,
                                    }}
                                >
                                    {celebration.regionNote ? (
                                        <div
                                            style={{
                                                color: RUBRIC_COLOR,
                                                fontWeight: "bold",
                                                fontSize: "90%",
                                            }}
                                        >
                                            {celebration.regionNote}:
                                        </div>
                                    ) : (
                                        <></>
                                    )}
                                    <div
                                        style={{
                                            //fontSize: "90%",
                                            fontWeight: "bold",
                                        }}
                                    >
                                        {officeLabel} of
                                    </div>
                                    <div
                                        style={{
                                            fontSize: "110%",
                                        }}
                                    >
                                        <span
                                            style={{
                                                fontWeight: "bold",
                                                // textTransform:
                                                //     celebration.type ===
                                                //         "solemnity" ||
                                                //     celebration.type ===
                                                //         "sunday"
                                                //         ? "uppercase"
                                                //         : undefined,
                                            }}
                                        >
                                            {celebration.title}
                                            <span
                                                style={
                                                    {
                                                        //fontWeight: "normal",
                                                    }
                                                }
                                            >
                                                {celebration.subtitle
                                                    ? `, ${celebration.subtitle}`
                                                    : ""}
                                                {celebration.commonlyCalled
                                                    ? ` (${celebration.commonlyCalled})`
                                                    : ""}
                                            </span>
                                        </span>
                                    </div>
                                    <div style={{ fontSize: "90%" }}>
                                        {celebration.optional ? "Optional" : ""}{" "}
                                        {
                                            {
                                                "optional memorial": "",
                                                memorial: "Memorial",
                                                feast: "Feast",
                                                solemnity: "Solemnity",
                                                sunday: "Sunday",
                                                misc: "",
                                                feria: "Feria",
                                            }[celebration.type]
                                        }
                                        {celebration.optionalInLent
                                            ? " (optional in Lent)"
                                            : ""}
                                    </div>
                                </div>
                                <div style={{ paddingLeft: 8 }}>
                                    <Link
                                        to={`${PRAY_PATH}/${year}/${month}/${date}/${
                                            celebration.label
                                        }/${officeLabel
                                            .toLocaleLowerCase()
                                            .replace(" ", "-")}`}
                                        style={{
                                            textDecoration: "none",
                                            backgroundColor: isSuggested
                                                ? RUBRIC_COLOR
                                                : BACKGROUND_COLOR,
                                            border: `1px solid ${RUBRIC_COLOR}`,
                                            color: isSuggested
                                                ? BACKGROUND_COLOR
                                                : RUBRIC_COLOR,
                                            paddingTop: 6,
                                            paddingRight: 12,
                                            paddingBottom: 7,
                                            paddingLeft: 12,
                                            borderRadius: 4,
                                            boxShadow:
                                                "0 2px 4px rgba(0, 0, 0, 0.1)",
                                            textTransform: "uppercase",
                                            fontSize: "85%",
                                            fontWeight: "bold",
                                            letterSpacing: 0.1,
                                        }}
                                    >
                                        Pray
                                    </Link>
                                </div>
                            </div>
                        </>
                    );
                })}
            </div>
            {outranked.length > 0 ? (
                <div style={{ lineHeight: 1.2, fontSize: "90%" }}>
                    Outranked:{" "}
                    {outranked.map((celebration, i) => {
                        let officeLabel = "Mattins";

                        if (isEvening) {
                            if (
                                celebration.type === "sunday" ||
                                celebration.type === "solemnity" ||
                                celebration.type === "feast"
                            ) {
                                if (
                                    isFirstEvensong.find(
                                        (ife) => ife === celebration.label
                                    )
                                ) {
                                    officeLabel = "First Evensong";
                                } else {
                                    officeLabel = "Second Evensong";
                                }
                            } else {
                                officeLabel = "Evensong";
                            }
                        }

                        return (
                            <>
                                <span style={{}}>
                                    <Link
                                        to={`pray/${year}/${month}/${date}/${
                                            celebration.label
                                        }/${officeLabel
                                            .toLocaleLowerCase()
                                            .replace(" ", "-")}`}
                                        style={{
                                            fontWeight: 700,
                                            color: "black",
                                            textDecoration: "underline",
                                            textDecorationColor: "#AAA",
                                        }}
                                    >
                                        {celebration.regionNote ? (
                                            <>{celebration.regionNote}: </>
                                        ) : (
                                            <></>
                                        )}
                                        {isFirstEvensong.find(
                                            (ife) => ife === celebration.label
                                        ) ? (
                                            <>Eve of </>
                                        ) : (
                                            <></>
                                        )}
                                        {celebration.title}
                                        {celebration.type === "feast" ? (
                                            " (Feast)"
                                        ) : (
                                            <></>
                                        )}
                                        {celebration.type === "solemnity" ? (
                                            " (Solemnity)"
                                        ) : (
                                            <></>
                                        )}
                                        {celebration.type === "memorial" &&
                                        !celebration.optional ? (
                                            " (Memorial)"
                                        ) : (
                                            <></>
                                        )}
                                    </Link>

                                    {i < outranked.length - 2
                                        ? ", "
                                        : i === outranked.length - 2
                                        ? ", and "
                                        : ""}
                                </span>
                            </>
                        );
                    })}
                </div>
            ) : (
                <></>
            )}
        </>
    );
};
