import React, { useEffect, useRef, useState } from "react";
import { CelebrationList } from "./CelebrationList";
import { getMorningAndEveningCelebrations } from "./calendar/ordinariate-calendar";

import { useLocalStorage } from "usehooks-ts";
import { Padding } from "./Padding";
import { HORIZONTAL_RULE_COLOR, RUBRIC_COLOR } from "./tokens";

export const Home: React.FC<{}> = () => {
    const [date, setDate] = useState(new Date());

    const [dateOffset, setDateOffset] = useState(0);

    const isDev = window.location.hostname === "localhost";

    const isAtBackwardLimit = !isDev && dateOffset <= -1;
    const isAtForwardLimit = !isDev && dateOffset >= 8;

    const [transferEpiphany, setTransferEpiphany] = useLocalStorage(
        "transfer-epiphany",
        "no"
    );
    const isEpiphanyTransferred = transferEpiphany === "yes";

    const [transferAscension, setTransferAscension] = useLocalStorage(
        "transfer-ascension",
        "no"
    );
    const isAscensionTransferred = transferAscension === "yes";

    const [transferCorpusChristi, setTransferCorpusChristi] = useLocalStorage(
        "transfer-corpus",
        "no"
    );
    const isCorpusChristiTransferred = transferCorpusChristi === "yes";

    const year = getMorningAndEveningCelebrations(
        date.getFullYear(),
        isEpiphanyTransferred,
        isAscensionTransferred,
        isCorpusChristiTransferred
    );

    const todayIndex = year.findIndex(
        (day) =>
            day.date === new Date().getDate() &&
            day.month === new Date().getMonth() + 1 &&
            day.year === new Date().getFullYear()
    );

    const tabDays = year.slice(todayIndex - 1, todayIndex + 10);

    const selectedDayIndex = year.findIndex(
        (day) =>
            day.date === date.getDate() &&
            day.month === date.getMonth() + 1 &&
            day.year === date.getFullYear()
    );
    const selectedDay = year[selectedDayIndex];

    const untransferredEipiphanyDay = tabDays.find(
        (day) => day.date === 6 && day.month === 1
    );

    const transferredEpiphanyDay = tabDays.find(
        (day) =>
            day.date >= 2 &&
            day.date <= 8 &&
            day.month === 1 &&
            day.day === "sun"
    );

    const showEpiphanySwitch =
        (untransferredEipiphanyDay || transferredEpiphanyDay) &&
        untransferredEipiphanyDay !== transferredEpiphanyDay;

    const easterSixIndex = year.findIndex(
        (day) =>
            day.morningOptions
                .concat(day.morningOutranked)
                .find(
                    (celebration) =>
                        celebration.title === "The Sixth Sunday of Easter"
                ) !== undefined
    );

    const untransferredAscensionDayIndex = year.findIndex(
        (day) =>
            day.morningOptions
                .concat(day.morningOutranked)
                .find(
                    (celebration) =>
                        celebration.title === "The Ascension of the Lord" ||
                        celebration.title === "Rogation Thursday"
                ) !== undefined
    );
    const untransferredAscensionDay = year[untransferredAscensionDayIndex];

    const transferredAscensionDayIndex = year.findIndex(
        (day) =>
            day.morningOptions
                .concat(day.morningOutranked)
                .find(
                    (celebration) =>
                        celebration.title === "The Seventh Sunday of Easter"
                ) !== undefined
    );
    const transferredAscensionDay = year[transferredAscensionDayIndex];

    let showAscensionSwitch =
        selectedDayIndex >= easterSixIndex + 1 &&
        selectedDayIndex <= transferredAscensionDayIndex;
    showAscensionSwitch = false; // TODO: support transferring

    const trinityOneIndex = year.findIndex(
        (day) =>
            day.morningOptions
                .concat(day.morningOutranked)
                .find(
                    (celebration) =>
                        celebration.title === "The First Sunday after Trinity"
                ) !== undefined
    );
    const transferredCorpusChristi = year[trinityOneIndex];

    const untransferredCorpusChristiIndex = year.findIndex(
        (day) =>
            day.morningOptions
                .concat(day.morningOutranked)
                .find(
                    (celebration) =>
                        celebration.title ===
                        "Thursday following Trinity Sunday"
                ) !== undefined
    );
    const untransferredCorpusChristi = year[untransferredCorpusChristiIndex];

    let showCorpusChristiSwitch =
        selectedDayIndex >= untransferredCorpusChristiIndex - 1 &&
        selectedDayIndex <= trinityOneIndex;

    const mailRef = useRef<HTMLAnchorElement>(null);

    useEffect(() => {
        if (mailRef.current) {
            mailRef.current.href =
                "mailto:" + ["contact", "singtheoffice.com"].join("@");
        }
    }, []);

    if (!selectedDay) {
        return <></>;
    }

    return (
        <>
            <Padding onlySides>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        paddingBottom: 24,
                        paddingTop: 32,
                    }}
                >
                    <img
                        src={`${process.env.PUBLIC_URL}/logo.svg`}
                        alt="App logo"
                        style={{ width: 140, maxWidth: "100%" }}
                    />
                </div>
                <div
                    style={{
                        textTransform: "uppercase",
                        fontSize: 40,
                        color: RUBRIC_COLOR,
                        lineHeight: 0.8,
                        textAlign: "center",
                    }}
                >
                    Daily Office Online
                </div>
                <div
                    style={{
                        fontSize: 16,
                        paddingBottom: 32,
                        textAlign: "center",
                    }}
                >
                    according to Divine Worship: Daily Office (Commonwealth
                    Edition)
                </div>

                {showEpiphanySwitch ? (
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                            paddingBottom: 32,
                        }}
                    >
                        <div
                            style={{
                                padding: 8,
                                paddingRight: 10,
                                border: `1px solid ${HORIZONTAL_RULE_COLOR}`,
                                boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                                borderRadius: 4,
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        >
                            <input
                                type="checkbox"
                                id="scales"
                                name="scales"
                                checked={isEpiphanyTransferred}
                                onClick={() =>
                                    setTransferEpiphany(
                                        isEpiphanyTransferred ? "no" : "yes"
                                    )
                                }
                            />
                            <label htmlFor="scales" style={{ paddingTop: 3 }}>
                                Transfer Epiphany to Sunday
                            </label>
                        </div>
                    </div>
                ) : (
                    <></>
                )}

                {showAscensionSwitch ? (
                    <div style={{ paddingTop: 24 }}>
                        <div
                            style={{
                                border: `1px solid ${RUBRIC_COLOR}`,
                                padding: 8,
                                boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                                borderRadius: 4,
                            }}
                        >
                            <div style={{ fontWeight: 700 }}>
                                Celebrate the Ascension of the Lord on:
                            </div>

                            <div className="radio">
                                <label>
                                    <input
                                        type="radio"
                                        checked={!isAscensionTransferred}
                                        onClick={() =>
                                            setTransferAscension("no")
                                        }
                                    />
                                    {untransferredAscensionDay?.day[0].toLocaleUpperCase()}
                                    {untransferredAscensionDay?.day.slice(1)}{" "}
                                    {untransferredAscensionDay?.date}{" "}
                                    {
                                        [
                                            "",
                                            "January",
                                            "Februrary",
                                            "March",
                                            "April",
                                            "May",
                                            "June",
                                            "July",
                                            "August",
                                            "September",
                                            "October",
                                            "November",
                                            "December",
                                        ][untransferredAscensionDay?.month ?? 0]
                                    }
                                </label>
                            </div>
                            <div className="radio">
                                <label>
                                    <input
                                        type="radio"
                                        checked={isAscensionTransferred}
                                        onClick={() =>
                                            setTransferAscension("yes")
                                        }
                                    />
                                    {transferredAscensionDay?.day[0].toLocaleUpperCase()}
                                    {transferredAscensionDay?.day.slice(1)}{" "}
                                    {transferredAscensionDay?.date}{" "}
                                    {
                                        [
                                            "",
                                            "January",
                                            "Februrary",
                                            "March",
                                            "April",
                                            "May",
                                            "June",
                                            "July",
                                            "August",
                                            "September",
                                            "October",
                                            "November",
                                            "December",
                                        ][transferredAscensionDay?.month ?? 0]
                                    }
                                </label>
                            </div>
                        </div>
                    </div>
                ) : (
                    <></>
                )}
                {showCorpusChristiSwitch ? (
                    <div style={{ paddingTop: 24 }}>
                        <div
                            style={{
                                border: `1px solid ${RUBRIC_COLOR}`,
                                padding: 8,
                                boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                                borderRadius: 4,
                            }}
                        >
                            <div style={{ fontWeight: 700 }}>
                                Celebrate Corpus Christi on:
                            </div>

                            <div className="radio">
                                <label>
                                    <input
                                        type="radio"
                                        checked={!isCorpusChristiTransferred}
                                        onClick={() =>
                                            setTransferCorpusChristi("no")
                                        }
                                    />
                                    {untransferredCorpusChristi?.day[0].toLocaleUpperCase()}
                                    {untransferredCorpusChristi?.day.slice(1)}{" "}
                                    {untransferredCorpusChristi?.date}{" "}
                                    {
                                        [
                                            "",
                                            "January",
                                            "Februrary",
                                            "March",
                                            "April",
                                            "May",
                                            "June",
                                            "July",
                                            "August",
                                            "September",
                                            "October",
                                            "November",
                                            "December",
                                        ][
                                            untransferredCorpusChristi?.month ??
                                                0
                                        ]
                                    }
                                </label>
                            </div>
                            <div className="radio">
                                <label>
                                    <input
                                        type="radio"
                                        checked={isCorpusChristiTransferred}
                                        onClick={() =>
                                            setTransferCorpusChristi("yes")
                                        }
                                    />
                                    {transferredCorpusChristi?.day[0].toLocaleUpperCase()}
                                    {transferredCorpusChristi?.day.slice(1)}{" "}
                                    {transferredCorpusChristi?.date}{" "}
                                    {
                                        [
                                            "",
                                            "January",
                                            "Februrary",
                                            "March",
                                            "April",
                                            "May",
                                            "June",
                                            "July",
                                            "August",
                                            "September",
                                            "October",
                                            "November",
                                            "December",
                                        ][transferredCorpusChristi?.month ?? 0]
                                    }
                                </label>
                            </div>
                        </div>
                    </div>
                ) : (
                    <></>
                )}
            </Padding>
            <div
                style={{
                    overflow: "auto",
                    paddingBottom: 12,
                }}
            >
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "stretch",
                    }}
                >
                    {tabDays.map((day) => {
                        const isSelectedDay =
                            day.date === selectedDay.date &&
                            day.month === selectedDay.month;

                        return (
                            <div
                                style={{
                                    overflow: "hidden",
                                    maxWidth: 110,
                                    minWidth: 110,
                                    paddingTop: isSelectedDay ? 3 : 4,
                                    paddingLeft: isSelectedDay ? 7 : 8,
                                    paddingRight: isSelectedDay ? 7 : 8,
                                    paddingBottom: isSelectedDay ? 9 : 8,
                                    borderTopLeftRadius: 3,
                                    borderTopRightRadius: 3,
                                    borderLeft: isSelectedDay
                                        ? `1px solid ${HORIZONTAL_RULE_COLOR}`
                                        : undefined,
                                    borderTop: isSelectedDay
                                        ? `1px solid ${HORIZONTAL_RULE_COLOR}`
                                        : undefined,
                                    borderRight: isSelectedDay
                                        ? `1px solid ${HORIZONTAL_RULE_COLOR}`
                                        : undefined,
                                    borderBottom: !isSelectedDay
                                        ? `1px solid ${HORIZONTAL_RULE_COLOR}`
                                        : undefined,
                                    cursor: "pointer",
                                    boxShadow: isSelectedDay
                                        ? undefined
                                        : "inset 0 -8px 8px -8px rgba(0, 0, 0, 0.1)",
                                }}
                                className={
                                    isSelectedDay ? undefined : "date-tab"
                                }
                                onClick={() => {
                                    setDate(
                                        new Date(
                                            day.year,
                                            day.month - 1,
                                            day.date
                                        )
                                    );
                                }}
                            >
                                <div
                                    style={{
                                        fontWeight: "bold",
                                        fontSize: "80%",
                                        whiteSpace: "pre",
                                    }}
                                >
                                    {day.day[0].toLocaleUpperCase()}
                                    {day.day.slice(1, 3)} {day.date}{" "}
                                    {
                                        [
                                            "",
                                            "January",
                                            "February",
                                            "March",
                                            "April",
                                            "May",
                                            "June",
                                            "July",
                                            "August",
                                            "September",
                                            "October",
                                            "November",
                                            "December",
                                        ][day.month]
                                    }
                                </div>
                                <div
                                    style={{
                                        fontSize: "75%",
                                        lineHeight: 1.1,
                                    }}
                                >
                                    {
                                        day.morningOptions
                                            .filter(
                                                (opt) =>
                                                    !opt.regionNote &&
                                                    !opt.optional
                                            )
                                            .map((opt) => (
                                                <div
                                                    style={{
                                                        textTransform:
                                                            opt.type ===
                                                                "sunday" ||
                                                            opt.type ===
                                                                "solemnity"
                                                                ? "uppercase"
                                                                : undefined,
                                                        fontVariant:
                                                            opt.type === "feast"
                                                                ? "small-caps"
                                                                : undefined,
                                                        fontStyle:
                                                            opt.type ===
                                                                "memorial" &&
                                                            opt.optional
                                                                ? "italic"
                                                                : undefined,
                                                    }}
                                                >
                                                    {opt.type === "feria"
                                                        ? "Feria"
                                                        : (
                                                              opt.shortTitle ??
                                                              opt.title
                                                          )
                                                              .replace(
                                                                  /Saint /g,
                                                                  "St "
                                                              )
                                                              .replace(
                                                                  /^Blessed /,
                                                                  "Bl "
                                                              )
                                                              .replace(
                                                                  /Saints /g,
                                                                  "Sts "
                                                              )}
                                                </div>
                                            ))[0]
                                    }
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
            <Padding>
                <>
                    {selectedDay.transferNotes.map((transferNote) => {
                        return (
                            <div
                                style={{
                                    color: RUBRIC_COLOR,
                                    textAlign: "center",
                                }}
                            >
                                {transferNote}
                            </div>
                        );
                    })}
                </>
                <div
                    style={{
                        paddingTop: 24,
                        fontWeight: "bold",
                        textAlign: "center",
                        fontSize: "120%",
                    }}
                >
                    Morning Prayer
                </div>
                <CelebrationList
                    year={date.getFullYear()}
                    month={date.getMonth() + 1}
                    date={date.getDate()}
                    options={selectedDay.morningOptions}
                    outranked={selectedDay.morningOutranked}
                    isFirstEvensong={[]}
                    isEvening={false}
                />
                <div
                    style={{
                        paddingTop: 24,
                        fontWeight: "bold",
                        textAlign: "center",
                        fontSize: "120%",
                    }}
                >
                    Evening Prayer
                </div>
                <CelebrationList
                    year={date.getFullYear()}
                    month={date.getMonth() + 1}
                    date={date.getDate()}
                    options={selectedDay.eveningOptions}
                    outranked={selectedDay.eveningOutranked}
                    isFirstEvensong={selectedDay.isFirstEvensong}
                    isEvening={true}
                />

                {selectedDay.morningOptions[0].title ===
                "Thursday in Holy Week" ? (
                    <div
                        style={{
                            color: RUBRIC_COLOR,
                            paddingTop: 24,
                            fontWeight: "bold",
                            textAlign: "center",
                        }}
                    >
                        Note that the liturgical colour at the Office on Maundy
                        Thursday is purple, not white.
                    </div>
                ) : (
                    <></>
                )}
                <a
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        paddingTop: 48,
                    }}
                    href="https://www.ordinariate.org.au/"
                    target="_blank"
                >
                    <img
                        src={`${process.env.PUBLIC_URL}/olsc-logo-large.png`}
                        alt="App logo"
                        style={{ height: 96, maxWidth: "100%" }}
                    />
                </a>
            </Padding>
        </>
    );
};
