import { ORD_SAINTS } from "./ord-saints";

type DayOfTheWeek = "sun" | "mon" | "tues" | "wed" | "thur" | "fri" | "sat";

const DAYS_OF_THE_WEEK: DayOfTheWeek[] = [
    "sun",
    "mon",
    "tues",
    "wed",
    "thur",
    "fri",
    "sat",
];

export interface Common {
    ep1HymnId?: string;
    ep1VandR?: string[];
    ep1MagId?: string;
    invId?: string;
    mpHymnId?: string;
    mpVandR?: string[];
    mpBenId?: string;
    ep2HymnId?: string;
    ep2VandR?: string[];
    ep2MagId?: string;
    easterOverrides?: Common;
    propersOfTheFeria?: boolean;
    properPsalmsOptional?: boolean;
}

export interface Celebration {
    label: string;
    type:
        | "optional memorial"
        | "memorial"
        | "feast"
        | "solemnity"
        | "sunday"
        | "misc"
        | "feria";
    rank: number;
    title: string;
    subtitle?: string;
    commonlyCalled?: string;
    optional?: boolean;
    regionNote?: string;
    notOutrankedByInTheMorning?: string[];
    notOutrankedByInTheEvening?: string[];
    operatesAsSunday?: boolean;
    shortTitle?: string;
    deletesFeria?: boolean;
    deletesEve?: boolean;
    optionalInLent?: boolean;
    doesntHaveFirstEvensong?: boolean;
    collect?: string;
    alternativeCollects?: string[];
    ep1HymnId?: string;
    ep1VandR?: string[];
    ep1MagId?: string;
    invId?: string;
    mpHymnId?: string;
    mpVandR?: string[];
    mpBenId?: string;
    ep2HymnId?: string;
    ep2VandR?: string[];
    ep2MagId?: string;
    weekdays?: {
        invId?: string;
        benId?: string;
        magId?: string;
        collect?: string;
        alternativeCollects?: string[];
        emberDay?: boolean;
        title?: string;
        mpHymnId?: string;
        mpVandR?: string[];
        ep2HymnId?: string;
        ep2VandR?: string[];
        rank?: number;
        mpProperPsalms?: string[];
        epProperPsalms?: string[];
    }[];
    ep1ProperPsalms?: string[];
    mpProperPsalms?: string[];
    ep2ProperPsalms?: string[];
    properPsalmsOptional?: boolean;
    athanasianCreed?: boolean;
    doesntHaveSeconeEvensong?: boolean;
    sundayAfterEpiphany?: boolean;
    sundayAfterTrinity?: boolean;
    sundayAfterEaster?: boolean;
    sundayInLent?: boolean;
    sundayInAdvent?: boolean;
    commons?: string[];
    propersOfTheFeria?: boolean;
    feastHasFirstEvensong?: boolean;
    specialManner?: boolean;
}

export interface CalendarDay {
    date: number;
    month: number;
    year: number;
    dayOfTheWeek: DayOfTheWeek;
    weekOfTheYear: number;
    dayOfTheYear: number;
    celebrations: Celebration[];
    transferNotes: string[];
}

export interface BlackLetterDay {
    id: string;
    title: string;
    optional?: boolean;
}

export interface Calendar {
    year: number;
    days: CalendarDay[];
}

export type PrayerHour = "mattins" | "evensong" | "compline";

const getEaster = function Easter(Y: number) {
    var C = Math.floor(Y / 100);
    var N = Y - 19 * Math.floor(Y / 19);
    var K = Math.floor((C - 17) / 25);
    var I = C - Math.floor(C / 4) - Math.floor((C - K) / 3) + 19 * N + 15;
    I = I - 30 * Math.floor(I / 30);
    I =
        I -
        Math.floor(I / 28) *
            (1 -
                Math.floor(I / 28) *
                    Math.floor(29 / (I + 1)) *
                    Math.floor((21 - N) / 11));
    var J = Y + Math.floor(Y / 4) + I + 2 - C + Math.floor(C / 4);
    J = J - 7 * Math.floor(J / 7);
    var L = I - J;
    var M = 3 + Math.floor((L + 40) / 44);
    var D = L + 28 - 31 * Math.floor(M / 4);

    return { date: D, month: M };
};

const createCalendar = (year: number) => {
    let day = new Date(`January 1, ${year}`);

    const calendar: Calendar = {
        year,
        days: [],
    };

    let weekOfTheYear = 0;
    let dayOfTheYear = 0;
    while (day.getFullYear() === year) {
        if (DAYS_OF_THE_WEEK[day.getDay()] === "sun") {
            weekOfTheYear++;
        }

        calendar.days.push({
            year,
            date: day.getDate(),
            dayOfTheWeek: DAYS_OF_THE_WEEK[day.getDay()],
            month: day.getMonth() + 1,
            weekOfTheYear,
            dayOfTheYear,
            celebrations: [],
            transferNotes: [],
        });

        // Increment the day
        day = new Date(day);
        day.setDate(day.getDate() + 1);
        dayOfTheYear++;
    }

    return calendar;
};

const getDateMethods = (
    calendar: Calendar,
    date: number,
    month: number,
    skip: boolean
) => {
    return {
        placeCelebration: (
            c: Celebration | ((day: CalendarDay) => Celebration)
        ) => {
            const day = calendar.days.find(
                (day) => day.date === date && day.month === month
            );

            if (day !== undefined) {
                let celebration: Celebration;

                if (typeof c === "function") {
                    celebration = c(day);
                } else {
                    celebration = c;
                }

                if (celebration.sundayAfterEpiphany) {
                    celebration = {
                        ...{
                            ep1HymnId: "creator of the earth and sky",
                            ep1VandR: [
                                "Let our eve_ning prayer come up be_fore thee, O Lord.",
                                "And let thy mer_cy come down up_on us.",
                            ],
                            invId: "inv sun ep",
                            mpHymnId: "this day the first of days was made",
                            mpVandR: [
                                "The Lord is King, and hath put on glo_rious ap_pa_rel.",
                                "The Lord hath put on his ap_pa_rel, and gir_ded him_self with strength.",
                            ],
                            ep2HymnId: "o blest creator of the light",
                            ep2VandR: [
                                "Let our eve_ning prayer come up be_fore thee, O Lord.",
                                "And let thy mer_cy come down up_on us.",
                            ],
                            weekdays: celebration.weekdays ?? [
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                            ],
                        },
                        ...celebration,
                    };
                }

                if (celebration.sundayAfterTrinity) {
                    celebration = {
                        ...celebration,
                        ...{
                            ep1HymnId: "o trinity of blessed light",
                            ep1VandR: [
                                "Let our eve_ning prayer come up be_fore thee, O Lord.",
                                "And let thy mer_cy come down up_on us.",
                            ],
                            invId: "inv sun trin",
                            mpHymnId:
                                "father we praise thee now the night is over",
                            mpVandR: [
                                "The Lord is King, and hath put on glo_rious ap_pa_rel.",
                                "The Lord hath put on his ap_pa_rel, and gir_ded him_self with strength.",
                            ],
                            ep2HymnId: "o blest creator of the light",
                            ep2VandR: [
                                "Let our eve_ning prayer come up be_fore thee, O Lord.",
                                "And let thy mer_cy come down up_on us.",
                            ],
                            weekdays: celebration.weekdays ?? [
                                {},
                                {},
                                {},
                                {},
                                {},
                                {},
                            ],
                        },
                    };
                }

                if (celebration.sundayInLent) {
                    celebration = {
                        ...celebration,
                        ...{
                            weekdays: (
                                celebration.weekdays ?? [{}, {}, {}, {}, {}, {}]
                            ).map((weekday) => {
                                return {
                                    invId: celebration.invId,
                                    mpHymnId: celebration.mpHymnId,
                                    mpVandR: celebration.mpVandR,
                                    ep2HymnId: celebration.ep2HymnId,
                                    ep2VandR: celebration.ep2VandR,
                                    rank: 9,
                                    ...weekday,
                                };
                            }),
                        },
                    };
                }

                if (celebration.sundayInAdvent) {
                    celebration = {
                        ...celebration,
                        ...{
                            weekdays: (
                                celebration.weekdays ?? [{}, {}, {}, {}, {}, {}]
                            ).map((weekday) => {
                                return {
                                    invId: "ad1 the lord the king ferias",
                                    mpHymnId:
                                        "high word of god who once didst come",
                                    mpVandR: [
                                        "The voice of one cry_ing in the wil_der_ness: Pre_pare ye the way of the Lord.",
                                        "Make straight the paths of our God.",
                                    ],
                                    ep2HymnId: "creator of the stars of night",
                                    ep2VandR: [
                                        "Drop down, ye hea_vens, from a_bove, and let the skies pour down right_eous_ness.",
                                        "Let the earth o_pen, and let it bring forth sal_va_tion.",
                                    ],
                                    rank:
                                        weekday.rank ??
                                        weekday.title?.includes("December")
                                            ? 9
                                            : 13,
                                    ...weekday,
                                };
                            }),
                        },
                    };
                }

                if (celebration.sundayAfterEaster) {
                    celebration = {
                        ...celebration,
                        ...{
                            weekdays: (
                                celebration.weekdays ?? [{}, {}, {}, {}, {}, {}]
                            ).map((weekday) => {
                                return {
                                    invId: celebration.invId,
                                    mpHymnId: celebration.mpHymnId,
                                    mpVandR: celebration.mpVandR,
                                    ep2HymnId: celebration.ep2HymnId,
                                    ep2VandR: celebration.ep2VandR,
                                    mpBenId:
                                        weekday.benId ?? celebration.mpBenId,
                                    ep2MagId:
                                        weekday.magId ?? celebration.ep2MagId,
                                    ...weekday,
                                };
                            }),
                        },
                    };
                }

                if (
                    celebration.label === "lent-5" ||
                    celebration.label === "palm-sunday"
                ) {
                    celebration = {
                        ...celebration,
                        ...{
                            weekdays: celebration.weekdays?.map(
                                (weekday, index) => {
                                    return {
                                        invId: celebration.invId,
                                        mpHymnId:
                                            index % 2 ===
                                            (celebration.label === "lent-5"
                                                ? 1
                                                : 0)
                                                ? "sing my tongue the glorious battle"
                                                : "thirty years among us dwelling",
                                        mpVandR: celebration.mpVandR,
                                        ep2HymnId:
                                            "the royal banners forward go",
                                        ep2VandR: celebration.ep2VandR,
                                        ...weekday,
                                    };
                                }
                            ),
                        },
                    };
                }

                day.celebrations.push(celebration);

                const dayIndex = calendar.days.findIndex(
                    (day) => day.date === date && day.month === month
                );

                const weekdayNames = [
                    "Monday",
                    "Tuesday",
                    "Wednesday",
                    "Thursday",
                    "Friday",
                    "Saturday",
                ];

                const weekdayRank = celebration.title.includes("Advent")
                    ? 13
                    : celebration.title.includes("Lent") ||
                      celebration.title.includes("Palm Sunday") ||
                      celebration.title.includes("Holy Week")
                    ? 13
                    : 13.1;

                celebration.weekdays?.forEach((weekday, weekdayIndex) => {
                    let inTheWeekOf = "";

                    if (celebration.title?.includes("Sunday of Advent")) {
                        inTheWeekOf = celebration.title
                            ?.replace("The ", "")
                            .replace("Sunday of Advent", " Week of Advent");
                    }

                    if (celebration.title?.includes("Sunday in Lent")) {
                        inTheWeekOf = celebration.title
                            ?.replace("The ", "")
                            .replace("Sunday in Lent", " Week of Lent");
                    }

                    if (celebration.title?.includes("Sunday of Easter")) {
                        inTheWeekOf = celebration.title
                            ?.replace("The ", "")
                            .replace("Sunday of Easter", " Week of Easter");
                    }

                    const defaultName =
                        inTheWeekOf.length > 0
                            ? `${weekdayNames[weekdayIndex]} in the ${inTheWeekOf}`
                            : `${weekdayNames[weekdayIndex]} following ${
                                  celebration.shortTitle ?? celebration.title
                              }`;

                    calendar.days[
                        dayIndex + weekdayIndex + 1
                    ]?.celebrations.push({
                        type:
                            weekday.title === "Ash Wednesday"
                                ? "misc"
                                : "feria",
                        deletesFeria: weekday.title === "Ash Wednesday",
                        title: weekday.title ?? defaultName,
                        rank:
                            weekday.rank ??
                            (weekday.emberDay ? 10 : weekdayRank),
                        label: `${celebration.label}-${weekdayNames[
                            weekdayIndex
                        ].toLocaleLowerCase()}`,
                        collect: weekday.collect ?? celebration.collect,
                        alternativeCollects:
                            weekday.alternativeCollects ??
                            celebration.alternativeCollects,
                        invId:
                            weekday.invId ??
                            [
                                "inv mon",
                                "inv tues",
                                "inv wed",
                                "inv thur",
                                "inv fri",
                                "inv sat",
                            ][weekdayIndex],
                        mpBenId: weekday.benId ?? celebration.mpBenId,
                        ep2MagId: weekday.magId ?? celebration.ep2MagId,
                        mpHymnId:
                            weekday.mpHymnId ??
                            [
                                "o splendour of gods glory bright",
                                "the winged herald of the day",
                                "ye clouds and darkness hosts of night",
                                "lo golden light rekindles day",
                                "eternal glory of the sky",
                                "the dawn is sprinkling in the east",
                            ][weekdayIndex],
                        mpVandR: weekday.mpVandR ?? [
                            "O sa_tis_fy us with thy mer_cy.",
                            "That we may re_joice and be glad.",
                        ],
                        ep2HymnId:
                            weekday.ep2HymnId ??
                            [
                                "o boundless wisdom god most high",
                                "earths mighty maker whose command",
                                "most holy lord and god of heaven",
                                "almighty god who from the flood",
                                "maker of man who from thy throne",
                                celebration.label.includes("trinity")
                                    ? "o trinity of blessed light"
                                    : "creator of the earth and sky",
                            ][weekdayIndex],
                        ep2VandR: weekday.ep2VandR ?? [
                            "Let our eve_ning prayer come up be_fore thee, O Lord.",
                            "And let thy mer_cy come down up_on us.",
                        ],
                        mpProperPsalms: weekday.mpProperPsalms,
                        ep2ProperPsalms: weekday.epProperPsalms,
                    });
                });

                // if (
                //     celebration.type === "sunday" ||
                //     celebration.operatesAsSunday
                // ) {
                //     const dayIndex = calendar.days.findIndex(
                //         (day) => day.date === date && day.month === month
                //     );

                //     const weekdayRank = celebration.title.includes("Advent")
                //         ? 13
                //         : celebration.title.includes("Lent")
                //         ? 13
                //         : 13.1;

                //     const monday = calendar.days[dayIndex + 1];
                //     monday?.celebrations.push({
                //         type: "feria",
                //         title: `Monday following ${
                //             celebration.shortTitle ?? celebration.title
                //         }`,
                //         rank: weekdayRank,
                //         label: `${celebration.label}-monday`,
                //     });

                //     const tuesday = calendar.days[dayIndex + 2];
                //     tuesday?.celebrations.push({
                //         type: "feria",
                //         title: `Tuesday following ${
                //             celebration.shortTitle ?? celebration.title
                //         }`,
                //         rank: weekdayRank,
                //         label: `${celebration.label}-tuesday`,
                //     });

                //     const wednesday = calendar.days[dayIndex + 3];
                //     wednesday?.celebrations.push({
                //         type: "feria",
                //         title: `Wednesday following ${
                //             celebration.shortTitle ?? celebration.title
                //         }`,
                //         rank: weekdayRank,
                //         label: `${celebration.label}-wednesday`,
                //     });

                //     const thursday = calendar.days[dayIndex + 4];
                //     thursday?.celebrations.push({
                //         type: "feria",
                //         title: `Thursday following ${
                //             celebration.shortTitle ?? celebration.title
                //         }`,
                //         rank: weekdayRank,
                //         label: `${celebration.label}-thursday`,
                //     });

                //     const friday = calendar.days[dayIndex + 5];
                //     friday?.celebrations.push({
                //         type: "feria",
                //         title: `Friday following ${
                //             celebration.shortTitle ?? celebration.title
                //         }`,
                //         rank: weekdayRank,
                //         label: `${celebration.label}-friday`,
                //     });

                //     const saturday = calendar.days[dayIndex + 6];
                //     saturday?.celebrations.push({
                //         type: "feria",
                //         title: `Saturday following ${
                //             celebration.shortTitle ?? celebration.title
                //         }`,
                //         rank: weekdayRank,
                //         label: `${celebration.label}-saturday`,
                //     });

                //     saturday?.celebrations.push({
                //         type: "memorial",
                //         title: `Saint Mary on Saturday`,
                //         rank: weekdayRank - 1,
                //         optional: true,
                //         label: `saint-mary-on-saturday`,
                //     });
                // }
            }

            if (day) {
                return getDateMethods(calendar, day.date, day.month, skip);
            } else {
                return getDateMethods(calendar, 0, 0, true);
            }
        },
        plus: (amount: number, unit: "weeks" | "days") => {
            const startIndex = calendar.days.findIndex(
                (day) => day.date === date && day.month === month
            );

            const endIndex = startIndex + amount * (unit === "weeks" ? 7 : 1);
            const day = calendar.days[endIndex];

            if (day) {
                return getDateMethods(calendar, day.date, day.month, skip);
            } else {
                return getDateMethods(calendar, 0, 0, true);
            }
        },
        minus: (amount: number, unit: "days" | "weeks") => {
            return getDateMethods(calendar, date, month, skip).plus(
                -amount,
                unit
            );
        },
        getNthAfter: (amount: number, dayOfTheWeek: DayOfTheWeek) => {
            let index = calendar.days.findIndex(
                (day) => day.date === date && day.month === month
            );

            while (amount !== 0) {
                index++;

                if (!calendar.days[index]) {
                    return getDateMethods(calendar, 0, 0, true);
                }

                if (calendar.days[index].dayOfTheWeek === dayOfTheWeek) {
                    amount--;
                }
            }

            const day = calendar.days[index];

            return getDateMethods(calendar, day.date, day.month, skip);
        },
        getNthBefore: (amount: number, dayOfTheWeek: DayOfTheWeek) => {
            let index = calendar.days.findIndex(
                (day) => day.date === date && day.month === month
            );

            while (amount !== 0) {
                index--;

                if (!calendar.days[index]) {
                    return getDateMethods(calendar, 0, 0, true);
                }

                if (calendar.days[index].dayOfTheWeek === dayOfTheWeek) {
                    amount--;
                }
            }

            const day = calendar.days[index];

            return getDateMethods(calendar, day.date, day.month, skip);
        },
    };
};

const modify = (calendar: Calendar) => {
    return {
        cal: modify,
        onDate: (date: number, month: number) => {
            return getDateMethods(calendar, date, month, false);
        },
        onCelebration: (label: string) => {
            const day = calendar.days.find(
                (day) =>
                    day.celebrations.find(
                        (celebration) => celebration.label === label
                    ) !== undefined
            );

            if (day) {
                return getDateMethods(calendar, day.date, day.month, false);
            } else {
                return getDateMethods(calendar, 0, 0, true);
            }
        },
    };
};

const getOptionsAndOutranked = (celebrations: Celebration[]) => {
    const addedCelebrations: Celebration[] = [];

    const finalCelebrations = celebrations.concat(addedCelebrations);

    const basicFeria = finalCelebrations.find(
        (celebration) =>
            celebration.type === "feria" && !celebration.deletesFeria
    );

    const highestRankIs9 =
        finalCelebrations.find((celebration) => celebration.rank === 9) !==
            undefined &&
        finalCelebrations.find(
            (celebration) => celebration.rank < 9 && !celebration.regionNote
        ) === undefined;

    const sortedCelebrations =
        finalCelebrations
            .map((celebration) => {
                if (celebration.type === "memorial" && highestRankIs9) {
                    return {
                        ...celebration,
                        optional: true,
                        specialManner: true,
                    };
                } else {
                    return celebration;
                }
            })
            .map((celebration) => {
                if (basicFeria && celebration.deletesFeria) {
                    return {
                        ...basicFeria,
                        ...celebration,
                    };
                } else {
                    return celebration;
                }
            })
            .filter((celebration, celebrationIndex) => {
                if (
                    finalCelebrations
                        .slice(0, celebrationIndex)
                        .find((c) => c.label === celebration.label)
                ) {
                    return false;
                } else {
                    return true;
                }
            })
            .sort((a, b) => a.rank - b.rank) ?? [];

    const outrankedCelebrations: { [label: string]: boolean } = {};
    const deletedCelebrations: { [label: string]: boolean } = {};
    sortedCelebrations.forEach((celebration) => {
        sortedCelebrations.forEach((otherCelebration) => {
            if (
                otherCelebration.rank < celebration.rank &&
                !otherCelebration.optional &&
                !highestRankIs9 &&
                !otherCelebration.regionNote
            ) {
                outrankedCelebrations[celebration.label] = true;
            }

            if (
                otherCelebration.type === "sunday" &&
                celebration.type === "feria"
            ) {
                deletedCelebrations[celebration.label] = true;
            }

            if (
                otherCelebration.type === "sunday" &&
                celebration.type === "sunday" &&
                otherCelebration.rank < celebration.rank
            ) {
                deletedCelebrations[celebration.label] = true;
            }

            if (
                otherCelebration.type === "feria" &&
                celebration.type === "feria" &&
                otherCelebration.rank < celebration.rank
            ) {
                deletedCelebrations[celebration.label] = true;
            }

            if (otherCelebration.deletesFeria && celebration.type === "feria") {
                deletedCelebrations[celebration.label] = true;
            }
        });
    });

    const sortedOutrankedCelebrations = sortedCelebrations.filter(
        (celebration) =>
            !deletedCelebrations[celebration.label] &&
            outrankedCelebrations[celebration.label]
    );

    return {
        options: sortedCelebrations.filter(
            (celebration) =>
                !deletedCelebrations[celebration.label] &&
                !outrankedCelebrations[celebration.label]
        ),
        outranked: sortedOutrankedCelebrations,
    };
};

export const getMorningAndEveningCelebrations = (
    year: number,
    epiphanyTransferred: boolean,
    ascensionTransferred: boolean,
    corpusChristiTransferred: boolean
) => {
    const days = getOrdinariateLiturgicalYear(
        year - 1,
        epiphanyTransferred,
        ascensionTransferred,
        corpusChristiTransferred
    )
        .days.concat(
            getOrdinariateLiturgicalYear(
                year,
                epiphanyTransferred,
                ascensionTransferred,
                corpusChristiTransferred
            ).days
        )
        .concat(
            getOrdinariateLiturgicalYear(
                year + 1,
                epiphanyTransferred,
                ascensionTransferred,
                corpusChristiTransferred
            ).days
        );

    return days.slice(0, days.length - 1).map((day, dayIndex) => {
        const tomorrow = days[dayIndex + 1];

        const isLentenWeekday =
            day.celebrations.find(
                (c) => c.title.includes("Lent") && c.type === "feria"
            ) !== undefined;

        const optionsToday = getOptionsAndOutranked(day.celebrations);
        const optionsTomorrow = getOptionsAndOutranked(tomorrow.celebrations);

        const feastsAndSolemnitiesOptionsTomorrow = optionsTomorrow.options
            .filter(
                (op) =>
                    (op.type === "feast" && op.feastHasFirstEvensong) ||
                    op.type === "solemnity" ||
                    op.type === "sunday"
            )
            .filter((op) => !op.doesntHaveFirstEvensong)
            .map((op) => {
                return { ...op, rank: op.rank + 0.05 };
            });

        const feastsAndSolemnitiesOutrankedTomorrow = optionsTomorrow.outranked
            .filter(
                (op) =>
                    (op.type === "feast" && op.feastHasFirstEvensong) ||
                    op.type === "solemnity" ||
                    op.type === "sunday"
            )
            .filter((op) => !op.doesntHaveFirstEvensong);

        const morningOptions = optionsToday.options;
        const morningOutranked = optionsToday.outranked;

        // BEGIN HACKS FOR PARTICLAR DAYS
        const extraEveningCelebrations: Celebration[] = [];
        if (day.month === 4 && day.date === 24) {
            const feria = day.celebrations.find((c) => c.type === "feria");

            if (feria) {
                extraEveningCelebrations.push({
                    ...feria,
                    label: `${feria.label}-australia`,
                    regionNote: "Australia",
                    rank: 7,
                });
            }
        }

        if (day.month === 4 && day.date === 25) {
            const ep1StMark = feastsAndSolemnitiesOptionsTomorrow.find(
                (c) => c.label === "saint-mark-australia"
            );
            if (ep1StMark) {
                ep1StMark.rank -= 0.1;
            }
        }
        // END HACKS FOR PARTICULAR DAYS

        const eveningOptionsAndOutranked = getOptionsAndOutranked(
            optionsToday.options
                .concat(feastsAndSolemnitiesOptionsTomorrow)
                .concat(extraEveningCelebrations)
        );

        const eveningOptions = eveningOptionsAndOutranked.options
            .filter(
                (c) =>
                    c.label !== "saint-mary-on-saturday" &&
                    c.label !== "anzac-day-australia" &&
                    c.label !== "saturday-after-whitsun"
            )
            .filter((c) => !c.doesntHaveSeconeEvensong);
        const eveningOutranked =
            eveningOptions.find((option) => option.deletesEve) !== undefined
                ? []
                : eveningOptionsAndOutranked.outranked
                      .concat(morningOutranked)
                      .concat(feastsAndSolemnitiesOutrankedTomorrow)
                      .filter(
                          (c) =>
                              c.label !== "saint-mary-on-saturday" &&
                              c.label !== "anzac-day-australia" &&
                              c.label !== "saturday-after-whitsun"
                      );

        return {
            year: day.year,
            day: day.dayOfTheWeek,
            date: day.date,
            month: day.month,
            morningOptions,
            morningOutranked,
            eveningOptions,
            eveningOutranked,
            isFirstEvensong: feastsAndSolemnitiesOptionsTomorrow.map(
                (c) => c.label
            ),
            transferNotes: day.transferNotes,
        };
    });
};

export const getOrdinariateLiturgicalYear = (
    year: number,
    epiphanyTransferred: boolean,
    ascensionTransferred: boolean,
    corpusChristiTransferred: boolean
) => {
    const calendar = createCalendar(year);

    // The end of Christmas until Ephipany Day
    modify(calendar)
        .onDate(1, 1)
        .placeCelebration({
            label: "mary-mother-of-god",
            type: "solemnity",
            rank: 3,
            title: "Mary, the Holy Mother of God",
            collect:
                "O God, who by the fruitful virginity of Blessed Mary hast bestowed upon mankind the reward of eternal salvation: grant, we beseech thee, that we may know the help of her intercession, through whom we have been accounted worthy to receive the Author of our life, Jesus Christ thy Son our Lord; who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
            ep1HymnId: "from east to west from shore to shore",
            ep1VandR: [
                "The Word was made flesh, al_le_lu_ia.",
                "And dwelt a_mong us, al_le_lu_ia.",
            ],
            ep1MagId: "mmg for his great love",
            invId: "nl unto us the christ is born",
            mpHymnId: "o happy day when first was poured",
            mpVandR: [
                "The Word was made flesh, al_le_lu_ia.",
                "And dwelt a_mong us, al_le_lu_ia.",
            ],
            mpBenId: "mmg a great and wondrous",
            ep2HymnId: "conquering kings their titles take",
            ep2VandR: [
                "The Lord hath made known, al_le_lu_ia.",
                "His sal_va_tion, al_le_lu_ia.",
            ],
            ep2MagId: "mmg heirs are we",
        });

    const showEpiphanySwitch =
        calendar.days.find((d) => d.date === 6 && d.month === 1)
            ?.dayOfTheWeek !== "sun";

    const isEpiphanyTransferred = showEpiphanySwitch && epiphanyTransferred;

    const baptismCelebration: Celebration = {
        label: "baptism-of-the-lord",
        type: "feast",
        rank: 5,
        title: "The Baptism of the Lord",
        feastHasFirstEvensong: true,
        collect:
            "Heavenly Father, whose blessed Son Jesus Christ did take our nature upon him, and was baptised for our sakes in the river Jordan: mercifully grant that we, being regenerate and made thy children by adoption and grace, may also be partakers of thy Holy Spirit; through the same Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        ep1HymnId: "why impious herod shouldst thou fear",
        ep1VandR: [
            "The kings of Thar_sis and of the isles shall give pre_sents.",
            "The kings of A_ra_bi_a and Sa_ba shall bring gifts.",
        ],
        ep1MagId: "botl the soldier",
        invId: "the christ hath been",
        mpHymnId: "sent down by god to this worlds frame",
        mpVandR: [
            "O wor_ship the Lord, al_le_lu_ia.",
            "All ye an_gels of his, al_le_lu_ia.",
        ],
        mpBenId: "botl john the",
        ep2HymnId: "why impious herod shouldst thou fear",
        ep2VandR: [
            "The kings of Thar_sis and of the isles shall give pre_sents.",
            "The kings of A_ra_bi_a and Sa_ba shall bring gifts.",
        ],
        ep2MagId: "botl the springs",
        sundayAfterEpiphany: true,
    };

    if (isEpiphanyTransferred) {
        const relevantSunday = calendar.days.find(
            (d) =>
                d.month === 1 &&
                d.date > 1 &&
                d.date <= 8 &&
                d.dayOfTheWeek === "sun"
        );
        modify(calendar)
            .onDate(1, 1)
            .getNthAfter(1, "sun")
            .placeCelebration({
                label: "epiphany",
                type: "solemnity",
                rank: 3,
                title: "The Epiphany of the Lord",
                ep1HymnId: "why impious herod shouldst thou fear",
                ep1VandR: [
                    "The kings of Thar_sis and of the isles shall give pre_sents.",
                    "The kings of A_ra_bi_a and Sa_ba shall bring gifts.",
                ],
                ep1MagId: "ep the sages beholding",
                invId: "the christ hath been",
                mpHymnId: "sent down by god to this worlds frame",
                mpVandR: [
                    "O wor_ship the Lord, al_le_lu_ia.",
                    "All ye an_gels of his, al_le_lu_ia.",
                ],
                mpBenId: "ep today to her heavenly",
                ep2HymnId: "why impious herod shouldst thou fear",
                ep2VandR: [
                    "The kings of Thar_sis and of the isles shall give pre_sents.",
                    "The kings of A_ra_bi_a and Sa_ba shall bring gifts.",
                ],
                ep2MagId: "ep we celebrate a holy day",
                collect:
                    "O God, who by the leading of a star didst manifest thy Only Begotten Son to the Gentiles: mercifully grant that we, who know thee now by faith, may be led onward through this earthly life, until we see the vision of thy heavenly glory; through the same Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
                athanasianCreed: true,
            });
        if (relevantSunday?.date === 7 || relevantSunday?.date === 8) {
            modify(calendar)
                .onDate(1, 1)
                .getNthAfter(1, "sun")
                .getNthAfter(1, "mon")
                .placeCelebration(baptismCelebration);
        } else {
            modify(calendar)
                .onDate(6, 1)
                .getNthAfter(1, "sun")
                .placeCelebration(baptismCelebration);
        }
    } else {
        modify(calendar)
            .onDate(6, 1)
            .placeCelebration({
                label: "epiphany",
                type: "solemnity",
                rank: 3,
                title: "The Epiphany of the Lord",
                ep1HymnId: "why impious herod shouldst thou fear",
                ep1VandR: [
                    "The kings of Thar_sis and of the isles shall give pre_sents.",
                    "The kings of A_ra_bi_a and Sa_ba shall bring gifts.",
                ],
                ep1MagId: "ep the sages beholding",
                invId: "the christ hath been",
                mpHymnId: "sent down by god to this worlds frame",
                mpVandR: [
                    "O wor_ship the Lord, al_le_lu_ia.",
                    "All ye an_gels of his, al_le_lu_ia.",
                ],
                mpBenId: "ep today to her heavenly",
                ep2HymnId: "why impious herod shouldst thou fear",
                ep2VandR: [
                    "The kings of Thar_sis and of the isles shall give pre_sents.",
                    "The kings of A_ra_bi_a and Sa_ba shall bring gifts.",
                ],
                ep2MagId: "ep we celebrate a holy day",
                collect:
                    "O God, who by the leading of a star didst manifest thy Only Begotten Son to the Gentiles: mercifully grant that we, who know thee now by faith, may be led onward through this earthly life, until we see the vision of thy heavenly glory; through the same Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
                athanasianCreed: true,
            });
        modify(calendar)
            .onDate(6, 1)
            .getNthAfter(1, "sun")
            .placeCelebration(baptismCelebration);
    }

    const epiphanyDate =
        calendar.days.findIndex(
            (d) =>
                d.celebrations.find((c) => c.label === "epiphany") !== undefined
        ) + 1;

    const baptismDate =
        calendar.days.findIndex(
            (d) =>
                d.celebrations.find(
                    (c) => c.label === "baptism-of-the-lord"
                ) !== undefined
        ) + 1;

    modify(calendar)
        .onCelebration("epiphany")
        .getNthBefore(1, "sun")
        .placeCelebration({
            label: "christmas-2",
            type: "sunday",
            rank: 6,
            title: "The Second Sunday of Christmas",
            shortTitle: "Christmas II",
            ep1HymnId: "come thou redeemer of the earth",
            ep1VandR: [
                "Tom_mor_row the i_ni_qui_ty of the earth shall be done a_way.",
                "And the Sa_viour of the world shall reign o_ver us.",
            ],
            ep1MagId: "nl2 as many as",
            invId: "nl unto us the christ is born",
            mpHymnId: "jesu the fathers only son",
            mpVandR: [
                "The Lord hath made known, al_le_lu_ia.",
                "His sal_va_tion, al_le_lu_ia.",
            ],
            mpBenId: "nl2 and we beheld",
            ep2HymnId: "from east to west from shore to shore",
            ep2VandR: [
                "The Lord hath made known, al_le_lu_ia.",
                "His sal_va_tion, al_le_lu_ia.",
            ],
            ep2MagId: "nl2 thine almighty word",
            collect:
                "Almighty God, who didst wonderfully create man in thine own image, and didst yet more wonderfully restore him: grant, we beseech thee, that as thy Son our Lord Jesus Christ was made in the likeness of men, so we may be made partakers of the divine nature; through the same Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        });

    if (!isEpiphanyTransferred || epiphanyDate > 2) {
        modify(calendar)
            .onCelebration("mary-mother-of-god")
            .plus(1, "days")
            .placeCelebration({
                type: "feria",
                title: `The Ninth Day of Christmas`,
                rank: 13,
                label: "day-of-christmas-9",
                invId: "nl unto us the christ is born",
                mpHymnId: "jesu the fathers only son",
                mpVandR: [
                    "The Lord hath made known, al_le_lu_ia.",
                    "His sal_va_tion, al_le_lu_ia.",
                ],
                mpBenId: "nl2 and we beheld",
                ep2HymnId: "from east to west from shore to shore",
                ep2VandR: [
                    "The Lord hath made known, al_le_lu_ia.",
                    "His sal_va_tion, al_le_lu_ia.",
                ],
                ep2MagId: "nl2 thine almighty word",
                collect:
                    "Almighty God, who didst wonderfully create man in thine own image, and didst yet more wonderfully restore him: grant, we beseech thee, that as thy Son our Lord Jesus Christ was made in the likeness of men, so we may be made partakers of the divine nature; through the same Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
            });
    }

    if (!isEpiphanyTransferred || epiphanyDate > 3) {
        modify(calendar)
            .onCelebration("mary-mother-of-god")
            .plus(2, "days")
            .placeCelebration({
                type: "feria",
                title: `The Tenth Day of Christmas`,
                rank: 13,
                label: "day-of-christmas-10",
                invId: "nl unto us the christ is born",
                mpHymnId: "jesu the fathers only son",
                mpVandR: [
                    "The Lord hath made known, al_le_lu_ia.",
                    "His sal_va_tion, al_le_lu_ia.",
                ],
                mpBenId: "nl2 and we beheld",
                ep2HymnId: "from east to west from shore to shore",
                ep2VandR: [
                    "The Lord hath made known, al_le_lu_ia.",
                    "His sal_va_tion, al_le_lu_ia.",
                ],
                ep2MagId: "nl2 thine almighty word",
                collect:
                    "Almighty God, who didst wonderfully create man in thine own image, and didst yet more wonderfully restore him: grant, we beseech thee, that as thy Son our Lord Jesus Christ was made in the likeness of men, so we may be made partakers of the divine nature; through the same Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
            });
    }
    if (epiphanyDate < 3) {
        modify(calendar)
            .onCelebration("mary-mother-of-god")
            .plus(2, "days")
            .placeCelebration({
                type: "feria",
                rank: 13,
                label: "transferred-epiphany-1",
                title: "Additional day after the Epiphany",
                ep1HymnId: "why impious herod shouldst thou fear",
                ep1VandR: [
                    "The kings of Thar_sis and of the isles shall give pre_sents.",
                    "The kings of A_ra_bi_a and Sa_ba shall bring gifts.",
                ],
                ep1MagId: "ep the sages beholding",
                invId: "the christ hath been",
                mpHymnId: "sent down by god to this worlds frame",
                mpVandR: [
                    "O wor_ship the Lord, al_le_lu_ia.",
                    "All ye an_gels of his, al_le_lu_ia.",
                ],
                mpBenId: "ep today to her heavenly",
                ep2HymnId: "why impious herod shouldst thou fear",
                ep2VandR: [
                    "The kings of Thar_sis and of the isles shall give pre_sents.",
                    "The kings of A_ra_bi_a and Sa_ba shall bring gifts.",
                ],
                ep2MagId: "ep we celebrate a holy day",
                collect:
                    "O God, who by the leading of a star didst manifest thy Only Begotten Son to the Gentiles: mercifully grant that we, who know thee now by faith, may be led onward through this earthly life, until we see the vision of thy heavenly glory; through the same Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
            });
    }

    if (!isEpiphanyTransferred || epiphanyDate > 4) {
        modify(calendar)
            .onCelebration("mary-mother-of-god")
            .plus(3, "days")
            .placeCelebration({
                type: "feria",
                title: `The Eleventh Day of Christmas`,
                rank: 13,
                label: "day-of-christmas-11",
                invId: "nl unto us the christ is born",
                mpHymnId: "jesu the fathers only son",
                mpVandR: [
                    "The Lord hath made known, al_le_lu_ia.",
                    "His sal_va_tion, al_le_lu_ia.",
                ],
                mpBenId: "nl2 and we beheld",
                ep2HymnId: "from east to west from shore to shore",
                ep2VandR: [
                    "The Lord hath made known, al_le_lu_ia.",
                    "His sal_va_tion, al_le_lu_ia.",
                ],
                ep2MagId: "nl2 thine almighty word",
                collect:
                    "Almighty God, who didst wonderfully create man in thine own image, and didst yet more wonderfully restore him: grant, we beseech thee, that as thy Son our Lord Jesus Christ was made in the likeness of men, so we may be made partakers of the divine nature; through the same Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
            });
    }
    if (epiphanyDate < 4) {
        modify(calendar)
            .onCelebration("mary-mother-of-god")
            .plus(3, "days")
            .placeCelebration({
                type: "feria",
                rank: 13,
                label: "transferred-epiphany-1",
                title: "Additional day after the Epiphany",
                ep1HymnId: "why impious herod shouldst thou fear",
                ep1VandR: [
                    "The kings of Thar_sis and of the isles shall give pre_sents.",
                    "The kings of A_ra_bi_a and Sa_ba shall bring gifts.",
                ],
                ep1MagId: "ep the sages beholding",
                invId: "the christ hath been",
                mpHymnId: "sent down by god to this worlds frame",
                mpVandR: [
                    "O wor_ship the Lord, al_le_lu_ia.",
                    "All ye an_gels of his, al_le_lu_ia.",
                ],
                mpBenId: "ep today to her heavenly",
                ep2HymnId: "why impious herod shouldst thou fear",
                ep2VandR: [
                    "The kings of Thar_sis and of the isles shall give pre_sents.",
                    "The kings of A_ra_bi_a and Sa_ba shall bring gifts.",
                ],
                ep2MagId: "ep we celebrate a holy day",
                collect:
                    "O God, who by the leading of a star didst manifest thy Only Begotten Son to the Gentiles: mercifully grant that we, who know thee now by faith, may be led onward through this earthly life, until we see the vision of thy heavenly glory; through the same Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
            });
    }

    if (!isEpiphanyTransferred || epiphanyDate > 5) {
        modify(calendar)
            .onCelebration("mary-mother-of-god")
            .plus(4, "days")
            .placeCelebration({
                type: "feria",
                title: `The Twelfth Day of Christmas`,
                rank: 13,
                label: "day-of-christmas-12",
                invId: "nl unto us the christ is born",
                mpHymnId: "jesu the fathers only son",
                mpVandR: [
                    "The Lord hath made known, al_le_lu_ia.",
                    "His sal_va_tion, al_le_lu_ia.",
                ],
                mpBenId: "nl2 and we beheld",
                ep2HymnId: "from east to west from shore to shore",
                ep2VandR: [
                    "The Lord hath made known, al_le_lu_ia.",
                    "His sal_va_tion, al_le_lu_ia.",
                ],
                ep2MagId: "nl2 thine almighty word",
                collect:
                    "Almighty God, who didst wonderfully create man in thine own image, and didst yet more wonderfully restore him: grant, we beseech thee, that as thy Son our Lord Jesus Christ was made in the likeness of men, so we may be made partakers of the divine nature; through the same Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
            });
    }
    if (epiphanyDate < 5) {
        modify(calendar)
            .onCelebration("mary-mother-of-god")
            .plus(4, "days")
            .placeCelebration({
                type: "feria",
                rank: 13,
                label: "transferred-epiphany-1",
                title: "Additional day after the Epiphany",
                ep1HymnId: "why impious herod shouldst thou fear",
                ep1VandR: [
                    "The kings of Thar_sis and of the isles shall give pre_sents.",
                    "The kings of A_ra_bi_a and Sa_ba shall bring gifts.",
                ],
                ep1MagId: "ep the sages beholding",
                invId: "the christ hath been",
                mpHymnId: "sent down by god to this worlds frame",
                mpVandR: [
                    "O wor_ship the Lord, al_le_lu_ia.",
                    "All ye an_gels of his, al_le_lu_ia.",
                ],
                mpBenId: "ep today to her heavenly",
                ep2HymnId: "why impious herod shouldst thou fear",
                ep2VandR: [
                    "The kings of Thar_sis and of the isles shall give pre_sents.",
                    "The kings of A_ra_bi_a and Sa_ba shall bring gifts.",
                ],
                ep2MagId: "ep we celebrate a holy day",
                collect:
                    "O God, who by the leading of a star didst manifest thy Only Begotten Son to the Gentiles: mercifully grant that we, who know thee now by faith, may be led onward through this earthly life, until we see the vision of thy heavenly glory; through the same Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
            });
    }

    if (epiphanyDate < 6 && isEpiphanyTransferred) {
        modify(calendar)
            .onCelebration("mary-mother-of-god")
            .plus(5, "days")
            .placeCelebration({
                type: "feria",
                rank: 13,
                label: "transferred-epiphany-1",
                title: "Additional day after the Epiphany",
                ep1HymnId: "why impious herod shouldst thou fear",
                ep1VandR: [
                    "The kings of Thar_sis and of the isles shall give pre_sents.",
                    "The kings of A_ra_bi_a and Sa_ba shall bring gifts.",
                ],
                ep1MagId: "ep the sages beholding",
                invId: "the christ hath been",
                mpHymnId: "sent down by god to this worlds frame",
                mpVandR: [
                    "O wor_ship the Lord, al_le_lu_ia.",
                    "All ye an_gels of his, al_le_lu_ia.",
                ],
                mpBenId: "ep today to her heavenly",
                ep2HymnId: "why impious herod shouldst thou fear",
                ep2VandR: [
                    "The kings of Thar_sis and of the isles shall give pre_sents.",
                    "The kings of A_ra_bi_a and Sa_ba shall bring gifts.",
                ],
                ep2MagId: "ep we celebrate a holy day",
                collect:
                    "O God, who by the leading of a star didst manifest thy Only Begotten Son to the Gentiles: mercifully grant that we, who know thee now by faith, may be led onward through this earthly life, until we see the vision of thy heavenly glory; through the same Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
            });
    }

    if (epiphanyDate >= 7) {
        modify(calendar)
            .onCelebration("mary-mother-of-god")
            .plus(5, "days")
            .placeCelebration({
                type: "feria",
                rank: 13,
                label: "extra-jan-6",
                title: "The Thirteenth Day of Christmastide",
                ep1HymnId: "come thou redeemer of the earth",
                ep1VandR: [
                    "Tom_mor_row the i_ni_qui_ty of the earth shall be done a_way.",
                    "And the Sa_viour of the world shall reign o_ver us.",
                ],
                ep1MagId: "nl2 as many as",
                invId: "nl unto us the christ is born",
                mpHymnId: "jesu the fathers only son",
                mpVandR: [
                    "The Lord hath made known, al_le_lu_ia.",
                    "His sal_va_tion, al_le_lu_ia.",
                ],
                mpBenId: "nl2 and we beheld",
                ep2HymnId: "from east to west from shore to shore",
                ep2VandR: [
                    "The Lord hath made known, al_le_lu_ia.",
                    "His sal_va_tion, al_le_lu_ia.",
                ],
                ep2MagId: "nl2 thine almighty word",
                collect:
                    "Almighty God, who didst wonderfully create man in thine own image, and didst yet more wonderfully restore him: grant, we beseech thee, that as thy Son our Lord Jesus Christ was made in the likeness of men, so we may be made partakers of the divine nature; through the same Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
                doesntHaveSeconeEvensong: epiphanyDate === 7,
            });
    }
    if (epiphanyDate >= 8) {
        modify(calendar)
            .onCelebration("mary-mother-of-god")
            .plus(6, "days")
            .placeCelebration({
                type: "feria",
                rank: 13,
                label: "extra-jan-7",
                title: "The Fourteenth Day of Christmastide",
                ep1HymnId: "come thou redeemer of the earth",
                ep1VandR: [
                    "Tom_mor_row the i_ni_qui_ty of the earth shall be done a_way.",
                    "And the Sa_viour of the world shall reign o_ver us.",
                ],
                ep1MagId: "nl2 as many as",
                invId: "nl unto us the christ is born",
                mpHymnId: "jesu the fathers only son",
                mpVandR: [
                    "The Lord hath made known, al_le_lu_ia.",
                    "His sal_va_tion, al_le_lu_ia.",
                ],
                mpBenId: "nl2 and we beheld",
                ep2HymnId: "from east to west from shore to shore",
                ep2VandR: [
                    "The Lord hath made known, al_le_lu_ia.",
                    "His sal_va_tion, al_le_lu_ia.",
                ],
                ep2MagId: "nl2 thine almighty word",
                collect:
                    "Almighty God, who didst wonderfully create man in thine own image, and didst yet more wonderfully restore him: grant, we beseech thee, that as thy Son our Lord Jesus Christ was made in the likeness of men, so we may be made partakers of the divine nature; through the same Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
                doesntHaveSeconeEvensong: epiphanyDate === 8,
            });
    }

    if (epiphanyDate < 7) {
        modify(calendar)
            .onCelebration("mary-mother-of-god")
            .plus(6, "days")
            .placeCelebration({
                type: "feria",
                rank: 13,
                label: "jan-7",
                title: "7 January",
                invId: "the christ hath been",
                mpHymnId: "sent down by god to this worlds frame",
                mpVandR: [
                    "O wor_ship the Lord, al_le_lu_ia.",
                    "All ye an_gels of his, al_le_lu_ia.",
                ],
                mpBenId: "j7 from the east",
                ep2HymnId: "why impious herod shouldst thou fear",
                ep2VandR: [
                    "The kings of Thar_sis and of the isles shall give pre_sents.",
                    "The kings of A_ra_bi_a and Sa_ba shall bring gifts.",
                ],
                ep2MagId: "j7 seeing the star",
                collect:
                    baptismDate <= 7
                        ? "Heavenly Father, whose blessed Son Jesus Christ did take our nature upon him, and was baptised for our sakes in the river Jordan: mercifully grant that we, being regenerate and made thy children by adoption and grace, may also be partakers of thy Holy Spirit; through the same Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen."
                        : "O God, who by the leading of a star didst manifest thy Only Begotten Son to the Gentiles: mercifully grant that we, who know thee now by faith, may be led onward through this earthly life, until we see the vision of thy heavenly glory; through the same Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
            });
    }
    if (epiphanyDate < 8) {
        modify(calendar)
            .onCelebration("mary-mother-of-god")
            .plus(7, "days")
            .placeCelebration({
                type: "feria",
                rank: 13,
                label: "jan-8",
                title: "8 January",
                invId: "the christ hath been",
                mpHymnId: "sent down by god to this worlds frame",
                mpVandR: [
                    "O wor_ship the Lord, al_le_lu_ia.",
                    "All ye an_gels of his, al_le_lu_ia.",
                ],
                mpBenId: "j8 there are the",
                ep2HymnId: "why impious herod shouldst thou fear",
                ep2VandR: [
                    "The kings of Thar_sis and of the isles shall give pre_sents.",
                    "The kings of A_ra_bi_a and Sa_ba shall bring gifts.",
                ],
                ep2MagId: "j8 light of light",
                collect:
                    baptismDate <= 8
                        ? "Heavenly Father, whose blessed Son Jesus Christ did take our nature upon him, and was baptised for our sakes in the river Jordan: mercifully grant that we, being regenerate and made thy children by adoption and grace, may also be partakers of thy Holy Spirit; through the same Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen."
                        : "O God, who by the leading of a star didst manifest thy Only Begotten Son to the Gentiles: mercifully grant that we, who know thee now by faith, may be led onward through this earthly life, until we see the vision of thy heavenly glory; through the same Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
            });
    }
    modify(calendar)
        .onCelebration("mary-mother-of-god")
        .plus(8, "days")
        .placeCelebration({
            type: "feria",
            rank: 13,
            label: "jan-9",
            title: "9 January",
            invId: "the christ hath been",
            mpHymnId: "sent down by god to this worlds frame",
            mpVandR: [
                "O wor_ship the Lord, al_le_lu_ia.",
                "All ye an_gels of his, al_le_lu_ia.",
            ],
            mpBenId: "j9 we have seen",
            ep2HymnId: "why impious herod shouldst thou fear",
            ep2VandR: [
                "The kings of Thar_sis and of the isles shall give pre_sents.",
                "The kings of A_ra_bi_a and Sa_ba shall bring gifts.",
            ],
            ep2MagId: "j9 herod inquired",
            collect:
                baptismDate <= 9
                    ? "Heavenly Father, whose blessed Son Jesus Christ did take our nature upon him, and was baptised for our sakes in the river Jordan: mercifully grant that we, being regenerate and made thy children by adoption and grace, may also be partakers of thy Holy Spirit; through the same Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen."
                    : "O God, who by the leading of a star didst manifest thy Only Begotten Son to the Gentiles: mercifully grant that we, who know thee now by faith, may be led onward through this earthly life, until we see the vision of thy heavenly glory; through the same Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        });
    modify(calendar)
        .onCelebration("mary-mother-of-god")
        .plus(9, "days")
        .placeCelebration({
            type: "feria",
            rank: 13,
            label: "jan-10",
            title: "10 January",
            invId: "the christ hath been",
            mpHymnId: "sent down by god to this worlds frame",
            mpVandR: [
                "O wor_ship the Lord, al_le_lu_ia.",
                "All ye an_gels of his, al_le_lu_ia.",
            ],
            mpBenId: "j10 many nations",
            ep2HymnId: "why impious herod shouldst thou fear",
            ep2VandR: [
                "The kings of Thar_sis and of the isles shall give pre_sents.",
                "The kings of A_ra_bi_a and Sa_ba shall bring gifts.",
            ],
            ep2MagId: "j10 all they from",
            collect:
                baptismDate <= 10
                    ? "Heavenly Father, whose blessed Son Jesus Christ did take our nature upon him, and was baptised for our sakes in the river Jordan: mercifully grant that we, being regenerate and made thy children by adoption and grace, may also be partakers of thy Holy Spirit; through the same Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen."
                    : "O God, who by the leading of a star didst manifest thy Only Begotten Son to the Gentiles: mercifully grant that we, who know thee now by faith, may be led onward through this earthly life, until we see the vision of thy heavenly glory; through the same Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        });
    modify(calendar)
        .onCelebration("mary-mother-of-god")
        .plus(10, "days")
        .placeCelebration({
            type: "feria",
            rank: 13,
            label: "jan-11",
            title: "11 January",
            invId: "the christ hath been",
            mpHymnId: "sent down by god to this worlds frame",
            mpVandR: [
                "O wor_ship the Lord, al_le_lu_ia.",
                "All ye an_gels of his, al_le_lu_ia.",
            ],
            mpBenId: "j11 they that",
            ep2HymnId: "why impious herod shouldst thou fear",
            ep2VandR: [
                "The kings of Thar_sis and of the isles shall give pre_sents.",
                "The kings of A_ra_bi_a and Sa_ba shall bring gifts.",
            ],
            ep2MagId: "j11 the sages",
            collect:
                baptismDate <= 11
                    ? "Heavenly Father, whose blessed Son Jesus Christ did take our nature upon him, and was baptised for our sakes in the river Jordan: mercifully grant that we, being regenerate and made thy children by adoption and grace, may also be partakers of thy Holy Spirit; through the same Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen."
                    : "O God, who by the leading of a star didst manifest thy Only Begotten Son to the Gentiles: mercifully grant that we, who know thee now by faith, may be led onward through this earthly life, until we see the vision of thy heavenly glory; through the same Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        });
    modify(calendar)
        .onCelebration("mary-mother-of-god")
        .plus(11, "days")
        .placeCelebration({
            type: "feria",
            rank: 13,
            label: "jan-12",
            title: "12 January",
            invId: "the christ hath been",
            mpHymnId: "sent down by god to this worlds frame",
            mpVandR: [
                "O wor_ship the Lord, al_le_lu_ia.",
                "All ye an_gels of his, al_le_lu_ia.",
            ],
            mpBenId: "j12 great is",
            ep2HymnId: "why impious herod shouldst thou fear",
            ep2VandR: [
                "The kings of Thar_sis and of the isles shall give pre_sents.",
                "The kings of A_ra_bi_a and Sa_ba shall bring gifts.",
            ],
            ep2MagId: "j12 the sages",
            collect:
                baptismDate <= 12
                    ? "Heavenly Father, whose blessed Son Jesus Christ did take our nature upon him, and was baptised for our sakes in the river Jordan: mercifully grant that we, being regenerate and made thy children by adoption and grace, may also be partakers of thy Holy Spirit; through the same Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen."
                    : "O God, who by the leading of a star didst manifest thy Only Begotten Son to the Gentiles: mercifully grant that we, who know thee now by faith, may be led onward through this earthly life, until we see the vision of thy heavenly glory; through the same Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        });

    // Easter and related feasts
    const easter = getEaster(year);
    modify(calendar).onDate(easter.date, easter.month).placeCelebration({
        label: "easter-day",
        type: "solemnity",
        rank: 1,
        title: "Easter Day",
        deletesEve: true,
        athanasianCreed: true,
        mpBenId: "e1 and very early",
        ep2MagId: "e1 and when they looked",
        collect:
            "Almighty God, who through thine Only Begotten Son Jesus Christ hast overcome death, and opened unto us the gate of everlasting life: we humbly beseech thee; that as by thy special grace thou dost put into our minds good desires, so by thy continual help we may bring the same to good effect; through the same Jesus Christ thy Son, our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
    });
    modify(calendar)
        .onCelebration("easter-day")
        .getNthBefore(1, "sat")
        .placeCelebration({
            label: "holy-saturday",
            type: "misc",
            rank: 1,
            title: "Holy Saturday",
            invId: "passiontide inv",
            mpHymnId: "thirty years among us dwelling",
            mpVandR: [
                "De_li_ver me from mine e_ne_mies, O God.",
                "De_fend me from them that rise up a_gainst me.",
            ],
            mpBenId: "hw the women also sitting",
            ep2HymnId: "the royal banners forward go",
            ep2VandR: [
                "De_li_ver me, O Lord, from the e_vil man.",
                "And pre_serve me from the wi_cked man.",
            ],
            ep2MagId: "hw the chief priests",
            collect:
                "Grant, O Lord, that as we are baptised into the death of thy blessed Son our Saviour Jesus Christ: so by continually mortifying our corrupt affections we may be buried with him; and that, through the grave, and gate of death, we may pass to our joyful resurrection; for his merits, who died, and was buried, and rose again for us, thy Son Jesus Christ our Lord. Amen.",
            deletesFeria: true,
            deletesEve: true,
        });
    modify(calendar)
        .onCelebration("easter-day")
        .getNthBefore(1, "fri")
        .placeCelebration({
            label: "good-friday",
            type: "misc",
            rank: 1,
            title: "Good Friday",
            invId: "passiontide inv",
            mpHymnId: "sing my tongue the glorious battle",
            mpVandR: [
                "De_li_ver me from mine e_ne_mies, O God.",
                "De_fend me from them that rise up a_gainst me.",
            ],
            mpBenId: "hw they set up",
            ep2HymnId: "the royal banners forward go",
            ep2VandR: [
                "De_li_ver me, O Lord, from the e_vil man.",
                "And pre_serve me from the wi_cked man.",
            ],
            ep2MagId: "hw when he had received",
            collect:
                "Almighty God: we beseech thee graciously to behold this thy family, for which our Lord Jesus Christ was contented to be betrayed, and given up into the hands of wicked men, and to suffer death upon the Cross; who now liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
            deletesFeria: true,
        });
    modify(calendar)
        .onCelebration("easter-day")
        .getNthBefore(1, "thur")
        .placeCelebration({
            label: "maundy-thursday",
            rank: 2,
            type: "feast",
            title: "Thursday in Holy Week",
            commonlyCalled: "Maundy Thursday",
            invId: "passiontide inv",
            ep1HymnId: "the royal banners forward go",
            mpHymnId: "thirty years among us dwelling",
            mpVandR: [
                "De_li_ver me from mine e_ne_mies, O God.",
                "De_fend me from them that rise up a_gainst me.",
            ],
            mpBenId: "hw now he that betrayed",
            ep2HymnId: "the royal banners forward go",
            ep2VandR: [
                "De_li_ver me, O Lord, from the e_vil man.",
                "And pre_serve me from the wi_cked man.",
            ],
            ep2MagId: "hw and as they were eating",
            collect:
                "Almighty God: we beseech thee graciously to behold this thy family, for which our Lord Jesus Christ was contented to be betrayed, and given up into the hands of wicked men, and to suffer death upon the Cross; who now liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
            deletesFeria: true,
            deletesEve: true,
        });
    modify(calendar)
        .onCelebration("easter-day")
        .getNthBefore(1, "wed")
        .placeCelebration({
            label: "wed-before-easter",
            rank: 2,
            type: "misc",
            title: "Wednesday in Holy Week",
            invId: "passiontide inv",
            mpHymnId: "sing my tongue the glorious battle",
            mpVandR: [
                "De_li_ver me from mine e_ne_mies, O God.",
                "De_fend me from them that rise up a_gainst me.",
            ],
            mpBenId: "hw simon sleepest thou",
            ep2HymnId: "the royal banners forward go",
            ep2VandR: [
                "De_li_ver me, O Lord, from the e_vil man.",
                "And pre_serve me from the wi_cked man.",
            ],
            ep2MagId: "hw a maid said",
            collect:
                "O God, who didst will that thy Son should suffer death upon the Cross that thou mightest deliver us from the snares of the enemy: grant that by the merits of his Passion and Death we may know the power of his Resurrection; through the same Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
            deletesFeria: true,
            deletesEve: true,
        });
    modify(calendar)
        .onCelebration("easter-day")
        .getNthBefore(1, "tues")
        .placeCelebration({
            label: "tues-before-easter",
            rank: 2,
            type: "misc",
            title: "Tuesday in Holy Week",
            invId: "passiontide inv",
            mpHymnId: "thirty years among us dwelling",
            mpVandR: [
                "De_li_ver me from mine e_ne_mies, O God.",
                "De_fend me from them that rise up a_gainst me.",
            ],
            mpBenId: "hw before the day",
            ep2HymnId: "the royal banners forward go",
            ep2VandR: [
                "De_li_ver me, O Lord, from the e_vil man.",
                "And pre_serve me from the wi_cked man.",
            ],
            ep2MagId: "hw i have power",
            collect:
                "O Lord God, whose blessed Son, our Saviour, gave his back to the smiters and hid not his face from shame: grant us grace to take joyfully the suffering of the present time, in full assurance of the glory that shall be revealed; through the same Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
            deletesFeria: true,
        });
    modify(calendar)
        .onCelebration("easter-day")
        .getNthBefore(1, "mon")
        .placeCelebration({
            label: "mon-before-easter",
            rank: 2,
            type: "misc",
            title: "Monday in Holy Week",
            invId: "passiontide inv",
            mpHymnId: "sing my tongue the glorious battle",
            mpVandR: [
                "De_li_ver me from mine e_ne_mies, O God.",
                "De_fend me from them that rise up a_gainst me.",
            ],
            mpBenId: "hw o father glorify",
            ep2HymnId: "the royal banners forward go",
            ep2VandR: [
                "De_li_ver me, O Lord, from the e_vil man.",
                "And pre_serve me from the wi_cked man.",
            ],
            ep2MagId: "hw thou couldest have",
            collect:
                "Almighty God, whose most dear Son went not up to joy but first he suffered pain, and entered not into glory before he was crucified: mercifully grant that we, walking in the way of the Cross, may find it none other than the way of life and peace; through the same Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
            deletesFeria: true,
        });
    modify(calendar)
        .onCelebration("easter-day")
        .getNthAfter(1, "mon")
        .placeCelebration({
            label: "easter-monday",
            rank: 2,
            type: "misc",
            title: "Monday in the Octave of Easter",
            deletesFeria: true,
            mpBenId: "e1 jesus joined himself",
            ep2MagId: "e1 what manner",
            collect:
                "O God, whose blessed Son did manifest himself to his disciples in the breaking of bread: open, we pray thee, the eyes of our faith; that we may behold thee in all thy works; through the same Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        });
    modify(calendar)
        .onCelebration("easter-day")
        .getNthAfter(1, "tues")
        .placeCelebration({
            label: "easter-tuesday",
            rank: 2,
            type: "misc",
            title: "Tuesday in the Octave of Easter",
            deletesFeria: true,
            mpBenId: "e1 jesus stood in",
            ep2MagId: "e1 behold my hands",
            collect:
                "O God, who by the glorious Resurrection of thy Son Jesus Christ destroyed death and brought life and immortality to light: grant that we, who have been raised with him, may abide in his presence and rejoice in the hope of eternal glory; through the same Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        });
    modify(calendar)
        .onCelebration("easter-day")
        .getNthAfter(1, "wed")
        .placeCelebration({
            label: "easter-wednesday",
            rank: 2,
            type: "misc",
            title: "Wednesday in the Octave of Easter",
            deletesFeria: true,
            mpBenId: "e1 cast the net",
            ep2MagId: "e1 jesus said to",
            collect:
                "O God, who dost gladden us with the yearly solemnity of the Resurrection of thy Son Jesus Christ our Lord: mercifully grant that we may so observe this temporal feast; that we may be found worthy to attain to everlasting felicity; through the same Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        });
    modify(calendar)
        .onCelebration("easter-day")
        .getNthAfter(1, "thur")
        .placeCelebration({
            label: "easter-thursday",
            rank: 2,
            type: "misc",
            title: "Thursday in the Octave of Easter",
            deletesFeria: true,
            mpBenId: "e1 many stood",
            ep2MagId: "e1 they have taken",
            collect:
                "Almighty and everlasting God, who in the Paschal Mystery hast established the new covenant of reconciliation: grant that all who have been reborn into the fellowship of Christ's Body may show forth in their lives what they profess by their faith; through the same Jesus Christ thy Son our Lord, who liveth and reigneth with thee in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        });
    modify(calendar)
        .onCelebration("easter-day")
        .getNthAfter(1, "fri")
        .placeCelebration({
            label: "easter-friday",
            rank: 2,
            type: "misc",
            title: "Friday in the Octave of Easter",
            deletesFeria: true,
            mpBenId: "e1 the eleven",
            ep2MagId: "e1 all power",
            collect:
                "O God, who hast united the diversity of nations in the confession of thy of thy Name: grant that they who are born again in the font of Baptism, may be of one mind in faith and in godliness of life; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        });
    modify(calendar)
        .onCelebration("easter-day")
        .getNthAfter(1, "sat")
        .placeCelebration({
            label: "easter-saturday",
            rank: 2,
            type: "misc",
            title: "Saturday in the Octave of Easter",
            deletesFeria: true,
            mpBenId: "e1 they ran both",
            collect:
                "We thank thee, heavenly Father, for that thou hast delivered us from the dominion of sin and death and hast brought us unto the kingdom of thy Son: and we pray thee that, as by his death he hath recalled us to life, so by his love he may raise us to joys eternal; who liveth and reigneth with thee in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        });
    modify(calendar)
        .onCelebration("easter-day")
        .getNthAfter(7, "sun")
        .placeCelebration({
            label: "whitsun",
            rank: 2,
            type: "sunday",
            title: "Pentecost",
            commonlyCalled: "Whitsunday",
            athanasianCreed: true,
            ep1HymnId: "when christ our lord had passed once more",
            ep1VandR: [
                "They were all filled with the Ho_ly Ghost.",
                "And be_gan to speak.",
            ],
            ep1MagId: "pen i will not",
            invId: "pen inv",
            mpHymnId: "when christ our lord had passed once more",
            mpVandR: [
                "They were all filled with the Ho_ly Ghost.",
                "And be_gan to speak.",
            ],
            mpBenId: "pen receive ye",
            ep2HymnId: "rejoice the year upon its way",
            ep2VandR: [
                "The A_po_stles did speak with o_ther tongues.",
                "The won_der_ful works of God.",
            ],
            ep2MagId: "pen today are fulfilled",
            collect:
                "O God, who as at this time didst teach the hearts of thy faithful people, by sending to them the light of thy Holy Spirit: grant us by the same Spirit to have a right judgement in all things, and evermore to rejoice in his holy comfort; through the merits of Christ Jesus thy Son our Lord, who liveth and reigneth with thee, in the unity of the same Holy Spirit, ever one God, world without end. Amen.",
        });
    modify(calendar)
        .onCelebration("whitsun")
        .getNthAfter(1, "mon")
        .placeCelebration({
            label: "monday-after-whitsun",
            rank: 9,
            type: "misc",
            title: "Monday in Whitsun Week",
            deletesFeria: true,
            invId: "pen inv",
            mpHymnId: "when christ our lord had passed once more",
            mpVandR: [
                "They were all filled with the Ho_ly Ghost.",
                "And be_gan to speak.",
            ],
            mpBenId: "pen god so loved",
            ep2HymnId: "rejoice the year upon its way",
            ep2VandR: [
                "The A_po_stles did speak with o_ther tongues.",
                "The won_der_ful works of God.",
            ],
            ep2MagId: "pen if a man love",
            collect:
                "Send, we beseech thee, Almighty God, thy Holy Spirit into our hearts: that he may direct and rule us according to thy will, comfort us in our afflictions, defend us from all error, and lead us into all truth; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the same Holy Spirit, ever one God, world without end. Amen.",
        });

    modify(calendar)
        .onCelebration("whitsun")
        .getNthAfter(1, "tues")
        .placeCelebration({
            label: "tuesday-after-whitsun",
            rank: 9,
            type: "misc",
            title: "Tuesday in Whitsun Week",
            deletesFeria: true,
            invId: "pen inv",
            mpHymnId: "when christ our lord had passed once more",
            mpVandR: [
                "They were all filled with the Ho_ly Ghost.",
                "And be_gan to speak.",
            ],
            mpBenId: "pen i am the door",
            ep2HymnId: "rejoice the year upon its way",
            ep2VandR: [
                "The A_po_stles did speak with o_ther tongues.",
                "The won_der_ful works of God.",
            ],
            ep2MagId: "pen peace i leave",
            collect:
                "Grant, we beseech thee, merciful God: that thy Church, being gathered together in unity by thy Holy Spirit, may manifest thy power among all peoples to the glory of thy Name; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the same Holy Spirit, ever one God, world without end. Amen.",
        });
    modify(calendar)
        .onCelebration("whitsun")
        .getNthAfter(1, "wed")
        .placeCelebration({
            label: "wednesday-after-whitsun",
            rank: 9,
            type: "misc",
            title: "Ember Wednesday in Whitsun Week",
            deletesFeria: true,
            invId: "pen inv",
            mpHymnId: "when christ our lord had passed once more",
            mpVandR: [
                "They were all filled with the Ho_ly Ghost.",
                "And be_gan to speak.",
            ],
            mpBenId: "pen i am the living",
            ep2HymnId: "rejoice the year upon its way",
            ep2VandR: [
                "The A_po_stles did speak with o_ther tongues.",
                "The won_der_ful works of God.",
            ],
            ep2MagId: "pen i am the living which came",
            collect:
                "We beseech thee, O Lord, that the Comforter who proceedeth from thee may enlighten our minds: and lead us, as thy Son hath promised, into all truth; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the same Holy Spirit, ever one God, world without end. Amen.",
        });
    modify(calendar)
        .onCelebration("whitsun")
        .getNthAfter(1, "thur")
        .placeCelebration({
            label: "thursday-after-whitsun",
            rank: 9,
            type: "misc",
            title: "Thursday in Whitsun Week",
            deletesFeria: true,
            invId: "pen inv",
            mpHymnId: "when christ our lord had passed once more",
            mpVandR: [
                "They were all filled with the Ho_ly Ghost.",
                "And be_gan to speak.",
            ],
            mpBenId: "pen jesus called unto",
            ep2HymnId: "rejoice the year upon its way",
            ep2VandR: [
                "The A_po_stles did speak with o_ther tongues.",
                "The won_der_ful works of God.",
            ],
            ep2MagId: "pen the spirit which",
            collect:
                "Almighty God, who as at this time didst open the way of eternal life to every race and nation by the promised gift of thy Holy Spirit: shed abroad this gift throughout the world by the preaching of the Gospel, that it may reach to the ends of the earth; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the same Holy Spirit, ever one God, world without end. Amen.",
        });
    modify(calendar)
        .onCelebration("whitsun")
        .getNthAfter(1, "thur")
        .placeCelebration({
            label: "oljc-eternal-high-priest",
            rank: 8,
            type: "feast",
            title: "Our Lord Jesus Christ, Eternal High Priest",
            regionNote: "England & Wales",
            collect:
                "O God, who, for the glory of thy majesty and the salvation of mankind, didst appoint thine Only Begotten Son supreme and eternal Priest: vouchsafe that they, whom he hath chosen to be the ministers and stewards of his mysteries, may be found faithful in fulfilling the ministry they have received; through the same Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.//Almighty God, who as at this time didst open the way of eternal life to every race and nation by the promised gift of thy Holy Spirit: shed abroad this gift throughout the world by the preaching of the Gospel, that it may reach to the ends of the earth; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the same Holy Spirit, ever one God, world without end. Amen.",
            doesntHaveFirstEvensong: true,
            invId: "oljchp inv",
            mpHymnId: "when the patriarch was returning",
            mpVandR: [
                "I will re_ceive the cup of sal_va_tion.",
                "And call u_pon the Name of the Lord.",
            ],
            mpBenId: "oljchp this is my body",
            ep2HymnId: "sing o earth for thy redemption",
            ep2VandR: [
                "Thou hast a_noin_ted my head with oil.",
                "And my cup shall be full.",
            ],
            ep2MagId: "oljchp behold i will send",
        });
    modify(calendar)
        .onCelebration("whitsun")
        .getNthAfter(1, "fri")
        .placeCelebration({
            label: "friday-after-whitsun",
            rank: 9,
            type: "misc",
            title: "Ember Friday in Whitsun Week",
            deletesFeria: true,
            invId: "pen inv",
            mpHymnId: "when christ our lord had passed once more",
            mpVandR: [
                "They were all filled with the Ho_ly Ghost.",
                "And be_gan to speak.",
            ],
            mpBenId: "pen jesus said",
            ep2HymnId: "rejoice the year upon its way",
            ep2VandR: [
                "The A_po_stles did speak with o_ther tongues.",
                "The won_der_ful works of God.",
            ],
            ep2MagId: "pen but the comforter",
            collect:
                "Most merciful God, we beseech thee: that thy Church, being gathered together in the Holy Spirit, may nevermore be disquieted by the assaults of her enemies; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the same Holy Spirit, ever one God, world without end. Amen.",
        });
    modify(calendar)
        .onCelebration("whitsun")
        .getNthAfter(1, "sat")
        .placeCelebration({
            label: "saturday-after-whitsun",
            rank: 9,
            type: "misc",
            title: "Ember Saturday in Whitsun Week",
            deletesFeria: true,
            invId: "pen inv",
            mpHymnId: "when christ our lord had passed once more",
            mpVandR: [
                "They were all filled with the Ho_ly Ghost.",
                "And be_gan to speak.",
            ],
            mpBenId: "pen the love of god",
            collect:
                "We beseech thee, O Lord, graciously pour the Holy Spirit into our hearts: by whose wisdom we were created, and by whose providence we are governed; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the same Holy Spirit, ever one God, world without end. Amen.",
        });
    modify(calendar)
        .onCelebration("easter-day")
        .getNthAfter(8, "sun")
        .placeCelebration({
            label: "trinity-sunday",
            rank: 3,
            type: "solemnity",
            title: "Trinity Sunday",
            operatesAsSunday: true,
            athanasianCreed: true,
            ep1HymnId: "be present holy trinity",
            ep1VandR: [
                "Let us bless the Fa_ther and the Son with the Ho_ly Ghost.",
                "Let us praise him and mag_ni_fy him for e_ver.",
            ],
            ep1MagId: "trin thanks o god",
            collect:
                "Almighty and everlasting God, who hast given unto us thy servants grace, by the confession of a true faith, to acknowledge the glory of the eternal Trinity, and in the power of the divine majesty to worship the Unity: we beseech thee; that this holy faith may evermore be our defence against all adversities; who livest and reignest, ever one God, world without end. Amen.",
            invId: "trin inv",
            mpHymnId: "father most holy merciful and tender",
            mpVandR: [
                "Let us bless the Fa_ther and the Son with the Ho_ly Ghost.",
                "Let us praise him and mag_ni_fy him for e_ver.",
            ],
            mpBenId: "trin blessed be",
            ep2HymnId: "be present holy trinity",
            ep2VandR: [
                "Bless_ed art thou, O Lord, in the fir_ma_ment of hea_ven.",
                "A_bove all to be praised and glo_ri_fied for e_ver.",
            ],
            ep2MagId: "trin thee o god",
            sundayAfterEpiphany: true,
        });

    const corpusChristi: Celebration = {
        label: "corpus-christi",
        rank: 3,
        type: "solemnity",
        title: "The Most Holy Body and Blood of Christ",
        commonlyCalled: "Corpus Christi",
        ep1HymnId: "at this our solemn feast",
        ep1VandR: [
            "Thou ga_vest them bread from hea_ven, al_le_lu_ia.",
            "Con_tain_ing in it_self all sweet_ness, al_le_lu_ia.",
        ],
        invId: "corpus inv",
        mpHymnId: "of the glorious body telling",
        mpVandR: [
            "He ma_keth peace in thy bor_ders, al_le_lu_ia.",
            "And fill_eth thee with the flour of wheat, al_le_lu_ia.",
        ],
        mpBenId: "corpus i am",
        ep1MagId: "corpus o how sweet",
        ep2HymnId: "at this our solemn feast",
        ep2VandR: [
            "Thou ga_vest them bread from hea_ven, al_le_lu_ia.",
            "Con_tain_ing in it_self all sweet_ness, al_le_lu_ia.",
        ],
        ep2MagId: "corpus o sacred",
        collect:
            "O God, who in a wonderful Sacrament hast left unto us a memorial of thy Passion: grant us, we beseech thee, so to venerate the sacred mysteries of thy Body and Blood; that we may ever know within ourselves the fruit of thy redemption; who livest and reignest with the Father, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
    };
    if (corpusChristiTransferred) {
        modify(calendar)
            .onCelebration("trinity-sunday")
            .getNthAfter(1, "sun")
            .placeCelebration(corpusChristi);
    } else {
        modify(calendar)
            .onCelebration("trinity-sunday")
            .getNthAfter(1, "thur")
            .placeCelebration(corpusChristi);
    }

    modify(calendar)
        .onCelebration("trinity-sunday")
        .getNthAfter(2, "fri")
        .placeCelebration({
            label: "sacred-heart-of-jesus",
            rank: 3,
            type: "solemnity",
            title: "The Most Sacred Heart of Jesus",
            ep1MagId: "sh i am come",
            ep1HymnId: "jesu creator of the world",
            ep1VandR: [
                "Take my yoke u_pon you, and learn of me.",
                "For I am meek and low_ly of heart.",
            ],
            invId: "sh inv",
            mpHymnId: "what proud attack our sins have launched",
            mpVandR: [
                "With joy shall ye draw wa_ter.",
                "From the wells of sal_va_tion.",
            ],
            mpBenId: "sh now these things",
            ep2HymnId: "jesu creator of the world",
            ep2VandR: [
                "With joy shall ye draw wa_ter.",
                "From the wells of sal_va_tion.",
            ],
            ep2MagId: "sh but when they",
            collect:
                "O God, who hast suffered the Heart of thy Son to be wounded by our sins, and in that very Heart hast bestowed on us the abundant riches of thy love: grant, we beseech thee; that the devout homage of our hearts which we render unto him, may of thy mercy be deemed a recompense acceptable in thy sight; through the same Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        });
    modify(calendar)
        .onCelebration("trinity-sunday")
        .getNthAfter(2, "sat")
        .placeCelebration({
            label: "immacuate-heart-of-mary",
            rank: 10,
            type: "memorial",
            title: "The Immaculate Heart of the Blessed Virgin Mary",
            commons: ["Blessed Virgin Mary"],
            collect:
                "Almighty and everlasting God, who in the heart of the Blessed Virgin Mary didst prepare an habitation meet for the Holy Spirit: mercifully grant that we, celebrating with devout mind the festival of the same Immaculate Heart, may be enabled to live after thine own Heart; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the same Holy Spirit, ever one God, world without end. Amen.",
        });

    const dateOfSeptuagesima = calendar.days.findIndex(
        (d) =>
            d.celebrations.find((c) => c.label === "septuagesima") !== undefined
    );
    const candlemasIsAfterSeptuagesima = dateOfSeptuagesima < 33;
    modify(calendar)
        .onDate(2, 2)
        .placeCelebration({
            label: "the-presentation-of-the-lord",
            title: "The Presentation of the Lord",
            commonlyCalled: "Candlemas",
            type: "feast",
            feastHasFirstEvensong: true,
            rank: 5,
            optional: false,
            collect:
                "Almighty and everliving God, we humbly beseech thy majesty: that, as thine Only Begotten Son was this day presented in the temple in substance of our flesh; so we may be presented unto thee with pure and clean hearts, by the same thy Son Jesus Christ our Lord; who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
            ep1HymnId: "all prophets hail thee from of old announcing",
            ep1VandR: [
                "It was re_vealed un_to Si_me_on by the Ho_ly Ghost.",
                "That he should not see death be_fore he had seen the Lord's Christ.",
            ],
            ep1MagId: "presentation the ancient",
            invId: "presentation behold the lord",
            mpHymnId: "the god whom earth and sea and sky",
            mpVandR: [
                "Full of grace are thy lips.",
                "Be_cause God hath blessed thee for_e_ver.",
            ],
            mpBenId: "presentation and when the parents",
            ep2HymnId: candlemasIsAfterSeptuagesima
                ? "all prophets hail thee from of old announcing"
                : "candlemas sequence",
            ep2VandR: [
                "It was re_vealed un_to Si_me_on by the Ho_ly Ghost.",
                "That he should not see death be_fore he had seen the Lord's Christ.",
            ],
            ep2MagId: "presentation today the blessed",
        });

    ORD_SAINTS.forEach((ordSaint) => {
        const type: any = ordSaint.type;

        const celebration: Celebration = {
            label: (
                ordSaint.title.replace("Æ", "ae") +
                " " +
                (ordSaint.regionNote ?? "")
                    ?.replaceAll("(", "")
                    .replaceAll(")", "")
                    .replaceAll(":", "")
                    .replaceAll(",", "")
            )
                .trim()
                .replaceAll(",", "")
                .replaceAll(" ", "-")
                .toLocaleLowerCase(),
            rank: ordSaint.rank,
            type,
            title: ordSaint.title,
            subtitle: ordSaint.subtitle,
            shortTitle: ordSaint.shortTitle,
            optional: ordSaint.optional,
            regionNote: ordSaint.regionNote,
            collect: ordSaint.collect,
            alternativeCollects: ordSaint.alternativeCollects,
            commonlyCalled: ordSaint.commonlyCalled,
            commons: ordSaint.commons,
            properPsalmsOptional: ordSaint.properPsalmsOptional,
            propersOfTheFeria: ordSaint.propersOfTheFeria,
            feastHasFirstEvensong: ordSaint.feastHasFirstEvensong,
            athanasianCreed: ordSaint.athanasianCreed,
            ...ordSaint.supplements,
        };

        modify(calendar)
            .onDate(ordSaint.day, ordSaint.month)
            .placeCelebration(celebration);
    });

    // Sundays in Lent
    modify(calendar)
        .onCelebration("easter-day")
        .getNthBefore(1, "sun")
        .placeCelebration({
            label: "palm-sunday",
            rank: 2,
            type: "sunday",
            title: "Palm Sunday",
            invId: "passiontide inv",
            ep1HymnId: "the royal banners forward go",
            ep1VandR: [
                "De_li_ver me, O Lord, from the e_vil man.",
                "And pre_serve me from the wi_cked man.",
            ],
            ep1MagId: "hw righteous father",
            mpHymnId: "thirty years among us dwelling",
            mpVandR: [
                "De_li_ver me from mine e_ne_mies, O God.",
                "De_fend me from them that rise up a_gainst me.",
            ],
            mpBenId: "hw much people",
            ep2HymnId: "the royal banners forward go",
            ep2VandR: [
                "De_li_ver me, O Lord, from the e_vil man.",
                "And pre_serve me from the wi_cked man.",
            ],
            ep2MagId: "hw for it is written",
            collect:
                "ALMIGHTY and everlasting God, who, of thy of tender love towards mankind, hast sent thy Son our Saviour Jesus Christ, to take upon him our flesh, and to suffer death upon the Cross, that all mankind should follow the example of his great humility: mercifully grant that we may follow the example of his patience, and so be made partakers of his Resurrection; through the same Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        });
    modify(calendar)
        .onCelebration("easter-day")
        .getNthBefore(2, "sun")
        .placeCelebration({
            label: "lent-5",
            rank: 2,
            type: "sunday",
            title: "The Fifth Sunday in Lent",
            commonlyCalled: "Passion Sunday",
            shortTitle: "Passion Sunday",
            ep1HymnId: "the royal banners forward go",
            ep1VandR: [
                "De_li_ver me, O Lord, from the e_vil man.",
                "And pre_serve me from the wi_cked man.",
            ],
            ep1MagId: "l5 i am one",
            invId: "passiontide inv",
            mpHymnId: "sing my tongue the glorious battle",
            mpVandR: [
                "De_li_ver me from mine e_ne_mies, O God.",
                "De_fend me from them that rise up a_gainst me.",
            ],
            mpBenId: "l5 jesus said unto the",
            ep2HymnId: "the royal banners forward go",
            ep2VandR: [
                "De_li_ver me, O Lord, from the e_vil man.",
                "And pre_serve me from the wi_cked man.",
            ],
            ep2MagId: "l5 your father abraham",
            collect:
                "We beseech thee, Almighty God, mercifully to look upon thy people: that by thy great goodness they may be governed and preserved evermore, both in body and soul; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
            weekdays: [
                {
                    benId: "l5 in the last day of the feast",
                    magId: "l5 if any man thirst",
                    collect:
                        "Let thy bountiful goodness, O Lord, we beseech thee, sanctify this our fasting: and effectually bestow upon us the remission of all our sins; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
                },
                {
                    benId: "l5 my time is not yet come",
                    magId: "l5 go ye up",
                    collect:
                        "Grant, O Lord, we pray thee, that this our fasting may be acceptable in thy sight: that we, being cleansed from our sins, and made worthy of thy grace, may be made whole unto everlasting life; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
                },
                {
                    benId: "l5 my sheep hear",
                    magId: "l5 many good works",
                    collect:
                        "O merciful God, we beseech thee to sanctify this our fasting to the enlightenment of the hearts of thy faithful people: that we, to whom thou hast given an hearty desire to pray, may obtain of thy loving-kindness the fulfilment of all our petitions; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
                },
                {
                    benId: "l5 the master saith",
                    magId: "l5 with desire have i",
                    collect:
                        "Grant, we beseech thee, Almighty God: that we, who by our self-indulgence have corrupted the worthiness of man's condition, may by devout abstinence heal and restore the same; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
                },
                {
                    benId: "l5 now the feast day",
                    magId: "l5 the chief priests",
                    collect:
                        "We beseech thee, O Lord, mercifully to pour thy grace into our hearts: that we, who by willing chastisement restrain our sins, may rather mortify ourselves in this life, than be condemned to eternal punishment; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
                },
                {
                    benId: "l5 o father glorify",
                    collect:
                        "Grant, O Lord, that thy people who are consecrated to thy service may continually increase in all godly affections: that they, being duly instructed in all holy learning, may both be made the more acceptable unto thy divine majesty, and prospered more abundantly in the gifts of thy bounty; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
                },
            ],
        });
    modify(calendar)
        .onCelebration("easter-day")
        .getNthBefore(2, "sun")
        .getNthAfter(1, "fri")
        .placeCelebration({
            label: "st-mary-in-passiontide",
            rank: 12,
            type: "memorial",
            optional: true,
            title: "Saint Mary in Passiontide",
            invId: "passiontide inv",
            mpHymnId: "sing my tongue the glorious battle",
            mpVandR: [
                "O Vir_gin Ma_ry, by the vir_tue of so ma_ny sor_rows.",
                "Make us to re_joice in the hea_ven_ly king_dom.",
            ],
            mpBenId: "sorrows come ye",
            ep2HymnId: "the royal banners forward go",
            ep2VandR: [
                "Pray for us, O Queen of Mar_tyrs.",
                "Who didst stand by the Cross of Je_sus.",
            ],
            ep2MagId: "sorrows sorrow hath weighed",
            collect:
                "O Lord, in whose Passion, according to the prophecy of Simeon, the sword of sorrow did pierce the most loving soul of thy glorious Virgin Mother Mary: mercifully grant that we, who devoutly call to mind the suffering whereby she was pierced, may, by the glorious merits and prayers of all the Saints who have stood beneath the Cross, obtain with gladness the benefits of thy Passion; who livest and reignest with the Father, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        });
    modify(calendar)
        .onCelebration("easter-day")
        .getNthBefore(3, "sun")
        .placeCelebration({
            label: "lent-4",
            rank: 2,
            type: "sunday",
            title: "The Fourth Sunday in Lent",
            shortTitle: "Lent IV",
            ep1HymnId: "now is the healing time decreed",
            ep1VandR: [
                "God shall give his An_gels charge o_ver thee.",
                "To keep thee in all thy ways.",
            ],
            ep1MagId: "l4 woman hath no",
            invId: "l1 inv",
            mpHymnId: "the glory of these forty days",
            mpVandR: [
                "God shall give his An_gels charge o_ver thee.",
                "To keep thee in all thy ways.",
            ],
            mpBenId: "l4 when jesus lift up",
            ep2HymnId: "now is the healing time decreed",
            ep2VandR: [
                "God shall give his An_gels charge o_ver thee.",
                "To keep thee in all thy ways.",
            ],
            ep2MagId: "l4 jesus went up",
            collect:
                "GRANT, we beseech thee, Almighty God: that we, who for our evil deeds do worthily deserve to be punished, by the comfort of thy grace, may mercifully be relieved; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
            sundayInLent: true,
            weekdays: [
                {
                    benId: "l4 take these things hence",
                    magId: "l4 destroy this temple",
                    collect:
                        "Grant, we beseech thee, Almighty God: that we who year by year devoutly keep this holy ordinance of fasting, may serve thee acceptably both in body and soul; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
                },
                {
                    benId: "l4 wherefore do ye seek",
                    magId: "l4 no man laid hands",
                    collect:
                        "We beseech thee, O Lord: that we may in such wise observe this solemn ordinance of fasting; that by reason of the same we may increase in all godliness of life, and effectually obtain the continual succour of thy mercy; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
                },
                {
                    benId: "l4 master wherein hath",
                    magId: "l4 a man that is called",
                    collect:
                        "O God, who dost grant unto the righteous the reward of their godliness, and unto sinners that turn unto thee with fasting the pardon of their offences: have compassion, we pray thee, upon us thy humble servants; that we, confessing the sins which we have committed, may obtain of thee the remission of all our iniquities; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
                },
                {
                    benId: "l4 jesus went into",
                    magId: "l4 a great prophet",
                    collect:
                        "Grant, we beseech thee, Almighty God: that we, duly mortifying the flesh by this abstinence, may inwardly rejoice in holiness of spirit; that being delivered from all earthly and carnal affections, we may speedily obtain thy heavenly promises; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
                },
                {
                    benId: "l4 our friend lazarus",
                    magId: "l4 lord if thou hadst",
                    collect:
                        "O God, who hast ordained thine ineffable Sacraments for the regeneration of all men: we humbly beseech thee; that thy Church, being profited by the same to her advancement in all things spiritual, may likewise fail not of thy succour in all things temporal; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
                },
                {
                    benId: "l4 he that followeth me",
                    collect:
                        "O God, forasmuch as our fast is unprofitable unto us unless it be acceptable unto thy loving-kindness: grant, we beseech thee; that the devout affection of our hearts may by thy grace be rendered abundant in the fruit of all good works; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
                },
            ],
        });
    modify(calendar)
        .onCelebration("easter-day")
        .getNthBefore(4, "sun")
        .placeCelebration({
            label: "lent-3",
            rank: 2,
            type: "sunday",
            title: "The Third Sunday in Lent",
            shortTitle: "Lent III",
            ep1HymnId: "now is the healing time decreed",
            ep1VandR: [
                "God shall give his An_gels charge o_ver thee.",
                "To keep thee in all thy ways.",
            ],
            ep1MagId: "l3 but the father",
            invId: "l1 inv",
            mpHymnId: "the glory of these forty days",
            mpVandR: [
                "God shall give his An_gels charge o_ver thee.",
                "To keep thee in all thy ways.",
            ],
            mpBenId: "l3 when a strong man",
            ep2HymnId: "now is the healing time decreed",
            ep2VandR: [
                "God shall give his An_gels charge o_ver thee.",
                "To keep thee in all thy ways.",
            ],
            ep2MagId: "l3 a certain woman",
            collect:
                "We beseech thee, Almighty God: look upon the hearty desires of thy humble servants, and stretch forth the right hand of thy majesty, to be our defence against all our enemies; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
            sundayInLent: true,
            weekdays: [
                {
                    benId: "l3 verily i say unto you",
                    magId: "l3 but jesus",
                    collect:
                        "We beseech thee, O Lord, mercifully to pour thy grace into our hearts: that like as we do refrain our bodies from carnal feastings, so we may be inwardly delivered from all wantonness that may hurt the soul; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
                },
                {
                    benId: "l3 if two of you",
                    magId: "l3 where two or three",
                    collect:
                        "Almighty and most merciful God: we beseech thee graciously to hearken unto our prayers; that we may obtain of thy great goodness the gift of continence unto our salvation; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
                },
                {
                    benId: "l3 hearken",
                    magId: "l3 to eat with",
                    collect:
                        "Grant, we beseech thee, O Lord: that we, being taught by abstinence in the way of salvation, may in such wise learn to refrain ourselves from all vices that may hurt the soul; that we may readily obtain the pardon of thy loving-kindness; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
                },
                {
                    benId: "l3 and devils also",
                    magId: "l3 whosoever had any",
                    collect:
                        "Grant, we beseech thee, Almighty God: that the holy devotion of this fast may both increase our purity, and render us acceptable to thy majesty; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
                },
                {
                    benId: "l3 whosoever drinketh",
                    magId: "l3 sir i perceive",
                    collect:
                        "Assist, O Lord, we pray thee, this our fasting with thy most gracious favour: that like as we do refrain from food in our outward bodies, so we may fast from sin in our inward souls; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
                },
                {
                    benId: "l3 and jesus stooped down",
                    collect:
                        "Grant, we beseech thee, Almighty God: that like as we thy family do abstain from food to the mortifying of the flesh; so we may likewise fast from sin to the following after righteousness; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
                },
            ],
        });
    modify(calendar)
        .onCelebration("easter-day")
        .getNthBefore(5, "sun")
        .placeCelebration({
            label: "lent-2",
            rank: 2,
            type: "sunday",
            title: "The Second Sunday in Lent",
            shortTitle: "Lent II",
            ep1HymnId: "the fast as taught by holy lore",
            ep1VandR: [
                "God shall give his An_gels charge o_ver thee.",
                "To keep thee in all thy ways.",
            ],
            ep1MagId: "l2 tell the vision",
            invId: "l1 inv",
            mpHymnId: "o kind creator bow thine ear",
            mpVandR: [
                "God shall give his An_gels charge o_ver thee.",
                "To keep thee in all thy ways.",
            ],
            mpBenId: "l2 and jesus went thence",
            ep2HymnId: "the fast as taught by holy lore",
            ep2VandR: [
                "God shall give his An_gels charge o_ver thee.",
                "To keep thee in all thy ways.",
            ],
            ep2MagId: "l2 o woman great is",
            collect:
                "Almighty God, who seest that we have no power of ourselves to help ourselves: keep us both outwardly in our bodies, and inwardly in our souls; that we may be defended from all adversities which may happen to the body, and from all evil thoughts which may assault and hurt the soul; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
            sundayInLent: true,
            weekdays: [
                {
                    benId: "l2 i am the beginning",
                    magId: "l2 he that sent me",
                    collect:
                        "Grant, we beseech thee, Almighty God: that, like as we thy family do abstain from food to the mortifying of the flesh; so we may likewise fast from sin to the following after righteousness; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
                },
                {
                    benId: "l2 one is your master",
                    magId: "l2 all ye are brethren",
                    collect:
                        "O most merciful God, we beseech thee so to perfect in us the work of this holy observance: that we, who by thine inspiration know those things that we ought to do, may likewise by thee be enabled to perform the same; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
                },
                {
                    benId: "l2 behold we go up",
                    magId: "l2 he shall be delivered",
                    collect:
                        "We beseech thee, O Lord, graciously to hear the prayers of thy people: that we, who by thine ordinance abstain from carnal food, may likewise be delivered from all sins that hurt the soul; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
                },
                {
                    benId: "l2 son remember",
                    magId: "l2 the same rich man",
                    collect:
                        "Grant to us, Lord, we beseech thee, the help of thy grace: that we, being devoutly given to abstinence and prayer, may be preserved both in body and soul from all the assaults of our enemies; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
                },
                {
                    benId: "l2 he will miserably",
                    magId: "l2 when they sought to lay",
                    collect:
                        "Almighty God: we humbly beseech thee, that we may in such wise be cleansed by this holy time of fasting; that we may keep the coming feast in all sincerity; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
                },
                {
                    benId: "l2 i will go unto my father",
                    collect:
                        "Grant, we beseech thee, O Lord, that this our abstinence may so work in us to good effect: that we, who now submit ourselves to the mortifying of the flesh, may be profited thereby to the quickening of our souls; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
                },
            ],
        });
    modify(calendar)
        .onCelebration("easter-day")
        .getNthBefore(6, "sun")
        .placeCelebration({
            label: "lent-1",
            rank: 2,
            type: "sunday",
            title: "The First Sunday in Lent",
            shortTitle: "Lent I",
            ep1HymnId: "the fast as taught by holy lore",
            ep1VandR: [
                "God shall give his An_gels charge o_ver thee.",
                "To keep thee in all thy ways.",
            ],
            ep1MagId: "l1 then thou shalt",
            invId: "l1 inv",
            mpHymnId: "o kind creator bow thine ear",
            mpVandR: [
                "God shall give his An_gels charge o_ver thee.",
                "To keep thee in all thy ways.",
            ],
            mpBenId: "l1 then was jesus led",
            ep2HymnId: "the fast as taught by holy lore",
            ep2VandR: [
                "God shall give his An_gels charge o_ver thee.",
                "To keep thee in all thy ways.",
            ],
            ep2MagId: "l1 behold now is",
            sundayInLent: true,
            collect:
                "O Lord, who for our sake didst fast forty days and forty nights: give us grace to use such abstinence, that, our flesh being subdued to the Spirit, we may ever obey thy godly motions in righteousness and true holiness, to thy honour and glory; who livest and reignest with the Father, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
            weekdays: [
                {
                    benId: "l1 come ye blessed",
                    magId: "l1 inasmuch",
                    collect:
                        "Turn thou us, O God our Saviour: and that this our Lenten fast may be profitable unto us, vouchsafe, we pray thee, to instruct our minds in all heavenly learning; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
                },
                {
                    benId: "l1 and jesus went into",
                    magId: "l1 truly it is written",
                    collect:
                        "We beseech thee, O Lord, graciously to behold this thy family: that, whereas we now do chasten our bodies by the mortifying of the flesh, our souls may shine in thy sight with longing after thee; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
                },
                {
                    rank: 10,
                    title: "Ember Wednesday in Lent",
                    benId: "l1 this crooked and perverse",
                    magId: "l1 for as jonah",
                    collect:
                        "We beseech thee, O Lord, that thou wouldest graciously hear our prayers: and stretch forth the right hand of thy majesty to be our defence against all adversities; through Jesus Christ our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
                },
                {
                    benId: "l1 and jesus went thence",
                    magId: "l1 o woman great is your faith",
                    collect:
                        "We beseech thee, O Lord, graciously to regard the devout prayers of thy people: that they, who by abstinence do mortify the deeds of the flesh, may by the fruit of good be renewed in the spirit of their minds; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
                },
                {
                    rank: 10,
                    title: "Ember Friday in Lent",
                    benId: "l1 an angel of the lord",
                    magId: "l1 he that made me",
                    collect:
                        "We beseech thee, O Lord, mercifully to have compassion on thy people: that they, who by thee are enabled to serve thee in all godliness, may ever be comforted by thy gracious and ready help; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
                },
                {
                    rank: 10,
                    title: "Ember Saturday in Lent",
                    benId: "l1 and jesus taketh",
                    collect:
                        "We beseech thee, O Lord, graciously to hear the prayers of thy people, and of thy great goodness turn aside from them the scourges of thine anger; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
                },
            ],
        });
    modify(calendar)
        .onCelebration("easter-day")
        .getNthBefore(7, "sun")
        .placeCelebration({
            label: "quinquagesima",
            rank: 6,
            type: "sunday",
            title: "The Sunday Next Before Lent",
            commonlyCalled: "Quinquagesima",
            shortTitle: "Quinquagesima",
            sundayAfterEpiphany: true,
            ep1MagId: "quinquagesima mighty abraham",
            invId: "gesima let us come",
            mpBenId: "quinquagesima behold we go up",
            ep2MagId: "quinquagesima and jesus standing",
            collect:
                "O Lord, who hast taught us that all our doings without charity are nothing worth: send thy Holy Spirit, and pour into our hearts that most excellent gift of charity, the very bond of peace and of all virtues, without which whosoever liveth is counted dead before thee; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
            weekdays: [
                {
                    invId: "gesima let us come",
                    magId: "quinquagesima and they which",
                },
                {
                    invId: "gesima let us come",
                    magId: "quinquagesima have mercy",
                },
                {
                    invId: "gesima let us come",
                    title: "Ash Wednesday",
                    rank: 2,
                    benId: "ash weds when ye fast",
                    magId: "ash weds lay up",
                    mpProperPsalms: ["6", "32", "38"],
                    epProperPsalms: ["102", "130", "143"],
                    collect:
                        "Almighty and everlasting God, who hatest nothing that thou hast made and dost forgive the sins of all those who are penitent: create and make in us new and contrite hearts, that we, worthily lamenting our sins and acknowledging our wretchedness, may obtain of thee, the God of all mercy, perfect remission and forgiveness; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
                },
                {
                    invId: "gesima let us come",
                    title: "Thursday following Ash Wednesday",
                    benId: "ash weds lord my servant",
                    magId: "ash weds lord i am not worthy",
                    collect:
                        "O God who art wroth with them that sin against thee, and sparest them that are penitent: we beseech thee to hear the prayers of thy people that call upon thee; that we, who have most justly deserved the scourges of thine anger, may by thy great mercy be delivered from the same; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
                    rank: 9,
                },
                {
                    invId: "gesima let us come",
                    title: "Friday following Ash Wednesday",
                    benId: "ash weds when thou doest thine alms",
                    magId: "ash weds but thou when",
                    collect:
                        "We beseech thee, O Lord, to further with thy gracious favour the fast which we have here begun: that we, who with our bodies therein do thee outward worship, may inwardly perform the same in singleness of heart; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
                    rank: 9,
                },
                {
                    invId: "gesima let us come",
                    title: "Saturday following Ash Wednesday",
                    benId: "ash weds yet they seek",
                    collect:
                        "Assist us mercifully, O Lord, in these our supplications: and grant that, like as this fast hath been ordained for the healing of our bodies and our souls, so we may in all godliness and lowliness observe the same; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
                    rank: 9,
                },
            ],
        });
    modify(calendar)
        .onCelebration("easter-day")
        .getNthBefore(8, "sun")
        .placeCelebration({
            label: "sexagesima",
            rank: 6,
            type: "sunday",
            title: "The Second Sunday Before Lent",
            commonlyCalled: "Sexagesima",
            shortTitle: "Sexagesima",
            sundayAfterEpiphany: true,
            ep1MagId: "sexagesima the lord spake unto noah",
            invId: "gesima let us come",
            mpBenId: "sexagesima when much people",
            ep2MagId: "sexagesima unto you it is given",
            collect:
                "O Lord God, who seest that we put not our trust in anything that we do: mercifully grant that by thy power, we may be defended against all adversity; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
            weekdays: [
                {
                    invId: "gesima let us come",
                    magId: "sexagesima if ye seek",
                },
                {
                    invId: "gesima let us come",
                    magId: "sexagesima the seed",
                },
                {
                    invId: "gesima let us come",
                    magId: "sexagesima but that",
                },
                {
                    invId: "gesima let us come",
                    magId: "sexagesima if then brethren",
                },
                {
                    invId: "gesima let us come",
                    magId: "sexagesima they which keep",
                },
                {
                    invId: "gesima let us come",
                },
            ],
        });
    modify(calendar)
        .onCelebration("easter-day")
        .getNthBefore(9, "sun")
        .placeCelebration({
            label: "septuagesima",
            rank: 6,
            type: "sunday",
            title: "The Third Sunday Before Lent",
            commonlyCalled: "Septuagesima",
            shortTitle: "Septuagesima",
            sundayAfterEpiphany: true,
            ep1MagId: "septuagesima the lord said",
            invId: "gesima let us come",
            mpVandR: [
                "O Lord, thou hast been our re_fuge.",
                "From one ge_ne_ra_tion to a_no_ther.",
            ],
            mpBenId: "septuagesima the kingdom of",
            ep2MagId: "septuagesima the householder said",
            weekdays: [
                {
                    invId: "gesima let us come",
                    magId: "septuagesima these last",
                },
                {
                    invId: "gesima let us come",
                    magId: "septuagesima but the householder",
                },
                {
                    invId: "gesima let us come",
                    magId: "septuagesima take that",
                },
                {
                    invId: "gesima let us come",
                    magId: "septuagesima is it not",
                },
                {
                    invId: "gesima let us come",
                    magId: "septuagesima so the last",
                },
                { invId: "gesima let us come" },
            ],
            collect:
                "O Lord, we beseech thee favourably to hear the prayers of thy people: that we, who are justly punished for our offences, may be mercifully delivered by thy goodness, for the glory of thy Name; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        });

    modify(calendar)
        .onDate(22, 2)
        .placeCelebration({
            label: "the-chair-of-saint-peter",
            title: "The Chair of Saint Peter",
            subtitle: "",
            type: "feast",
            rank: 7,
            optional: false,
            collect:
                "O Almighty God, who by thy Son Jesus Christ didst give to thy Apostle Saint Peter many excellent gifts, and commandedst him earnestly to feed thy flock: make, we beseech thee, all Bishops and Pastors diligently to preach thy holy Word, and the people obediently to follow the same; that they may receive the crown of everlasting glory; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
            ep1HymnId:
                "lord of creation bow thine ear o christ to hear chair of st peter",
            ep1VandR: [
                "Thou art Pe_ter.",
                "And u_pon this rock I will build my Church.",
            ],
            ep1MagId: "chair of st peter thou art",
            invId: "chair of st peter inv",
            mpHymnId:
                "lord of creation bow thine ear o christ to hear chair of st peter",
            mpVandR: [
                "Let them ex_alt him in the con_gre_ga_tion of the peo_ple.",
                "And praise him in the seat of the el_ders.",
            ],
            mpBenId: "chair of st peter whatsoever",
            ep2HymnId: "whateer on earth below thy word o peter chained",
            ep2VandR: [
                "The Lord chose him for a Priest un_to him_self.",
                "To of_fer unto him the sa_cri_fice of praise.",
            ],
            ep2MagId: "chair of st peter being a high priest",
            feastHasFirstEvensong: true,
        });

    // Sundays in Epiphany (lower precedence than sundays in Lent)
    modify(calendar).onDate(6, 1).getNthAfter(2, "sun").placeCelebration({
        label: "epiphany-2",
        rank: 6.1,
        type: "sunday",
        title: "The Second Sunday after the Epiphany",
        shortTitle: "Epiphany II",
        sundayAfterEpiphany: true,
        collect:
            "Almighty and everlasting God, who dost govern all things in heaven and earth: mercifully hear the supplications of thy people; and grant us thy peace all the days of our life; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        ep1MagId: "ep sat ep1 god hath holpen",
        mpBenId: "ep2 there was a marriage",
        ep2MagId: "ep2 and when they",
    });
    modify(calendar).onDate(6, 1).getNthAfter(3, "sun").placeCelebration({
        label: "epiphany-3",
        rank: 6.1,
        type: "sunday",
        title: "The Third Sunday after the Epiphany",
        shortTitle: "Epiphany III",
        sundayAfterEpiphany: true,
        collect:
            "Almighty and everlasting God: mercifully look upon our infirmities; and in all our dangers and necessities, stretch forth thy right hand to help and defend us; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        mpBenId: "ep3 and when jesus",
        ep1MagId: "ep sat ep1 god hath holpen",
        ep2MagId: "ep3 lord if thou wilt",
    });
    modify(calendar).onDate(6, 1).getNthAfter(4, "sun").placeCelebration({
        label: "epiphany-4",
        rank: 6.1,
        type: "sunday",
        title: "The Fourth Sunday after the Epiphany",
        shortTitle: "Epiphany IV",
        sundayAfterEpiphany: true,
        ep1MagId: "ep sat ep1 god hath holpen",
        mpBenId: "ep4 when jesus",
        ep2MagId: "ep4 master save",
        collect:
            "O God, who knowest us to be set in the midst of so many and great dangers, that by reason of the frailty of our nature we cannot always stand upright: grant to us such strength and protection, as may support us in all dangers, and carry us through all temptations; through Jesus thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
    });
    modify(calendar).onDate(6, 1).getNthAfter(5, "sun").placeCelebration({
        label: "epiphany-5",
        rank: 6.1,
        type: "sunday",
        title: "The Fifth Sunday after the Epiphany",
        shortTitle: "Epiphany V",
        sundayAfterEpiphany: true,
        ep1MagId: "ep sat ep1 god hath holpen",
        mpBenId: "ep5 master sowedst",
        ep2MagId: "ep5 gather ye",
        collect:
            "O Lord, we beseech thee to keep thy Church and household continually in thy true religion: that they who do lean only upon the hope of thy heavenly grace, may evermore be defended by thy mighty power; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
    });
    modify(calendar).onDate(6, 1).getNthAfter(6, "sun").placeCelebration({
        label: "epiphany-6",
        rank: 6.1,
        type: "sunday",
        title: "The Sixth Sunday after the Epiphany",
        shortTitle: "Epiphany VI",
        sundayAfterEpiphany: true,
        ep1MagId: "ep sat ep1 god hath holpen",
        mpBenId: "ep6 as the lightning",
        ep2MagId: "ep6 wheresoever",
        collect:
            "O God, whose blessed Son was manifested that he might destroy the works of the devil, and make us the sons of God, and heirs of eternal life: grant us, we beseech thee, that, having this hope, we may purify ourselves, even as he is pure; that, when he shall appear again with power and great glory, we may be made like unto him in his eternal and glorious kingdom; through the same Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
    });

    // Sundays in Easter
    modify(calendar)
        .onCelebration("easter-day")
        .getNthAfter(1, "sun")
        .placeCelebration({
            label: "easter-2",
            type: "sunday",
            rank: 1,
            title: "The Second Sunday of Easter",
            shortTitle: "Easter II",
            ep1HymnId: "ye choirs of new jerusalem",
            ep1VandR: [
                "A_bide with us, O Lord.",
                "For it is to_ward eve_ning.",
            ],
            ep1MagId: "e2 that same evening",
            invId: "easter inv",
            mpHymnId: "the day draws on with golden light",
            mpVandR: [
                "In thy re_sur_rec_tion, O Christ.",
                "Let hea_ven and earth re_joice.",
            ],
            mpBenId: "e2 that same evening",
            ep2HymnId: "the lambs high banquet we await",
            ep2VandR: [
                "A_bide with us, O Lord.",
                "For it is to_ward eve_ning.",
            ],
            ep2MagId: "e2 after eight days",
            collect:
                "Almighty Father, who hast given thine only Son to die for our sins, and to rise again for our justification: grant us so to put away the leaven of malice and wickedness; that we may always serve thee in pureness of living and truth; through the merits of the same Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
            sundayAfterEaster: true,
            weekdays: [
                {
                    benId: "e2 when jesus was risen",
                    magId: "e2 peace be unto you",
                },
                {
                    benId: "e2 i go before you",
                    magId: "e2 reach hither",
                },
                {
                    benId: "e2 i am the true vine",
                    magId: "e2 because thou hast",
                },
                {
                    benId: "e2 my heart burns",
                    magId: "e2 i did put",
                },
                {
                    benId: "e2 there came unto the tomb",
                    magId: "e2 after ... when the doors",
                },
                {}, // saturday
            ],
        });
    modify(calendar)
        .onCelebration("easter-day")
        .getNthAfter(2, "sun")
        .placeCelebration({
            label: "easter-3",
            type: "sunday",
            rank: 2,
            title: "The Third Sunday of Easter",
            shortTitle: "Easter III",
            ep1HymnId: "ye choirs of new jerusalem",
            ep1VandR: [
                "A_bide with us, O Lord.",
                "For it is to_ward eve_ning.",
            ],
            ep1MagId: "e3 i am the shepherd",
            invId: "easter inv",
            mpHymnId: "the day draws on with golden light",
            mpVandR: [
                "In thy re_sur_rec_tion, O Christ.",
                "Let hea_ven and earth re_joice.",
            ],
            mpBenId: "e3 i am the shepherd",
            ep2HymnId: "the lambs high banquet we await",
            ep2VandR: [
                "A_bide with us, O Lord.",
                "For it is to_ward eve_ning.",
            ],
            ep2MagId: "e3 i am the good shepherd",
            collect:
                "Almighty God, who hast given thine only Son to be unto us both a sacrifice for sin, and also an example of godly life: give us grace that we may always most thankfully receive his inestimable benefit; and also daily endeavour ourselves to follow the blessed steps of his most holy life; through the same Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
            sundayAfterEaster: true,
            weekdays: [
                {
                    benId: "e3 go ye into all",
                    magId: "e3 the good shepherd lays",
                },
                {
                    benId: "e3 go ye into the world",
                    magId: "e3 he that is an hireling",
                },
                {
                    benId: "e3 go unto my",
                    magId: "e3 as the father knows",
                },
                {
                    benId: "e3 art thou only",
                    magId: "e3 other sheep i have",
                },
                {
                    benId: "e3 ought not christ",
                    magId: "e3 i am the good shepherd",
                },
                {}, // saturday
            ],
        });
    modify(calendar)
        .onCelebration("easter-day")
        .getNthAfter(3, "sun")
        .placeCelebration({
            label: "easter-4",
            type: "sunday",
            rank: 2,
            title: "The Fourth Sunday of Easter",
            commonlyCalled: "Good Shepherd Sunday",
            shortTitle: "Easter IV",
            ep1HymnId: "ye choirs of new jerusalem",
            ep1VandR: [
                "A_bide with us, O Lord.",
                "For it is to_ward eve_ning.",
            ],
            ep1MagId: "e4 a little while",
            invId: "easter inv",
            mpHymnId: "the day draws on with golden light",
            mpVandR: [
                "In thy re_sur_rec_tion, O Christ.",
                "Let hea_ven and earth re_joice.",
            ],
            mpBenId: "e4 a little while",
            ep2HymnId: "the lambs high banquet we await",
            ep2VandR: [
                "A_bide with us, O Lord.",
                "For it is to_ward eve_ning.",
            ],
            ep2MagId: "e4 verily i say",
            collect:
                "Almighty God, who showest to them that be in error the light of thy truth, to the intent that they may return into the way of righteousness: grant unto all them that are admitted into the fellowship of Christ's religion; that they may forsake those things that are contrary to their profession, and follow all such things as are agreeable to the same; through the same Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
            sundayAfterEaster: true,
            weekdays: [
                {
                    benId: "e4 and beginning",
                    magId: "e4 your sorrow shall be",
                },
                {
                    benId: "e4 and they constrained",
                    magId: "e4 sorrow hath filled",
                },
                {
                    benId: "e4 abide with us for",
                    magId: "e4 your sorrow will be turned",
                },
                {
                    benId: "e4 and he went in",
                    magId: "e4 verily verily i say",
                },
                {
                    benId: "e4 they knew the lord",
                    magId: "e4 verily i say",
                },
                {}, // saturday
            ],
        });
    modify(calendar)
        .onCelebration("easter-day")
        .getNthAfter(4, "sun")
        .placeCelebration({
            label: "easter-5",
            type: "sunday",
            rank: 2,
            title: "The Fifth Sunday of Easter",
            shortTitle: "Easter V",
            ep1HymnId: "ye choirs of new jerusalem",
            ep1VandR: [
                "A_bide with us, O Lord.",
                "For it is to_ward eve_ning.",
            ],
            ep1MagId: "e5 i go my way",
            invId: "easter inv",
            mpHymnId: "the day draws on with golden light",
            mpVandR: [
                "In thy re_sur_rec_tion, O Christ.",
                "Let hea_ven and earth re_joice.",
            ],
            mpBenId: "e5 i go my way",
            ep2HymnId: "the lambs high banquet we await",
            ep2VandR: [
                "A_bide with us, O Lord.",
                "For it is to_ward eve_ning.",
            ],
            ep2MagId: "e5 i go my way",
            collect:
                "Almighty God, who alone makest the minds of the faithful to be of one will: grant unto thy people, that they may love the thing which thou commandest, and desire that which thou dost promise; that so, among sundry and manifold changes of the world, our hearts may surely there be fixed, where true joys are to be found; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
            sundayAfterEaster: true,
            weekdays: [
                {
                    benId: "e5 did not our heart",
                    magId: "e5 i tell you the truth",
                },
                {
                    benId: "e5 peace but unto you it is",
                    magId: "e5 when the comforter",
                },
                {
                    benId: "e5 a spirit hath not",
                    magId: "e5 i have yet many",
                },
                {
                    benId: "e5 the disciples offered",
                    magId: "e5 for he shall not",
                },
                {
                    benId: "e5 these are the words",
                    magId: "e5 now i go my way",
                },
                {}, // saturday
            ],
        });
    modify(calendar)
        .onCelebration("easter-day")
        .getNthAfter(5, "sun")
        .placeCelebration({
            label: "easter-6",
            type: "sunday",
            rank: 2,
            title: "The Sixth Sunday of Easter",
            commonlyCalled: "Rogation Sunday",
            shortTitle: "Easter VI",
            ep1HymnId: "ye choirs of new jerusalem",
            ep1VandR: [
                "A_bide with us, O Lord.",
                "For it is to_ward eve_ning.",
            ],
            ep1MagId: "e6 hitherto have ye",
            invId: "easter inv",
            mpHymnId: "the day draws on with golden light",
            mpVandR: [
                "In thy re_sur_rec_tion, O Christ.",
                "Let hea_ven and earth re_joice.",
            ],
            mpBenId: "e6 hitherto have ye",
            ep2HymnId: "the lambs high banquet we await",
            ep2VandR: [
                "A_bide with us, O Lord.",
                "For it is to_ward eve_ning.",
            ],
            ep2MagId: "e6 ask ye",
            collect:
                "O Lord, from whom all good things do come: grant to us thy humble servants; that by thy holy inspiration we may think those things that be good, and by thy merciful guiding may perform the same; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        });

    // Ascension week can have a transfer
    if (ascensionTransferred) {
        modify(calendar)
            .onCelebration("easter-day")
            .getNthAfter(5, "sun")
            .getNthAfter(1, "mon")
            .placeCelebration({
                label: "easter-6-monday",
                type: "feria",
                rank: 12,
                title: "Monday following Easter VI",
                shortTitle: "Easter VI",
                invId: "easter inv",
                mpHymnId: "the day draws on with golden light",
                mpVandR: [
                    "In thy re_sur_rec_tion, O Christ.",
                    "Let hea_ven and earth re_joice.",
                ],
                mpBenId: "e6 hitherto have ye",
                ep2HymnId: "the lambs high banquet we await",
                ep2VandR: [
                    "A_bide with us, O Lord.",
                    "For it is to_ward eve_ning.",
                ],
                ep2MagId: "e6 ask ye",
                collect:
                    "O Lord, from whom all good things do come: grant to us thy humble servants; that by thy holy inspiration we may think those things that be good, and by thy merciful guiding may perform the same; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
            });
    } else {
        modify(calendar)
            .onCelebration("easter-day")
            .getNthAfter(5, "sun")
            .getNthAfter(1, "mon")
            .placeCelebration({
                label: "rogation-monday",
                type: "feria",
                rank: 10.1,
                title: "Rogation Monday",
                invId: "easter inv",
                mpHymnId: "the day draws on with golden light",
                mpVandR: [
                    "In thy re_sur_rec_tion, O Christ.",
                    "Let hea_ven and earth re_joice.",
                ],
                mpBenId: "e6 ask and it shall",
                ep2HymnId: "the lambs high banquet we await",
                ep2VandR: [
                    "A_bide with us, O Lord.",
                    "For it is to_ward eve_ning.",
                ],
                ep2MagId: "e6 for the father",
                collect:
                    "Assist us mercifully, O Lord, in these our supplications and prayers: and dispose the way of thy servants towards the attainment of everlasting salvation; that, among all the changes and chances of this mortal life, they may ever be defended by thy most gracious and ready help; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
            });
        modify(calendar)
            .onCelebration("easter-day")
            .getNthAfter(5, "sun")
            .getNthAfter(1, "tues")
            .placeCelebration({
                label: "rogation-tuesday",
                type: "feria",
                rank: 10.1,
                title: "Rogation Tuesday",
                invId: "easter inv",
                mpHymnId: "the day draws on with golden light",
                mpVandR: [
                    "In thy re_sur_rec_tion, O Christ.",
                    "Let hea_ven and earth re_joice.",
                ],
                mpBenId: "e6 it behoved christ",
                ep2HymnId: "the lambs high banquet we await",
                ep2VandR: [
                    "A_bide with us, O Lord.",
                    "For it is to_ward eve_ning.",
                ],
                ep2MagId: "e6 i came forth",
                collect:
                    "Assist us mercifully, O Lord, in these our supplications and prayers: and dispose the way of thy servants towards the attainment of everlasting salvation; that, among all the changes and chances of this mortal life, they may ever be defended by thy most gracious and ready help; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
            });
        modify(calendar)
            .onCelebration("easter-day")
            .getNthAfter(5, "sun")
            .getNthAfter(1, "wed")
            .placeCelebration({
                label: "rogation-wednesday",
                type: "feria",
                rank: 10.1,
                title: "Rogation Wednesday",
                invId: "easter inv",
                mpHymnId: "the day draws on with golden light",
                mpVandR: [
                    "In thy re_sur_rec_tion, O Christ.",
                    "Let hea_ven and earth re_joice.",
                ],
                mpBenId: "e6 father the hour",
                collect:
                    "Assist us mercifully, O Lord, in these our supplications and prayers: and dispose the way of thy servants towards the attainment of everlasting salvation; that, among all the changes and chances of this mortal life, they may ever be defended by thy most gracious and ready help; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
            });
        modify(calendar)
            .onCelebration("easter-day")
            .getNthAfter(6, "thur")
            .placeCelebration({
                label: "ascension",
                rank: 3,
                type: "solemnity",
                title: "The Ascension of the Lord",
                athanasianCreed: true,
                ep1HymnId: "eternal monarch king most high",
                ep1VandR: [
                    "God is gone up with a merry noise.",
                    "And the Lord with the sound of the trump.",
                ],
                ep1MagId: "ascension father i have",
                invId: "ascension inv",
                mpHymnId: "o christ our joy to whom is given",
                mpVandR: ["The Lord hath pre_pared.", "His throne in hea_ven."],
                mpBenId: "ascension i ascend",
                ep2HymnId: "eternal monarch king most high",
                ep2VandR: [
                    "God is gone up with a merry noise.",
                    "And the Lord with the sound of the trump.",
                ],
                ep2MagId: "ascension o king of",
                collect:
                    "Grant, we beseech thee, Almighty God: that like as we do believe thy Only Begotten Son our Lord Jesus Christ to have ascended into the heavens; so we may also in heart and mind thither ascend, and with him continually dwell; who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
            });
        modify(calendar)
            .onCelebration("easter-day")
            .getNthAfter(6, "fri")
            .placeCelebration({
                label: "ascension-friday",
                rank: 13,
                type: "feria",
                title: "Friday following the Ascension",
                invId: "ascension inv",
                mpHymnId: "o christ our joy to whom is given",
                mpVandR: ["The Lord hath pre_pared.", "His throne in hea_ven."],
                mpBenId: "ascension i ascend",
                ep2HymnId: "eternal monarch king most high",
                ep2VandR: [
                    "God is gone up with a merry noise.",
                    "And the Lord with the sound of the trump.",
                ],
                ep2MagId: "ascension o king of",
                collect:
                    "Grant, we beseech thee, Almighty God: that like as we do believe thy Only Begotten Son our Lord Jesus Christ to have ascended into the heavens; so we may also in heart and mind thither ascend, and with him continually dwell; who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
            });
        modify(calendar)
            .onCelebration("easter-day")
            .getNthAfter(6, "sat")
            .placeCelebration({
                label: "ascension-saturday",
                rank: 13,
                type: "feria",
                title: "Saturday following the Ascension",
                invId: "ascension inv",
                mpHymnId: "o christ our joy to whom is given",
                mpVandR: ["The Lord hath pre_pared.", "His throne in hea_ven."],
                mpBenId: "ascension i ascend",
                ep2HymnId: "eternal monarch king most high",
                ep2VandR: [
                    "God is gone up with a merry noise.",
                    "And the Lord with the sound of the trump.",
                ],
                ep2MagId: "ascension o king of",
                collect:
                    "Grant, we beseech thee, Almighty God: that like as we do believe thy Only Begotten Son our Lord Jesus Christ to have ascended into the heavens; so we may also in heart and mind thither ascend, and with him continually dwell; who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
            });
    }

    modify(calendar)
        .onCelebration("easter-day")
        .getNthAfter(6, "sun")
        .placeCelebration({
            label: "easter-7",
            type: "sunday",
            rank: 2,
            title: "The Seventh Sunday of Easter",
            shortTitle: "Easter VII",
            ep1HymnId: "eternal monarch king most high",
            mpVandR: [
                "God is gone up with a merry noise.",
                "And the Lord with the sound of the trump.",
            ],
            mpHymnId: "o christ our joy to whom is given",
            ep1VandR: ["The Lord hath pre_pared.", "His throne in hea_ven."],
            ep2VandR: ["The Lord hath pre_pared.", "His throne in hea_ven."],
            ep2HymnId: "eternal monarch king most high",
            ep1MagId: "e7 when the",
            invId: "ascension inv",
            mpBenId: "e7 when the",
            ep2MagId: "e7 these things",
            collect:
                "O God, the King of glory, who hast exalted thine only Son Jesus Christ with great triumph unto thy kingdom in heaven: we beseech thee, leave us not comfortless; but send to us thy Holy Spirit to comfort us, and exalt us unto the same place whither our Saviour Christ is gone before; who liveth and reigneth with thee, in the unity of the same Holy Spirit, ever one God, world without end. Amen.",
            sundayAfterEaster: true,
            weekdays: [
                {
                    mpHymnId: "o christ our joy to whom is given",
                    mpVandR: [
                        "The Lord hath pre_pared.",
                        "His throne in hea_ven.",
                    ],
                    benId: "ascension i ascend",
                    ep2HymnId: "eternal monarch king most high",
                    ep2VandR: [
                        "God is gone up with a merry noise.",
                        "And the Lord with the sound of the trump.",
                    ],
                    magId: "ascension o king of",
                },
                {
                    mpHymnId: "o christ our joy to whom is given",
                    mpVandR: [
                        "The Lord hath pre_pared.",
                        "His throne in hea_ven.",
                    ],
                    benId: "ascension i ascend",
                    ep2HymnId: "eternal monarch king most high",
                    ep2VandR: [
                        "God is gone up with a merry noise.",
                        "And the Lord with the sound of the trump.",
                    ],
                    magId: "ascension o king of",
                },
                {
                    mpHymnId: "o christ our joy to whom is given",
                    mpVandR: [
                        "The Lord hath pre_pared.",
                        "His throne in hea_ven.",
                    ],
                    benId: "ascension i ascend",
                    ep2HymnId: "eternal monarch king most high",
                    ep2VandR: [
                        "God is gone up with a merry noise.",
                        "And the Lord with the sound of the trump.",
                    ],
                    magId: "ascension o king of",
                },
                {
                    mpHymnId: "o christ our joy to whom is given",
                    mpVandR: [
                        "The Lord hath pre_pared.",
                        "His throne in hea_ven.",
                    ],
                    benId: "ascension i ascend",
                    ep2HymnId: "eternal monarch king most high",
                    ep2VandR: [
                        "God is gone up with a merry noise.",
                        "And the Lord with the sound of the trump.",
                    ],
                    magId: "ascension o king of",
                },
                {
                    mpHymnId: "o christ our joy to whom is given",
                    mpVandR: [
                        "The Lord hath pre_pared.",
                        "His throne in hea_ven.",
                    ],
                    benId: "ascension i ascend",
                    ep2HymnId: "eternal monarch king most high",
                    ep2VandR: [
                        "God is gone up with a merry noise.",
                        "And the Lord with the sound of the trump.",
                    ],
                    magId: "ascension o king of",
                },
                {
                    mpHymnId: "o christ our joy to whom is given",
                    mpVandR: [
                        "The Lord hath pre_pared.",
                        "His throne in hea_ven.",
                    ],
                    benId: "ascension i ascend",
                    ep2HymnId: "eternal monarch king most high",
                    ep2VandR: [
                        "God is gone up with a merry noise.",
                        "And the Lord with the sound of the trump.",
                    ],
                    magId: "ascension o king of",
                },
            ],
        });

    // Christmas Day, and the Season of Advent
    modify(calendar)
        .onDate(25, 12)
        .placeCelebration({
            label: "christmas-day",
            rank: 1.9,
            type: "solemnity",
            title: "The Nativity of the Lord",
            commonlyCalled: "Christmas",
            athanasianCreed: true,
            collect:
                "Almighty God, who hast given us thy Only Begotten Son to take our nature upon him, and as at this time to be born of a pure Virgin: grant that we being regenerate, and made thy children by adoption and grace, may daily be renewed by thy Holy Spirit; through the same Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
            // TODO: make this collect appear after the collect of the day throughout the octave on other memorials
            mpProperPsalms: ["19", "45", "85"],
            ep2ProperPsalms: ["89", "110", "132"],
            ep1HymnId: "come thou redeemer of the earth",
            ep1VandR: [
                "Tom_mor_row the i_ni_qui_ty of the earth shall be done a_way.",
                "And the Sa_viour of the world shall reign o_ver us.",
            ],
            ep1MagId: "nl or ever the sun",
            invId: "nl unto us the christ is born",
            mpHymnId: "jesu the fathers only son",
            mpVandR: [
                "The Lord hath made known, al_le_lu_ia.",
                "His sal_va_tion, al_le_lu_ia.",
            ],
            mpBenId: "nl glory to god",
            ep2HymnId: "from east to west from shore to shore",
            ep2VandR: [
                "The Lord hath made known, al_le_lu_ia.",
                "His sal_va_tion, al_le_lu_ia.",
            ],
            ep2MagId: "nl today the christ is born",
            weekdays: [
                {
                    title: "The Second Day in the Octave of Christmas",
                    invId: "nl unto us the christ is born",
                    mpHymnId: "jesu the fathers only son",
                    mpVandR: [
                        "The Lord hath made known, al_le_lu_ia.",
                        "His sal_va_tion, al_le_lu_ia.",
                    ],
                    benId: "nl glory to god",
                    ep2HymnId: "from east to west from shore to shore",
                    ep2VandR: [
                        "The Lord hath made known, al_le_lu_ia.",
                        "His sal_va_tion, al_le_lu_ia.",
                    ],
                    magId: "nl today the christ is born",
                    collect:
                        "Almighty God, who hast given us thy Only Begotten Son to take our nature upon him, and as at this time to be born of a pure Virgin: grant that we being regenerate, and made thy children by adoption and grace, may daily be renewed by thy Holy Spirit; through the same Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
                    rank: 9,
                },
                {
                    title: "The Third Day in the Octave of Christmas",
                    benId: "nl glory to god",
                    magId: "nl today the christ is born",
                    collect:
                        "Almighty God, who hast given us thy Only Begotten Son to take our nature upon him, and as at this time to be born of a pure Virgin: grant that we being regenerate, and made thy children by adoption and grace, may daily be renewed by thy Holy Spirit; through the same Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
                    rank: 9,
                },
                {
                    title: "The Fourth Day in the Octave of Christmas",
                    invId: "nl unto us the christ is born",
                    mpHymnId: "jesu the fathers only son",
                    mpVandR: [
                        "The Lord hath made known, al_le_lu_ia.",
                        "His sal_va_tion, al_le_lu_ia.",
                    ],
                    benId: "nl glory to god",
                    ep2HymnId: "from east to west from shore to shore",
                    ep2VandR: [
                        "The Lord hath made known, al_le_lu_ia.",
                        "His sal_va_tion, al_le_lu_ia.",
                    ],
                    magId: "nl today the christ is born",
                    collect:
                        "Almighty God, who hast given us thy Only Begotten Son to take our nature upon him, and as at this time to be born of a pure Virgin: grant that we being regenerate, and made thy children by adoption and grace, may daily be renewed by thy Holy Spirit; through the same Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
                    rank: 9,
                },
                {
                    title: "The Fifth Day in the Octave of Christmas",
                    invId: "nl unto us the christ is born",
                    mpHymnId: "jesu the fathers only son",
                    mpVandR: [
                        "The Lord hath made known, al_le_lu_ia.",
                        "His sal_va_tion, al_le_lu_ia.",
                    ],
                    benId: "nl glory to god",
                    ep2HymnId: "from east to west from shore to shore",
                    ep2VandR: [
                        "The Lord hath made known, al_le_lu_ia.",
                        "His sal_va_tion, al_le_lu_ia.",
                    ],
                    magId: "nl today the christ is born",
                    collect:
                        "Almighty God, who hast given us thy Only Begotten Son to take our nature upon him, and as at this time to be born of a pure Virgin: grant that we being regenerate, and made thy children by adoption and grace, may daily be renewed by thy Holy Spirit; through the same Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
                    rank: 9,
                },
                {
                    title: "The Sixth Day in the Octave of Christmas",
                    invId: "nl unto us the christ is born",
                    mpHymnId: "jesu the fathers only son",
                    mpVandR: [
                        "The Lord hath made known, al_le_lu_ia.",
                        "His sal_va_tion, al_le_lu_ia.",
                    ],
                    benId: "nl glory to god",
                    ep2HymnId: "from east to west from shore to shore",
                    ep2VandR: [
                        "The Lord hath made known, al_le_lu_ia.",
                        "His sal_va_tion, al_le_lu_ia.",
                    ],
                    magId: "nl today the christ is born",
                    collect:
                        "Almighty God, who hast given us thy Only Begotten Son to take our nature upon him, and as at this time to be born of a pure Virgin: grant that we being regenerate, and made thy children by adoption and grace, may daily be renewed by thy Holy Spirit; through the same Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
                    rank: 9,
                },
                {
                    title: "The Seventh Day in the Octave of Christmas",
                    invId: "nl unto us the christ is born",
                    mpHymnId: "jesu the fathers only son",
                    mpVandR: [
                        "The Lord hath made known, al_le_lu_ia.",
                        "His sal_va_tion, al_le_lu_ia.",
                    ],
                    benId: "nl glory to god",
                    ep2HymnId: "from east to west from shore to shore",
                    ep2VandR: [
                        "The Lord hath made known, al_le_lu_ia.",
                        "His sal_va_tion, al_le_lu_ia.",
                    ],
                    magId: "nl today the christ is born",
                    collect:
                        "Almighty God, who hast given us thy Only Begotten Son to take our nature upon him, and as at this time to be born of a pure Virgin: grant that we being regenerate, and made thy children by adoption and grace, may daily be renewed by thy Holy Spirit; through the same Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
                    rank: 9,
                },
            ],
        });

    const christmasIsOnASunday =
        calendar.days.find(
            (d) => d.month === 12 && d.date === 25 && d.dayOfTheWeek === "sun"
        ) !== undefined;

    (christmasIsOnASunday
        ? modify(calendar).onDate(30, 12)
        : modify(calendar).onDate(25, 12).getNthAfter(1, "sun")
    ).placeCelebration({
        label: "holy-family",
        rank: 5,
        type: "feast",
        title: "The Holy Family of Jesus, Mary and Joseph",
        shortTitle: "The Holy Family",
        collect:
            "O Lord Jesus Christ, who by thy wondrous holiness didst adorn a human home, and by thy subjection to Mary and Joseph didst consecrate the order of earthly families: grant that we, being enlightened by the example of their life with thee in thy Holy Family, and assisted by their prayers, may at last be joined with them in thine eternal fellowship; who livest and reignest with the Father, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        ep1HymnId: "from east to west from shore to shore",
        ep1VandR: [
            "The Lord hath made known, al_le_lu_ia.",
            "His sal_va_tion, al_le_lu_ia.",
        ],
        ep1MagId: "nl today the christ is born",
        invId: "hf christ the son of god",
        mpHymnId: "now are the churches all with lights agleaming", // liber hymnarius p28
        mpVandR: [
            "All thy child_ren shall be taught of the Lord.",
            "And great shall be the peace of thy child_ren.",
        ],
        mpBenId: "hf enlighten us",
        ep2HymnId: "o jesu light of hosts above",
        ep2VandR: [
            "All thy child_ren shall be taught of the Lord.",
            "And great shall be the peace of thy child_ren.",
        ],
        ep2MagId: "hf but mary kept",
        feastHasFirstEvensong: true,
    });

    modify(calendar)
        .onCelebration("christmas-day")
        .getNthBefore(4, "sun")
        .placeCelebration({
            label: "advent-1",
            rank: 2,
            type: "sunday",
            title: "The First Sunday of Advent",
            shortTitle: "Advent I",
            ep1HymnId: "creator of the stars of night",
            ep1VandR: [
                "Drop down, ye hea_vens, from a_bove, and let the skies pour down right_eous_ness.",
                "Let the earth o_pen, and let it bring forth sal_va_tion.",
            ],
            ep1MagId: "ad1 behold the name",
            invId: "ad1 the lord the king",
            mpHymnId: "high word of god who once didst come",
            mpVandR: [
                "The voice of one cry_ing in the wil_der_ness: Pre_pare ye the way of the Lord.",
                "Make straight the paths of our God.",
            ],
            mpBenId: "ad1 the holy ghost",
            ep2HymnId: "creator of the stars of night",
            ep2VandR: [
                "Drop down, ye hea_vens, from a_bove, and let the skies pour down right_eous_ness.",
                "Let the earth o_pen, and let it bring forth sal_va_tion.",
            ],
            ep2MagId: "ad1 fear not mary",
            collect:
                "Almighty God, give us grace that we may cast away the works of darkness, and put upon us the armour of light, now in the time of this mortal life, in which thy Son Jesus Christ came to visit us in great humility; that in the last day, when he shall come again in his glorious majesty, to judge both the quick and the dead; we may rise to the life immortal; through the same Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
            sundayInAdvent: true,
            weekdays: [
                {
                    invId: "ad1 the lord the king ferias",
                    benId: "ad1 the angel",
                    magId: "ad1 lift up",
                },
                {
                    invId: "ad1 the lord the king ferias",
                    benId: "ad1 before they",
                    magId: "ad1 see ye",
                },
                {
                    title: "Ember Wednesday in Advent",
                    emberDay: true,
                    invId: "ad1 the lord the king ferias",
                    benId: "ad1 out of sion",
                    magId: "ad1 there shall",
                    collect:
                        "Grant, we beseech thee, Almighty God: that the coming festival of our redemption may obtain for us the comfort of thy succour in this life, and in the life to come the reward of eternal felicity; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
                    rank: 10.1,
                },
                {
                    invId: "ad1 the lord the king ferias",
                    benId: "ad1 blessed art",
                    magId: "ad1 i will",
                },
                {
                    title: "Ember Friday in Advent",
                    emberDay: true,
                    invId: "ad1 the lord the king ferias",
                    benId: "ad1 lo there",
                    magId: "ad1 out of egypt",
                    collect:
                        "Stir up, we beseech thee, O Lord, thy power, and come among us that we, who put our trust and confidence in thy mercy, may speedily may be delivered from all our adversities; who livest and reignest with the Father, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
                    rank: 10.1,
                },
                {
                    title: "Ember Saturday in Advent",
                    emberDay: true,
                    invId: "ad1 the lord the king ferias",
                    benId: "ad1 sion be not",
                    collect:
                        "O God, who seest us to be sore afflicted by reason of the frailty of our mortal nature: grant, we beseech thee, that we may be relieved by thy gracious visitation; who livest and reignest with the Father, in the unity of the Holy Spirit, ever one God, world without end Amen.",
                    rank: 10.1,
                },
            ],
        });
    modify(calendar)
        .onCelebration("christmas-day")
        .getNthBefore(3, "sun")
        .placeCelebration({
            label: "advent-2",
            rank: 2,
            type: "sunday",
            title: "The Second Sunday of Advent",
            shortTitle: "Advent II",
            ep1HymnId: "creator of the stars of night",
            ep1VandR: [
                "Drop down, ye hea_vens, from a_bove, and let the skies pour down right_eous_ness.",
                "Let the earth o_pen, and let it bring forth sal_va_tion.",
            ],
            ep1MagId: "ad2 come o lord",
            invId: "ad1 the lord the king",
            mpHymnId: "high word of god who once didst come",
            mpVandR: [
                "The voice of one cry_ing in the wil_der_ness: Pre_pare ye the way of the Lord.",
                "Make straight the paths of our God.",
            ],
            mpBenId: "ad2 now when john",
            ep2HymnId: "creator of the stars of night",
            ep2VandR: [
                "Drop down, ye hea_vens, from a_bove, and let the skies pour down right_eous_ness.",
                "Let the earth o_pen, and let it bring forth sal_va_tion.",
            ],
            ep2MagId: "ad2 art thou he",
            collect:
                "Stir up our hearts, O Lord, to prepare the ways of thine Only Begotten Son: that through his advent we may be worthy to serve thee with purified minds; through the same Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
            alternativeCollects: [
                "Blessed Lord, who hast caused all holy Scriptures to be written for our learning: grant that we may in such wise hear them, read, mark, learn, and inwardly digest them, that by patience and comfort of thy holy Word, we may embrace and ever hold fast the blessed hope of everlasting life, which thou hast given us in our Saviour Jesus Christ; who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
            ],
            sundayInAdvent: true,
            weekdays: [
                {
                    invId: "ad1 the lord the king ferias",
                    benId: "ad2 from heaven there",
                    magId: "ad2 behold the king",
                },
                {
                    invId: "ad1 the lord the king ferias",
                    benId: "ad2 the lord shall",
                    magId: "ad2 the voice of",
                },
                {
                    invId: "ad1 the lord the king ferias",
                    benId: "ad2 behold i send",
                    magId: "ad2 sion thou shalt",
                },
                {
                    invId: "ad1 the lord the king ferias",
                    benId: "ad2 thou o lord",
                    magId: "ad2 he that cometh",
                },
                {
                    invId: "ad1 the lord the king ferias",
                    benId: "ad2 say to them",
                    magId: "ad2 o sing unto",
                },
                {
                    invId: "ad1 the lord the king ferias",
                    benId: "ad2 the lord shall",
                },
            ],
        });

    const getAdventMag = (date: number): string | undefined => {
        const lookup: { [d: string]: string } = {
            "17": "ad o wisdom",
            "18": "ad o adonai",
            "19": "ad o root of jesse",
            "20": "ad o key of david",
            "21": "ad o dayspring",
            "22": "ad o king of the nations",
            "23": "ad o emmanuel",
        };

        return lookup[date + ""];
    };

    const getAdventBen = (date: number): string | undefined => {
        const lookup: { [d: string]: string } = {
            "21": "ad be not afraid",
            "23": "ad behold all things",
            "24": "ad the saviour of the world",
        };

        return lookup[date + ""];
    };

    const getAdventCollect = (date: number): string | undefined => {
        const lookup: { [d: string]: string } = {
            "17": "O God, the Creator and Redeemer of all mankind, who didst will that thy Word should take flesh in an ever-virgin womb: mercifully hear our prayers; that thine Only Begotten Son, who hath taken unto himself our humanity, may graciously grant us a share in his divinity; through the same Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
            "18": "Grant, we beseech thee, Almighty God: that we, who through our ancient bondage are held beneath the yoke of sin, may by the new birth of thine Only Begotten Son in the flesh obtain deliverance; through the same Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
            "19": "O God, who through the child-bearing of the holy Virgin hast graciously revealed the brightness of thy glory unto all the world: grant, we beseech thee; that we may worship with purity of faith the mystery of so wondrous an Incarnation, and always celebrate it with due reverence; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
            "20": "O God, eternal majesty, whose ineffable Word the immaculate Virgin received by the message of an Angel, and so became the dwelling-place of divinity filled with the light of the Holy Spirit: grant, we beseech thee; that by her example we may in humility hold fast to thy will; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
            "21": "We beseech thee, O Lord, to hear the prayers of thy people: that those who rejoice at the advent of thine Only Begotten Son in our flesh, may, when at last he cometh in glory, obtain the reward of eternal life; through the same Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
            "22": "O God, who seeing man sunk in death didst will to redeem him by the advent of thine Only Begotten Son: grant, we beseech thee; that those who confess with humble devotion his Incarnation may also merit participation in him as their Redeemer; through the same Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
            "23": "Almighty and everlasting God, as the Nativity of thy Son according to the flesh draweth nigh: we beseech thee, that to us thine unworthy servants thy Word may grant mercy; for he Vouchsafed to be made flesh of the Virgin Mary and dwell among us, even Jesus Christ thy Son our Lord; who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
            "24": "Raise up, we beseech thee, O Lord, thy power, and come among us, and with great might succour us: that whereas, through our sins and wickedness, we are sorely hindered in running the race that is set before us, thy bountiful grace and mercy may speedily help and deliver us; who livest and reignest with the Father, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        };

        return lookup[date + ""];
    };

    const getSapientiatideTitle = (date: number): string | undefined => {
        const lookup: { [d: string]: string } = {
            "17": "17th December",
            "18": "18th December",
            "19": "19th December",
            "20": "20th December",
            "21": "21st December",
            "22": "22nd December",
            "23": "23rd December",
            "24": "24th December",
        };

        return lookup[date + ""];
    };

    modify(calendar)
        .onCelebration("christmas-day")
        .getNthBefore(2, "sun")
        .placeCelebration((day) => {
            return {
                label: "advent-3",
                rank: 2,
                type: "sunday",
                title: "The Third Sunday of Advent",
                shortTitle: "Advent III",
                ep1HymnId: "creator of the stars of night",
                ep1VandR: [
                    "Drop down, ye hea_vens, from a_bove, and let the skies pour down right_eous_ness.",
                    "Let the earth o_pen, and let it bring forth sal_va_tion.",
                ],
                ep1MagId: getAdventMag(day.date - 1) ?? "ad3 before me",
                invId: "ad3 the lord is now nigh",
                mpHymnId: "high word of god who once didst come",
                mpVandR: [
                    "The voice of one cry_ing in the wil_der_ness: Pre_pare ye the way of the Lord.",
                    "Make straight the paths of our God.",
                ],
                mpBenId: "ad3 on the throne",
                ep2HymnId: "creator of the stars of night",
                ep2VandR: [
                    "Drop down, ye hea_vens, from a_bove, and let the skies pour down right_eous_ness.",
                    "Let the earth o_pen, and let it bring forth sal_va_tion.",
                ],
                ep2MagId: getAdventMag(day.date) ?? "ad3 blessed art thou",
                collect:
                    "O Lord Jesus Christ, who at thy first coming didst send thy messenger to prepare thy way before thee: grant that the ministers and stewards of thy mysteries may likewise so prepare and make ready thy way, by turning the hearts of the disobedient to the wisdom of the just; that at thy second coming to judge the world we may be found an acceptable people in thy sight; who livest and reignest with the Father in the unity of the Holy Spirit, ever one God, world without end. Amen.",
                sundayInAdvent: true,
                weekdays: [
                    {
                        invId: "ad1 the lord the king ferias",
                        benId:
                            getAdventBen(day.date + 1) ??
                            "ad3 there shall come",
                        magId:
                            getAdventMag(day.date + 1) ?? "ad3 all generations",
                        collect: getAdventCollect(day.date + 1),
                        rank: getAdventCollect(day.date + 1) ? 9 : undefined,
                        title: getSapientiatideTitle(day.date + 1),
                    },
                    {
                        invId: "ad1 the lord the king ferias",
                        benId:
                            getAdventBen(day.date + 2) ?? "ad3 thou bethlehem",
                        magId:
                            getAdventMag(day.date + 2) ??
                            "ad3 awake awake arise",
                        collect: getAdventCollect(day.date + 2),
                        rank: getAdventCollect(day.date + 2) ? 9 : undefined,
                        title: getSapientiatideTitle(day.date + 2),
                    },
                    {
                        invId: "ad1 the lord the king ferias",
                        benId:
                            getAdventBen(day.date + 3) ??
                            "ad3 the angel gabriel",
                        magId:
                            getAdventMag(day.date + 3) ??
                            "ad3 behold the handmaid",
                        collect: getAdventCollect(day.date + 3),
                        rank: getAdventCollect(day.date + 3) ? 9 : undefined,
                        title: getSapientiatideTitle(day.date + 3),
                    },
                    {
                        invId: "ad1 the lord the king ferias",
                        benId:
                            getAdventBen(day.date + 4) ?? "ad3 watch therefore",
                        magId:
                            getAdventMag(day.date + 4) ?? "ad3 rejoice ye with",
                        collect: getAdventCollect(day.date + 4),
                        rank: getAdventCollect(day.date + 4) ? 9 : undefined,
                        title: getSapientiatideTitle(day.date + 4),
                    },
                    {
                        invId: "ad1 the lord the king ferias",
                        benId: getAdventBen(day.date + 5) ?? "ad3 as soon as",
                        magId: getAdventMag(day.date + 5),
                        collect: getAdventCollect(day.date + 5),
                        rank: getAdventCollect(day.date + 5) ? 9 : undefined,
                        title: getSapientiatideTitle(day.date + 5),
                    },
                    {
                        invId: "ad1 the lord the king ferias",
                        benId:
                            getAdventBen(day.date + 6) ??
                            "ad3 how shall this be",
                        magId: getAdventMag(day.date + 6),
                        collect: getAdventCollect(day.date + 6),
                        rank: getAdventCollect(day.date + 6) ? 9 : undefined,
                        title: getSapientiatideTitle(day.date + 6),
                    },
                ],
            };
        });
    modify(calendar)
        .onCelebration("christmas-day")
        .getNthBefore(1, "sun")
        .placeCelebration((day) => {
            return {
                label: "advent-4",
                rank: 2,
                type: "sunday",
                title: "The Fourth Sunday of Advent",
                shortTitle: "Advent IV",
                ep1HymnId: "creator of the stars of night",
                ep1VandR: [
                    "Drop down, ye hea_vens, from a_bove, and let the skies pour down right_eous_ness.",
                    "Let the earth o_pen, and let it bring forth sal_va_tion.",
                ],
                ep1MagId: getAdventMag(day.date - 1),
                invId:
                    day.date === 24
                        ? "christmas eve inv"
                        : "ad3 the lord is now nigh",
                mpHymnId: "high word of god who once didst come",
                mpVandR:
                    day.date === 24
                        ? [
                              "To_mor_row the i_ni_qui_ty of the earth shall be done a_way.",
                              "And the Sa_viour of the world shall reign o_ver us.",
                          ]
                        : [
                              "The voice of one cry_ing in the wil_der_ness: Pre_pare ye the way of the Lord.",
                              "Make straight the paths of our God.",
                          ],
                mpBenId:
                    day.date === 21 || day.date === 23
                        ? getAdventBen(day.date)
                        : "ad4 hail mary full",
                ep2HymnId: "creator of the stars of night",
                ep2VandR: [
                    "Drop down, ye hea_vens, from a_bove, and let the skies pour down right_eous_ness.",
                    "Let the earth o_pen, and let it bring forth sal_va_tion.",
                ],
                ep2MagId: getAdventMag(day.date),
                collect:
                    "Raise up, we beseech thee, O Lord, thy power, and come among us, and with great might succour us: that whereas, through our sins and wickedness, we are sorely hindered in running the race that is set before us, thy bountiful grace and mercy may speedily help and deliver us; who livest and reignest with the Father, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
                sundayInAdvent: true,
                weekdays: [
                    {
                        invId:
                            day.date + 1 === 24
                                ? "christmas eve inv"
                                : "ad1 the lord the king ferias",
                        benId: getAdventBen(day.date + 1) ?? "ad4 thus says",
                        magId: getAdventMag(day.date + 1),
                        collect: getAdventCollect(day.date + 1),
                        alternativeCollects:
                            day.date + 1 === 24
                                ? [
                                      "O God, who makest us glad with the yearly remembrance of the birth of thy only Son Jesus Christ: grant that as we joyfully as our Redeemer, we may with sure confidence behold him when he shall come again to be our Judge; who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
                                  ]
                                : undefined,
                        rank: getAdventCollect(day.date + 1) ? 9 : undefined,
                        title: getSapientiatideTitle(day.date + 1),
                        mpVandR:
                            day.date + 1 === 24
                                ? [
                                      "To_mor_row the i_ni_qui_ty of the earth shall be done a_way.",
                                      "And the Sa_viour of the world shall reign o_ver us.",
                                  ]
                                : [
                                      "The voice of one cry_ing in the wil_der_ness: Pre_pare ye the way of the Lord.",
                                      "Make straight the paths of our God.",
                                  ],
                    },
                    {
                        invId:
                            day.date + 2 === 24
                                ? "christmas eve inv"
                                : "ad1 the lord the king ferias",
                        benId: getAdventBen(day.date + 2) ?? "ad4 awake awake",
                        magId: getAdventMag(day.date + 2),
                        collect: getAdventCollect(day.date + 2),
                        alternativeCollects:
                            day.date + 2 === 24
                                ? [
                                      "O God, who makest us glad with the yearly remembrance of the birth of thy only Son Jesus Christ: grant that as we joyfully as our Redeemer, we may with sure confidence behold him when he shall come again to be our Judge; who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
                                  ]
                                : undefined,
                        rank: getAdventCollect(day.date + 2) ? 9 : undefined,
                        title: getSapientiatideTitle(day.date + 2),
                        mpVandR:
                            day.date + 2 === 24
                                ? [
                                      "To_mor_row the i_ni_qui_ty of the earth shall be done a_way.",
                                      "And the Sa_viour of the world shall reign o_ver us.",
                                  ]
                                : [
                                      "The voice of one cry_ing in the wil_der_ness: Pre_pare ye the way of the Lord.",
                                      "Make straight the paths of our God.",
                                  ],
                    },
                    {
                        invId:
                            day.date + 3 === 24
                                ? "christmas eve inv"
                                : "ad1 the lord the king ferias",
                        benId: getAdventBen(day.date + 3) ?? "ad4 i will place",
                        magId: getAdventMag(day.date + 3),
                        collect: getAdventCollect(day.date + 3),
                        alternativeCollects:
                            day.date + 3 === 24
                                ? [
                                      "O God, who makest us glad with the yearly remembrance of the birth of thy only Son Jesus Christ: grant that as we joyfully as our Redeemer, we may with sure confidence behold him when he shall come again to be our Judge; who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
                                  ]
                                : undefined,
                        rank: getAdventCollect(day.date + 3) ? 9 : undefined,
                        title: getSapientiatideTitle(day.date + 3),
                        mpVandR:
                            day.date + 3 === 24
                                ? [
                                      "To_mor_row the i_ni_qui_ty of the earth shall be done a_way.",
                                      "And the Sa_viour of the world shall reign o_ver us.",
                                  ]
                                : [
                                      "The voice of one cry_ing in the wil_der_ness: Pre_pare ye the way of the Lord.",
                                      "Make straight the paths of our God.",
                                  ],
                    },
                    {
                        invId:
                            day.date + 4 === 24
                                ? "christmas eve inv"
                                : "ad1 the lord the king ferias",
                        benId: getAdventBen(day.date + 4) ?? "ad4 comfort ye",
                        magId: getAdventMag(day.date + 4),
                        collect: getAdventCollect(day.date + 4),
                        alternativeCollects:
                            day.date + 4 === 24
                                ? [
                                      "O God, who makest us glad with the yearly remembrance of the birth of thy only Son Jesus Christ: grant that as we joyfully as our Redeemer, we may with sure confidence behold him when he shall come again to be our Judge; who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
                                  ]
                                : undefined,
                        rank: getAdventCollect(day.date + 4) ? 9 : undefined,
                        title: getSapientiatideTitle(day.date + 4),
                        mpVandR:
                            day.date + 4 === 24
                                ? [
                                      "To_mor_row the i_ni_qui_ty of the earth shall be done a_way.",
                                      "And the Sa_viour of the world shall reign o_ver us.",
                                  ]
                                : [
                                      "The voice of one cry_ing in the wil_der_ness: Pre_pare ye the way of the Lord.",
                                      "Make straight the paths of our God.",
                                  ],
                    },
                    {
                        invId:
                            day.date + 5 === 24
                                ? "christmas eve inv"
                                : "ad1 the lord the king ferias",
                        benId: getAdventBen(day.date + 5),
                        magId: getAdventMag(day.date + 5),
                        collect: getAdventCollect(day.date + 5),
                        alternativeCollects:
                            day.date + 5 === 24
                                ? [
                                      "O God, who makest us glad with the yearly remembrance of the birth of thy only Son Jesus Christ: grant that as we joyfully as our Redeemer, we may with sure confidence behold him when he shall come again to be our Judge; who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
                                  ]
                                : undefined,
                        rank: getAdventCollect(day.date + 5) ? 9 : undefined,
                        title: getSapientiatideTitle(day.date + 5),
                        mpVandR:
                            day.date + 5 === 24
                                ? [
                                      "To_mor_row the i_ni_qui_ty of the earth shall be done a_way.",
                                      "And the Sa_viour of the world shall reign o_ver us.",
                                  ]
                                : [
                                      "The voice of one cry_ing in the wil_der_ness: Pre_pare ye the way of the Lord.",
                                      "Make straight the paths of our God.",
                                  ],
                    },
                    {
                        invId:
                            day.date + 6 === 24
                                ? "christmas eve inv"
                                : "ad1 the lord the king ferias",
                        benId: getAdventBen(day.date + 6),
                        magId: getAdventMag(day.date + 6),
                        collect: getAdventCollect(day.date + 6),
                        alternativeCollects:
                            day.date + 6 === 24
                                ? [
                                      "O God, who makest us glad with the yearly remembrance of the birth of thy only Son Jesus Christ: grant that as we joyfully as our Redeemer, we may with sure confidence behold him when he shall come again to be our Judge; who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
                                  ]
                                : undefined,
                        rank: getAdventCollect(day.date + 6) ? 9 : undefined,
                        title: getSapientiatideTitle(day.date + 6),
                        mpVandR:
                            day.date + 6 === 24
                                ? [
                                      "To_mor_row the i_ni_qui_ty of the earth shall be done a_way.",
                                      "And the Sa_viour of the world shall reign o_ver us.",
                                  ]
                                : [
                                      "The voice of one cry_ing in the wil_der_ness: Pre_pare ye the way of the Lord.",
                                      "Make straight the paths of our God.",
                                  ],
                    },
                ].slice(0, 24 - day.date),
            };
        });
    modify(calendar)
        .onCelebration("advent-1")
        .getNthBefore(1, "sun")
        .placeCelebration({
            label: "christ-the-king",
            rank: 3,
            type: "solemnity",
            title: "Our Lord Jesus Christ, King of the Universe",
            shortTitle: "Christ the King",
            operatesAsSunday: true,
            ep1HymnId: "o christ to endless ages lord",
            ep1VandR: [
                "All po_wer is gi_ven to me.",
                "In hea_ven and in earth.",
            ],
            ep1MagId: "ctk the lord god",
            invId: "ctk inv",
            mpHymnId: "unfurled the glorious banners fly",
            mpVandR: [
                "Of the in_crease of his go_vern_ment.",
                "And of his peace there shall be no end.",
            ],
            mpBenId: "ctk unto god",
            ep2HymnId: "o christ to endless ages lord",
            ep2VandR: [
                "Of the in_crease of his go_vern_ment.",
                "And of his peace there shall be no end.",
            ],
            ep2MagId: "ctk he hath",
            collect:
                "Almighty and everlasting God, whose will it is to restore all things in thy well- beloved Son, the King of kings and Lord of lords: mercifully grant that the peoples of the earth, divided and enslaved by sin, may be freed and brought together under his most gracious rule; who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        });
    modify(calendar)
        .onCelebration("advent-1")
        .getNthBefore(1, "mon")
        .placeCelebration({
            label: "monday-before-advent",
            rank: 13,
            type: "feria",
            title: "Monday before Advent",
            collect:
                "Stir up, we beseech thee, O Lord, the wills of thy faithful people: that they, plenteously bringing forth the fruit of good works, may of thee be plenteously rewarded; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
            alternativeCollects: [
                "Blessed Lord, who hast caused all holy Scriptures to be written for our learning: grant that we may in such wise hear them, read, mark, learn, and inwardly digest them, that by patience and comfort of thy holy Word, we may embrace and ever hold fast the blessed hope of everlasting life, which thou hast given us in our Saviour Jesus Christ; who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
            ],
            invId: "inv mon",
            mpHymnId: "o splendour of gods glory bright",
            mpVandR: [
                "O sa_tis_fy us with thy mer_cy.",
                "That we may re_joice and be glad.",
            ],
            mpBenId: "sba when jesus",
            ep2HymnId: "o boundless wisdom god most high",
            ep2VandR: [
                "Let our eve_ning prayer come up be_fore thee, O Lord.",
                "And let thy mer_cy come down up_on us.",
            ],
            ep2MagId: "sba then those",
        });
    modify(calendar)
        .onCelebration("advent-1")
        .getNthBefore(1, "tues")
        .placeCelebration({
            label: "tuesday-before-advent",
            rank: 13,
            type: "feria",
            title: "Tuesday before Advent",
            collect:
                "Stir up, we beseech thee, O Lord, the wills of thy faithful people: that they, plenteously bringing forth the fruit of good works, may of thee be plenteously rewarded; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
            invId: "inv tues",
            mpHymnId: "the winged herald of the day",
            mpVandR: [
                "O sa_tis_fy us with thy mer_cy.",
                "That we may re_joice and be glad.",
            ],
            mpBenId: "sba when jesus",
            ep2HymnId: "earths mighty maker whose command",
            ep2VandR: [
                "Let our eve_ning prayer come up be_fore thee, O Lord.",
                "And let thy mer_cy come down up_on us.",
            ],
            ep2MagId: "sba then those",
        });
    modify(calendar)
        .onCelebration("advent-1")
        .getNthBefore(1, "wed")
        .placeCelebration({
            label: "wednesday-before-advent",
            rank: 13,
            type: "feria",
            title: "Wednesday before Advent",
            collect:
                "Stir up, we beseech thee, O Lord, the wills of thy faithful people: that they, plenteously bringing forth the fruit of good works, may of thee be plenteously rewarded; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
            invId: "inv wed",
            mpHymnId: "ye clouds and darkness hosts of night",
            mpVandR: [
                "O sa_tis_fy us with thy mer_cy.",
                "That we may re_joice and be glad.",
            ],
            mpBenId: "sba when jesus",
            ep2HymnId: "most holy lord and god of heaven",
            ep2VandR: [
                "Let our eve_ning prayer come up be_fore thee, O Lord.",
                "And let thy mer_cy come down up_on us.",
            ],
            ep2MagId: "sba then those",
        });
    modify(calendar)
        .onCelebration("advent-1")
        .getNthBefore(1, "thur")
        .placeCelebration({
            label: "thursday-before-advent",
            rank: 13,
            type: "feria",
            title: "Thursday before Advent",
            collect:
                "Stir up, we beseech thee, O Lord, the wills of thy faithful people: that they, plenteously bringing forth the fruit of good works, may of thee be plenteously rewarded; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
            invId: "inv thur",
            mpHymnId: "lo golden light rekindles day",
            mpVandR: [
                "O sa_tis_fy us with thy mer_cy.",
                "That we may re_joice and be glad.",
            ],
            mpBenId: "sba when jesus",
            ep2HymnId: "almighty god who from the flood",
            ep2VandR: [
                "Let our eve_ning prayer come up be_fore thee, O Lord.",
                "And let thy mer_cy come down up_on us.",
            ],
            ep2MagId: "sba then those",
        });
    modify(calendar)
        .onCelebration("advent-1")
        .getNthBefore(1, "fri")
        .placeCelebration({
            label: "friday-before-advent",
            rank: 13,
            type: "feria",
            title: "Friday before Advent",
            collect:
                "Stir up, we beseech thee, O Lord, the wills of thy faithful people: that they, plenteously bringing forth the fruit of good works, may of thee be plenteously rewarded; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
            invId: "inv fri",
            mpHymnId: "eternal glory of the sky",
            mpVandR: [
                "O sa_tis_fy us with thy mer_cy.",
                "That we may re_joice and be glad.",
            ],
            mpBenId: "sba when jesus",
            ep2HymnId: "maker of man who from thy throne",
            ep2VandR: [
                "Let our eve_ning prayer come up be_fore thee, O Lord.",
                "And let thy mer_cy come down up_on us.",
            ],
            ep2MagId: "sba then those",
        });
    modify(calendar)
        .onCelebration("advent-1")
        .getNthBefore(1, "sat")
        .placeCelebration({
            label: "saturday-before-advent",
            rank: 13,
            type: "feria",
            title: "Saturday before Advent",
            collect:
                "Stir up, we beseech thee, O Lord, the wills of thy faithful people: that they, plenteously bringing forth the fruit of good works, may of thee be plenteously rewarded; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
            invId: "inv sat",
            mpHymnId: "the dawn is sprinkling in the east",
            mpVandR: [
                "O sa_tis_fy us with thy mer_cy.",
                "That we may re_joice and be glad.",
            ],
            mpBenId: "sba when jesus",
            ep2HymnId: "o trinity of blessed light",
            ep2VandR: [
                "Let our eve_ning prayer come up be_fore thee, O Lord.",
                "And let thy mer_cy come down up_on us.",
            ],
            ep2MagId: "sba then those",
        });

    modify(calendar).onDate(14, 9).getNthAfter(1, "wed").placeCelebration({
        label: "ember-wednesday-in-september",
        rank: 9,
        type: "misc",
        deletesFeria: true,
        title: "Ember Wednesday in September",
        mpBenId: "ember sept this kind",
        collect:
            "Grant, we beseech thee, O Lord, to us thy humble servants: that we, who do refrain ourselves from carnal feastings, may likewise fast from sin within our souls; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
    });
    modify(calendar).onDate(14, 9).getNthAfter(1, "fri").placeCelebration({
        label: "ember-friday-in-september",
        rank: 9,
        type: "misc",
        deletesFeria: true,
        title: "Ember Friday in September",
        mpBenId: "ember sept a woman",
        collect:
            "Grant, we beseech thee, Almighty God: that we, who year by year devotedly keep this holy ordinance of abstinence, may serve thee acceptably both in body and soul; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
    });
    modify(calendar).onDate(14, 9).getNthAfter(1, "sat").placeCelebration({
        label: "ember-saturday-in-september",
        rank: 9,
        type: "misc",
        deletesFeria: true,
        title: "Ember Saturday in September",
        mpBenId: "ember sept give light",
        collect:
            "Almighty and everlasting God, who through godly continency bestowest healing both of body and soul: we humbly beseech thy majesty graciously to look upon the devout prayers and abstinence of thy people, granting us in this world the succour of thy grace, and in the world to come life everlasting; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
    });

    // Sundays after Trinity (lower precedence than Sundays in Advent)
    const trinitytidePropers: {
        [label: string]: {
            ep1MagId: string;
            mpBenId: string;
            ep2MagId: string;
            collect: string;
        };
    } = {
        "trinity-1": {
            ep1MagId: "t1 the child samuel",
            mpBenId: "t1 father abraham",
            ep2MagId: "t1 son remember",
            collect:
                "O God, the strength of all them that put their trust in thee: mercifully accept our prayers; and because through the weakness of our mortal nature we can do no good thing without thee, grant us the help of thy grace, that in keeping thy commandments we may please thee both in will and deed; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        },
        "trinity-2": {
            ep1MagId: "t2 and all israel",
            mpBenId: "t2 a certain man",
            ep2MagId: "t2 go out quickly",
            collect:
                "O Lord, who never failest to help and govern them whom thou dost bring up in thy steadfast fear and love: keep us, we beseech thee, under the protection of thy good providence; and make us to have a perpetual fear and love of thy holy Name; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        },
        "trinity-3": {
            ep1MagId: "t3 ye mountains",
            mpBenId: "t3 what man",
            ep2MagId: "t3 what woman",
            collect:
                "O Lord, we beseech thee mercifully to hear us: and grant that we, to whom thou hast given an hearty desire to pray, may, by thy mighty aid, be defended and comforted in all dangers and adversities; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        },
        "trinity-4": {
            ep1MagId: "t4 and david said",
            mpBenId: "t4 be ye therefore",
            ep2MagId: "t4 judge not",
            collect:
                "O God, the protector of all that trust in thee, without whom nothing is strong, nothing is holy: increase and multiply upon us thy mercy; that, thou being our ruler and guide, we may so pass through things temporal, that we finally lose not the things eternal; grant this, O heavenly Father, for the sake of Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        },
        "trinity-5": {
            ep1MagId: "t5 so david",
            mpBenId: "t5 and jesus",
            ep2MagId: "t5 master we have toiled",
            collect:
                "Grant, O Lord, we beseech thee: that the course of this world may be so peaceably ordered by thy governance; that thy Church may joyfully serve thee in all godly quietness; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        },
        "trinity-6": {
            ep1MagId: "t6 i am distressed",
            mpBenId: "t6 ye have heard",
            ep2MagId: "t6 if thou bring",
            collect:
                "O God, who hast prepared for them that love thee such good things as pass man's understanding: pour into our hearts such love toward thee; that we, loving thee in all things and above all things, may obtain thy promises, which exceed all that we can desire; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        },
        "trinity-7": {
            ep1MagId: "t7 but king david",
            mpBenId: "t7 when a great",
            ep2MagId: "t7 i have compassion",
            collect:
                "Lord of all power and might, who art the author and giver of all good things: graft in our hearts the love of thy Name; increase in us true religion, nourish us with all goodness, and of thy great mercy keep us in the same; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        },
        "trinity-8": {
            ep1MagId: "t8 wisdom crieth",
            mpBenId: "t8 beware ye",
            ep2MagId: "t8 a good tree",
            collect:
                "O God, whose never-failing providence ordereth all things both in heaven and earth: we humbly beseech thee to put away from us all hurtful things, and to give us those things which be profitable for us; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        },
        "trinity-9": {
            ep1MagId: "t9 i beseech",
            mpBenId: "t9 the lord said",
            ep2MagId: "t9 what shall i do",
            collect:
                "Grant to us, Lord, we beseech thee, the spirit to think and do always such things as be rightful: that we, who cannot do any thing that is good without thee, may by thee be enabled to live according to thy will; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        },
        "trinity-10": {
            ep1MagId: "t10 go not",
            mpBenId: "t10 when jesus",
            ep2MagId: "t10 it is written",
            collect:
                "Let thy merciful ears, O Lord, be open to the prayers of thy humble servants: and that they may obtain their petitions, make them to ask such things as shall please thee; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        },
        "trinity-11": {
            ep1MagId: "t11 son of man",
            mpBenId: "t11 standing afar",
            ep2MagId: "t11 this man",
            collect:
                "O God, who declarest thy almighty power most chiefly in showing mercy and pity; mercifully grant unto us such a measure of thy grace; that we, running the way of thy commandments, may obtain thy gracious promises, and be made partakers of thy heavenly treasure; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        },
        "trinity-12": {
            ep1MagId: "t12 i will shake",
            mpBenId: "t12 when the lord",
            ep2MagId: "t12 he hath done",
            collect:
                "Almighty and everlasting God, who art always more ready to hear than we to pray, and art wont to give more than either we desire or deserve: pour down upon us the abundance of thy mercy; forgiving us those things whereof our conscience is afraid, and giving us those good things which we are not worthy to ask, but through the merits and mediation of Jesus Christ thy Son our Lord; who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        },
        "trinity-13": {
            ep1MagId: "t13 i will stand",
            mpBenId: "t13 master what shall",
            ep2MagId: "t13 a certain man",
            collect:
                "Almighty and merciful God, of whose only gift it cometh that thy faithful people do unto thee true and laudable service: grant, we beseech thee; that we may so faithfully serve thee in this life, that we fail not finally to attain thy heavenly promises; through the merits of Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        },
        "trinity-14": {
            ep1MagId: "t14 blessed be",
            mpBenId: "t14 as jesus",
            ep2MagId: "t14 but one",
            collect:
                "Almighty and everlasting God: give unto us the increase of faith, hope, and charity; and, that we may obtain that which thou dost promise, make us to love that which thou dost command; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        },
        "trinity-15": {
            ep1MagId: "t15 blessed be",
            mpBenId: "t15 take no",
            ep2MagId: "t15 but seek ye",
            collect:
                "Keep, we beseech thee, O Lord, thy Church with thy perpetual mercy: and, because the frailty of man without thee cannot but fall; keep us ever by thy help from all things hurtful, and lead us to all things profitable to our salvation; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        },
        "trinity-16": {
            ep1MagId: "t16 the sun shone",
            mpBenId: "t16 jesus entered",
            ep2MagId: "t16 a great prophet",
            collect:
                "O Lord, we beseech thee, let thy continual pity cleanse and defend thy Church: and, because she cannot continue in safety without thy succour; preserve her evermore by thy help and goodness; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        },
        "trinity-17": {
            ep1MagId: "t17 now job",
            mpBenId: "t17 as jesus",
            ep2MagId: "t17 when thou",
            collect:
                "Lord, we pray thee that thy grace may always precede and follow us: and make us continually to be given to all good works; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        },
        "trinity-18": {
            ep1MagId: "t18 dominion and fear",
            mpBenId: "t18 master which",
            ep2MagId: "t18 what think ye",
            collect:
                "Lord, we beseech thee, grant thy people grace to withstand the temptations of the world, the flesh, and the devil: and with pure hearts and minds to follow thee the only God; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        },
        "trinity-19": {
            ep1MagId: "t19 where shall",
            mpBenId: "t19 jesus said",
            ep2MagId: "t19 the sick",
            collect:
                "O God, forasmuch as without thee we are not able to please thee: mercifully grant that thy Holy Spirit may in all things direct and rule our hearts; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the same Holy Spirit, ever one God, world without end. Amen.",
        },
        "trinity-20": {
            ep1MagId: "t20 wisdom hath",
            mpBenId: "t20 tell them",
            ep2MagId: "t20 but when",
            collect:
                "O Almighty and most merciful God: of thy bountiful goodness keep us, we beseech thee, from all things that may hurt us; that we, being ready both in body and soul, may cheerfully accomplish those things which thou commandest; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        },
        "trinity-21": {
            ep1MagId: "t21 in the way",
            mpBenId: "t21 there was",
            ep2MagId: "t21 so the father",
            collect:
                "Grant, we beseech thee, merciful Lord, to thy faithful people pardon and peace: that they may be cleansed from all their sins, and serve thee with a quiet mind; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        },
        "trinity-22": {
            ep1MagId: "t22 all wisdom",
            mpBenId: "t22 the lord said",
            ep2MagId: "t22 thou wicked",
            collect:
                "Lord, we beseech thee to keep thy household the Church in continual godliness: that through thy protection she may be free from all adversities, and devoutly given to serve thee in good works, to the glory of thy Name; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        },
        "trinity-23": {
            ep1MagId: "t23 arm yourselves",
            mpBenId: "t23 master we know",
            ep2MagId: "t23 render therefore",
            collect:
                "O God, our refuge and strength, who art the author of all godliness: be ready, we beseech thee, to hear the devout prayers of thy Church; and grant that those things which we ask faithfully we may obtain effectually; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        },
        "trinity-24": {
            ep1MagId: "t24 i dwell",
            mpBenId: "t24 for she said",
            ep2MagId: "t24 but jesus",
            collect:
                "O Lord, we beseech thee, absolve thy people from their offences: that through thy bountiful goodness we may all be delivered from the bonds of those sins, which by our frailty we have committed; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
        },
        "trinity-25": {
            ep1MagId: "ep sat ep1 god hath holpen",
            mpBenId: "ep6 as the lightning",
            ep2MagId: "t25 give peace",
            collect:
                "O God, whose blessed Son was manifested that he might destroy the works of the devil, and make us the sons of God, and heirs of eternal life: grant us, we beseech thee; that, having this hope, we may purify ourselves, even as he is pure; that, when he shall appear again with power and great glory, we may be made like unto him in his eternal glorious kingdom; where with thee, O Father, and thee, O Holy Spirit, he liveth and reigneth, ever one God, world without end. Amen.",
        },
        "trinity-26": {
            ep1MagId: "ep sat ep1 god hath holpen",
            mpBenId: "ep6 as the lightning",
            ep2MagId: "t26 let your song",
            collect:
                "O God, whose blessed Son was manifested that he might destroy the works of the devil, and make us the sons of God, and heirs of eternal life: grant us, we beseech thee; that, having this hope, we may purify ourselves, even as he is pure; that, when he shall appear again with power and great glory, we may be made like unto him in his eternal glorious kingdom; where with thee, O Father, and thee, O Holy Spirit, he liveth and reigneth, ever one God, world without end. Amen.",
        },
    };
    for (let i = 1; i <= 27; i++) {
        modify(calendar)
            .onCelebration("trinity-sunday")
            .getNthAfter(i, "sun")
            .placeCelebration({
                label: `trinity-${i}`,
                rank: 6,
                type: "sunday",
                title:
                    "The " +
                    [
                        "First",
                        "Second",
                        "Third",
                        "Fourth",
                        "Fifth",
                        "Sixth",
                        "Seventh",
                        "Eighth",
                        "Ninth",
                        "Tenth",
                        "Eleventh",
                        "Twelfth",
                        "Thirteenth",
                        "Fourteenth",
                        "Fifteenth",
                        "Sixteenth",
                        "Seventeenth",
                        "Eighteenth",
                        "Nineteenth",
                        "Twentieth",
                        "Twenty-first",
                        "Twenty-second",
                        "Twenty-third",
                        "Twenty-fourth",
                        "Twenty-fifth",
                        "Twenty-sixth",
                        "Twenty-seventh",
                    ][i - 1] +
                    " Sunday after Trinity",
                shortTitle:
                    "Trinity " +
                    [
                        "I",
                        "II",
                        "III",
                        "IV",
                        "V",
                        "VI",
                        "VII",
                        "VIII",
                        "IX",
                        "X",
                        "XI",
                        "XII",
                        "XIII",
                        "XIV",
                        "XV",
                        "XVI",
                        "XVII",
                        "XVIII",
                        "XIX",
                        "XX",
                        "XXI",
                        "XXII",
                        "XXIII",
                        "XXIV",
                        "XXV",
                        "XXVI",
                        "XXVII",
                    ][i - 1],
                sundayAfterTrinity: true,
                ...(trinitytidePropers[`trinity-${i}`] ?? {}),
            });
    }

    const trinity25Index = calendar.days.findIndex(
        (day) =>
            day.celebrations.find((c) => c.label === "trinity-25") !==
                undefined && day.year === year
    );
    const advent1Index = calendar.days.findIndex(
        (day) =>
            day.celebrations.find((c) => c.label === "advent-1") !==
                undefined && day.year === year
    );
    if (advent1Index - trinity25Index > 7) {
        const t25 = calendar.days[trinity25Index].celebrations.find(
            (c) => c.label === "trinity-25"
        );

        if (t25) {
            t25.mpBenId = "ep5 master sowedst";

            for (let i = 1; i <= 6; i++) {
                const feria = calendar.days[
                    trinity25Index + i
                ].celebrations.find((c) => c.type === "feria");

                if (feria) {
                    feria.mpBenId = "ep5 master sowedst";
                }
            }
        }
    }

    const grabbedSolemnities: string[] = [];

    // Transfer outranked solemnities
    calendar.days.forEach((day, dayIndex) => {
        const { outranked } = getOptionsAndOutranked(day.celebrations);

        outranked.forEach((outrankedCelebration) => {
            if (outrankedCelebration.type === "solemnity") {
                const alreadyGrabbed = grabbedSolemnities.includes(
                    outrankedCelebration.label
                );

                if (alreadyGrabbed) {
                    day.celebrations = day.celebrations.filter(
                        (celebration) =>
                            celebration.label !== outrankedCelebration.label
                    );
                } else {
                    day.transferNotes.push(
                        `The Solemnity of ${
                            outrankedCelebration.shortTitle ??
                            outrankedCelebration.title
                        }${
                            outrankedCelebration.regionNote
                                ? ` (${outrankedCelebration.regionNote})`
                                : ""
                        }, which would usually fall on this day, is outranked this year, and has been transferred to the next available day.`
                    );

                    grabbedSolemnities.push(outrankedCelebration.label);
                }

                calendar.days[dayIndex + 1].celebrations.push(
                    outrankedCelebration
                );
            }
        });
    });

    calendar.days.forEach((day) => {
        if (day.dayOfTheWeek === "sat") {
            // Add Office of St Mary on Saturday

            const celebration: Celebration = {
                invId: "annunciation inv",
                label: "saint-mary-on-saturday",
                rank: 12,
                type: "memorial",
                optional: true,
                title: "Saint Mary on Saturday",
                mpHymnId: "o glorious maid exalted far",
                mpVandR: [
                    "Bless_ed art thou a_mong wo_men.",
                    "And bless_ed is the fruit of thy womb.",
                ],
                mpBenId:
                    (day.month === 1 && day.date >= 14) ||
                    (day.month === 2 && day.date === 1)
                        ? "bvm a great and wondrous"
                        : day.celebrations.find(
                              (c) =>
                                  c.title.includes("Easter") ||
                                  c.title.includes("Ascension") ||
                                  c.title.includes("Whitsun")
                          )
                        ? "bvm joy to thee"
                        : "bvm o blessed mother", // https://www.google.com/search?sca_esv=0bc39af9d1d15b37&sxsrf=ACQVn08gnyDUF7n5oFqo0c_JhqidI6z3tg:1713108935704&q=Beata+Dei+Genitrix+english&uds=AMwkrPsEWyVwflfgdLPXU7sRgmi0PEtLpnJsW_TzG6tb2GCnD06vTgFpFC1bWNK-B5Sx433eIsKRAFpH2QxmzObSdQr5NlGSWnv-RXz3Z9_VFN_TOTjoMgRzyZ4zjrL9VlYCeusJtSx8JULLxJ_AV3B0P81PDAKtTUBkwtMY8ZAZKLkT0XGlgaLt9ZPXmDSP0byT9wXdZTbqyJs4HaZjl4xkZv9NdnJdkVBSeRMusHaS4Ujwjl1VclqURf6gi_u-5tWVaxBikpMizsomuvMWyaNBhoPqdC43or7laenrQ7C_ZirS-biYUo8&udm=2&prmd=ivnbmtz&sa=X&ved=2ahUKEwic0cuMhMKFAxXETEEAHUgoBn0QtKgLegQIEBAB&biw=1728&bih=906&dpr=2#vhid=BuP_7WUqLg2zKM&vssid=mosaic
                collect: day.celebrations.find((c) =>
                    c.title.includes("Advent")
                )
                    ? "O God, who didst will that thy Word should take flesh in the womb of the Blessed Virgin Mary, at the message of an Angel: grant that we thy suppliants, who believe her to be in very deed the Mother of God, may be aided by her intercession with thee; through the same Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen."
                    : day.celebrations.find((c) =>
                          c.title.includes("Christmas")
                      )
                    ? "O God, who through the fruitful virginity of Blessed Mary hast bestowed upon mankind the rewards of eternal salvation: grant, we beseech thee; that we may continually perceive the benefit of her intercession, since through her we have been counted worthy to receive the Author of life, Jesus Christ thy Son our Lord; who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen."
                    : "Grant, we beseech thee, O Lord God, that we thy servants may enjoy perpetual health of mind and body: and, at the glorious intercession of the blessed and ever-Virgin Mary, may be delivered from present sadness, and may rejoice in continual gladness; through Jesus Christ thy Son our Lord, who liveth and reigneth with thee, in the unity of the Holy Spirit, ever one God, world without end. Amen.",
            };

            // Fr Stephen (Vicar General) wants this only showing up in 'Ordinary Time', so we check for that here
            const isOrdinaryTime =
                day.celebrations.find(
                    (c) =>
                        c.title.includes("Trinity") ||
                        c.title.includes("Epiphany") ||
                        c.title.includes("gesima")
                ) !== undefined &&
                day.celebrations.find(
                    (c) =>
                        c.title.includes("Advent") &&
                        !c.title.includes("before Advent")
                ) === undefined;

            if (isOrdinaryTime) {
                day.celebrations.push(celebration);
            }
        }
    });

    // Change how memorials outranked by ferias of rank 9 are populated
    calendar.days.forEach((day, dayIndex) => {
        const outrankingFeria = day.celebrations.find((c) => c.rank === 9);

        calendar.days[dayIndex].celebrations = calendar.days[
            dayIndex
        ].celebrations.map((celebration) => {
            if (celebration.rank > 9 && outrankingFeria) {
                return {
                    ...celebration,
                    invId: outrankingFeria.invId,
                    mpHymnId: outrankingFeria.mpHymnId,
                    mpVandR: outrankingFeria.mpVandR,
                    mpBenId: outrankingFeria.mpBenId,
                    ep2HymnId: outrankingFeria.ep2HymnId,
                    ep2VandR: outrankingFeria.ep2VandR,
                    ep2MagId: outrankingFeria.ep2MagId,
                };
            } else {
                return celebration;
            }
        });
    });

    return calendar;
};

export const getOrdinariateCalendarDay = (
    year: number | string,
    month: number | string,
    date: number | string,
    epiphanyTransferred: boolean,
    ascensionTransferred: boolean,
    corupsChristiTransferred: boolean
) => {
    const y = parseInt(`${year}`);
    const m = parseInt(`${month}`);
    const d = parseInt(`${date}`);

    const day = getOrdinariateLiturgicalYear(
        y,
        epiphanyTransferred,
        ascensionTransferred,
        corupsChristiTransferred
    ).days.find((day) => day.month === m && day.date === d);

    if (day) {
        return day;
    } else {
        throw new Error("Could not find that day");
    }
};

export const getMonthName = (month: number) => {
    return [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
    ][month - 1];
};
