const spreadsheet = `
ID (DO NOT CHANGE)	LABEL	ANTIPHON TEXT
mmg for his great love	Mary, the Holy Mother of God Mag at EP1	For his great love * wherewith God loved us, he sent his Son in the likeness of sinful flesh, alleluia.
nl unto us the christ is born	Mary, the Holy Mother of God Inv	Unto us the Christ is born: * O come, let us worship.
mmg a great and wondrous	Mary, the Holy Mother of God Ben	A great and wondrous mystery * is made known to us this day: a new thing is wrought in nature; God is made man; that which he was he remaineth, and that which he was not he assumeth, suffering neither confusion nor yet division.
mmg heirs are we	Mary, the Holy Mother of God Mag at EP2	Heirs are we * of a great mystery: the womb of her that knew not man is become the temple of the Godhead: he, of a Virgin incarnate, suffereth no defilement: all the nations shall gather, saying: Glory be to thee, O Lord.
bishop o high priest	Common of Confessor: Bishop Mag at EP1	High priest and bishop, * mighty in virtues, good shepherd of the people, pray for us unto the Lord.
religious the lord the king	Common of Confessor: Bishop Inv	The Lord, the King of Confessors: * O come, let us worship him.
bishops well done good and faithful	Common of Confessor: Bishop Ben	Well done, good and faithful servant, * because thou hast been faithful over a few things, I will make thee ruler over many things, saith the Lord.
bishops the lord loved him	Common of Confessor: Bishop Mag at EP2	The Lord loved him * and adorned him: he clothed him with a robe of glory, and at the gates of paradise he crowned him.
annunciation inv	Saint Mary on Saturday Inv	Hail, Mary, full of grace; * The Lord is with thee.
bvm o blessed mother	Saint Mary on Saturday Ben	How shall this be, *O Angel of God, seeing I know not a man? Hearken, O Virgin Mary: the Holy Ghost shall come upon thee, and the power of the highest shall overshadow thee.
nl2 and we beheld	The Ninth Day of Christmas Ben	And we beheld his glory, * the glory as of the Only Begotten of the Father, full of grace and truth.
nl2 thine almighty word	The Ninth Day of Christmas Mag at EP2	Thine almighty Word, O Lord, * hath leaped down from heaven out of thy royal throne.
nl2 as many as	The Second Sunday of Christmas Mag at EP1	As many as received him, * to them gave he power to become the sons of God, even to them that believe on his Name.
hnj inv	The Most Holy Name of Jesus Inv	How excellent is the Name of Jesus, which is above every name: * O come, let us worship him.
hnj he gave himself	The Most Holy Name of Jesus Ben	He gave himself * that he might deliver his people, and obtain for himself an everlasting Name, alleluia.
hnj thou salt call	The Most Holy Name of Jesus Mag at EP2	Thou shalt call * his Name Jesus; for he shall save his people from their sins, alleluia.
ep the sages beholding	The Epiphany of the Lord Mag at EP1	The Sages, * beholding the star, said one to another: This is the sign of a mighty King; let us go forth and seek him, and let us offer him gifts, gold, incense, and myrrh.
the christ hath been	The Epiphany of the Lord Inv	The Christ hath been manifested unto us: * O come, let us worship him.
ep today to her heavenly	The Epiphany of the Lord Ben	Today * to her heavenly Bridegroom is the Church espoused, forasmuch as in Jordan Christ hath washed away her iniquities; Sages with their offerings hasten to the royal marriage; and with water turned to wine the guests are regaled, alleluia.
ep we celebrate a holy day	The Epiphany of the Lord Mag at EP2	We celebrate a holy day * adorned with three wonders: today the star led the Sages to the crib; today did water become wine at the marriage feast; today Christ willed to be baptised by John in Jordan, for our salvation, alleluia.
religious i will liken him	Common of Confessor: Priest Mag at EP1	I will liken him * unto a wise man, which built his house upon a rock.
religious well done good and faithful	Common of Confessor: Priest Ben	Well done, good and faithful servant, * because thou hast been faithful over a few things, I will make thee ruler over many things, saith the Lord.
religious this man the world	Common of Confessor: Priest Mag at EP2	This man, the world despising, * and earthly things renouncing, gaining the victory, laid up treasures in heaven by word and deed.
j7 from the east	7 January Ben	From the east * there came Sages to Bethlehem for to worship the Lord: and when they had opened their treasures, they presented unto him precious gifts: gold as to a mighty monarch, incense as to the true God, and myrrh to foreshow his burial, alleluia.
j7 seeing the star	7 January Mag at EP2	Seeing the star, the Sages * rejoiced with great rejoicing: and entering into the house, they presented to the Lord incense, gold and myrrh.
j8 there are the	8 January Ben	Three are the offerings * which the Sages brought unto the Lord: gold and myrrh and incense, to the Son of God, the mighty King, alleluia.
j8 light of light	8 January Mag at EP2	Light of light, * thou, O Christ, hast appeared, unto whom the Wise Men present their gifts, alleluia, alleluia, alleluia.
j9 we have seen	9 January Ben	We have seen his star * in the east, and are come with gifts to worship the Lord.
j9 herod inquired	9 January Mag at EP2	Herod inquired * of the Sages: What sign did ye see over the newborn King? We have seen a star gleaming, the brightness of which lighteneth the world.
botl the soldier	The Baptism of the Lord Mag at EP1	The soldier baptiseth the King, the servant his Master, the Baptist his Redeemer: * the water of Jordan marvelled, the Dove bare witness, the Father's voice was. heard, This is my beloved Son.
botl john the	The Baptism of the Lord Ben	John the Forerunner rejoiceth with the river Jordan; when the Lord was baptised, joy and gladness were come to the whole world: * through the hallowing of water there came to us remission of sins; let us all beseech him, Have mercy upon us.
botl the springs	The Baptism of the Lord Mag at EP2	The springs of water were hallowed when Christ appeared in majesty before the world: * draw ye water from the wells of salvation, for now is every creature sanctified by Christ our God.
j10 many nations	10 January Ben	Many nations * shall come from afar, bearing their gifts, alleluia.
j10 all they from	10 January Mag at EP2	All they from Saba * shall come, bearing gold and incense, alleluia, alleluia.
j11 they that	11 January Ben	They that despised thee * shall come unto thee, and shall bow themselves before thy feet.
j11 the sages	11 January Mag at EP2	The Sages, being warned * in dreams by an Angel, departed into their own country another way.
j12 great is	12 January Ben	Great is the mystery * of godliness: God manifest in the flesh, seen of Angels, preached unto the Gentiles, and believed on in the world.
j12 the sages	12 January Mag at EP2	The Sages, * beholding the star, said one to another: This is the sign of a mighty King; let us go forth and seek him, and let us offer him gifts, gold, incense and myrrh.
bvm a great and wondrous	Saint Mary on Saturday Ben	A great and wondrous mystery * is made known to us this day: a new thing is wrought in nature; God is made man: that which he was he remaineth, and that which he was not he assumeth, suffering neither confusion nor yet division.
ep sat ep1 god hath holpen	The Second Sunday after the Epiphany Mag at EP1	God hath holpen * his servant Israel: as he promised to Abraham, and his seed for ever.
inv sun ep	The Second Sunday after the Epiphany Inv	The Christ hath been manifested unto us: * O come, let us worship him.
ep2 there was a marriage	The Second Sunday after the Epiphany Ben	Now there was a marriage * in Cana of Galilee and Jesus himself was there, with Mary his Mother.
ep2 and when they	The Second Sunday after the Epiphany Mag at EP2	And when they wanted wine, * Jesus commanded them to fill the waterpots with water: and it was turned into wine, alleluia.
inv mon	Monday following Epiphany II Inv	O come, * let us sing unto the Lord.
inv tues	Tuesday following Epiphany II Inv	Let us heartily rejoice * in the strength of our salvation.
martyr inv	Common of Martyr: Pope or Bishop Inv	The Lord, the King of Martyrs: * O come, let us worship him.
martyr ben	Common of Martyr: Pope or Bishop Ben	He that hateth * his life in this world, shall keep it unto life eternal.
martyr ep2 mag	Common of Martyr: Pope or Bishop Mag at EP2	If any man will come after me, * saith the Lord, let him deny himself, and take up his cross, and follow me.
inv wed	Wednesday following Epiphany II Inv	The Lord is a great God: * O come, let us worship him.
virgins come thou bride	Common of Virgin Martyr Mag at EP1	Come, thou Bride of Christ, * the crown which the Lord hath prepared for thee for ever.
virgins the lord the king	Common of Virgin Martyr Inv	The Lord, the King of Virgins: * O come, let us worship him.
virgins the kingdom of	Common of Virgin Martyr Ben	The kingdom of heaven * is likened unto a merchant-man seeking goodly pearls: who, when he had found one pearl of great price, went and sold all that he had, and bought it.
inv thur	Thursday following Epiphany II Inv	The Christ hath been manifested unto us: * O come, let us worship him.
inv fri	Friday following Epiphany II Inv	The Christ hath been manifested unto us: * O come, let us worship him.
inv sat	Saturday following Epiphany II Inv	The Christ hath been manifested unto us: * O come, let us worship him.
ep3 and when jesus	The Third Sunday after the Epiphany Ben	And when Jesus was come down * from the mountain, behold ,there came a leper and worshipped him, saying: Lord, if thou wilt thou canst make me clean: and he put forth his hand and touched him, saying: I will, be thou clean.
ep3 lord if thou wilt	The Third Sunday after the Epiphany Mag at EP2	Lord, * if thou wilt, thou canst make me clean; and Jesus said: I will, be thou clean.
doctor francis de sales	Saint Francis de Sales Mag at EP2	O teacher most excellent, * O light of holy Church, O blessed Francis de Sales, lover of the divine law, intercede for us unto the Son of God.
saint paul go forth	The Conversion of Saint Paul the Apostle Mag at EP1	Go forth, Ananias, * and seek out Saul; for behold, he prayeth: for he is a chosen vessel unto me, to carry my name before the Gentiles and kings and the children of Israel.
saint paul inv	The Conversion of Saint Paul the Apostle Inv	For the conversion of the Teacher of the Gentiles : Let us praise our God.
saint paul ye which have	The Conversion of Saint Paul the Apostle Ben	Ye which have followed me * shall sit upon twelve thrones, judging the twelve tribes of Israel, saith the Lord.
saint paul o holy apostle	The Conversion of Saint Paul the Apostle Mag at EP2	Holy Apostle Paul, * preacher of the truth and Teacher ofthe Gentiles, intercede for us unto God who chose thee.
doctor thomas aquinas	Saint Thomas Aquinas Mag at EP2	O teacher most excellent, * O light of holy Church, O blessed Thomas Aquinas, lover of the divine law, intercede for us unto the Son of God.
septuagesima the lord said	The Third Sunday Before Lent Mag at EP1	The Lord said unto Adam: * Of the tree which is in the midst of the garden thou shalt not eat: in the day wherein thou eatest thereof, thou shalt surely die.
gesima let us come	The Third Sunday Before Lent Inv	Let us come before the presence of the Lord: * And show ourselves glad in him with Psalms.
septuagesima the kingdom of	The Third Sunday Before Lent Ben	The kingdom of heaven * is likened unto a man that is an householder, which went out early in the morning to hire labourers into his vineyard, saith the Lord.
septuagesima the householder said	The Third Sunday Before Lent Mag at EP2	The householder said * unto the labourers: Why stand ye here all the day idle? And they answering said: Because no man hath hired us. Go ye also into the vineyard; and whatsoever is right, that will I give you.
septuagesima these last	Monday following Septuagesima Mag at EP2	These last * have wrought but one hour, and thou hast made them equal unto us, which have borne the burden and heat of the day.
presentation the ancient	The Presentation of the Lord Mag at EP1	The elder * took up the Child in his arms; yet was the Child the elder's Lord: whom a Virgin bare, after childbirth remaining a Virgin; he was her Child, and him she worshipped.
presentation behold the lord	The Presentation of the Lord Inv	Behold, the Lord, the Ruler, cometh into his holy temple: * Rejoice and be glad, O Sion, and go forth to meet thy God.
presentation and when the parents	The Presentation of the Lord Ben	And when his parents * brought in the Child Jesus, then Simeon took him up in his arms, and blessed God, saying: Lord, now lettest thou thy servant depart in peace.
presentation today the blessed	The Presentation of the Lord Mag at EP2	Today * the blessed Virgin Mary presented the Child Jesus in the temple, and Simeon being full of the Holy Ghost took him up in his arms, and blessed God for ever.
septuagesima but the householder	Tuesday following Septuagesima Mag at EP2	But the householder said: * Friend, I do thee no wrong; didst thou not agree with me for a penny? Take that thine is, and go thy way.
septuagesima take that	Wednesday following Septuagesima Mag at EP2	Take that thine is, * and go thy way: for I am righteous, saith the Lord.
septuagesima is it not	Thursday following Septuagesima Mag at EP2	Is it not lawful * for me to do what I will? Is thine eye evil? For I am righteous, saith the Lord.
septuagesima so the last	Friday following Septuagesima Mag at EP2	So the last shall be first, * and the first last: for many are called, but few are chosen.
many martyrs for theirs is the kingdom	Common of Several Martyrs Mag at EP1	For there is the kingdom * of heaven, who despised the life of the world, and attained unto the rewards of the kingdom, and washed their robes in the blood of the Lamb.
many martyrs the very hairs	Common of Several Martyrs Ben	The hairs of your head * are all numbered: fear not, ye are of more value than many sparrows.
many martyrs in the heavenly	Common of Several Martyrs Mag at EP2	In the heavenly kingdom * the souls of the Saints are rejoicing, who followed the footsteps of Christ their Master: and since for love of him they freely poured forth their life-blood, therefore with Christ they reign for ever and ever.
sexagesima the lord spake unto noah	The Second Sunday Before Lent Mag at EP1	The Lord spake * unto Noah: The end of all flesh is come before me: make thee an ark of gopher wood: that the seed of all may be saved therein.
sexagesima when much people	The Second Sunday Before Lent Ben	When much people * were gathered together to Jesus, and were come to him out of every city, he spake by a parable: A sower went out to sow his seed.
sexagesima unto you it is given	The Second Sunday Before Lent Mag at EP2	Unto, you it is given * to know the mystery of the kingdom of God, but to others in parables, saith Jesus to his disciples.
sexagesima if ye seek	Monday following Sexagesima Mag at EP2	If ye seek * the summit of true honour, hasten to your heavenly country with what speed ye may.
sexagesima the seed	Tuesday following Sexagesima Mag at EP2	The seed is the word * of God, and Christ is the sower: every one that heareth him, abideth for ever.
sexagesima but that	Wednesday following Sexagesima Mag at EP2	But that which fell * on the good ground are they which in an honest and good heart receive the word, and bring forth fruit with patience.
bvm o holy mary [check for pointing]	Common of Blessed Virgin Mary Mag at EP1	Holy Mary, * succour the unhappy, help the faint-hearted, comfort the mourners, pray for the people, entreat for the clergy, intercede for the consecrated women: may all perceive thy help, who celebrate thy holy festival.
bvm inv	Common of Blessed Virgin Mary Inv	Holy Mary, Virgin Mother of God: * Intercede for us.
bvm blessed art thou	Common of Blessed Virgin Mary Ben	Blessed art thou, * O Mary, who hast believed; there shall be performed in thee the things which were told thee from the Lord.
bvm all generations	Common of Blessed Virgin Mary Mag at EP2	All generations * Shall call me blessed, for God hath regarded his lowly handmaiden.
sexagesima if then brethren	Thursday following Sexagesima Mag at EP2	If then, brethren, * ye would be truly rich, set your affection on the true riches.
sexagesima they which keep	Friday following Sexagesima Mag at EP2	They which keep the word of God * in an honest and good heart, bring forth fruit with patience.
quinquagesima mighty abraham	The Sunday Next Before Lent Mag at EP1	The great Abraham * is our father in the faith, who on the altar sacrificed a burnt-offering for his son.
quinquagesima behold we go up	The Sunday Next Before Lent Ben	Behold, we go up * to Jerusalem, and all things shall be accomplished which were written concerning the Son of man: for he shall be delivered unto the Gentiles, and shall be mocked and spitted upon: and after they have scourged him, they shall kill him; and the third day he shall rise again.
quinquagesima and jesus standing	The Sunday Next Before Lent Mag at EP2	And Jesus, standing, * commanded him to be brought unto him, and asked him, saying: What wilt thou that I should do unto thee? Lord, that I may receive my sight. And Jesus said unto him: Receive thy sight, thy faith hath saved thee. And straightway he received his sight, and followed, glorifying God.
quinquagesima and they which	Monday following Quinquagesima Mag at EP2	And they which went before * rebuked him, that he should hold his peace: but he cried so much the more: Have mercy on me, thou Son of David.
quinquagesima have mercy	Tuesday following Quinquagesima Mag at EP2	Have mercy upon me, * thou Son of David. What wilt thou that I should do unto thee? Lord, that I may receive my sight.
ash weds when ye fast	Ash Wednesday Ben	When ye fast, be not like the hypocrites, of a sad countenance.
ash weds lay up	Ash Wednesday Mag at EP2	Lay up for yourselves treasures in heaven, where neither moth nor rust doth corrupt.
ash weds lord my servant	Thursday following Ash Wednesday Ben	Lord, my servant * lieth at home, sick of the palsy, grievously tormented. Verily I say unto thee: I will come and heal him.
ash weds lord i am not worthy	Thursday following Ash Wednesday Mag at EP2	Lord, I am not worthy * that thou shouldest come under my roof: but speak the word only, and my servant shall be healed.
ash weds when thou doest thine alms	Friday following Ash Wednesday Ben	When thou doest thine alms, * let not thy left hand know what thy right hand doeth.
ash weds but thou when	Friday following Ash Wednesday Mag at EP2	But thou, when thou prayest, * enter into thy closet: and when thou hast shut thy door, pray to thy Father.
ash weds yet they seek	Saturday following Ash Wednesday Ben	From day to day * they seek me therefore, and would know my ways.
l1 then thou shalt	The First Sunday in Lent Mag at EP1	Then thou shalt call, * and the Lord shall hear thee: thou shalt cry out, and he shall say: Behold, here I am.
l1 inv	The First Sunday in Lent Inv	Think it not lost labour that ye haste to rise up early before the light: * For the Lord hath promised a crown unto them that watch.
l1 then was jesus led	The First Sunday in Lent Ben	Jesus was led up * of the Spirit into the wilderness, to be tempted of the devil: and when he had fasted forty days and forty nights, he was afterward an hungred.
l1 behold now is	The First Sunday in Lent Mag at EP2	Behold, now is the accepted time, * now is the day of salvation; let us therefore in these days approve ourselves, as the servants of God, in much patience, in watchings, in fastings, and by love unfeigned.
doctor peter damian	Saint Peter Damian Mag at EP2	O teacher most excellent, * O light of holy Church, O blessed Peter Damian, lover of the divine law, intercede for us unto the Son of God.
chair of st peter thou art	The Chair of Saint Peter Mag at EP1	Thou art shepherd * of the sheep, and prince of the Apostles; God hath given unto thee, O Peter, the keys of the kingdom of heaven.
chair of st peter inv	The Chair of Saint Peter Inv	Thou art the shepherd of the sheep, the prince of the Apostles; * To thee were given the keys of the kingdom of heaven.
chair of st peter whatsoever	The Chair of Saint Peter Ben	Whatsoever * thou shalt bind on earth shall be bound in heaven; and whatsoever thou shalt loose on earth shall be loosed in heaven: saith the Lord to Simon Peter.
chair of st peter being a high priest	The Chair of Saint Peter Mag at EP2	Being a high Priest, * he feared not the things of earth, but attained in glory unto the heavenly realms.
l1 come ye blessed	Monday following Lent I Ben	O come, ye blessed * children of my Father: inherit the kingdom prepared for you from the foundation of the world.
l1 inasmuch	Monday following Lent I Mag at EP2	Inasmuch * as ye have done it unto the least of these, ye have done it unto me, saith the Lord.
l1 and jesus went into	Tuesday following Lent I Ben	. And Jesus went * into the temple of God, and began to cast out all them that sold and bought in the temple: and overthrew the tables of the moneychangers, and the seats of them that sold doves.
l1 truly it is written	Tuesday following Lent I Mag at EP2	For it is written, * that my house is a house a prayer for all nations: but ye have made it a den of thieves: and he was teaching daily in the temple.
l1 this crooked and perverse	Ember Wednesday in Lent Ben	This generation * being perverse and crooked, seeketh a sign from me: and no sign shall be given to it, but the sign of Jonah the prophet.
l1 for as jonah	Ember Wednesday in Lent Mag at EP2	For as Jonah *was three days and three nights in the whale's belly, so shall the Son of man be in the heart of the earth.
l1 and jesus went thence	Thursday following Lent I Ben	And Jesus went thence * and departed into the coasts of Tyre and Sidon: and behold, a woman of Canaan came out of the same coasts, and cried unto him, saying: Have mercy on me, thou Son of David.
l1 o woman great is your faith	Thursday following Lent I Mag at EP2	O woman, * great is thy faith: be it unto thee, even as thou wilt.
l1 an angel of the lord	Ember Friday in Lent Ben	An Angel of the Lord * descended from heaven, and troubled the water: and one was made whole.
l1 he that made me	Ember Friday in Lent Mag at EP2	He that made me whole, * the same said unto me: Take up thy bed, and go in peace.
l1 and jesus taketh	Ember Saturday in Lent Ben	And Jesus taketh * his disciples, and goeth up into a mountain, and was transfigured before them.
doctor gregory	Saint Gregory of Narek Mag at EP2	O teacher most excellent, * O light of holy Church, O blessed Gregory of Narek, lover of the divine law, intercede for us unto the Son of God.
l2 tell the vision	The Second Sunday in Lent Mag at EP1	Tell the vision which ye have seen * to no man, until the Son of man be risen from the dead.
l2 and jesus went thence	The Second Sunday in Lent Ben	And Jesus went thence * and departed into the coasts of Tyre and Sidon: and behold, a woman of Canaan came out of the same coasts and cried unto him, saying: Have mercy on me, thou Son of David.
l2 o woman great is	The Second Sunday in Lent Mag at EP2	O woman, * great is thy faith: be it unto thee, even as thou wilt.
l2 i am the beginning	Monday following Lent II Ben	I am the Beginning: * even I that speak unto you.
l2 he that sent me	Monday following Lent II Mag at EP2	He that sent me * is with me, and he hath not left me alone: for I do always those things that please him.
l2 one is your master	Tuesday following Lent II Ben	One is your Master, * which is in heaven, even Christ the Lord.
l2 all ye are brethren	Tuesday following Lent II Mag at EP2	All ye are brethren; * and call no man your father upon earth; for one is your Father, which is in heaven: neither be ye called masters, for one is your Master, even Christ.
l2 behold we go up	Wednesday following Lent II Ben	Behold we go up * to Jerusalem; and the Son of man shall be betrayed to be crucified.
l2 he shall be delivered	Wednesday following Lent II Mag at EP2	He shall be delivered * to the Gentiles, to mock, and to scourge, and to crucify him.
inv any holy man	Common of Any Holy Man Inv	The Lord, the King of kings, O come let us worship him: * For he is the crown of all the saints.
l2 son remember	Thursday following Lent II Ben	Son, remember * that thou in thy lifetime receivedst thy good things, and likewise Lazarus evil things.
l2 the same rich man	Thursday following Lent II Mag at EP2	The same rich man * begged a drop of water, who had denied crumbs of bread to Lazarus.
l2 he will miserably	Friday following Lent II Ben	He will miserably destroy * those miserable men; and will let out his vineyard unto other husbandmen, which shall render him the fruits in their seasons.
l2 when they sought to lay	Friday following Lent II Mag at EP2	When they sought to take him, * they feared the people; because they counted him as a prophet.
l2 i will go unto my father	Saturday following Lent II Ben	I will go unto my father, and will say unto him: Father, make me as one of thy hired servants.
l3 but the father	The Third Sunday in Lent Mag at EP1	But the father said * unto his servants: Bring forth quickly the best robe and put it on him, and put a ring on his finger, and shoes on his feet.
l3 when a strong man	The Third Sunday in Lent Ben	When a strong man armed * keepeth his palace, his goods are in peace.
l3 a certain woman	The Third Sunday in Lent Mag at EP2	A certain woman * of the company lifted up her voice, and cried: Blessed is the womb that bare thee, and the paps which thou hast sucked. But Jesus answered: Yea rather, blessed are they that hear the word of God, and keep it.
l3 verily i say unto you	Monday following Lent III Ben	Verily, I say unto you: * that no prophet is accepted in his own country.
l3 but jesus	Monday following Lent III Mag at EP2	But Jesus, passing * through the midst of them, went his way.
frances of rome inv	Saint Frances of Rome Inv	Let us praise our God: * Whom blessed Frances of Rome confessed.
holy woman give her	Common of Any Matron or Holy Woman Ben	Give her * of the fruit of her hands, and let her own works praise her in the gates.
matron she stretcheth	Common of Any Matron or Holy Woman Mag at EP2	She stretched out her hand * to the poor, yea, she reacheth forth her hands to the needy, and eateth not the bread of idleness.
l3 if two of you	Tuesday following Lent III Ben	If two of you shall agree * on earth as touching aught for which they shall make request, it shall be done for them of my Father which is in heaven, saith the Lord.
l3 where two or three	Tuesday following Lent III Mag at EP2	Where two or three * are gathered together in my name, there am I in the midst of them, saith the Lord.
l3 hearken	Wednesday following Lent III Ben	Hearken and understand ye: * the traditions which the Lord hath given you.
l3 to eat with	Wednesday following Lent III Mag at EP2	To eat with unwashen hands * defileth not a man.
l3 and devils also	Thursday following Lent III Ben	And devils also came out, of many, * crying out and saying: Thou art Christ, the Son of God: and he rebuking them suffered them not to speak, for they knew that he was Christ.
l3 whosoever had any	Thursday following Lent III Mag at EP2	All they that had any sick * brought them to Jesus, and they were healed.
l3 whosoever drinketh	Friday following Lent III Ben	If any man drink * of the water that I shall give, he shall never thirst.
l3 sir i perceive	Friday following Lent III Mag at EP2	Sir, I perceive * that thou art a prophet: our fathers worshipped in this mountain.
l3 and jesus stooped down	Saturday following Lent III Ben	And Jesus stooped down, * and wrote upon the ground: he that is without sin, let him cast a stone at her.
l4 woman hath no	The Fourth Sunday in Lent Mag at EP1	Woman, hath no man condemned thee? * No man, Lord. Neither do I condemn thee: go and sin no more.
l4 when jesus lift up	The Fourth Sunday in Lent Ben	When Jesus lift up his eyes, * and saw a great company come unto him, he said unto Philip: Whence shall we buy bread, that these may eat? And this he said to prove him, for he himself knew what he would do.
l4 jesus went up	The Fourth Sunday in Lent Mag at EP2	Jesus went up * into a mountain, and there he sat with his disciples.
l4 take these things hence	Monday following Lent IV Ben	Take these things hence, * saith the Lord: and make not my Father's house a house of merchandise.
l4 destroy this temple	Monday following Lent IV Mag at EP2	Destroy this temple * saith the Lord: and in three days I will raise it up; but this he said of the temple of his body.
l4 wherefore do ye seek	Tuesday following Lent IV Ben	Wherefore do ye seek * to kill me, a man that hath told you the truth?
l4 no man laid hands	Tuesday following Lent IV Mag at EP2	No man laid hands on him, * because his hour was not yet come.
l4 master wherein hath	Wednesday following Lent IV Ben	Master, who hath sinned, * this man or his parents, that he was born blind? And Jesus answered, saying: Neither hath this man sinned, nor his parents: but that the works of God should be manifested in him.
l4 a man that is called	Wednesday following Lent IV Mag at EP2	A man that is called Jesus * made clay out of spittle, and anointed mine eyes, and now I see.
doctor cyril	Saint Cyril of Jerusalem Mag at EP2	O teacher most excellent, * O light of holy Church, O blessed Cyril of Jerusalem, lover of the divine law, intercede for us unto the Son of God.
l4 jesus went into	Thursday following Lent IV Ben	Jesus went * into a city called Nain: and behold, there was a dead man carried out, the only son of his mother.
l4 a great prophet	Thursday following Lent IV Mag at EP2	A great prophet * is risen up amongst us, and God hath visited his people.
joseph then joseph being raised	Saint Joseph Mag at EP1	Then Joseph * being raised from sleep, did as the Angel of the Lord had bidden him, and took unto him his wife.
joseph inv	Saint Joseph Inv	Christ, the Son of God, deigned to be thought the son of Joseph: * O come, let us worship him.
joseph jesus himself	Saint Joseph Ben	Jesus himself * began to be about thirty years of age, being, as was supposed, the son of Joseph.
joseph behold a faithful	Saint Joseph Mag at EP2	Behold a faithful and wise steward, * whom his Lord hath made ruler over his household.
l4 our friend lazarus	Friday following Lent IV Ben	Our friend Lazarus * sleepeth: let us go, that we may awake him out of sleep.
l4 lord if thou hadst	Friday following Lent IV Mag at EP2	Lord, if thou hadst been here, * Lazarus would not have died: lo, by this time he stinketh, being in the sepulchre four days already.
l4 he that followeth me	Saturday following Lent IV Ben	He that followeth me * shall not walk in darkness: but shall have the light of life, saith the Lord.
l5 i am one	The Fifth Sunday in Lent Mag at EP1	I am one, * that bear witness of myself, and the Father that sent me beareth witness of me.
passiontide inv	The Fifth Sunday in Lent Inv	Today, if ye will hear the voice of the Lord, * Harden not your hearts.
l5 jesus said unto the	The Fifth Sunday in Lent Ben	Jesus said * unto the multitudes of the Jews, and to the chief priests: He that is of God, heareth my words; ye therefore hear them not, because ye are not of God.
l5 your father abraham	The Fifth Sunday in Lent Mag at EP2	Your father Abraham * rejoiced to see my day: he saw it, and was glad.
l5 in the last day of the feast	Monday following Passion Sunday Ben	In the last day * of the feast, Jesus stood and cried, saying: If any man thirst let him come unto me and drink.
l5 if any man thirst	Monday following Passion Sunday Mag at EP2	If any man thirst, * let him come and drink: and out of his belly shall flow rivers of living water, saith the Lord.
l5 my time is not yet come	Tuesday following Passion Sunday Ben	My time * is not yet come: but your time is always ready.
l5 go ye up	Tuesday following Passion Sunday Mag at EP2	Go ye up * unto this feast: I go not up yet, for my time is not yet fully come.
l5 my sheep hear	Wednesday following Passion Sunday Ben	My sheep * hear my voice: and I the Lord do know them.
l5 many good works	Wednesday following Passion Sunday Mag at EP2	Many good works * have I wrought among you: for which of those works do ye seek to kill me?
annunciation the holy ghost	The Annunciation of the Lord Mag at EP1	The Holy Ghost * shall come upon thee, and the power of the highest shall overshadow thee.
annunciation how shall this be	The Annunciation of the Lord Ben	How shall this be, *O Angel of God, seeing I know not a man? Hearken, O Virgin Mary: the Holy Ghost shall come upon thee, and the power of the highest shall overshadow thee.
annunciation the angel gabriel	The Annunciation of the Lord Mag at EP2	The Angel Gabriel * spake unto Mary, saying: Hail, full of grace; the Lord is with thee: blessed art thou among women.
l5 the master saith	Thursday following Passion Sunday Ben	The Master saith: * My time is at hand: I will keep the Passover at thy house with my disciples.
l5 with desire have i	Thursday following Passion Sunday Mag at EP2	With desire have I desired * to eat this Passover with you, before I suffer.
sorrows come ye	Saint Mary in Passiontide Ben	O come ye, * and let us go up to the hill of the Lord; and behold ye if there be any sorrow like unto my sorrow.
sorrows sorrow hath weighed	Saint Mary in Passiontide Mag at EP2	Sorrow hath weighted me down, * and my face is swollen with weeping, and on mine eyelids is the shadow of death.
l5 now the feast day	Friday following Passion Sunday Ben	Now the feast day * was nigh at hand, and the chief priests and elders sought for occasion to slay Jesus: but they feared the multitude.
l5 the chief priests	Friday following Passion Sunday Mag at EP2	The chief priests and elders * took counsel together how they might destroy him: but they spake and said: Not on the feast day, lest there be an uproar among the people.
l5 o father glorify	Saturday following Passion Sunday Ben	Glorify me, O Father, * with thine own self, with the glory which I had before the world was.
hw righteous father	Palm Sunday Mag at EP1	Righteous Father, * the world hath not known thee: but I have known that thou didst send me.
hw much people	Palm Sunday Ben	Much people, * which was come together on' the feast day, cried out and said: Blessed is he that cometh in the name of the Lord: Hosanna in the highest.
hw for it is written	Palm Sunday Mag at EP2	For it is written: * I will smite the shepherd, and the sheep of the flock shall be scattered: but after I am risen, I will go before you into Galilee: there shall ye see me, saith the Lord.
hw o father glorify	Monday in Holy Week Ben	Glorify me, O Father, * with thine own self, with the glory which I had before the world was.
hw thou couldest have	Monday in Holy Week Mag at EP2	Thou couldest have no power * at all against me, except it were given thee from above.
hw before the day	Tuesday in Holy Week Ben	Before the day of the feast * of the Passover, Jesus, knowing that his hour was come, when he loved his own, he loved them unto the end.
hw i have power	Tuesday in Holy Week Mag at EP2	I have power * to lay down my life, and I have power to take it up again.
hw simon sleepest thou	Wednesday in Holy Week Ben	Simon, sleepest thou? * Couldest thou not watch with me one hour?
hw a maid said	Wednesday in Holy Week Mag at EP2	A maid said * unto Peter: Verily thou art one of them; and thy speech betrayeth thee.
hw now he that betrayed	Thursday in Holy Week Ben	Now he that betrayed him * gave them a sign, saying: Whomsoever I shall kiss, that same is he; hold him fast.
hw and as they were eating	Thursday in Holy Week Mag at EP2	And as they were eating, * Jesus took bread, and blessed it, and brake it, and gave it to his disciples.
hw they set up	Good Friday Ben	And they set up * over his head his accusation written: Jesus of Nazareth, the King of the Jews.
hw when he had received	Good Friday Mag at EP2	When he had received the vinegar, * he said: It is finished; and he bowed his head, and gave up the ghost.
hw the women also sitting	Holy Saturday Ben	The women also, * sitting at the sepulchre, made lamentation, weeping for the Lord.
hw the chief priests	Holy Saturday Mag at EP2	The chief priests * and Pharisees guarded the sepulchre, sealing the stone, and setting a watch.
e1 and very early [easter]	Easter Day Ben with Alleluia	And very early in the morning, * the first day of the week, they came to the sepulchre at the rising of the sun, alleluia.
e1 and when they looked [easter]	Easter Day Mag at EP2 with Alleluia	And when they looked, * they saw that the stone was rolled away; for it was very great, alleluia.
doctor isidore [easter]	Saint Isidore Mag at EP2 with Alleluia	O teacher most excellent, * O light of holy Church, O blessed Isidore, lover of the divine law, intercede for us unto the Son of God. Alleluia.
religious i will liken him [easter]	Common of Doctor of the Church Mag at EP1 with Alleluia	I will liken him * unto a wise man, which built his house upon a rock. Alleluia.
religious the lord the king [easter]	Common of Doctor of the Church Inv with Alleluia	The Lord, the King of Confessors: * O come, let us worship him. Alleluia.
religious well done good and faithful [easter]	Common of Doctor of the Church Ben with Alleluia	Well done, good and faithful servant, * because thou hast been faithful over a few things, I will make thee ruler over many things, saith the Lord. Alleluia.
religious this man the world [easter]	Common of Doctor of the Church Mag at EP2 with Alleluia	This man, the world despising, * and earthly things renouncing, gaining the victory, laid up treasures in heaven by word and deed. Alleluia.
bishop o high priest [easter]	Common of Confessor: Bishop Mag at EP1 with Alleluia	High priest and bishop, * mighty in virtues, good shepherd of the people, pray for us unto the Lord. Alleluia.
bishops well done good and faithful [easter]	Common of Confessor: Bishop Ben with Alleluia	Well done, good and faithful servant, * because thou hast been faithful over a few things, I will make thee ruler over many things, saith the Lord. Alleluia.
bishops the lord loved him [easter]	Common of Confessor: Bishop Mag at EP2 with Alleluia	The Lord loved him * and adorned him: he clothed him with a robe of glory, and at the gates of paradise he crowned him. Alleluia.
e1 jesus joined himself [easter]	Monday in the Octave of Easter Ben with Alleluia	Jesus joined himself * to his disciples in the way, and went with them; but their eyes were holden that they knew him not; and he rebuked them, saying: O fools and slow of heart to believe all that the Prophets have spoken, alleluia.
e1 what manner [easter]	Monday in the Octave of Easter Mag at EP2 with Alleluia	What manner of communications * are these that ye have one to another, as ye walk, and are sad? alleluia.
e1 jesus stood in [easter]	Tuesday in the Octave of Easter Ben with Alleluia	Jesus stood * in the midst of his disciples, and said unto them: Peace be unto you, alleluia, alleluia.
e1 behold my hands [easter]	Tuesday in the Octave of Easter Mag at EP2 with Alleluia	Behold my hands * and my feet, that it is I myself, alleluia, alleluia.
e1 cast the net [easter]	Wednesday in the Octave of Easter Ben with Alleluia	Cast the net * on the right side of the ship, and ye shall find, alleluia.
e1 jesus said to [easter]	Wednesday in the Octave of Easter Mag at EP2 with Alleluia	Jesus said * to his disciples: Take of the fish which ye have now caught. But Simon Peter went up, and drew the net to land, full of great fishes, alleluia.
e1 many stood [easter]	Thursday in the Octave of Easter Ben with Alleluia	Mary stood * at the sepulchre weeping, and seeth two Angels in white sitting, and the napkin that was about the head of Jesus, alleluia.
e1 they have taken [easter]	Thursday in the Octave of Easter Mag at EP2 with Alleluia	They have taken away my Lord * and I know not where they have laid him; if thou have borne him hence, tell me, alleluia, and I will take him away, alleluia.
e1 the eleven [easter]	Friday in the Octave of Easter Ben with Alleluia	The eleven disciples * when they saw the Lord in Galilee, worshipped him, alleluia.
e1 all power [easter]	Friday in the Octave of Easter Mag at EP2 with Alleluia	All power is given * unto me in heaven and in earth, alleluia.
e1 they ran both [easter]	Saturday in the Octave of Easter Ben with Alleluia	They ran both together, * and the other disciple did outrun Peter, and came first to the sepulchre, alleluia.
annunciation inv [easter]	Saint Mary on Saturday Inv with Alleluia	Hail, Mary, full of grace; * The Lord is with thee. Alleluia.
bvm joy to thee [easter]	Saint Mary on Saturday Ben with Alleluia	Joy to thee, * O Queen of heaven, alleluia; he whom thou wast meet to bear, alleluia, as he promised hath arisen, alleluia: pour for us to God thy prayer, alleluia.
e2 that same evening [easter]	The Second Sunday of Easter Mag at EP1 with Alleluia	The same day at evening, * being the first day of the week, when the doors were shut where the disciples were assembled, Jesus stood in the midst of them, and said unto them: Peace be unto you, alleluia.
easter inv [easter]	The Second Sunday of Easter Inv with Alleluia	The Lord is risen indeed, * alleluia.
e2 after eight days [easter]	The Second Sunday of Easter Mag at EP2 with Alleluia	After eight days, * when the doors were shut, the Lord entered, and said unto them: Peace be unto you; alleluia, alleluia.
martyr inv [easter]	Common of Martyr: Pope or Bishop Inv with Alleluia	Let the Saints be joyful in the Lord: * Alleluia.
martyr ben [easter]	Common of Martyr: Pope or Bishop Ben with Alleluia	Daughters of Jerusalem, * come forth, and behold the Martyrs with the diadems wherewith the Lord hath crowned them in the day of solemnity and rejoicing, alleluia, alleluia.
martyr ep2 mag [easter]	Common of Martyr: Pope or Bishop Mag at EP2 with Alleluia	O ye Saints and righteous, * rejoice in the Lord, alleluia: God hath chosen you for his own inheritance, alleluia.
e2 when jesus was risen [easter]	Monday following Easter II Ben with Alleluia	When Jesus was risen * early on the first day of the week, he appeared first to Mary Magdalene, out of whom he had cast seven devils, alleluia.
e2 peace be unto you [easter]	Monday following Easter II Mag at EP2 with Alleluia	Peace be unto you * it is I: be not afraid, alleluia.
e2 i go before you [easter]	Tuesday following Easter II Ben with Alleluia	I go before you * into Galilee: there shall ye see me, as I said unto you, alleluia, alleluia.
e2 reach hither [easter]	Tuesday following Easter II Mag at EP2 with Alleluia	Reach hither thy hand * and thrust it into my side, alleluia: and be not faithless but believing, alleluia.
e2 i am the true vine [easter]	Wednesday following Easter II Ben with Alleluia	I am the true vine, * and ye are the branches, alleluia.
e2 because thou hast [easter]	Wednesday following Easter II Mag at EP2 with Alleluia	Because thou hast seen me, * Thomas, thou hast believed: blessed are they that have not seen, and yet have believed, alleluia.
e2 my heart burns [easter]	Thursday following Easter II Ben with Alleluia	My heart burneth, *I long to behold my Lord: I seek him, and I find him not where they laid him, alleluia, alleluia.
e2 i did put [easter]	Thursday following Easter II Mag at EP2 with Alleluia	I put my finger * into the prints of the nails, and my hands into his side; and I said: My Lord and my God, alleluia.
e2 there came unto the tomb [easter]	Friday following Easter II Ben with Alleluia	There came unto the tomb * Mary Magdalene and the other Mary, to see the sepulchre, alleluia.
e2 after ... when the doors [easter]	Friday following Easter II Mag at EP2 with Alleluia	After eight days, * when the doors were shut, the Lord entered, and said unto them: Peace be unto you; alleluia, alleluia.
e3 i am the shepherd [easter]	The Third Sunday of Easter Mag at EP1 with Alleluia	I am the Shepherd of the sheep: * I am the way, the truth and the life; I am the good Shepherd, and know my sheep and am known of mine, alleluia, alleluia.
e3 i am the good shepherd [easter]	The Third Sunday of Easter Mag at EP2 with Alleluia	I am the good Shepherd, * and I feed my sheep; and I lay down my life for the sheep, alleluia.
e3 go ye into all [easter]	Monday following Easter III Ben with Alleluia	Go ye into all the world * and teach all nations, alleluia.
e3 the good shepherd lays [easter]	Monday following Easter III Mag at EP2 with Alleluia	The good Shepherd * layeth down his life for the sheep, alleluia.
e3 go ye into the world [easter]	Tuesday following Easter III Ben with Alleluia	Go ye into all the world, * and teach all nations, baptising them in the name of the Father, and of the Son, and of the Holy Ghost, alleluia.
e3 he that is an hireling [easter]	Tuesday following Easter III Mag at EP2 with Alleluia	He that is an hireling, * whose own the sheep are not, seeth the wolf coming, and leaveth the sheep, and fleeth: and the wolf catcheth them, and scattereth the sheep, alleluia.
e3 go unto my [easter]	Wednesday following Easter III Ben with Alleluia	Go unto my brethren * and say unto them, alleluia, that they go into Galilee, alleluia: there shall they see me, alleluia, alleluia, alleluia.
e3 as the father knows [easter]	Wednesday following Easter III Mag at EP2 with Alleluia	As the Father knoweth me, * even so know I the Father: and I lay down my life for the sheep, alleluia.
e3 art thou only [easter]	Thursday following Easter III Ben with Alleluia	Art thou only a stranger * and hast not heard concerning Jesus, how they delivered him to be condemned to death, alleluia.
e3 other sheep i have [easter]	Thursday following Easter III Mag at EP2 with Alleluia	Other sheep I have, * which are not of this fold: them also I must bring, and they shall hear my voice: and there shall be one fold and one Shepherd, alleluia.
st george the saints through faith [easter]	Saint George Mag at EP1 with Alleluia	The Saints through faith * subdued kingdoms, wrought righteousness, obtained promises, alleluia.
e3 ought not christ [easter]	Friday following Easter III Ben with Alleluia	Ought not Christ to have suffered* these things, and to enter into his glory? alleluia.
e4 a little while [easter]	The Fourth Sunday of Easter Mag at EP1 with Alleluia	A little while * and ye shall not see me, saith the Lord: and again a little while, and ye shall see me, because I go to the Father, alleluia.
e4 verily i say [easter]	The Fourth Sunday of Easter Mag at EP2 with Alleluia	Verily I say unto you, * that ye shall weep and lament; and the world shall rejoice; and ye shall be sorrowful, but your sorrow shall be turned into joy, alleluia.
apostles for they shall [easter]	Common of Apostles Mag at EP1 with Alleluia	Light perpetual * shall shine upon thy Saints, O Lord, and an ageless eternity, alleluia.
apostles inv [easter]	Common of Apostles Inv with Alleluia	The Lord, the King of Apostles: * O come, let us worship him, alleluia.
apostles ye which have [easter]	Common of Apostles Ben with Alleluia	Daughter of Jerusalem, come forth, and behold the Martyrs with the diadems wherewith the Lord hath crowned them in the day of solemnity and rejoicing, alleluia, alleluia.
apostles be ye valiant [easter]	Common of Apostles Mag at EP2 with Alleluia	O ye Saints and righteous, * rejoice in the Lord, alleluia: God hath chosen you for his own inheritance, alleluia.
e4 and beginning [easter]	Monday following Easter IV Ben with Alleluia	And beginning at Moses * and all the Prophets, he expounded unto them the scriptures concerning himself, alleluia.
e4 your sorrow shall be [easter]	Monday following Easter IV Mag at EP2 with Alleluia	Your sorrow * shall be turned into joy, alleluia: and your joy no man taketh from you, alleluia, alleluia.
e4 and they constrained [easter]	Tuesday following Easter IV Ben with Alleluia	And they constrained him * saying: Abide with us, for it is toward evening, alleluia.
e4 sorrow hath filled [easter]	Tuesday following Easter IV Mag at EP2 with Alleluia	Sorrow hath filled * your heart: but your joy no man taketh from you, alleluia, alleluia.
e4 abide with us for [easter]	Wednesday following Easter IV Ben with Alleluia	Abide with us, * for it is toward evening, and the day is far spent, alleluia.
e4 your sorrow will be turned [easter]	Wednesday following Easter IV Mag at EP2 with Alleluia	Your sorrow, * alleluia, shall be turned into joy, alleluia.
doctor catherine [easter]	Saint Catherine of Siena Mag at EP2 with Alleluia	O teacher most excellent, * O light of holy Church, O blessed Catherine of Siena, lover of the divine law, intercede for us unto the Son of God. Alleluia.
virgins come thou bride [easter]	Common of Virgin Mag at EP1 with Alleluia	Come, thou Bride of Christ, * receive the crown which the Lord hath prepared for thee for ever. Alleluia.
virgins the lord the king [easter]	Common of Virgin Inv with Alleluia	The Lord, the King of Virgins: * O come, let us worship him. Alleluia.
virgins the kingdom of [easter]	Common of Virgin Ben with Alleluia	The kingdom of heaven * is likened unto a merchant-man seeking goodly pearls: who, when he had found one pearl of great price, went and sold all that he had, and bought it. Alleluia.
e4 and he went in [easter]	Thursday following Easter IV Ben with Alleluia	And he went in * to tarry with them; and it came to pass, as he sat at meat with them, he took bread, and blessed it, and brake, and gave it to them, alleluia.
e4 verily verily i say [easter]	Thursday following Easter IV Mag at EP2 with Alleluia	Verily, verily, * I say unto you: I will see you again, and your heart shall rejoice, and your joy no man taketh from you, alleluia.
e4 they knew the lord [easter]	Friday following Easter IV Ben with Alleluia	They knew the Lord * Jesus, alleluia, in breaking of bread, alleluia.
joseph then joseph being raised [easter]	Saint Joseph the Worker Mag at EP1 with Alleluia	Then Joseph * being raised from sleep, did as the Angel of the Lord had bidden him, and took unto him his wife. Alleluia.
joseph inv [easter]	Saint Joseph the Worker Inv with Alleluia	Christ, the Son of God, deigned to be thought the son of Joseph: * O come, let us worship him. Alleluia.
joseph jesus himself [easter]	Saint Joseph the Worker Ben with Alleluia	Jesus himself * began to be about thirty years of age, being, as was supposed, the son of Joseph. Alleluia.
joseph behold a faithful [easter]	Saint Joseph the Worker Mag at EP2 with Alleluia	Behold a faithful and wise steward, * whom his Lord hath made ruler over his household. Alleluia.
e5 i go my way [easter]	The Fifth Sunday of Easter Mag at EP1 with Alleluia	I go my way to him * that sent me; and none of you asketh me: Whither goest thou? alleluia, alleluia.
doctor athanasius [easter]	Saint Athanasius Mag at EP2 with Alleluia	O teacher most excellent, * O light of holy Church, O blessed Athanasius, lover of the divine law, intercede for us unto the Son of God. Alleluia.
e5 did not our heart [easter]	Monday following Easter V Ben with Alleluia	Did not our heart burn * within us concerning Jesus, while he talked with us in the way? alleluia.
e5 i tell you the truth [easter]	Monday following Easter V Mag at EP2 with Alleluia	I tell you the truth: * it is expedient for you that I go away; for if I go not away, the Comforter will not come unto you, alleluia.
many martyrs for theirs is the kingdom [easter]	Common of Several Martyrs Mag at EP1 with Alleluia	Light perpetual * shall shine upon thy Saints, O Lord, and an ageless eternity, alleluia.
many martyrs the very hairs [easter]	Common of Several Martyrs Ben with Alleluia	Daughters of Jerusalem, * come forth, and behold the Martyrs with the diadems wherewith the Lord hath crowned them in the day of solemnity and rejoicing, alleluia, alleluia.
many martyrs in the heavenly [easter]	Common of Several Martyrs Mag at EP2 with Alleluia	O ye Saints and righteous, * rejoice in the Lord, alleluia: God hath chosen you for his own inheritance, alleluia.
e5 peace but unto you it is [easter]	Tuesday following Easter V Ben with Alleluia	Peace be unto you, * it is I, alleluia; be not afraid, alleluia.
e5 when the comforter [easter]	Tuesday following Easter V Mag at EP2 with Alleluia	When the Comforter shall come, * even the Spirit of truth, he shall reprove the world of sin, and of righteousness, and of judgement, alleluia.
e5 a spirit hath not [easter]	Wednesday following Easter V Ben with Alleluia	A spirit hath not flesh * and bones, as ye see me have; therefore believe, alleluia.
e5 i have yet many [easter]	Wednesday following Easter V Mag at EP2 with Alleluia	I have yet many things * to say into you, but ye cannot bear them now; howbeit, when he, the Spirit of truth, shall come, he will guide you into all truth, alleluia.
e5 the disciples offered [easter]	Thursday following Easter V Ben with Alleluia	The disciples * set before the Lord a piece of broiled fish, and of an honeycomb, alleluia, alleluia.
e5 for he shall not [easter]	Thursday following Easter V Mag at EP2 with Alleluia	He shall not speak * of himself, but whatsoever he shall hear, that shall he speak; and he will show you things to come, alleluia.
e5 these are the words [easter]	Friday following Easter V Ben with Alleluia	These are the words * which I spake unto you, while I was yet with you, alleluia, alleluia.
e5 now i go my way [easter]	Friday following Easter V Mag at EP2 with Alleluia	I go my way to him * that sent me; but because I have said these things unto you, sorrow hath filled your heart, alleluia.
e6 hitherto have ye [easter]	The Sixth Sunday of Easter Mag at EP1 with Alleluia	Hitherto * have ye asked nothing in my name; ask, and ye shall receive, alleluia.
e6 ask ye [easter]	The Sixth Sunday of Easter Mag at EP2 with Alleluia	Ask ye, * and ye shall receive, that your joy may be fulfilled; for the Father himself loveth you, because ye have loved me, and have believed, alleluia.
e6 ask and it shall [easter]	Rogation Monday Ben with Alleluia	Ask, and it shall be given you; * seek, and ye shall find; knock, and it shall be opened unto you, alleluia.
e6 for the father [easter]	Rogation Monday Mag at EP2 with Alleluia	The Father himself loveth * you, because ye have loved me, and have believed, alleluia.
doctor john [easter]	Saint John of Avila Mag at EP2 with Alleluia	O teacher most excellent, * O light of holy Church, O blessed John of Avila, lover of the divine law, intercede for us unto the Son of God. Alleluia.
e6 it behoved christ [easter]	Rogation Tuesday Ben with Alleluia	It beloved * Christ to suffer, and to rise again from the dead, alleluia.
e6 i came forth [easter]	Rogation Tuesday Mag at EP2 with Alleluia	I came forth * from the Father, and am come into the world; again, I leave the world, and go to the Father, alleluia.
e6 father the hour [easter]	Rogation Wednesday Ben with Alleluia	Father, the hour is come, * glorify thy Son with the glory which I had with thee before the world was, alleluia.
ascension father i have [easter]	The Ascension of the Lord Mag at EP1 with Alleluia	Father, * I have manifested thy name unto the men whom thou hast given me; and now I pray for them, not for the world, because I come to thee, alleluia.
ascension inv [easter]	The Ascension of the Lord Inv with Alleluia	Alleluia, Christ the Lord ascendeth into heaven: * O come, let us worship him, alleluia.
ascension i ascend [easter]	The Ascension of the Lord Ben with Alleluia	I ascend to my Father, * and your Father; and to my God, and your God, alleluia.
ascension o king of [easter]	The Ascension of the Lord Mag at EP2 with Alleluia	O King of glory, * Lord of Sabaoth, who on this day ascendest with exceeding triumph far above all heavens: we pray thee, leave us not comfortless, but send the promise of the Father on us, even the Spirit of truth, alleluia.
bvm o holy mary [check for pointing] [easter]	Common of Blessed Virgin Mary Mag at EP1 with Alleluia	Holy Mary, * succour the unhappy, help the faint-hearted, comfort the mourners, pray for the people, entreat for the clergy, intercede for the consecrated women: may all perceive thy help, who celebrate thy holy festival. Alleluia.
bvm inv [easter]	Common of Blessed Virgin Mary Inv with Alleluia	Holy Mary, Virgin Mother of God: * Intercede for us. Alleluia.
bvm blessed art thou [easter]	Common of Blessed Virgin Mary Ben with Alleluia	Blessed art thou, * O Mary, who hast believed; there shall be performed in thee the things which were told thee from the Lord. Alleluia.
bvm all generations [easter]	Common of Blessed Virgin Mary Mag at EP2 with Alleluia	All generations * Shall call me blessed, for God hath regarded his lowly handmaiden. Alleluia.
e7 when the [easter]	The Seventh Sunday of Easter Mag at EP1 with Alleluia	When the Comforter is come, * whom I will send unto you, even the Spirit of truth, which proceedeth from the Father, he shall testify of me, alleluia.
e7 these things [easter]	The Seventh Sunday of Easter Mag at EP2 with Alleluia	These things have I told you, * that when the hour cometh, ye may remember that I told you of them, alleluia.
holy woman give her [easter]	Common of Any Matron or Holy Woman Ben with Alleluia	Give her * of the fruit of her hands, and let her own works praise her in the gates. Alleluia.
matron she stretcheth [easter]	Common of Any Matron or Holy Woman Mag at EP2 with Alleluia	She stretched out her hand * to the poor, yea, she reacheth forth her hands to the needy, and eateth not the bread of idleness. Alleluia.
pen i will not [easter]	Pentecost Mag at EP1 with Alleluia	I will not leave you * comfortless, alleluia; I go away, and come again unto you, alleluia; and your heart shall rejoice, alleluia.
pen inv [easter]	Pentecost Inv with Alleluia	Alleluia, the Spirit of the Lord filleth the world:* O come, let us worship him, alleluia.
pen receive ye [easter]	Pentecost Ben with Alleluia	Receive ye * the Holy Ghost; whosesoever sins ye remit, they are remitted unto them, alleluia.
pen today are fulfilled [easter]	Pentecost Mag at EP2 with Alleluia	Today * are fulfilled the days of Pentecost, alleluia; today the Holy Spirit appeared in fire to the disciples, and bestowed upon them his manifold graces, sending them into all the world, to preach and to testify: He that believeth and is baptised shall be saved, alleluia.
olhoc behold mary [easter]	Our Lady, Help of Christians Mag at EP1 with Alleluia	Behold, Mary * was our hope, to whom we fled for help, that she might deliver us, and she. came to our aid. Alleluia.
olhoc to thee we cried [easter]	Our Lady, Help of Christians Ben with Alleluia	To thee * we cried, O holy Mother of God, and through thee came the help of the Lord to us. Alleluia.
olhoc holy mary help [easter]	Our Lady, Help of Christians Mag at EP2 with Alleluia	Holy Mary, * help those in misery, strengthen the fainthearted, comfort the sorrowful, pray for the people, intercede for clerics, plead for all women vowed to God; may all feel thy help, whosoever implore thine aid. Alleluia.
pen god so loved [easter]	Monday in Whitsun Week Ben with Alleluia	God so loved the world * that he gave his only-begotten Son: that whosoever believeth in him should not perish, but have everlasting life, alleluia.
pen if a man love [easter]	Monday in Whitsun Week Mag at EP2 with Alleluia	If a man love me, * he will keep my word: and my Father will love him, and we will come unto him, and make our abode with him, alleluia.
pen i am the door [easter]	Tuesday in Whitsun Week Ben with Alleluia	I am the door, * saith the Lord: by me if any man enter in, he shall be saved, and shall find pasture, alleluia.
pen peace i leave [easter]	Tuesday in Whitsun Week Mag at EP2 with Alleluia	Peace I leave * with you, my peace I give unto you: not as the world giveth, give I unto you, alleluia.
doctor bede [easter]	Saint Bede the Venerable Mag at EP2 with Alleluia	O teacher most excellent, * O light of holy Church, O blessed Bede the Venerable, lover of the divine law, intercede for us unto the Son of God. Alleluia.
pen i am the living [easter]	Ember Wednesday in Whitsun Week Ben with Alleluia	I am the living bread, * saith the Lord, which came down from heaven, alleluia.
pen i am the living which came [easter]	Ember Wednesday in Whitsun Week Mag at EP2 with Alleluia	I am the living bread, * which came down from heaven: if any man eat of this bread, he shall live for ever; and the bread that I will give is my flesh, for the life of the world, alleluia.
oljchp inv [easter]	Our Lord Jesus Christ, Eternal High Priest Inv with Alleluia	The Lord, the High Priest, * O come, let us worship him. Alleluia.
oljchp this is my body [easter]	Our Lord Jesus Christ, Eternal High Priest Ben with Alleluia	This is my body * which is given for you: this cup is the new testament in my blood, which is shed for you: this do in remembrance of me. Alleluia.
oljchp behold i will send [easter]	Our Lord Jesus Christ, Eternal High Priest Mag at EP2 with Alleluia	Behold, I will send my messenger, * and he shall prepare the way before me. He shall purify the sons of Levi; that they may offer unto the Lord an offering in righteousness: and the offering of Juda and Jerusalem shall be pleasant unto the Lord, as in former years. Alleluia.
pen jesus called unto [easter]	Thursday in Whitsun Week Ben with Alleluia	Jesus called the twelve * disciples together, and gave them power and authority over unclean spirits, and to cure diseases: and he sent them to preach the kingdom of God, and to heal the sick, alleluia.
pen the spirit which [easter]	Thursday in Whitsun Week Mag at EP2 with Alleluia	The Spirit, * which proceeded from the Father, alleluia: he shall glorify me, alleluia, alleluia.
pen jesus said [easter]	Ember Friday in Whitsun Week Ben with Alleluia	Jesus said: * But that ye know that the Son of man hath power on earth to forgive sins (he saith unto the sick of the palsy): I say unto thee: Arise, take up thy bed, and go thy way into thine house, alleluia.
pen but the comforter [easter]	Ember Friday in Whitsun Week Mag at EP2 with Alleluia	But the Comforter, * which is the Holy Ghost, whom the Father will send in my name, he shall teach you all things, and bring all things to your remembrance, whatsoever I have said unto you, alleluia.
pen the love of god [easter]	Ember Saturday in Whitsun Week Ben with Alleluia	The love of God * is shed abroad in our hearts by his Spirit which dwelleth in us, alleluia.
trin thanks o god	Trinity Sunday Mag at EP1	Thanks, O God, * be unto thee, thanks be unto thee: one and very Trinity, one and supreme Deity, holy and perfect Unity.
trin inv	Trinity Sunday Inv	The very God, one in Trinity, and Trinity in Unity: * O come, let us worship him.
trin blessed be	Trinity Sunday Ben	Blessed be the Creator * and Ruler of all things, the holy and undivided Trinity, both now and throughout all ages, world without end.
trin thee o god	Trinity Sunday Mag at EP2	Thee, O God, * the Father unbegotten; thee, the only-begotten Son; thee, O Holy Spirit, the Paraclete; holy and undivided Trinity, with our whole heart and voice we acknowledge thee, we praise thee, we bless thee; to thee be glory for ever and ever.
visit blessed art thou	The Visitation of the Blessed Virgin Mary Mag at EP1	Blessed art thou, * O Mary, who hast believed: for there shall be a performance of those things which were told thee from the Lord, alleluia.
visit inv	The Visitation of the Blessed Virgin Mary Inv	Let us celebrate the Visitation of the Virgin Mary: * Let us worship her Son, Christ the Lord.
visit when elizabeth	The Visitation of the Blessed Virgin Mary Ben	When Elizabeth * heard the salutation of Mary, she cried with a loud voice, saying: Whence is this to me, that the Mother of my Lord should come to me? Alleluia.
visit all generations	The Visitation of the Blessed Virgin Mary Mag at EP2	All generations * shall call me blessed, for God hath regarded the lowliness of his handmaiden, alleluia.
corpus o how sweet	The Most Holy Body and Blood of Christ Mag at EP1	O how sweet * O Lord is thy spirit, who, to show forth thy loving- kindness to thy children, feedest them with the most sweet Bread, which came from heaven; filling the hungry with good things, and sending the disdainful rich empty away.
corpus inv	The Most Holy Body and Blood of Christ Inv	Let us worship Christ the King, the Lord of all the world: * Who giveth health of spirit to them that feed on him.
corpus i am	The Most Holy Body and Blood of Christ Ben	I am the living Bread * which came down from heaven;
corpus o sacred	The Most Holy Body and Blood of Christ Mag at EP2	O sacred banquet, * in which Christ is received, the memory of his passion renewed, the mind filled with grace, and a pledge of future glory given unto us, alleluia.
t1 the child samuel	The First Sunday after Trinity Mag at EP1	The child Samuel * ministered before the Lord in the presence of Eli; and the word of the Lord was precious unto him.
inv sun trin	The First Sunday after Trinity Inv	Let us worship the Lord, * for he hath made us.
t1 father abraham	The First Sunday after Trinity Ben	Father Abraham, * have mercy upon me, and send Lazarus that he may dip the tip of his finger in water, and cool my tongue.
t1 son remember	The First Sunday after Trinity Mag at EP2	Son, remember * that thou in thy lifetime receivedst thy good things, and likewise Lazarus evil things.
doctor ephrem	Saint Ephrem Mag at EP2	O teacher most excellent, * O light of holy Church, O blessed Ephrem, lover of the divine law, intercede for us unto the Son of God.
sh i am come	The Most Sacred Heart of Jesus Mag at EP1	I am come * to send fire on the earth: and what will I if it be already kindled?
sh inv	The Most Sacred Heart of Jesus Inv	The Heart of Jesus was wounded for love of us: * O come, let us worship him.
sh now these things	The Most Sacred Heart of Jesus Ben	Now these things * were done, that the Scripture should be fulfilled which saith: They shall look on him whom they pierced.
sh but when they	The Most Sacred Heart of Jesus Mag at EP2	But when they came to Jesus, * and saw that he was dead already, they brake not his legs;. but one of the soldiers with a spear pierced his side, and forthwith came there out blood and water.
apostles for they shall	Common of Apostles Mag at EP1	For they shall deliver you up * to the councils, and they will scourge you in the synagogues, and ye shall be brought before governors and kings for my sake, for a testimony against them and the Gentiles.
apostles inv	Common of Apostles Inv	The Lord, the King of Apostles: * O come, let us worship him.
apostles ye which have	Common of Apostles Ben	Ye which have forsaken all * and followed me, shall receive an hundredfold, and shall inherit everlasting life.
apostles be ye valiant	Common of Apostles Mag at EP2	Be ye valiant * in the warfare, fight against the old serpent: and ye shall receive an everlasting kingdom.
t2 and all israel	The Second Sunday after Trinity Mag at EP1	And all Israel knew * from. Dan even to Beersheba, that Samuel was ordained a prophet of the Lord.
t2 a certain man	The Second Sunday after Trinity Ben	A certain man * made a great supper, and bade many; and he sent his servants at supper time to say to them that were bidden: Come, for all things are now ready, alleluia.
t2 go out quickly	The Second Sunday after Trinity Mag at EP2	Go out quickly into the streets and lanes of the city; and compel the poor and the maimed, the halt and the blind, to come in, that my house may be filled, alleluia.
doctor anthony	Saint Anthony of Padua Mag at EP2	O teacher most excellent, * O light of holy Church, O blessed Anthony of Padua, lover of the divine law, intercede for us unto the Son of God.
t3 ye mountains	The Third Sunday after Trinity Mag at EP1	Ye mountains of Gilboa, * let there be neither rain nor dew upon you; for there the shield of the mighty is vilely cast away, the shield of Saul, as though he had not been anointed with oil. How are the mighty fallen in the midst of the battle! Jonathan was slain upon your high places. Saul and Jonathan were lovely and pleasant in their lives, and in their death they were not divided.
t3 what man	The Third Sunday after Trinity Ben	What man among you * having an hundred sheep, if he lose one of them, doth not leave the ninety and nine in the wilderness, and go after that which is lost, until he find it? alleluia.
t3 what woman	The Third Sunday after Trinity Mag at EP2	What woman * having ten pieces of silver, if she lose one piece, doth not light a candle, and sweep the house, and seek diligently, until she find it?
njb when zacharias	The Nativity of Saint John the Baptist Mag at EP1	When Zacharias * went into the temple, there appeared unto him the Angel Gabriel, standing on the right side of the altar of incense.
njb inv	The Nativity of Saint John the Baptist Inv	The Lord, the King of the forerunner: * O come, let us worship him.
njb the mouth	The Nativity of Saint John the Baptist Ben	The mouth of Zacharias * was opened, and he prophesied, saying: Blessed be the God of Israel.
njb the child	The Nativity of Saint John the Baptist Mag at EP2	The Child * who is born unto us, is more than a Prophet; for this is he of whom the Saviour saith: Among all those born of women there hath not arisen a greater than John the Baptist.
t4 and david said	The Fourth Sunday after Trinity Mag at EP1	And David said * unto the Lord, when he saw the Angel that smote the people: It is I that have sinned, I that have done perversely; but these which are the sheep, what have they done?
t4 be ye therefore	The Fourth Sunday after Trinity Ben	Be ye therefore merciful, * even as your Father also is merciful, saith the Lord.
t4 judge not	The Fourth Sunday after Trinity Mag at EP2	Judge not, * that ye be not judged: for with what judgement ye judge, ye shall be judged, saith the Lord.
peter and paul today	Saints Peter and Paul Mag at EP2	Today * Simon Peter ascended the scaffold of the cross, alleluia; today the keybearer of heaven went with joy unto Christ, today the Apostle Paul, the light of the world, bowed his head, and for the name of Christ was crowned with martyrdom, alleluia.
t5 so david	The Fifth Sunday after Trinity Mag at EP1	So David prevailed * over the Philistine with a sling and a stone, in the name of the Lord.
t5 and jesus	The Fifth Sunday after Trinity Ben	And Jesus entered * into a ship and sat down and taught the people.
t5 master we have toiled	The Fifth Sunday after Trinity Mag at EP2	Master, * we have toiled all the night, and have taken nothing; nevertheless, at thy word I will let down the net.
elizabeth inv	Saint Elizabeth of Portugal Inv	Let us praise our God: * Whom blessed Elizabeth of Portugal confessed.
t6 i am distressed	The Sixth Sunday after Trinity Mag at EP1	I am distressed * for thee, my brother Jonathan; thy love to me was wonderful, passing the love of women; as a mother loveth her only son, so have I loved thee. The bow of Jonathan was never turned back, neither swerved his shield from the battle, and his spear returned not empty.
t6 ye have heard	The Sixth Sunday after Trinity Ben	Ye have heard * that it was said by them of old time: Thou shalt not kill, and whosoever shall kill shall be in danger of the judgement.
t6 if thou bring	The Sixth Sunday after Trinity Mag at EP2	If thou bring thy gift * to the altar, and rememberest that thy brother hath ought against thee, leave there thy gift before the altar, and go thy way; first be reconciled to thy brother, and then come and offer thy gift, alleluia.
doctor bonaventure	Saint Bonaventure Mag at EP2	O teacher most excellent, * O light of holy Church, O blessed Bonaventure, lover of the divine law, intercede for us unto the Son of God.
t7 but king david	The Seventh Sunday after Trinity Mag at EP1	But King David * covered his head, and mourned for Absalom, saying: O my son Absalom, O my son Absalom; would God I had died for thee, O my son Absalom!
t7 when a great	The Seventh Sunday after Trinity Ben	When a great multitude * was with Jesus, and had nothing to eat, he called his disciples and said unto them: I have compassion on the multitude, because they have now been with me three days; and have nothing to eat, alleluia.
t7 i have compassion	The Seventh Sunday after Trinity Mag at EP2	I have compassion * on the multitude, because they have now been with me three days, and have nothing to eat; and if I send them away fasting, they will faint by the way, alleluia.
doctor lawrence	Saint Lawrence of Brindisi Mag at EP2	O teacher most excellent, * O light of holy Church, O blessed Lawrence of Brindisi, lover of the divine law, intercede for us unto the Son of God.
mary mag a woman	Saint Mary Magdalene Mag at EP1	A woman * in the city, which was a sinner, when she knew that Jesus sat at meat in the house of Simon the leper, brought an alabaster box of ointment, and stood behind at the feet of Jesus, and began to wash his feet with tears, and did wipe them with the hairs of her head; and kissed his feet, and anointed them with the ointment.
mary mag inv	Saint Mary Magdalene Inv	For the conversion of Mary Magdalene: * Let us praise our God.
mary mag mary anointed	Saint Mary Magdalene Ben	Mary * anointed the feet of Jesus and wiped them with her hair, and the house was filled with the fragrance of the ointment.
mary mag a woman in the city	Saint Mary Magdalene Mag at EP2	A woman * in the city, which was a sinner, brought an alabaster box of ointment, and stood behind at the Lord's feet, and began to wash his feet with her tears, and wiped them with the hair of her head.
t8 wisdom crieth	The Eighth Sunday after Trinity Mag at EP1	Wisdom crieth aloud * in the broad places: Whosoever loveth wisdom, let him turn in hither, and he shall find her; and when he hath found her, happy is he if he hold her fast.
t8 beware ye	The Eighth Sunday after Trinity Ben	Beware ye * of false prophets, which come unto you in sheep's clothing, but inwardly they are ravening wolves; ye shall know them by their fruits.
t8 a good tree	The Eighth Sunday after Trinity Mag at EP2	A good tree * cannot bring forth evil fruit, neither can a corrupt tree bring forth good fruit. Every tree which bringeth not forth good fruit is hewn down, and cast into the fire, alleluia.
joachim and anne inv	Saints Joachim and Anne Inv	Christ the King, the Son of David: * O come, let us worship.
joachim and anne i am	Saints Joachim and Anne Ben	I am the God * of your fathers, saith the Lord; I have seen the affliction of my people, and have heard their cry, and I am come to deliver them, alleluia.
joachim and anne the glorious	Saints Joachim and Anne Mag at EP2	The glorious stock of Jesse * produced a beautiful branch, from which has sprung a flower of wondrous fragrance.
martha inv	Saints Martha, Mary and Lazarus Inv	The Lord, the King of all that confess him, O come, let us worship.
martha watch and pray	Saints Martha, Mary and Lazarus Ben	Watch and pray * for blessed is that servant whom his Lord when he cometh shall find so doing.
martha since the	Saints Martha, Mary and Lazarus Mag at EP2	Since the beginning of the world * men have not heard, nor perceived by the ear, neither hath the eye seen, any God beside thee, who worketh for them that wait upon him.
doctor peter	Saint Peter Chrysologus Mag at EP2	O teacher most excellent, * O light of holy Church, O blessed Peter Chrysologus, lover of the divine law, intercede for us unto the Son of God.
t9 i beseech	The Ninth Sunday after Trinity Mag at EP1	I beseech thee, O Lord, * remember now how I have walked before thee in truth and with a perfect heart, and have done that which is good in thy sight.
t9 the lord said	The Ninth Sunday after Trinity Ben	The lord  said unto his steward: * What is this I hear of thee? Give an account of thy stewardship, alleluia.
t9 what shall i do	The Ninth Sunday after Trinity Mag at EP2	What shall I do, * seeing my master taketh from-me the stewardship? I cannot dig, to beg I am ashamed; I am resolved what to do, that when I am removed from the stewardship, they may receive me into to their houses.
doctor alphonsus	Saint Alphonsus Liguori Mag at EP2	O teacher most excellent, * O light of holy Church, O blessed Alphonsus Liguori, lover of the divine law, intercede for us unto the Son of God.
transfiguration christ jesus	The Transfiguration of the Lord Mag at EP1	Christ Jesus, * the brightness of the Father and the express image of his substance, upholding all things by the word of his power, making atonement for our sins, today on the lofty mountain deigned to appear in his glory.
transfiguration inv	The Transfiguration of the Lord Inv	Christ, the most high King of glory: * O come, let us worship him.
transfiguration and behold	The Transfiguration of the Lord Ben	And behold, * a voice out of the cloud, which said: This is my beloved Son, in whom I am well pleased; hear ye him, alleluia.
transfiguration and when	The Transfiguration of the Lord Mag at EP2	And when the disciples * heard it, they fell on their faces, and were sore afraid; and Jesus came and touched them, and said: Arise, and be not afraid, alleluia.
smotc let us	Saint Mary of the Cross Mag at EP1	Let us honour Saint Mary of the Cross, * who, hearing the quiet voice of the Lord, ran courageously the race set before her.
smotc forsaking	Saint Mary of the Cross Ben	Forsaking worldly comforts, Saint Mary of the Cross, thy words and life remind us: * we are but travellers here.
smotc inspired	Saint Mary of the Cross Mag at EP2	Inspired by the holy Virgin, Saint Mary of the Cross, * let us listen in our hearts to the whisperings of our God.
t10 go not	The Tenth Sunday after Trinity Mag at EP1	Go not * after other gods to serve them.
t10 when jesus	The Tenth Sunday after Trinity Ben	When Jesus drew nigh  * to Jerusalem, he beheld the city and wept over it, saying: O that thou hadst known, even thou; for the days shall come unto thee, when they shall compass thee about, and shall keep thee in on every side, and I shall lay thee even with the ground; forasmuch as thou knewest not the time of thy visitation, alleluia.
t10 it is written	The Tenth Sunday after Trinity Mag at EP2	It is written: * My house shall be called the house of prayer for all nations; but ye have made it a den of thieves. And he was daily with them, teaching in the temple.
assump o virgin	The Assumption of the Blessed Virgin Mary Mag at EP1	O Virgin most prudent, * whither goest thou, shining forth like the dawn? O daughter of Sion, thou art all fair and gracious, fair as the moon, clear as the sun.
assump inv	The Assumption of the Blessed Virgin Mary Inv	O come, let us worship the King of kings: * Today hath he taken his Virgin Mother to heaven above.
assump who is she	The Assumption of the Blessed Virgin Mary Ben	Who is she * that ascendeth as the dawning of the morning, fair as the moon, clear as the sun, and terrible as an army of banners?
assump today	The Assumption of the Blessed Virgin Mary Mag at EP2	Today * Mary the Virgin is taken up into heaven: rejoice, for with Christ she reigneth for eyer.
t11 son of man	The Eleventh Sunday after Trinity Mag at EP1	Son of man, * I send thee to the children of Israel, to a rebellious nation.
t11 standing afar	The Eleventh Sunday after Trinity Ben	Standing afar off, * the publican would not so much as lift up his eyes unto heaven; but smote upon his breast, saying: God be merciful to me a sinner.
t11 this man	The Eleventh Sunday after Trinity Mag at EP2	This man went down * justified rather than the other; for every one that exalteth himself shall be abased, and he that humbleth himself shall be exalted.
doctor bernard	Saint Bernard Mag at EP2	O teacher most excellent, * O light of holy Church, O blessed Bernard, lover of the divine law, intercede for us unto the Son of God.
t12 i will shake	The Twelfth Sunday after Trinity Mag at EP1	I will shake the heavens * and the earth; and I will destroy the strength of the kingdom of the heathen.
t12 when the lord	The Twelfth Sunday after Trinity Ben	When the Lord had passed * through the coasts of Tyre, he made the deaf to hear, and the dumb to speak.
t12 he hath done	The Twelfth Sunday after Trinity Mag at EP2	He hath done all things well; * he maketh the deaf to hear, and the dumb to speak.
monica inv	Saint Monica Inv	Let us praise our God: * Whom blessed Monica  confessed.
doctor augustine	Saint Augustine of Hippo Mag at EP2	O teacher most excellent, * O light of holy Church, O blessed Augustine of Hippo, lover of the divine law, intercede for us unto the Son of God.
t13 i will stand	The Thirteenth Sunday after Trinity Mag at EP1	I will stand * upon my watch, and set me upon my tower.
t13 master what shall	The Thirteenth Sunday after Trinity Ben	Master, * what shall I do to inherit eternal life? He said unto him: What is written in the law? Thou shalt love the Lord thy God with all thy heart, alleluia.
t13 a certain man	The Thirteenth Sunday after Trinity Mag at EP2	A certain man * went down from Jerusalem to Jericho, and fell among thieves, which stripped him of his raiment, and wounded him, and departed, leaving him half dead.
bsjb herod sent	The Passion of Saint John the Baptist Ben	Herod sent an executioner * and commanded the head of John to be cut off in the prison. And when his disciples heard it, they came and took up his corpse, and laid it in a tomb.
bsjb the unbelieving king	The Passion of Saint John the Baptist Mag at EP2	The unbelieving king * sent his wicked servants, and commanded that the head of John the Baptist be cut off.
olsc the blessed	Our Lady of the Southern Cross Mag at EP1	The Blessed Virgin Mary, enthroned in heaven beyond the stars, ever rejoiceth in God her Saviour.
olsc our lady	Our Lady of the Southern Cross Ben	Our Lady of the Southern Cross, thou didst bring forth the dayspring from on high; * guide those, who sit in darkness and the shadow of death, unto the radiance of thy most blessed Son: Christ our God.
olsc we turn	Our Lady of the Southern Cross Mag at EP2	We turn to thee, Our Lady of the Southern Cross, whom Christ Crucified gave as Mother to all the faithful: * God hath regarded thy lowliness, and rightly we call thee blessed.
t14 blessed be	The Fourteenth Sunday after Trinity Mag at EP1	Blessed be the name * of God for ever; for wisdom and might are his.
t14 as jesus	The Fourteenth Sunday after Trinity Ben	As Jesus entered * into a certain village, there went out to meet him ten men that were lepers; and they stood afar off, and lifted up their voices, saying: Jesus, Master, have mercy upon us.
t14 but one	The Fourteenth Sunday after Trinity Mag at EP2	But one of them, * when he saw that he was cleansed, turned back, and with a loud voice glorified God, alleluia.
nativity bvm let us celebrate	The Nativity of the Blessed Virgin Mary Mag at EP1	Let us celebrate * the most honourable birth of the glorious Virgin Mary, who both attained to the dignity of motherhood, and suffered no loss of virgin modesty.
nativity bvm inv	The Nativity of the Blessed Virgin Mary Inv	Let us celebrate the Nativity of the Virgin Mary: Let us worship her Son, Christ the Lord.
nativity bvm today let us	The Nativity of the Blessed Virgin Mary Ben	Today let us devoutly celebrate * the Nativity of God's most holy Mother, the ever-Virgin Mary; from whom the Son of the Highest proceeded, alleluia.
nativity bvm thy nativity	The Nativity of the Blessed Virgin Mary Mag at EP2	Thy Nativity, * O Virgin Mother of God, hath proclaimed joyful tidings unto all the world; for from thee arose the Sun of righteousness, even Christ our God; who, taking away the curse, hath bestowed a blessing, and despoiling death, hath given unto us life everlasting.
t15 blessed be	The Fifteenth Sunday after Trinity Mag at EP1	Blessed be the God * of Shadrach, Meshach and Abednego, who hath delivered his servants that trusted in him.
t15 take no	The Fifteenth Sunday after Trinity Ben	Take no thought for your life, * saying: What shall we eat, or what shall we drink? for your Father knoweth that ye have need of all these things, alleluia.
t15 but seek ye	The Fifteenth Sunday after Trinity Mag at EP2	But seek ye first * the kingdom of God, and his righteousness, and all these things shall be added unto you, alleluia.
doctor john	Saint John Chrysostom Mag at EP2	O teacher most excellent, * O light of holy Church, O blessed John of Avila, lover of the divine law, intercede for us unto the Son of God.
holy cross o cross	The Exaltation of the Holy Cross Mag at EP1	O Cross, * surpassing all the stars in splendour, world-renowned, justly dear to all Christian people, holiest of earth's treasures; which only wast counted worthy to bear the price of our redemption; sweetest wood and sweetest iron, sweetest weight is hung on thee; bring aid to this congregation, who are assembled to celebrate thy praises.
holy cross inv	The Exaltation of the Holy Cross Inv	Christ crucified is our King: *O come, let us worship him.
holy cross above all	The Exaltation of the Holy Cross Ben	Above all cedar trees * art thou noble. O Tree on which the life of the world hung, on which Christ triumphed, and death for evermore.
holy cross he the holy	The Exaltation of the Holy Cross Mag at EP2	He the holy Cross endured, * who destroyed the power of hell; he with strength did gird himself, on the third day to rise again, alleluia.
ember sept this kind	Ember Wednesday in September Ben	This kind * of evil spirit can come forth by nothing but by prayer and fasting.
sorrows inv	Our Lady of Sorrows Inv	Let us stand by the Cross with Mary the Mother of Jesus: * through whose soul a sword of sorrow passed.
ember sept a woman	Ember Friday in September Ben	A woman * in the city, which was a sinner, stood at the Lord's feet behind him, and began to wash his feet with tears, and did wipe them with the hairs of her head, and kissed his feet, and anointed them with ointment.
doctor robert	Saint Robert Bellarmine Mag at EP2	O teacher most excellent, * O light of holy Church, O blessed Robert Bellarmine, lover of the divine law, intercede for us unto the Son of God.
doctor hildegard	Saint Hildegard of Bingen Mag at EP2	O teacher most excellent, * O light of holy Church, O blessed Hildegard of Bingen, lover of the divine law, intercede for us unto the Son of God.
ember sept give light	Ember Saturday in September Ben	Give light, O Lord * to them that sit in darkness, and in the shadow of death, and guide our feet into the way of peace.
t16 the sun shone	The Sixteenth Sunday after Trinity Mag at EP1	The sun shone * upon the shields of gold, and the mountains glistened therewith; and yet the forces of the heathen were discomfited.
t16 jesus entered	The Sixteenth Sunday after Trinity Ben	Jesus entered * into a city which was called Nain; and behold there was a dead man carried out, the only son of his mother.
t16 a great prophet	The Sixteenth Sunday after Trinity Mag at EP2	A great Prophet * is risen up among us, and God hath visited his people.
dedication the lord hath	Our Lady of Walsingham Mag at EP1	The Lord hath hallowed * his tabernacle: this is the house of the Lord, wherein men may call upon his Name; whereof it is written: My Name shall be there, saith the Lord.
walsingham inv	Our Lady of Walsingham Inv	The Saviour of the world was conceived by the Holy Ghost in the house of the Virgin: * O come, let us worship him.
walsingham behold the tabernacle	Our Lady of Walsingham Ben	Behold, * the tabernacle of God is with men, and he will dwell with them, and they shall be his people, and God himself shall be with them, and be their God.
dedication o how	Our Lady of Walsingham Mag at EP2	O how dreadful * is this place: truly this is none other than the house of God and the gate of heaven.
t17 now job	The Seventeenth Sunday after Trinity Mag at EP1	Now when Job had heard * the words of the messengers, he endured with patience, saying: Shall we receive good at the Lord's hand, and shall  we not' receive evil also? In all this Job sinned not with his lips, neither charged God in any thing foolishly.
t17 as jesus	The Seventeenth Sunday after Trinity Ben	As Jesus went * into the house of one of the chief Pharisees to eat bread on the sabbath day, behold there was a certain man before him which had the dropsy; and he took him, and healed him, and let him go.
t17 when thou	The Seventeenth Sunday after Trinity Mag at EP2	When thou art bidden * to a wedding, sit down in the lowest room; that he that bade thee may say: Friend, go up higher. Then shalt thou have worship in the presence of them that sit at meat with thee, alleluia.
archangels inv	Saints Michael, Gabriel, and Raphael Inv	The Lord, the King of Archangels: * O come, let us worship him.
doctor jerome	Saint Jerome Mag at EP2	O teacher most excellent, * O light of holy Church, O blessed Jerome, lover of the divine law, intercede for us unto the Son of God.
doctor therese	Saint Th√©r√®se of the Child Jesus Mag at EP2	O teacher most excellent, * O light of holy Church, O blessed Theresa of the Child Jesus, lover of the divine law, intercede for us unto the Son of God.
angels inv	Holy Guardian Angels Inv	The Lord, the King of Angels: * O come, let us worship him.
hga the angel	Holy Guardian Angels Ben	The Angel that talked with me * came again, and waked me, as a man that is awakened out of sleep.
hga holy angels	Holy Guardian Angels Mag at EP2	Holy Angels, * our guardians, defend us in the day of battle, that we perish not in the fearful judgement.
t18 dominion and fear	The Eighteenth Sunday after Trinity Mag at EP1	Dominion and fear * are with him: he maketh peace in his high places.
t18 master which	The Eighteenth Sunday after Trinity Ben	Master * which is the great commandment of the law? And Jesus answered: Thou shalt love the Lord thy God with all thy heart, alleluia.
t18 what think ye	The Eighteenth Sunday after Trinity Mag at EP2	What think ye * of Christ? whose son is he? They say unto him: The Son of David. Jesus saith unto them: How then doth David in spirit call him Lord, saying: The Lord said unto my Lord, Sit thou on my right hand?
jhn this man	Saint John Henry Newman Ben	This man * sought not personal gain but gazes on our Saviour and our Lord.
jhn every scribe	Saint John Henry Newman Mag at EP2	Every scribe * which is instructed unto the kingdom of heaven is like unto a man that is an householder, which bringeth forth out of his treasure things new and old.
t19 where shall	The Nineteenth Sunday after Trinity Mag at EP1	Where shall wisdom be found, * and where is the place of understanding?
t19 jesus said	The Nineteenth Sunday after Trinity Ben	Jesus said * to the sick of the palsy: Son, be of good cheer, thy sins are forgiven thee.
t19 the sick	The Nineteenth Sunday after Trinity Mag at EP2	The sick of the palsy therefore * took up his bed, on which he lay, and glorified God: and all the people, when they saw it, gave praise to God.
doctor teresa	Saint Teresa of Jesus Mag at EP2	O teacher most excellent, * O light of holy Church, O blessed Theresa of Jesus, lover of the divine law, intercede for us unto the Son of God.
t20 wisdom hath	The Twentieth Sunday after Trinity Mag at EP1	Wisdom hath builded her house, * she hath hewn out her seven pillars, she hath subdued the nations, and hath trodden on the necks of the haughty in the might of her own strength.
t20 tell them	The Twentieth Sunday after Trinity Ben	Tell them which are bidden: * Behold, I have prepared my dinner, come unto the marriage, alleluia.
t20 but when	The Twentieth Sunday after Trinity Mag at EP2	But when the king came in * to see the guests, he saw there a man not having a wedding garment, and said unto him: Friend, how earnest thou in hither, not having a wedding garment?
t21 in the way	The Twenty-first Sunday after Trinity Mag at EP1	In the way of righteousness * is life, and in the pathway thereof there is no death.
t21 there was	The Twenty-first Sunday after Trinity Ben	There was a certain nobleman * whose son was sick at Capernaum; who, when he heard that Jesus was come into Galilee, went and besought him that he would come down and heal his son.
t21 so the father	The Twenty-first Sunday after Trinity Mag at EP2	So the father knew * that it was at the selfsame hour in the which Jesus said: Thy son liveth; and himself believed, and his whole house.
t22 all wisdom	The Twenty-second Sunday after Trinity Mag at EP1	All wisdom * proceedeth from the Lord, and was with him for all time, and is before the ages.
t22 the lord said	The Twenty-second Sunday after Trinity Ben	The lord said unto his servant: * Pay that thou owest. But that servant fell down and besought him, saying: Have patience with me, and I will pay thee all.
t22 thou wicked	The Twenty-second Sunday after Trinity Mag at EP2	Thou wicked servant, * I forgave thee all that debt, because thou desiredst me: shouldest not thou also have had compassion on thy fellow servant, even as I had pity on thee? alleluia.
as angels archangels	All Saints Mag at EP1	Angels, * Archangels, Thrones and Dominations, Principalities and powers, heavenly Virtues, Cherubim and Seraphim, Patriarchs and Prophets, holy Doctors of the law, Apostles, all Martyrs of Christ,. holy Confessors, Virgins of the Lord, Hermits and all Saints, intercede for us.
as inv	All Saints Inv	The Lord, the King of kings, O come let us worship him: For he is the crown of all the Saints.
as the glorious	All Saints Ben	The glorious company * of the Apostles praise thee; the goodly fellowship of the Prophets praise thee; the noble army of Martyrs praise thee; and all the Saints and elect with one voice acknowledge thee, O blessed Trinity, one God.
as o how glorious	All Saints Mag at EP2	O how glorious is the kingdom, * wherein all the Saints rejoice with Christ, and clothed in white robes follow the Lamb whithersoever he goeth!
t23 arm yourselves	The Twenty-third Sunday after Trinity Mag at EP1	Arm yourselves * and be ye men of valour, and be in readiness for the conflict; for it is better for us to perish in battle than to look upon the evils of our nation and our altars. As the will of God is in heaven, even so let him do.
t23 master we know	The Twenty-third Sunday after Trinity Ben	Master, * we know that thou art true, and teachest the way of God in truth, alleluia.
t23 render therefore	The Twenty-third Sunday after Trinity Mag at EP2	Render therefore * unto Caesar the things which are Caesar's; and unto God the things that are God's, alleluia.
dedication inv	Common of Dedication of a Church Inv	Holiness becometh the house of God: * Therein let us worship Christ her Bridegroom.
dedication zacchaeus	Common of Dedication of a Church Ben	Zacchaeus, * make haste and come down, for today I must abide at thy house: and he made haste and came down, and received him joyfully into his house. This day is salvation come to this house from the Lord.
doctor leo	Saint Leo the Great Mag at EP2	O teacher most excellent, * O light of holy Church, O blessed Leo the Great, lover of the divine law, intercede for us unto the Son of God.
t24 i dwell	The Twenty-fourth Sunday after Trinity Mag at EP1	I dwell * in the height above, and my throne is set in a cloudy pillar.
t24 for she said	The Twenty-fourth Sunday after Trinity Ben	For she said * within herself: If I may but touch the hem of his garment, I shall be whole.
t24 but jesus	The Twenty-fourth Sunday after Trinity Mag at EP2	But Jesus turned him about, * and when he saw her, he said: Daughter, be of good comfort, thy faith hath made thee whole, alleluia.
doctor albert	Saint Albert the Great Mag at EP2	O teacher most excellent, * O light of holy Church, O blessed Theresa of Jesus, lover of the divine law, intercede for us unto the Son of God.
margaret of scotland inv	Saint Margaret of Scotland Inv	Let us praise our God: * Whom blessed Margaret of Scotland confessed.
elizabeth of hungary inv	Saint Elizabeth of Hungary Inv	Let us praise our God: * Whom blessed Elizabeth of Hungary confessed.
ctk the lord god	Our Lord Jesus Christ, King of the Universe Mag at EP1	The Lord God shall give * unto him the throne of his father David: and he shall reign over the house of Jacob for ever, and of his kingdom there shall be no end, alleluia.
ctk inv	Our Lord Jesus Christ, King of the Universe Inv	Jesus Christ, the King of kings: * O come, let us worship him.
ctk unto god	Our Lord Jesus Christ, King of the Universe Ben	Unto God and his Father * hath he made us to be a kingdom; who is the first-begotten of the dead, and the Prince of the kings of the earth, alleluia.
ctk he hath	Our Lord Jesus Christ, King of the Universe Mag at EP2	He hath on his vesture * and on his thigh a name is written: King of kings and Lord of lords; to him be glory and dominion for ever and ever.
sba when jesus	Monday before Advent Ben	When Jesus lift up his eyes, * and saw a great company come unto him, he said unto Philip: Whence shall we buy bread, that these may eat? And this he said to prove him, for he himself knew what he would do.
sba then those	Monday before Advent Mag at EP2	Then those men, * beholding the miracle that Jesus did, glorified God, and said: Of a truth, this is the Saviour of the world.
ad1 behold the name	The First Sunday of Advent Mag at EP1	Behold, the name of the Lord cometh from afar, and his glory filleth all the earth.
ad1 the lord the king	The First Sunday of Advent Inv	The Lord, the King that draweth nigh, * O come, let us worship him.
ad1 the holy ghost	The First Sunday of Advent Ben	The Holy Ghost shall come down upon thee, Mary: fear not, thou shalt conceive in thy womb the Son of God, alleluia.
ad1 fear not mary	The First Sunday of Advent Mag at EP2	Fear not, Mary, * thou hast found favour with the Lord; behold, thou shalt conceive and bear a Son, alleluia.
ad1 the lord the king ferias	Monday following Advent I Inv	The Lord, the King that draweth nigh, * O come, let us worship him.
ad1 the angel	Monday following Advent I Ben	The Angel of the Lord * brought the tidings to Mary, and she conceived by the Holy Ghost, alleluia.
ad1 lift up	Monday following Advent I Mag at EP2	Lift up thine eyes, * O Jerusalem, and see the King in his power; lo, thy Redeemer cometh to release thee from thy chain.
andrew on of the two	Saint Andrew Mag at EP1	One of the two * which followed the Lord was Andrew, Simon Peter's brother, alleluia.
ad1 before they	Tuesday following Advent I Ben	Before they came together, * Mary was found with child of the Holy Ghost.
ad1 see ye	Tuesday following Advent I Mag at EP2	Seek ye the Lord * while he may be found: call ye upon him while he is near, alleluia.
ad1 out of sion	Ember Wednesday in Advent Ben	Out of Sion * shall go forth the law: and the word of the Lord from Jerusalem.
ad1 there shall	Ember Wednesday in Advent Mag at EP2	There shall come * after me one that is mightier than I; the latchet of whose shoes I am not worthy to unloose.
ad1 blessed art	Thursday following Advent I Ben	Blessed art thou * among women, and blessed is the fruit of thy womb.
ad1 i will	Thursday following Advent I Mag at EP2	I will wait * upon the Lord my Saviour, and I will look for him when he is near, alleluia.
ad1 lo there	Ember Friday in Advent Ben	Lo, there cometh * one that is both God and man, of the house of David, to sit upon the throne, alleluia.
ad1 out of egypt	Ember Friday in Advent Mag at EP2	Out of Egypt * have I called my Son: he shall come to save his people.
ad1 sion be not	Ember Saturday in Advent Ben	Sion, be not dismayed, * for behold, thy God cometh, alleluia.
ad2 come o lord	The Second Sunday of Advent Mag at EP1	Come, O Lord, * and visit us in peace, that we may rejoice before thee with a perfect heart.
ad2 now when john	The Second Sunday of Advent Ben	Now when John * had heard in the prison the works of Christ, he sent two of his disciples, and asked him, saying: Art thou he that should come, or look we for another?
ad2 art thou he	The Second Sunday of Advent Mag at EP2	Art thou he that should come, * or look we for another? Tell John the things which ye have seen: the blind receive their sight, the dead are raised up, the poor have the gospel preached unto them, alleluia.
ad2 from heaven there	Monday following Advent II Ben	From heaven there cometh * the Lord the Ruler, and in his hand are honour and dominion.
ad2 behold the king	Monday following Advent II Mag at EP2	Behold the King cometh, * the Lord of the earth, and he shall take away the yoke of our captivity.
doctor ambrose	Saint Ambrose Mag at EP2	O teacher most excellent, * O light of holy Church, O blessed Ambrose, lover of the divine law, intercede for us unto the Son of God.
ad2 the lord shall	Tuesday following Advent II Ben	The Lord shall arise * upon thee, O Jerusalem, and his glory shall be seen upon thee.
ad2 the voice of	Tuesday following Advent II Mag at EP2	The voice of one crying * in the wilderness: Prepare ye the way of the Lord: make straight a highway for our God.
icbvm all generations	The Immaculate Conception of the Blessed Virgin Mary Mag at EP1	All generations shall call me blessed, for he that is mighty hath done to me great things, alleluia.
icbvm inv	The Immaculate Conception of the Blessed Virgin Mary Inv	Let us celebrate the Immaculate Conception of the Virgin Mary: * Let us worship her Son Christ our Lord.
icbvm today let us	The Immaculate Conception of the Blessed Virgin Mary Ben	Today let us devoutly celebrate * with due solemnity the Immaculate Conception of the most holy Mother of God, the ever-Virgin Mary; of whom the King of kings was born, alleluia.
icbvm let us celebrate	The Immaculate Conception of the Blessed Virgin Mary Mag at EP2	Let us celebrate * the worshipful and Immaculate Conception of the blessed and glorious Virgin Mary; whose lowliness the Lord regarded, when at the word of an Angel she conceived the world's Redeemer, alleluia.
ad2 behold i send	Wednesday following Advent II Ben	Behold, I send * my messenger, and he shall prepare thy way before thy face.
ad2 sion thou shalt	Wednesday following Advent II Mag at EP2	Sion, thou shalt be renewed, * and shalt see thy Righteousness, that is come unto thee.
ad2 thou o lord	Thursday following Advent II Ben	Thou, O Lord, * art he that should come, for whom we look, to save thy people.
ad2 he that cometh	Thursday following Advent II Mag at EP2	He that cometh after me * is preferred before me: the latchet: of whose shoes I am not worthy to unloose.
ad2 say to them	Friday following Advent II Ben	Say to them * that are of a fearful heart: Be strong, for behold, the Lord our God shall come.
ad2 o sing unto	Friday following Advent II Mag at EP2	O sing unto the Lord * a new song: and his praise to the ends of the earth.
ad3 before me	The Third Sunday of Advent Mag at EP1	Before me * there was no God formed, nor shall there be after me: for to me shall every knee be bowed, and me shall every tongue confess.
ad3 the lord is now nigh	The Third Sunday of Advent Inv	The Lord is now nigh at hand: * O come, let us worship him.
ad3 on the throne	The Third Sunday of Advent Ben	On the throne * of David, and over his kingdom, shall he reign for ever, alleluia.
ad3 blessed art thou	The Third Sunday of Advent Mag at EP2	Blessed art thou, Mary * who hast believed: there shall be performed in thee the things which were told thee from the Lord, alleluia.
ad3 there shall come	Monday following Advent III Ben	There shall come forth * a Rod from the stem of Jesse, and all the earth shall be filled with his glory: and all flesh shall see the salvation of God.
ad3 all generations	Monday following Advent III Mag at EP2	All generations * shall call me blessed, for God hath regarded his lowly handmaiden.
ad3 thou bethlehem	Tuesday following Advent III Ben	Thou, Bethlehem, * in and of Judah, shall not be the least; for out of thee shall come a Governor, that shall rule my people Israel.
ad3 awake awake arise	Tuesday following Advent III Mag at EP2	Awake, awake, * arise, O Jerusalem: loose the bands from off the neck, O captive daughter of Sion.
ad3 the angel gabriel	Wednesday following Advent III Ben	The Angel Gabriel * was sent to Mary, a Virgin espoused to Joseph.
ad3 behold the handmaid	Wednesday following Advent III Mag at EP2	Behold the handmaid of the Lord: * be it unto me according to thy word.
ad3 watch therefore	Thursday following Advent III Ben	Watch ye therefore * in your hearts: for the Lord our God is nigh at hand.
ad3 rejoice ye with	Thursday following Advent III Mag at EP2	Rejoice ye * with Jerusalem, and be glad with her, all ye that love her, for ever.
ad3 as soon as	Friday following Advent III Ben	As soon as the voice * of thy salutation sounded in mine ears, the babe leaped in my womb for joy, alleluia.
ad o wisdom	Friday following Advent III Mag at EP2	O Wisdom, * which camest out of the mouth of the Most High, and reachest from one end to another, mightily and sweetly ordering all things: come and teach us the way of prudence.
ad3 how shall this be	Saturday following Advent III Ben	How shall this be, * Angel of God, seeing I know not a man?  Hearken, O Virgin Mary: the Holy Ghost shall come upon thee, and the power of the Highest shall overshadow thee.
ad o adonai	Saturday following Advent III Mag at EP2	O Adonai, * and Leader of the house of Israel, who appearedst in the bush to Moses in a flame of fire, and gavest him the Law in Sinai: come and deliver us with an outstretched arm.
ad4 hail mary full	The Fourth Sunday of Advent Ben	Hail, Mary, * full of grace: the Lord is with thee, blessed art thou among women, alleluia.
ad o root of jesse	The Fourth Sunday of Advent Mag at EP2	O Root of Jesse, * which standest for an ensign of the peoples, at whom kings shall shut their mouths, to whom the Gentiles shall seek: come and deliver us, and tarry not.
ad4 thus says	Monday following Advent IV Ben	Thus saith the Lord: * Repent ye and turn again, because the kingdom of heaven is at hand, alleluia.
ad o key of david	Monday following Advent IV Mag at EP2	O Key of David, * and Sceptre of the house of Israel; that openest, and no man shutteth, and shuttest, and no man openeth: come and bring the prisoner out of the prison-house, and him that sitteth in darkness and the shadow of death.
ad be not afraid	Tuesday following Advent IV Ben	Be not afraid, * for on the fifth day our Lord will. come unto you.
ad o dayspring	Tuesday following Advent IV Mag at EP2	O Day-Spring, * Brightness of the Light everlasting and righteousness: come and enlighten him that sitteth in darkness and in the shadow of death.
ad4 i will place	Wednesday following Advent IV Ben	I will place salvation * in Sion, and my glory in Jerusalem, alleluia.
ad o king of the nations	Wednesday following Advent IV Mag at EP2	O King of the Nations, * and their Desire; the Corner-stone, who makest both one: come and save mankind, whom thou formedst of clay.
ad behold all things	Thursday following Advent IV Ben	Behold, all things are fulfilled * which were spoken by the Angel of the Virgin Mary.
ad o emmanuel	Thursday following Advent IV Mag at EP2	O Emmanuel, * our King and Lawgiver, the Desire of all nations and their Salvation: come and save us, O Lord our God.
ad the saviour of the world	Friday following Advent IV Ben	The Saviour of the world shall * arise as the sun: and shall come down into the Virgin's womb as the showers upon the grass.
nl or ever the sun	The Nativity of the Lord Mag at EP1	Or ever the sun * be risen in the heavens, ye shall see the King of kings proceeding from the Father, as a bridegroom out of his chamber.
nl glory to god	The Nativity of the Lord Ben	Glory to God in the highest, * and on earth peace, goodwill toward men, alleluia, alleluia.
nl today the christ is born	The Nativity of the Lord Mag at EP2	Today * the Christ is born: today hath a Saviour appeared: today on earth Angels are singing, Archangels rejoicing: today the righteous exult and say: Glory to God in the highest, alleluia.
hf christ the son of god	The Holy Family of Jesus, Mary and Joseph Inv	Christ the Son of God, who was made subject to Mary and Joseph: * O come, let us worship him.
hf enlighten us	The Holy Family of Jesus, Mary and Joseph Ben	Enlighten us, O Lord, * by the example of thy Family, and guide our feet into the way of peace.
hf but mary kept	The Holy Family of Jesus, Mary and Joseph Mag at EP2	But Mary * kept all these things, and pondered them in her heart.
stephen inv	Saint Stephen Inv	The newborn Christ, who on this day crowned blessed Stephen: * O come, let us worship him.
stephen but stephen	Saint Stephen Ben	But Stephen; * full of grace and power, did great wonders among the people.
stephen devout men	Saint Stephen Mag at EP2	Devout men carried Stephen * to his burial, and made great lamentation over him.
john this is that john	Saint John Ben	This is that John * which leaned on the Lord's bosom at supper; the blessed Apostle, unto whom were revealed the secrets of heaven.
john this saying	Saint John Mag at EP2	This saying * went out among the brethren, that that disciple should not die; yet Jesus said not: He shall not die; but: If I will that he tarry till I come, what is that to thee?
hi these are they	The Holy Innocents Ben	These are they * which were not defiled with women, for they are Virgins: and they follow the Lamb whithersoever he goeth.
hi many innocent	The Holy Innocents Mag at EP2	Many innocent children * were slain in Christ's behalf; by a ruthless tyrant were sucklings put to death; pure and unspotted, they follow the Lamb himself, and say without ceasing: Glory be to thee, O Lord.
doctor isidore	Saint Isidore Mag at EP2	O teacher most excellent, * O light of holy Church, O blessed Isidore, lover of the divine law, intercede for us unto the Son of God.
visit blessed art thou [easter]	The Visitation of the Blessed Virgin Mary Mag at EP1 with Alleluia	Blessed art thou, * O Mary, who hast believed: for there shall be a performance of those things which were told thee from the Lord, alleluia.
visit inv [easter]	The Visitation of the Blessed Virgin Mary Inv with Alleluia	Let us celebrate the Visitation of the Virgin Mary: * Let us worship her Son, Christ the Lord. Alleluia.
visit when elizabeth [easter]	The Visitation of the Blessed Virgin Mary Ben with Alleluia	When Elizabeth * heard the salutation of Mary, she cried with a loud voice, saying: Whence is this to me, that the Mother of my Lord should come to me? Alleluia.
visit all generations [easter]	The Visitation of the Blessed Virgin Mary Mag at EP2 with Alleluia	All generations * shall call me blessed, for God hath regarded the lowliness of his handmaiden, alleluia.
doctor ephrem [easter]	Saint Ephrem Mag at EP2 with Alleluia	O teacher most excellent, * O light of holy Church, O blessed Ephrem, lover of the divine law, intercede for us unto the Son of God. Alleluia.
doctor anthony [easter]	Saint Anthony of Padua Mag at EP2 with Alleluia	O teacher most excellent, * O light of holy Church, O blessed Anthony of Padua, lover of the divine law, intercede for us unto the Son of God. Alleluia.
olhoc behold mary	Our Lady, Help of Christians Mag at EP1	Behold, Mary * was our hope, to whom we fled for help, that she might deliver us, and she. came to our aid.
olhoc to thee we cried	Our Lady, Help of Christians Ben	To thee * we cried, O holy Mother of God, and through thee came the help of the Lord to us.
olhoc holy mary help	Our Lady, Help of Christians Mag at EP2	Holy Mary, * help those in misery, strengthen the fainthearted, comfort the sorrowful, pray for the people, intercede for clerics, plead for all women vowed to God; may all feel thy help, whosoever implore thine aid.
doctor bede	Saint Bede the Venerable Mag at EP2	O teacher most excellent, * O light of holy Church, O blessed Bede the Venerable, lover of the divine law, intercede for us unto the Son of God.
annunciation the holy ghost [easter]	The Annunciation of the Lord Mag at EP1 with Alleluia	The Holy Ghost * shall come upon thee, and the power of the highest shall overshadow thee. Alleluia.
annunciation how shall this be [easter]	The Annunciation of the Lord Ben with Alleluia	How shall this be, *O Angel of God, seeing I know not a man? Hearken, O Virgin Mary: the Holy Ghost shall come upon thee, and the power of the highest shall overshadow thee. Alleluia.
annunciation the angel gabriel [easter]	The Annunciation of the Lord Mag at EP2 with Alleluia	The Angel Gabriel * spake unto Mary, saying: Hail, full of grace; the Lord is with thee: blessed art thou among women. Alleluia.
ad4 awake awake	Tuesday following Advent IV Ben	Awake, awake * put on strength, O arm of the Lord.
ad4 comfort ye	Thursday following Advent IV Ben	Comfort ye, comfort ye, * my people, saith the Lord your God.
doctor catherine	Saint Catherine of Siena Mag at EP2	O teacher most excellent, * O light of holy Church, O blessed Catherine of Siena, lover of the divine law, intercede for us unto the Son of God.
doctor athanasius	Saint Athanasius Mag at EP2	O teacher most excellent, * O light of holy Church, O blessed Athanasius, lover of the divine law, intercede for us unto the Son of God.
st george the saints through faith	Saint George Mag at EP1	The Saints through faith * subdued kingdoms, wrought righteousness, obtained promises.
mmg for his great love	Mary, the Holy Mother of God Mag at EP1	For his great love * wherewith God loved us, he sent his Son in the likeness of sinful flesh, alleluia.
nl unto us the christ is born	Mary, the Holy Mother of God Inv	Unto us the Christ is born: * O come, let us worship.
mmg a great and wondrous	Mary, the Holy Mother of God Ben	A great and wondrous mystery * is made known to us this day: a new thing is wrought in nature; God is made man; that which he was he remaineth, and that which he was not he assumeth, suffering neither confusion nor yet division.
mmg heirs are we	Mary, the Holy Mother of God Mag at EP2	Heirs are we * of a great mystery: the womb of her that knew not man is become the temple of the Godhead: he, of a Virgin incarnate, suffereth no defilement: all the nations shall gather, saying: Glory be to thee, O Lord.
bishop o high priest	Common of Confessor: Bishop Mag at EP1	High priest and bishop, * mighty in virtues, good shepherd of the people, pray for us unto the Lord.
religious the lord the king	Common of Confessor: Bishop Inv	The Lord, the King of Confessors: * O come, let us worship him.
bishops well done good and faithful	Common of Confessor: Bishop Ben	Well done, good and faithful servant, * because thou hast been faithful over a few things, I will make thee ruler over many things, saith the Lord. Alleluia.
bishops the lord loved him	Common of Confessor: Bishop Mag at EP2	The Lord loved him * and adorned him: he clothed him with a robe of glory, and at the gates of paradise he crowned him. Alleluia.
annunciation inv	Saint Mary on Saturday Inv	Hail, Mary, full of grace; * The Lord is with thee.
bvm o blessed mother	Saint Mary on Saturday Ben	How shall this be, *O Angel of God, seeing I know not a man? Hearken, O Virgin Mary: the Holy Ghost shall come upon thee, and the power of the highest shall overshadow thee.
nl2 and we beheld	The Ninth Day of Christmas Ben	And we beheld his glory, * the glory as of the Only Begotten of the Father, full of grace and truth.
nl2 thine almighty word	The Ninth Day of Christmas Mag at EP2	Thine almighty Word, O Lord, * hath leaped down from heaven out of thy royal throne.
nl2 as many as	The Second Sunday of Christmas Mag at EP1	As many as received him, * to them gave he power to become the sons of God, even to them that believe on his Name.
hnj inv	The Most Holy Name of Jesus Inv	How excellent is the Name of Jesus, which is above every name: * O come, let us worship him.
hnj he gave himself	The Most Holy Name of Jesus Ben	He gave himself * that he might deliver his people, and obtain for himself an everlasting Name, alleluia.
hnj thou salt call	The Most Holy Name of Jesus Mag at EP2	Thou shalt call * his Name Jesus; for he shall save his people from their sins, alleluia.
ep the sages beholding	The Epiphany of the Lord Mag at EP1	The Sages, * beholding the star, said one to another: This is the sign of a mighty King; let us go forth and seek him, and let us offer him gifts, gold, incense, and myrrh.
the christ hath been	The Epiphany of the Lord Inv	The Christ hath been manifested unto us: * O come, let us worship him.
ep today to her heavenly	The Epiphany of the Lord Ben	Today * to her heavenly Bridegroom is the Church espoused, forasmuch as in Jordan Christ hath washed away her iniquities; Sages with their offerings hasten to the royal marriage; and with water turned to wine the guests are regaled, alleluia.
ep we celebrate a holy day	The Epiphany of the Lord Mag at EP2	We celebrate a holy day * adorned with three wonders: today the star led the Sages to the crib; today did water become wine at the marriage feast; today Christ willed to be baptised by John in Jordan, for our salvation, alleluia.
religious i will liken him	Common of Confessor: Priest Mag at EP1	I will liken him * unto a wise man, which built his house upon a rock.
religious well done good and faithful	Common of Confessor: Priest Ben	Well done, good and faithful servant, * because thou hast been faithful over a few things, I will make thee ruler over many things, saith the Lord.
religious this man the world	Common of Confessor: Priest Mag at EP2	This man, the world despising, * and earthly things renouncing, gaining the victory, laid up treasures in heaven by word and deed.
j7 from the east	7 January Ben	From the east * there came Sages to Bethlehem for to worship the Lord: and when they had opened their treasures, they presented unto him precious gifts: gold as to a mighty monarch, incense as to the true God, and myrrh to foreshow his burial, alleluia.
j7 seeing the star	7 January Mag at EP2	Seeing the star, the Sages * rejoiced with great rejoicing: and entering into the house, they presented to the Lord incense, gold and myrrh.
j8 there are the	8 January Ben	Three are the offerings * which the Sages brought unto the Lord: gold and myrrh and incense, to the Son of God, the mighty King, alleluia.
j8 light of light	8 January Mag at EP2	Light of light, * thou, O Christ, hast appeared, unto whom the Wise Men present their gifts, alleluia, alleluia, alleluia.
j9 we have seen	9 January Ben	We have seen his star * in the east, and are come with gifts to worship the Lord.
j9 herod inquired	9 January Mag at EP2	Herod inquired * of the Sages: What sign did ye see over the newborn King? We have seen a star gleaming, the brightness of which lighteneth the world.
botl the soldier	The Baptism of the Lord Mag at EP1	The soldier baptiseth the King, the servant his Master, the Baptist his Redeemer: * the water of Jordan marvelled, the Dove bare witness, the Father's voice was. heard, This is my beloved Son.
botl john the	The Baptism of the Lord Ben	John the Forerunner rejoiceth with the river Jordan; when the Lord was baptised, joy and gladness were come to the whole world: * through the hallowing of water there came to us remission of sins; let us all beseech him, Have mercy upon us.
botl the springs	The Baptism of the Lord Mag at EP2	The springs of water were hallowed when Christ appeared in majesty before the world: * draw ye water from the wells of salvation, for now is every creature sanctified by Christ our God.
j10 many nations	10 January Ben	Many nations * shall come from afar, bearing their gifts, alleluia.
j10 all they from	10 January Mag at EP2	All they from Saba * shall come, bearing gold and incense, alleluia, alleluia.
j11 they that	11 January Ben	They that despised thee * shall come unto thee, and shall bow themselves before thy feet.
j11 the sages	11 January Mag at EP2	The Sages, being warned * in dreams by an Angel, departed into their own country another way.
j12 great is	12 January Ben	Great is the mystery * of godliness: God manifest in the flesh, seen of Angels, preached unto the Gentiles, and believed on in the world.
j12 the sages	12 January Mag at EP2	The Sages, * beholding the star, said one to another: This is the sign of a mighty King; let us go forth and seek him, and let us offer him gifts, gold, incense and myrrh.
bvm a great and wondrous	Saint Mary on Saturday Ben	A great and wondrous mystery * is made known to us this day: a new thing is wrought in nature; God is made man: that which he was he remaineth, and that which he was not he assumeth, suffering neither confusion nor yet division.
ep sat ep1 god hath holpen	The Second Sunday after the Epiphany Mag at EP1	God hath holpen * his servant Israel: as he promised to Abraham, and his seed for ever.
inv sun ep	The Second Sunday after the Epiphany Inv	The Christ hath been manifested unto us: * O come, let us worship him.
ep2 there was a marriage	The Second Sunday after the Epiphany Ben	Now there was a marriage * in Cana of Galilee and Jesus himself was there, with Mary his Mother.
ep2 and when they	The Second Sunday after the Epiphany Mag at EP2	And when they wanted wine, * Jesus commanded them to fill the waterpots with water: and it was turned into wine, alleluia.
inv mon	Monday following Epiphany II Inv	O come, * let us sing unto the Lord.
inv tues	Tuesday following Epiphany II Inv	Let us heartily rejoice * in the strength of our salvation.
martyr inv	Common of Martyr: Pope or Bishop Inv	The Lord, the King of Martyrs: * O come, let us worship him.
martyr ben	Common of Martyr: Pope or Bishop Ben	He that hateth * his life in this world, shall keep it unto life eternal.
martyr ep2 mag	Common of Martyr: Pope or Bishop Mag at EP2	If any man will come after me, * saith the Lord, let him deny himself, and take up his cross, and follow me.
inv wed	Wednesday following Epiphany II Inv	The Lord is a great God: * O come, let us worship him.
virgins come thou bride	Common of Virgin Martyr Mag at EP1	Come, thou Bride of Christ, * the crown which the Lord hath prepared for thee for ever.
virgins the lord the king	Common of Virgin Martyr Inv	The Lord, the King of Virgins: * O come, let us worship him.
virgins the kingdom of	Common of Virgin Martyr Ben	The kingdom of heaven * is likened unto a merchant-man seeking goodly pearls: who, when he had found one pearl of great price, went and sold all that he had, and bought it. Alleluia.
inv thur	Thursday following Epiphany II Inv	The Christ hath been manifested unto us: * O come, let us worship him.
inv fri	Friday following Epiphany II Inv	The Christ hath been manifested unto us: * O come, let us worship him.
inv sat	Saturday following Epiphany II Inv	The Christ hath been manifested unto us: * O come, let us worship him.
ep3 and when jesus	The Third Sunday after the Epiphany Ben	And when Jesus was come down * from the mountain, behold ,there came a leper and worshipped him, saying: Lord, if thou wilt thou canst make me clean: and he put forth his hand and touched him, saying: I will, be thou clean.
ep3 lord if thou wilt	The Third Sunday after the Epiphany Mag at EP2	Lord, * if thou wilt, thou canst make me clean; and Jesus said: I will, be thou clean.
doctor francis de sales	Saint Francis de Sales Mag at EP2	O teacher most excellent, * O light of holy Church, O blessed Francis de Sales, lover of the divine law, intercede for us unto the Son of God.
saint paul go forth	The Conversion of Saint Paul the Apostle Mag at EP1	Go forth, Ananias, * and seek out Saul; for behold, he prayeth: for he is a chosen vessel unto me, to carry my name before the Gentiles and kings and the children of Israel.
saint paul inv	The Conversion of Saint Paul the Apostle Inv	For the conversion of the Teacher of the Gentiles : Let us praise our God.
saint paul ye which have	The Conversion of Saint Paul the Apostle Ben	Ye which have followed me * shall sit upon twelve thrones, judging the twelve tribes of Israel, saith the Lord.
saint paul o holy apostle	The Conversion of Saint Paul the Apostle Mag at EP2	Holy Apostle Paul, * preacher of the truth and Teacher ofthe Gentiles, intercede for us unto God who chose thee.
doctor thomas aquinas	Saint Thomas Aquinas Mag at EP2	O teacher most excellent, * O light of holy Church, O blessed Thomas Aquinas, lover of the divine law, intercede for us unto the Son of God.
septuagesima the lord said	The Third Sunday Before Lent Mag at EP1	The Lord said unto Adam: * Of the tree which is in the midst of the garden thou shalt not eat: in the day wherein thou eatest thereof, thou shalt surely die.
gesima let us come	The Third Sunday Before Lent Inv	Let us come before the presence of the Lord: * And show ourselves glad in him with Psalms.
septuagesima the kingdom of	The Third Sunday Before Lent Ben	The kingdom of heaven * is likened unto a man that is an householder, which went out early in the morning to hire labourers into his vineyard, saith the Lord.
septuagesima the householder said	The Third Sunday Before Lent Mag at EP2	The householder said * unto the labourers: Why stand ye here all the day idle? And they answering said: Because no man hath hired us. Go ye also into the vineyard; and whatsoever is right, that will I give you.
septuagesima these last	Monday following Septuagesima Mag at EP2	These last * have wrought but one hour, and thou hast made them equal unto us, which have borne the burden and heat of the day.
presentation the ancient	The Presentation of the Lord Mag at EP1	The elder * took up the Child in his arms; yet was the Child the elder's Lord: whom a Virgin bare, after childbirth remaining a Virgin; he was her Child, and him she worshipped.
presentation behold the lord	The Presentation of the Lord Inv	Behold, the Lord, the Ruler, cometh into his holy temple: * Rejoice and be glad, O Sion, and go forth to meet thy God.
presentation and when the parents	The Presentation of the Lord Ben	And when his parents * brought in the Child Jesus, then Simeon took him up in his arms, and blessed God, saying: Lord, now lettest thou thy servant depart in peace.
presentation today the blessed	The Presentation of the Lord Mag at EP2	Today * the blessed Virgin Mary presented the Child Jesus in the temple, and Simeon being full of the Holy Ghost took him up in his arms, and blessed God for ever.
septuagesima but the householder	Tuesday following Septuagesima Mag at EP2	But the householder said: * Friend, I do thee no wrong; didst thou not agree with me for a penny? Take that thine is, and go thy way.
septuagesima take that	Wednesday following Septuagesima Mag at EP2	Take that thine is, * and go thy way: for I am righteous, saith the Lord.
septuagesima is it not	Thursday following Septuagesima Mag at EP2	Is it not lawful * for me to do what I will? Is thine eye evil? For I am righteous, saith the Lord.
septuagesima so the last	Friday following Septuagesima Mag at EP2	So the last shall be first, * and the first last: for many are called, but few are chosen.
many martyrs for theirs is the kingdom	Common of Several Martyrs Mag at EP1	For there is the kingdom * of heaven, who despised the life of the world, and attained unto the rewards of the kingdom, and washed their robes in the blood of the Lamb.
many martyrs the very hairs	Common of Several Martyrs Ben	The hairs of your head * are all numbered: fear not, ye are of more value than many sparrows.
many martyrs in the heavenly	Common of Several Martyrs Mag at EP2	In the heavenly kingdom * the souls of the Saints are rejoicing, who followed the footsteps of Christ their Master: and since for love of him they freely poured forth their life-blood, therefore with Christ they reign for ever and ever.
sexagesima the lord spake unto noah	The Second Sunday Before Lent Mag at EP1	The Lord spake * unto Noah: The end of all flesh is come before me: make thee an ark of gopher wood: that the seed of all may be saved therein.
sexagesima when much people	The Second Sunday Before Lent Ben	When much people * were gathered together to Jesus, and were come to him out of every city, he spake by a parable: A sower went out to sow his seed.
sexagesima unto you it is given	The Second Sunday Before Lent Mag at EP2	Unto, you it is given * to know the mystery of the kingdom of God, but to others in parables, saith Jesus to his disciples.
sexagesima if ye seek	Monday following Sexagesima Mag at EP2	If ye seek * the summit of true honour, hasten to your heavenly country with what speed ye may.
sexagesima the seed	Tuesday following Sexagesima Mag at EP2	The seed is the word * of God, and Christ is the sower: every one that heareth him, abideth for ever.
sexagesima but that	Wednesday following Sexagesima Mag at EP2	But that which fell * on the good ground are they which in an honest and good heart receive the word, and bring forth fruit with patience.
bvm o holy mary [check for pointing]	Common of Blessed Virgin Mary Mag at EP1	Holy Mary, * succour the unhappy, help the faint-hearted, comfort the mourners, pray for the people, entreat for the clergy, intercede for the consecrated women: may all perceive thy help, who celebrate thy holy festival.
bvm inv	Common of Blessed Virgin Mary Inv	Holy Mary, Virgin Mother of God: * Intercede for us.
bvm blessed art thou	Common of Blessed Virgin Mary Ben	Blessed art thou, * O Mary, who hast believed; there shall be performed in thee the things which were told thee from the Lord.
bvm all generations	Common of Blessed Virgin Mary Mag at EP2	All generations * Shall call me blessed, for God hath regarded his lowly handmaiden.
sexagesima if then brethren	Thursday following Sexagesima Mag at EP2	If then, brethren, * ye would be truly rich, set your affection on the true riches.
sexagesima they which keep	Friday following Sexagesima Mag at EP2	They which keep the word of God * in an honest and good heart, bring forth fruit with patience.
quinquagesima mighty abraham	The Sunday Next Before Lent Mag at EP1	The great Abraham * is our father in the faith, who on the altar sacrificed a burnt-offering for his son.
quinquagesima behold we go up	The Sunday Next Before Lent Ben	Behold, we go up * to Jerusalem, and all things shall be accomplished which were written concerning the Son of man: for he shall be delivered unto the Gentiles, and shall be mocked and spitted upon: and after they have scourged him, they shall kill him; and the third day he shall rise again.
quinquagesima and jesus standing	The Sunday Next Before Lent Mag at EP2	And Jesus, standing, * commanded him to be brought unto him, and asked him, saying: What wilt thou that I should do unto thee? Lord, that I may receive my sight. And Jesus said unto him: Receive thy sight, thy faith hath saved thee. And straightway he received his sight, and followed, glorifying God.
quinquagesima and they which	Monday following Quinquagesima Mag at EP2	And they which went before * rebuked him, that he should hold his peace: but he cried so much the more: Have mercy on me, thou Son of David.
quinquagesima have mercy	Tuesday following Quinquagesima Mag at EP2	Have mercy upon me, * thou Son of David. What wilt thou that I should do unto thee? Lord, that I may receive my sight.
ash weds when ye fast	Ash Wednesday Ben	When ye fast, be not like the hypocrites, of a sad countenance.
ash weds lay up	Ash Wednesday Mag at EP2	Lay up for yourselves treasures in heaven, where neither moth nor rust doth corrupt.
ash weds lord my servant	Thursday following Ash Wednesday Ben	Lord, my servant * lieth at home, sick of the palsy, grievously tormented. Verily I say unto thee: I will come and heal him.
ash weds lord i am not worthy	Thursday following Ash Wednesday Mag at EP2	Lord, I am not worthy * that thou shouldest come under my roof: but speak the word only, and my servant shall be healed.
ash weds when thou doest thine alms	Friday following Ash Wednesday Ben	When thou doest thine alms, * let not thy left hand know what thy right hand doeth.
ash weds but thou when	Friday following Ash Wednesday Mag at EP2	But thou, when thou prayest, * enter into thy closet: and when thou hast shut thy door, pray to thy Father.
ash weds yet they seek	Saturday following Ash Wednesday Ben	From day to day * they seek me therefore, and would know my ways.
l1 then thou shalt	The First Sunday in Lent Mag at EP1	Then thou shalt call, * and the Lord shall hear thee: thou shalt cry out, and he shall say: Behold, here I am.
l1 inv	The First Sunday in Lent Inv	Think it not lost labour that ye haste to rise up early before the light: * For the Lord hath promised a crown unto them that watch.
l1 then was jesus led	The First Sunday in Lent Ben	Jesus was led up * of the Spirit into the wilderness, to be tempted of the devil: and when he had fasted forty days and forty nights, he was afterward an hungred.
l1 behold now is	The First Sunday in Lent Mag at EP2	Behold, now is the accepted time, * now is the day of salvation; let us therefore in these days approve ourselves, as the servants of God, in much patience, in watchings, in fastings, and by love unfeigned.
doctor peter damian	Saint Peter Damian Mag at EP2	O teacher most excellent, * O light of holy Church, O blessed Peter Damian, lover of the divine law, intercede for us unto the Son of God.
chair of st peter thou art	The Chair of Saint Peter Mag at EP1	Thou art shepherd * of the sheep, and prince of the Apostles; God hath given unto thee, O Peter, the keys of the kingdom of heaven.
chair of st peter inv	The Chair of Saint Peter Inv	Thou art the shepherd of the sheep, the prince of the Apostles; * To thee were given the keys of the kingdom of heaven.
chair of st peter whatsoever	The Chair of Saint Peter Ben	Whatsoever * thou shalt bind on earth shall be bound in heaven; and whatsoever thou shalt loose on earth shall be loosed in heaven: saith the Lord to Simon Peter.
chair of st peter being a high priest	The Chair of Saint Peter Mag at EP2	Being a high Priest, * he feared not the things of earth, but attained in glory unto the heavenly realms.
l1 come ye blessed	Monday following Lent I Ben	O come, ye blessed * children of my Father: inherit the kingdom prepared for you from the foundation of the world.
l1 inasmuch	Monday following Lent I Mag at EP2	Inasmuch * as ye have done it unto the least of these, ye have done it unto me, saith the Lord.
l1 and jesus went into	Tuesday following Lent I Ben	. And Jesus went * into the temple of God, and began to cast out all them that sold and bought in the temple: and overthrew the tables of the moneychangers, and the seats of them that sold doves.
l1 truly it is written	Tuesday following Lent I Mag at EP2	For it is written, * that my house is a house a prayer for all nations: but ye have made it a den of thieves: and he was teaching daily in the temple.
l1 this crooked and perverse	Ember Wednesday in Lent Ben	This generation * being perverse and crooked, seeketh a sign from me: and no sign shall be given to it, but the sign of Jonah the prophet.
l1 for as jonah	Ember Wednesday in Lent Mag at EP2	For as Jonah *was three days and three nights in the whale's belly, so shall the Son of man be in the heart of the earth.
l1 and jesus went thence	Thursday following Lent I Ben	And Jesus went thence * and departed into the coasts of Tyre and Sidon: and behold, a woman of Canaan came out of the same coasts, and cried unto him, saying: Have mercy on me, thou Son of David.
l1 o woman great is your faith	Thursday following Lent I Mag at EP2	O woman, * great is thy faith: be it unto thee, even as thou wilt.
l1 an angel of the lord	Ember Friday in Lent Ben	An Angel of the Lord * descended from heaven, and troubled the water: and one was made whole.
l1 he that made me	Ember Friday in Lent Mag at EP2	He that made me whole, * the same said unto me: Take up thy bed, and go in peace.
l1 and jesus taketh	Ember Saturday in Lent Ben	And Jesus taketh * his disciples, and goeth up into a mountain, and was transfigured before them.
doctor gregory	Saint Gregory of Narek Mag at EP2	O teacher most excellent, * O light of holy Church, O blessed Gregory of Narek, lover of the divine law, intercede for us unto the Son of God.
l2 tell the vision	The Second Sunday in Lent Mag at EP1	Tell the vision which ye have seen * to no man, until the Son of man be risen from the dead.
l2 and jesus went thence	The Second Sunday in Lent Ben	And Jesus went thence * and departed into the coasts of Tyre and Sidon: and behold, a woman of Canaan came out of the same coasts and cried unto him, saying: Have mercy on me, thou Son of David.
l2 o woman great is	The Second Sunday in Lent Mag at EP2	O woman, * great is thy faith: be it unto thee, even as thou wilt.
l2 i am the beginning	Monday following Lent II Ben	I am the Beginning: * even I that speak unto you.
l2 he that sent me	Monday following Lent II Mag at EP2	He that sent me * is with me, and he hath not left me alone: for I do always those things that please him.
l2 one is your master	Tuesday following Lent II Ben	One is your Master, * which is in heaven, even Christ the Lord.
l2 all ye are brethren	Tuesday following Lent II Mag at EP2	All ye are brethren; * and call no man your father upon earth; for one is your Father, which is in heaven: neither be ye called masters, for one is your Master, even Christ.
l2 behold we go up	Wednesday following Lent II Ben	Behold we go up * to Jerusalem; and the Son of man shall be betrayed to be crucified.
l2 he shall be delivered	Wednesday following Lent II Mag at EP2	He shall be delivered * to the Gentiles, to mock, and to scourge, and to crucify him.
inv any holy man	Common of Any Holy Man Inv	The Lord, the King of kings, O come let us worship him: * For he is the crown of all the saints.
l2 son remember	Thursday following Lent II Ben	Son, remember * that thou in thy lifetime receivedst thy good things, and likewise Lazarus evil things.
l2 the same rich man	Thursday following Lent II Mag at EP2	The same rich man * begged a drop of water, who had denied crumbs of bread to Lazarus.
l2 he will miserably	Friday following Lent II Ben	He will miserably destroy * those miserable men; and will let out his vineyard unto other husbandmen, which shall render him the fruits in their seasons.
l2 when they sought to lay	Friday following Lent II Mag at EP2	When they sought to take him, * they feared the people; because they counted him as a prophet.
l2 i will go unto my father	Saturday following Lent II Ben	I will go unto my father, and will say unto him: Father, make me as one of thy hired servants.
l3 but the father	The Third Sunday in Lent Mag at EP1	But the father said * unto his servants: Bring forth quickly the best robe and put it on him, and put a ring on his finger, and shoes on his feet.
l3 when a strong man	The Third Sunday in Lent Ben	When a strong man armed * keepeth his palace, his goods are in peace.
l3 a certain woman	The Third Sunday in Lent Mag at EP2	A certain woman * of the company lifted up her voice, and cried: Blessed is the womb that bare thee, and the paps which thou hast sucked. But Jesus answered: Yea rather, blessed are they that hear the word of God, and keep it.
l3 verily i say unto you	Monday following Lent III Ben	Verily, I say unto you: * that no prophet is accepted in his own country.
l3 but jesus	Monday following Lent III Mag at EP2	But Jesus, passing * through the midst of them, went his way.
frances of rome inv	Saint Frances of Rome Inv	Let us praise our God: * Whom blessed Frances of Rome confessed.
holy woman give her	Common of Any Matron or Holy Woman Ben	Give her * of the fruit of her hands, and let her own works praise her in the gates.
matron she stretcheth	Common of Any Matron or Holy Woman Mag at EP2	She stretched out her hand * to the poor, yea, she reacheth forth her hands to the needy, and eateth not the bread of idleness.
l3 if two of you	Tuesday following Lent III Ben	If two of you shall agree * on earth as touching aught for which they shall make request, it shall be done for them of my Father which is in heaven, saith the Lord.
l3 where two or three	Tuesday following Lent III Mag at EP2	Where two or three * are gathered together in my name, there am I in the midst of them, saith the Lord.
l3 hearken	Wednesday following Lent III Ben	Hearken and understand ye: * the traditions which the Lord hath given you.
l3 to eat with	Wednesday following Lent III Mag at EP2	To eat with unwashen hands * defileth not a man.
l3 and devils also	Thursday following Lent III Ben	And devils also came out, of many, * crying out and saying: Thou art Christ, the Son of God: and he rebuking them suffered them not to speak, for they knew that he was Christ.
l3 whosoever had any	Thursday following Lent III Mag at EP2	All they that had any sick * brought them to Jesus, and they were healed.
l3 whosoever drinketh	Friday following Lent III Ben	If any man drink * of the water that I shall give, he shall never thirst.
l3 sir i perceive	Friday following Lent III Mag at EP2	Sir, I perceive * that thou art a prophet: our fathers worshipped in this mountain.
l3 and jesus stooped down	Saturday following Lent III Ben	And Jesus stooped down, * and wrote upon the ground: he that is without sin, let him cast a stone at her.
l4 woman hath no	The Fourth Sunday in Lent Mag at EP1	Woman, hath no man condemned thee? * No man, Lord. Neither do I condemn thee: go and sin no more.
l4 when jesus lift up	The Fourth Sunday in Lent Ben	When Jesus lift up his eyes, * and saw a great company come unto him, he said unto Philip: Whence shall we buy bread, that these may eat? And this he said to prove him, for he himself knew what he would do.
l4 jesus went up	The Fourth Sunday in Lent Mag at EP2	Jesus went up * into a mountain, and there he sat with his disciples.
l4 take these things hence	Monday following Lent IV Ben	Take these things hence, * saith the Lord: and make not my Father's house a house of merchandise.
l4 destroy this temple	Monday following Lent IV Mag at EP2	Destroy this temple * saith the Lord: and in three days I will raise it up; but this he said of the temple of his body.
l4 wherefore do ye seek	Tuesday following Lent IV Ben	Wherefore do ye seek * to kill me, a man that hath told you the truth?
l4 no man laid hands	Tuesday following Lent IV Mag at EP2	No man laid hands on him, * because his hour was not yet come.
l4 master wherein hath	Wednesday following Lent IV Ben	Master, who hath sinned, * this man or his parents, that he was born blind? And Jesus answered, saying: Neither hath this man sinned, nor his parents: but that the works of God should be manifested in him.
l4 a man that is called	Wednesday following Lent IV Mag at EP2	A man that is called Jesus * made clay out of spittle, and anointed mine eyes, and now I see.
doctor cyril	Saint Cyril of Jerusalem Mag at EP2	O teacher most excellent, * O light of holy Church, O blessed Cyril of Jerusalem, lover of the divine law, intercede for us unto the Son of God.
l4 jesus went into	Thursday following Lent IV Ben	Jesus went * into a city called Nain: and behold, there was a dead man carried out, the only son of his mother.
l4 a great prophet	Thursday following Lent IV Mag at EP2	A great prophet * is risen up amongst us, and God hath visited his people.
joseph then joseph being raised	Saint Joseph Mag at EP1	Then Joseph * being raised from sleep, did as the Angel of the Lord had bidden him, and took unto him his wife.
joseph inv	Saint Joseph Inv	Christ, the Son of God, deigned to be thought the son of Joseph: * O come, let us worship him.
joseph jesus himself	Saint Joseph Ben	Jesus himself * began to be about thirty years of age, being, as was supposed, the son of Joseph.
joseph behold a faithful	Saint Joseph Mag at EP2	Behold a faithful and wise steward, * whom his Lord hath made ruler over his household.
l4 our friend lazarus	Friday following Lent IV Ben	Our friend Lazarus * sleepeth: let us go, that we may awake him out of sleep.
l4 lord if thou hadst	Friday following Lent IV Mag at EP2	Lord, if thou hadst been here, * Lazarus would not have died: lo, by this time he stinketh, being in the sepulchre four days already.
l4 he that followeth me	Saturday following Lent IV Ben	He that followeth me * shall not walk in darkness: but shall have the light of life, saith the Lord.
l5 i am one	The Fifth Sunday in Lent Mag at EP1	I am one, * that bear witness of myself, and the Father that sent me beareth witness of me.
passiontide inv	The Fifth Sunday in Lent Inv	Today, if ye will hear the voice of the Lord, * Harden not your hearts.
l5 jesus said unto the	The Fifth Sunday in Lent Ben	Jesus said * unto the multitudes of the Jews, and to the chief priests: He that is of God, heareth my words; ye therefore hear them not, because ye are not of God.
l5 your father abraham	The Fifth Sunday in Lent Mag at EP2	Your father Abraham * rejoiced to see my day: he saw it, and was glad.
l5 in the last day of the feast	Monday following Passion Sunday Ben	In the last day * of the feast, Jesus stood and cried, saying: If any man thirst let him come unto me and drink.
l5 if any man thirst	Monday following Passion Sunday Mag at EP2	If any man thirst, * let him come and drink: and out of his belly shall flow rivers of living water, saith the Lord.
l5 my time is not yet come	Tuesday following Passion Sunday Ben	My time * is not yet come: but your time is always ready.
l5 go ye up	Tuesday following Passion Sunday Mag at EP2	Go ye up * unto this feast: I go not up yet, for my time is not yet fully come.
l5 my sheep hear	Wednesday following Passion Sunday Ben	My sheep * hear my voice: and I the Lord do know them.
l5 many good works	Wednesday following Passion Sunday Mag at EP2	Many good works * have I wrought among you: for which of those works do ye seek to kill me?
annunciation the holy ghost	The Annunciation of the Lord Mag at EP1	The Holy Ghost * shall come upon thee, and the power of the highest shall overshadow thee.
annunciation how shall this be	The Annunciation of the Lord Ben	How shall this be, *O Angel of God, seeing I know not a man? Hearken, O Virgin Mary: the Holy Ghost shall come upon thee, and the power of the highest shall overshadow thee.
annunciation the angel gabriel	The Annunciation of the Lord Mag at EP2	The Angel Gabriel * spake unto Mary, saying: Hail, full of grace; the Lord is with thee: blessed art thou among women.
l5 the master saith	Thursday following Passion Sunday Ben	The Master saith: * My time is at hand: I will keep the Passover at thy house with my disciples.
l5 with desire have i	Thursday following Passion Sunday Mag at EP2	With desire have I desired * to eat this Passover with you, before I suffer.
sorrows come ye	Saint Mary in Passiontide Ben	O come ye, * and let us go up to the hill of the Lord; and behold ye if there be any sorrow like unto my sorrow.
sorrows sorrow hath weighed	Saint Mary in Passiontide Mag at EP2	Sorrow hath weighted me down, * and my face is swollen with weeping, and on mine eyelids is the shadow of death.
l5 now the feast day	Friday following Passion Sunday Ben	Now the feast day * was nigh at hand, and the chief priests and elders sought for occasion to slay Jesus: but they feared the multitude.
l5 the chief priests	Friday following Passion Sunday Mag at EP2	The chief priests and elders * took counsel together how they might destroy him: but they spake and said: Not on the feast day, lest there be an uproar among the people.
l5 o father glorify	Saturday following Passion Sunday Ben	Glorify me, O Father, * with thine own self, with the glory which I had before the world was.
hw righteous father	Palm Sunday Mag at EP1	Righteous Father, * the world hath not known thee: but I have known that thou didst send me.
hw much people	Palm Sunday Ben	Much people, * which was come together on' the feast day, cried out and said: Blessed is he that cometh in the name of the Lord: Hosanna in the highest.
hw for it is written	Palm Sunday Mag at EP2	For it is written: * I will smite the shepherd, and the sheep of the flock shall be scattered: but after I am risen, I will go before you into Galilee: there shall ye see me, saith the Lord.
hw o father glorify	Monday in Holy Week Ben	Glorify me, O Father, * with thine own self, with the glory which I had before the world was.
hw thou couldest have	Monday in Holy Week Mag at EP2	Thou couldest have no power * at all against me, except it were given thee from above.
hw before the day	Tuesday in Holy Week Ben	Before the day of the feast * of the Passover, Jesus, knowing that his hour was come, when he loved his own, he loved them unto the end.
hw i have power	Tuesday in Holy Week Mag at EP2	I have power * to lay down my life, and I have power to take it up again.
hw simon sleepest thou	Wednesday in Holy Week Ben	Simon, sleepest thou? * Couldest thou not watch with me one hour?
hw a maid said	Wednesday in Holy Week Mag at EP2	A maid said * unto Peter: Verily thou art one of them; and thy speech betrayeth thee.
hw now he that betrayed	Thursday in Holy Week Ben	Now he that betrayed him * gave them a sign, saying: Whomsoever I shall kiss, that same is he; hold him fast.
hw and as they were eating	Thursday in Holy Week Mag at EP2	And as they were eating, * Jesus took bread, and blessed it, and brake it, and gave it to his disciples.
hw they set up	Good Friday Ben	And they set up * over his head his accusation written: Jesus of Nazareth, the King of the Jews.
hw when he had received	Good Friday Mag at EP2	When he had received the vinegar, * he said: It is finished; and he bowed his head, and gave up the ghost.
hw the women also sitting	Holy Saturday Ben	The women also, * sitting at the sepulchre, made lamentation, weeping for the Lord.
hw the chief priests	Holy Saturday Mag at EP2	The chief priests * and Pharisees guarded the sepulchre, sealing the stone, and setting a watch.
e1 and very early [easter]	Easter Day Ben with Alleluia	And very early in the morning, * the first day of the week, they came to the sepulchre at the rising of the sun, alleluia.
e1 and when they looked [easter]	Easter Day Mag at EP2 with Alleluia	And when they looked, * they saw that the stone was rolled away; for it was very great, alleluia.
doctor isidore [easter]	Saint Isidore Mag at EP2 with Alleluia	O teacher most excellent, * O light of holy Church, O blessed Isidore, lover of the divine law, intercede for us unto the Son of God. Alleluia.
religious i will liken him [easter]	Common of Doctor of the Church Mag at EP1 with Alleluia	I will liken him * unto a wise man, which built his house upon a rock. Alleluia.
religious the lord the king [easter]	Common of Doctor of the Church Inv with Alleluia	The Lord, the King of Confessors: * O come, let us worship him. Alleluia.
religious well done good and faithful [easter]	Common of Doctor of the Church Ben with Alleluia	Well done, good and faithful servant, * because thou hast been faithful over a few things, I will make thee ruler over many things, saith the Lord. Alleluia.
religious this man the world [easter]	Common of Doctor of the Church Mag at EP2 with Alleluia	This man, the world despising, * and earthly things renouncing, gaining the victory, laid up treasures in heaven by word and deed. Alleluia.
bishop o high priest [easter]	Common of Confessor: Bishop Mag at EP1 with Alleluia	High priest and bishop, * mighty in virtues, good shepherd of the people, pray for us unto the Lord. Alleluia.
bishops well done good and faithful [easter]	Common of Confessor: Bishop Ben with Alleluia	Well done, good and faithful servant, * because thou hast been faithful over a few things, I will make thee ruler over many things, saith the Lord. Alleluia.
bishops the lord loved him [easter]	Common of Confessor: Bishop Mag at EP2 with Alleluia	The Lord loved him * and adorned him: he clothed him with a robe of glory, and at the gates of paradise he crowned him. Alleluia.
e1 jesus joined himself [easter]	Monday in the Octave of Easter Ben with Alleluia	Jesus joined himself * to his disciples in the way, and went with them; but their eyes were holden that they knew him not; and he rebuked them, saying: O fools and slow of heart to believe all that the Prophets have spoken, alleluia.
e1 what manner [easter]	Monday in the Octave of Easter Mag at EP2 with Alleluia	What manner of communications * are these that ye have one to another, as ye walk, and are sad? alleluia.
e1 jesus stood in [easter]	Tuesday in the Octave of Easter Ben with Alleluia	Jesus stood * in the midst of his disciples, and said unto them: Peace be unto you, alleluia, alleluia.
e1 behold my hands [easter]	Tuesday in the Octave of Easter Mag at EP2 with Alleluia	Behold my hands * and my feet, that it is I myself, alleluia, alleluia.
e1 cast the net [easter]	Wednesday in the Octave of Easter Ben with Alleluia	Cast the net * on the right side of the ship, and ye shall find, alleluia.
e1 jesus said to [easter]	Wednesday in the Octave of Easter Mag at EP2 with Alleluia	Jesus said * to his disciples: Take of the fish which ye have now caught. But Simon Peter went up, and drew the net to land, full of great fishes, alleluia.
e1 many stood [easter]	Thursday in the Octave of Easter Ben with Alleluia	Mary stood * at the sepulchre weeping, and seeth two Angels in white sitting, and the napkin that was about the head of Jesus, alleluia.
e1 they have taken [easter]	Thursday in the Octave of Easter Mag at EP2 with Alleluia	They have taken away my Lord * and I know not where they have laid him; if thou have borne him hence, tell me, alleluia, and I will take him away, alleluia.
e1 the eleven [easter]	Friday in the Octave of Easter Ben with Alleluia	The eleven disciples * when they saw the Lord in Galilee, worshipped him, alleluia.
e1 all power [easter]	Friday in the Octave of Easter Mag at EP2 with Alleluia	All power is given * unto me in heaven and in earth, alleluia.
e1 they ran both [easter]	Saturday in the Octave of Easter Ben with Alleluia	They ran both together, * and the other disciple did outrun Peter, and came first to the sepulchre, alleluia.
annunciation inv [easter]	Saint Mary on Saturday Inv with Alleluia	Hail, Mary, full of grace; * The Lord is with thee. Alleluia.
bvm joy to thee [easter]	Saint Mary on Saturday Ben with Alleluia	Joy to thee, * O Queen of heaven, alleluia; he whom thou wast meet to bear, alleluia, as he promised hath arisen, alleluia: pour for us to God thy prayer, alleluia.
e2 that same evening [easter]	The Second Sunday of Easter Mag at EP1 with Alleluia	The same day at evening, * being the first day of the week, when the doors were shut where the disciples were assembled, Jesus stood in the midst of them, and said unto them: Peace be unto you, alleluia.
easter inv [easter]	The Second Sunday of Easter Inv with Alleluia	The Lord is risen indeed, * alleluia.
e2 after eight days [easter]	The Second Sunday of Easter Mag at EP2 with Alleluia	After eight days, * when the doors were shut, the Lord entered, and said unto them: Peace be unto you; alleluia, alleluia.
martyr inv [easter]	Common of Martyr: Pope or Bishop Inv with Alleluia	Let the Saints be joyful in the Lord: * Alleluia.
martyr ben [easter]	Common of Martyr: Pope or Bishop Ben with Alleluia	Daughters of Jerusalem, * come forth, and behold the Martyrs with the diadems wherewith the Lord hath crowned them in the day of solemnity and rejoicing, alleluia, alleluia.
martyr ep2 mag [easter]	Common of Martyr: Pope or Bishop Mag at EP2 with Alleluia	O ye Saints and righteous, * rejoice in the Lord, alleluia: God hath chosen you for his own inheritance, alleluia.
e2 when jesus was risen [easter]	Monday following Easter II Ben with Alleluia	When Jesus was risen * early on the first day of the week, he appeared first to Mary Magdalene, out of whom he had cast seven devils, alleluia.
e2 peace be unto you [easter]	Monday following Easter II Mag at EP2 with Alleluia	Peace be unto you * it is I: be not afraid, alleluia.
e2 i go before you [easter]	Tuesday following Easter II Ben with Alleluia	I go before you * into Galilee: there shall ye see me, as I said unto you, alleluia, alleluia.
e2 reach hither [easter]	Tuesday following Easter II Mag at EP2 with Alleluia	Reach hither thy hand * and thrust it into my side, alleluia: and be not faithless but believing, alleluia.
e2 i am the true vine [easter]	Wednesday following Easter II Ben with Alleluia	I am the true vine, * and ye are the branches, alleluia.
e2 because thou hast [easter]	Wednesday following Easter II Mag at EP2 with Alleluia	Because thou hast seen me, * Thomas, thou hast believed: blessed are they that have not seen, and yet have believed, alleluia.
e2 my heart burns [easter]	Thursday following Easter II Ben with Alleluia	My heart burneth, *I long to behold my Lord: I seek him, and I find him not where they laid him, alleluia, alleluia.
e2 i did put [easter]	Thursday following Easter II Mag at EP2 with Alleluia	I put my finger * into the prints of the nails, and my hands into his side; and I said: My Lord and my God, alleluia.
e2 there came unto the tomb [easter]	Friday following Easter II Ben with Alleluia	There came unto the tomb * Mary Magdalene and the other Mary, to see the sepulchre, alleluia.
e2 after ... when the doors [easter]	Friday following Easter II Mag at EP2 with Alleluia	After eight days, * when the doors were shut, the Lord entered, and said unto them: Peace be unto you; alleluia, alleluia.
e3 i am the shepherd [easter]	The Third Sunday of Easter Mag at EP1 with Alleluia	I am the Shepherd of the sheep: * I am the way, the truth and the life; I am the good Shepherd, and know my sheep and am known of mine, alleluia, alleluia.
e3 i am the good shepherd [easter]	The Third Sunday of Easter Mag at EP2 with Alleluia	I am the good Shepherd, * and I feed my sheep; and I lay down my life for the sheep, alleluia.
e3 go ye into all [easter]	Monday following Easter III Ben with Alleluia	Go ye into all the world * and teach all nations, alleluia.
e3 the good shepherd lays [easter]	Monday following Easter III Mag at EP2 with Alleluia	The good Shepherd * layeth down his life for the sheep, alleluia.
e3 go ye into the world [easter]	Tuesday following Easter III Ben with Alleluia	Go ye into all the world, * and teach all nations, baptising them in the name of the Father, and of the Son, and of the Holy Ghost, alleluia.
e3 he that is an hireling [easter]	Tuesday following Easter III Mag at EP2 with Alleluia	He that is an hireling, * whose own the sheep are not, seeth the wolf coming, and leaveth the sheep, and fleeth: and the wolf catcheth them, and scattereth the sheep, alleluia.
e3 go unto my [easter]	Wednesday following Easter III Ben with Alleluia	Go unto my brethren * and say unto them, alleluia, that they go into Galilee, alleluia: there shall they see me, alleluia, alleluia, alleluia.
e3 as the father knows [easter]	Wednesday following Easter III Mag at EP2 with Alleluia	As the Father knoweth me, * even so know I the Father: and I lay down my life for the sheep, alleluia.
e3 art thou only [easter]	Thursday following Easter III Ben with Alleluia	Art thou only a stranger * and hast not heard concerning Jesus, how they delivered him to be condemned to death, alleluia.
e3 other sheep i have [easter]	Thursday following Easter III Mag at EP2 with Alleluia	Other sheep I have, * which are not of this fold: them also I must bring, and they shall hear my voice: and there shall be one fold and one Shepherd, alleluia.
st george the saints through faith [easter]	Saint George Mag at EP1 with Alleluia	The Saints through faith * subdued kingdoms, wrought righteousness, obtained promises, alleluia.
e3 ought not christ [easter]	Friday following Easter III Ben with Alleluia	Ought not Christ to have suffered* these things, and to enter into his glory? alleluia.
e4 a little while [easter]	The Fourth Sunday of Easter Mag at EP1 with Alleluia	A little while * and ye shall not see me, saith the Lord: and again a little while, and ye shall see me, because I go to the Father, alleluia.
e4 verily i say [easter]	The Fourth Sunday of Easter Mag at EP2 with Alleluia	Verily I say unto you, * that ye shall weep and lament; and the world shall rejoice; and ye shall be sorrowful, but your sorrow shall be turned into joy, alleluia.
apostles for they shall [easter]	Common of Apostles Mag at EP1 with Alleluia	Light perpetual * shall shine upon thy Saints, O Lord, and an ageless eternity, alleluia.
apostles inv [easter]	Common of Apostles Inv with Alleluia	The Lord, the King of Apostles: * O come, let us worship him, alleluia.
apostles ye which have [easter]	Common of Apostles Ben with Alleluia	Daughter of Jerusalem, come forth, and behold the Martyrs with the diadems wherewith the Lord hath crowned them in the day of solemnity and rejoicing, alleluia, alleluia.
apostles be ye valiant [easter]	Common of Apostles Mag at EP2 with Alleluia	O ye Saints and righteous, * rejoice in the Lord, alleluia: God hath chosen you for his own inheritance, alleluia.
e4 and beginning [easter]	Monday following Easter IV Ben with Alleluia	And beginning at Moses * and all the Prophets, he expounded unto them the scriptures concerning himself, alleluia.
e4 your sorrow shall be [easter]	Monday following Easter IV Mag at EP2 with Alleluia	Your sorrow * shall be turned into joy, alleluia: and your joy no man taketh from you, alleluia, alleluia.
e4 and they constrained [easter]	Tuesday following Easter IV Ben with Alleluia	And they constrained him * saying: Abide with us, for it is toward evening, alleluia.
e4 sorrow hath filled [easter]	Tuesday following Easter IV Mag at EP2 with Alleluia	Sorrow hath filled * your heart: but your joy no man taketh from you, alleluia, alleluia.
e4 abide with us for [easter]	Wednesday following Easter IV Ben with Alleluia	Abide with us, * for it is toward evening, and the day is far spent, alleluia.
e4 your sorrow will be turned [easter]	Wednesday following Easter IV Mag at EP2 with Alleluia	Your sorrow, * alleluia, shall be turned into joy, alleluia.
doctor catherine [easter]	Saint Catherine of Siena Mag at EP2 with Alleluia	O teacher most excellent, * O light of holy Church, O blessed Catherine of Siena, lover of the divine law, intercede for us unto the Son of God. Alleluia.
virgins come thou bride [easter]	Common of Virgin Mag at EP1 with Alleluia	Come, thou Bride of Christ, * receive the crown which the Lord hath prepared for thee for ever. Alleluia.
virgins the lord the king [easter]	Common of Virgin Inv with Alleluia	The Lord, the King of Virgins: * O come, let us worship him. Alleluia.
virgins the kingdom of [easter]	Common of Virgin Ben with Alleluia	The kingdom of heaven * is likened unto a merchant-man seeking goodly pearls: who, when he had found one pearl of great price, went and sold all that he had, and bought it. Alleluia.
e4 and he went in [easter]	Thursday following Easter IV Ben with Alleluia	And he went in * to tarry with them; and it came to pass, as he sat at meat with them, he took bread, and blessed it, and brake, and gave it to them, alleluia.
e4 verily verily i say [easter]	Thursday following Easter IV Mag at EP2 with Alleluia	Verily, verily, * I say unto you: I will see you again, and your heart shall rejoice, and your joy no man taketh from you, alleluia.
e4 they knew the lord [easter]	Friday following Easter IV Ben with Alleluia	They knew the Lord * Jesus, alleluia, in breaking of bread, alleluia.
joseph then joseph being raised [easter]	Saint Joseph the Worker Mag at EP1 with Alleluia	Then Joseph * being raised from sleep, did as the Angel of the Lord had bidden him, and took unto him his wife. Alleluia.
joseph inv [easter]	Saint Joseph the Worker Inv with Alleluia	Christ, the Son of God, deigned to be thought the son of Joseph: * O come, let us worship him. Alleluia.
joseph jesus himself [easter]	Saint Joseph the Worker Ben with Alleluia	Jesus himself * began to be about thirty years of age, being, as was supposed, the son of Joseph. Alleluia.
joseph behold a faithful [easter]	Saint Joseph the Worker Mag at EP2 with Alleluia	Behold a faithful and wise steward, * whom his Lord hath made ruler over his household. Alleluia.
e5 i go my way [easter]	The Fifth Sunday of Easter Mag at EP1 with Alleluia	I go my way to him * that sent me; and none of you asketh me: Whither goest thou? alleluia, alleluia.
doctor athanasius [easter]	Saint Athanasius Mag at EP2 with Alleluia	O teacher most excellent, * O light of holy Church, O blessed Athanasius, lover of the divine law, intercede for us unto the Son of God. Alleluia.
e5 did not our heart [easter]	Monday following Easter V Ben with Alleluia	Did not our heart burn * within us concerning Jesus, while he talked with us in the way? alleluia.
e5 i tell you the truth [easter]	Monday following Easter V Mag at EP2 with Alleluia	I tell you the truth: * it is expedient for you that I go away; for if I go not away, the Comforter will not come unto you, alleluia.
many martyrs for theirs is the kingdom [easter]	Common of Several Martyrs Mag at EP1 with Alleluia	For theirs is the kingdom * of heaven, who despised the life of the world, and attained unto the rewards of the kingdom, and washed their robes in the blood of the Lamb.
many martyrs the very hairs [easter]	Common of Several Martyrs Ben with Alleluia	Daughters of Jerusalem, * come forth, and behold the Martyrs with the diadems wherewith the Lord hath crowned them in the day of solemnity and rejoicing, alleluia, alleluia.
many martyrs in the heavenly [easter]	Common of Several Martyrs Mag at EP2 with Alleluia	O ye Saints and righteous, * rejoice in the Lord, alleluia: God hath chosen you for his own inheritance, alleluia.
e5 peace but unto you it is [easter]	Tuesday following Easter V Ben with Alleluia	Peace be unto you, * it is I, alleluia; be not afraid, alleluia.
e5 when the comforter [easter]	Tuesday following Easter V Mag at EP2 with Alleluia	When the Comforter shall come, * even the Spirit of truth, he shall reprove the world of sin, and of righteousness, and of judgement, alleluia.
e5 a spirit hath not [easter]	Wednesday following Easter V Ben with Alleluia	A spirit hath not flesh * and bones, as ye see me have; therefore believe, alleluia.
e5 i have yet many [easter]	Wednesday following Easter V Mag at EP2 with Alleluia	I have yet many things * to say into you, but ye cannot bear them now; howbeit, when he, the Spirit of truth, shall come, he will guide you into all truth, alleluia.
e5 the disciples offered [easter]	Thursday following Easter V Ben with Alleluia	The disciples * set before the Lord a piece of broiled fish, and of an honeycomb, alleluia, alleluia.
e5 for he shall not [easter]	Thursday following Easter V Mag at EP2 with Alleluia	He shall not speak * of himself, but whatsoever he shall hear, that shall he speak; and he will show you things to come, alleluia.
e5 these are the words [easter]	Friday following Easter V Ben with Alleluia	These are the words * which I spake unto you, while I was yet with you, alleluia, alleluia.
e5 now i go my way [easter]	Friday following Easter V Mag at EP2 with Alleluia	I go my way to him * that sent me; but because I have said these things unto you, sorrow hath filled your heart, alleluia.
e6 hitherto have ye [easter]	The Sixth Sunday of Easter Mag at EP1 with Alleluia	Hitherto * have ye asked nothing in my name; ask, and ye shall receive, alleluia.
e6 ask ye [easter]	The Sixth Sunday of Easter Mag at EP2 with Alleluia	Ask ye, * and ye shall receive, that your joy may be fulfilled; for the Father himself loveth you, because ye have loved me, and have believed, alleluia.
e6 ask and it shall [easter]	Rogation Monday Ben with Alleluia	Ask, and it shall be given you; * seek, and ye shall find; knock, and it shall be opened unto you, alleluia.
e6 for the father [easter]	Rogation Monday Mag at EP2 with Alleluia	The Father himself loveth * you, because ye have loved me, and have believed, alleluia.
doctor john [easter]	Saint John of Avila Mag at EP2 with Alleluia	O teacher most excellent, * O light of holy Church, O blessed John of Avila, lover of the divine law, intercede for us unto the Son of God. Alleluia.
e6 it behoved christ [easter]	Rogation Tuesday Ben with Alleluia	It beloved * Christ to suffer, and to rise again from the dead, alleluia.
e6 i came forth [easter]	Rogation Tuesday Mag at EP2 with Alleluia	I came forth * from the Father, and am come into the world; again, I leave the world, and go to the Father, alleluia.
e6 father the hour [easter]	Rogation Wednesday Ben with Alleluia	Father, the hour is come, * glorify thy Son with the glory which I had with thee before the world was, alleluia.
ascension father i have [easter]	The Ascension of the Lord Mag at EP1 with Alleluia	Father, * I have manifested thy name unto the men whom thou hast given me; and now I pray for them, not for the world, because I come to thee, alleluia.
ascension inv [easter]	The Ascension of the Lord Inv with Alleluia	Alleluia, Christ the Lord ascendeth into heaven: * O come, let us worship him, alleluia.
ascension i ascend [easter]	The Ascension of the Lord Ben with Alleluia	I ascend to my Father, * and your Father; and to my God, and your God, alleluia.
ascension o king of [easter]	The Ascension of the Lord Mag at EP2 with Alleluia	O King of glory, * Lord of Sabaoth, who on this day ascendest with exceeding triumph far above all heavens: we pray thee, leave us not comfortless, but send the promise of the Father on us, even the Spirit of truth, alleluia.
bvm o holy mary [check for pointing] [easter]	Common of Blessed Virgin Mary Mag at EP1 with Alleluia	Holy Mary, * succour the unhappy, help the faint-hearted, comfort the mourners, pray for the people, entreat for the clergy, intercede for the consecrated women: may all perceive thy help, who celebrate thy holy festival. Alleluia.
bvm inv [easter]	Common of Blessed Virgin Mary Inv with Alleluia	Holy Mary, Virgin Mother of God: * Intercede for us. Alleluia.
bvm blessed art thou [easter]	Common of Blessed Virgin Mary Ben with Alleluia	Blessed art thou, * O Mary, who hast believed; there shall be performed in thee the things which were told thee from the Lord. Alleluia.
bvm all generations [easter]	Common of Blessed Virgin Mary Mag at EP2 with Alleluia	All generations * Shall call me blessed, for God hath regarded his lowly handmaiden. Alleluia.
e7 when the [easter]	The Seventh Sunday of Easter Mag at EP1 with Alleluia	When the Comforter is come, * whom I will send unto you, even the Spirit of truth, which proceedeth from the Father, he shall testify of me, alleluia.
e7 these things [easter]	The Seventh Sunday of Easter Mag at EP2 with Alleluia	These things have I told you, * that when the hour cometh, ye may remember that I told you of them, alleluia.
holy woman give her [easter]	Common of Any Matron or Holy Woman Ben with Alleluia	Give her * of the fruit of her hands, and let her own works praise her in the gates. Alleluia.
matron she stretcheth [easter]	Common of Any Matron or Holy Woman Mag at EP2 with Alleluia	She stretched out her hand * to the poor, yea, she reacheth forth her hands to the needy, and eateth not the bread of idleness. Alleluia.
pen i will not [easter]	Pentecost Mag at EP1 with Alleluia	I will not leave you * comfortless, alleluia; I go away, and come again unto you, alleluia; and your heart shall rejoice, alleluia.
pen inv [easter]	Pentecost Inv with Alleluia	Alleluia, the Spirit of the Lord filleth the world:* O come, let us worship him, alleluia.
pen receive ye [easter]	Pentecost Ben with Alleluia	Receive ye * the Holy Ghost; whosesoever sins ye remit, they are remitted unto them, alleluia.
pen today are fulfilled [easter]	Pentecost Mag at EP2 with Alleluia	Today * are fulfilled the days of Pentecost, alleluia; today the Holy Spirit appeared in fire to the disciples, and bestowed upon them his manifold graces, sending them into all the world, to preach and to testify: He that believeth and is baptised shall be saved, alleluia.
olhoc behold mary [easter]	Our Lady, Help of Christians Mag at EP1 with Alleluia	Behold, Mary * was our hope, to whom we fled for help, that she might deliver us, and she. came to our aid. Alleluia.
olhoc to thee we cried [easter]	Our Lady, Help of Christians Ben with Alleluia	To thee * we cried, O holy Mother of God, and through thee came the help of the Lord to us. Alleluia.
olhoc holy mary help [easter]	Our Lady, Help of Christians Mag at EP2 with Alleluia	Holy Mary, * help those in misery, strengthen the fainthearted, comfort the sorrowful, pray for the people, intercede for clerics, plead for all women vowed to God; may all feel thy help, whosoever implore thine aid. Alleluia.
pen god so loved [easter]	Monday in Whitsun Week Ben with Alleluia	God so loved the world * that he gave his only-begotten Son: that whosoever believeth in him should not perish, but have everlasting life, alleluia.
pen if a man love [easter]	Monday in Whitsun Week Mag at EP2 with Alleluia	If a man love me, * he will keep my word: and my Father will love him, and we will come unto him, and make our abode with him, alleluia.
pen i am the door [easter]	Tuesday in Whitsun Week Ben with Alleluia	I am the door, * saith the Lord: by me if any man enter in, he shall be saved, and shall find pasture, alleluia.
pen peace i leave [easter]	Tuesday in Whitsun Week Mag at EP2 with Alleluia	Peace I leave * with you, my peace I give unto you: not as the world giveth, give I unto you, alleluia.
doctor bede [easter]	Saint Bede the Venerable Mag at EP2 with Alleluia	O teacher most excellent, * O light of holy Church, O blessed Bede the Venerable, lover of the divine law, intercede for us unto the Son of God. Alleluia.
pen i am the living [easter]	Ember Wednesday in Whitsun Week Ben with Alleluia	I am the living bread, * saith the Lord, which came down from heaven, alleluia.
pen i am the living which came [easter]	Ember Wednesday in Whitsun Week Mag at EP2 with Alleluia	I am the living bread, * which came down from heaven: if any man eat of this bread, he shall live for ever; and the bread that I will give is my flesh, for the life of the world, alleluia.
oljchp inv [easter]	Our Lord Jesus Christ, Eternal High Priest Inv with Alleluia	The Lord, the High Priest, * O come, let us worship him. Alleluia.
oljchp this is my body [easter]	Our Lord Jesus Christ, Eternal High Priest Ben with Alleluia	This is my body * which is given for you: this cup is the new testament in my blood, which is shed for you: this do in remembrance of me. Alleluia.
oljchp behold i will send [easter]	Our Lord Jesus Christ, Eternal High Priest Mag at EP2 with Alleluia	Behold, I will send my messenger, * and he shall prepare the way before me. He shall purify the sons of Levi; that they may offer unto the Lord an offering in righteousness: and the offering of Juda and Jerusalem shall be pleasant unto the Lord, as in former years. Alleluia.
pen jesus called unto [easter]	Thursday in Whitsun Week Ben with Alleluia	Jesus called the twelve * disciples together, and gave them power and authority over unclean spirits, and to cure diseases: and he sent them to preach the kingdom of God, and to heal the sick, alleluia.
pen the spirit which [easter]	Thursday in Whitsun Week Mag at EP2 with Alleluia	The Spirit, * which proceeded from the Father, alleluia: he shall glorify me, alleluia, alleluia.
pen jesus said [easter]	Ember Friday in Whitsun Week Ben with Alleluia	Jesus said: * But that ye know that the Son of man hath power on earth to forgive sins (he saith unto the sick of the palsy): I say unto thee: Arise, take up thy bed, and go thy way into thine house, alleluia.
pen but the comforter [easter]	Ember Friday in Whitsun Week Mag at EP2 with Alleluia	But the Comforter, * which is the Holy Ghost, whom the Father will send in my name, he shall teach you all things, and bring all things to your remembrance, whatsoever I have said unto you, alleluia.
pen the love of god [easter]	Ember Saturday in Whitsun Week Ben with Alleluia	The love of God * is shed abroad in our hearts by his Spirit which dwelleth in us, alleluia.
trin thanks o god	Trinity Sunday Mag at EP1	Thanks, O God, * be unto thee, thanks be unto thee: one and very Trinity, one and supreme Deity, holy and perfect Unity.
trin thanks o god [easter]	Trinity Sunday Mag at EP1	Thanks, O God, * be unto thee, thanks be unto thee: one and very Trinity, one and supreme Deity, holy and perfect Unity.
trin inv	Trinity Sunday Inv	The very God, one in Trinity, and Trinity in Unity: * O come, let us worship him.
trin blessed be	Trinity Sunday Ben	Blessed be the Creator * and Ruler of all things, the holy and undivided Trinity, both now and throughout all ages, world without end.
trin thee o god	Trinity Sunday Mag at EP2	Thee, O God, * the Father unbegotten; thee, the only-begotten Son; thee, O Holy Spirit, the Paraclete; holy and undivided Trinity, with our whole heart and voice we acknowledge thee, we praise thee, we bless thee; to thee be glory for ever and ever.
visit blessed art thou	The Visitation of the Blessed Virgin Mary Mag at EP1	Blessed art thou, * O Mary, who hast believed: for there shall be a performance of those things which were told thee from the Lord, alleluia.
visit inv	The Visitation of the Blessed Virgin Mary Inv	Let us celebrate the Visitation of the Virgin Mary: * Let us worship her Son, Christ the Lord.
visit when elizabeth	The Visitation of the Blessed Virgin Mary Ben	When Elizabeth * heard the salutation of Mary, she cried with a loud voice, saying: Whence is this to me, that the Mother of my Lord should come to me? Alleluia.
visit all generations	The Visitation of the Blessed Virgin Mary Mag at EP2	All generations * shall call me blessed, for God hath regarded the lowliness of his handmaiden, alleluia.
corpus o how sweet	The Most Holy Body and Blood of Christ Mag at EP1	O how sweet * O Lord is thy spirit, who, to show forth thy loving- kindness to thy children, feedest them with the most sweet Bread, which came from heaven; filling the hungry with good things, and sending the disdainful rich empty away.
corpus inv	The Most Holy Body and Blood of Christ Inv	Let us worship Christ the King, the Lord of all the world: * Who giveth health of spirit to them that feed on him.
corpus i am	The Most Holy Body and Blood of Christ Ben	I am the living Bread * which came down from heaven;
corpus o sacred	The Most Holy Body and Blood of Christ Mag at EP2	O sacred banquet, * in which Christ is received, the memory of his passion renewed, the mind filled with grace, and a pledge of future glory given unto us, alleluia.
t1 the child samuel	The First Sunday after Trinity Mag at EP1	The child Samuel * ministered before the Lord in the presence of Eli; and the word of the Lord was precious unto him.
inv sun trin	The First Sunday after Trinity Inv	Let us worship the Lord, * for he hath made us.
t1 father abraham	The First Sunday after Trinity Ben	Father Abraham, * have mercy upon me, and send Lazarus that he may dip the tip of his finger in water, and cool my tongue.
t1 son remember	The First Sunday after Trinity Mag at EP2	Son, remember * that thou in thy lifetime receivedst thy good things, and likewise Lazarus evil things.
doctor ephrem	Saint Ephrem Mag at EP2	O teacher most excellent, * O light of holy Church, O blessed Ephrem, lover of the divine law, intercede for us unto the Son of God.
sh i am come	The Most Sacred Heart of Jesus Mag at EP1	I am come * to send fire on the earth: and what will I if it be already kindled?
sh inv	The Most Sacred Heart of Jesus Inv	The Heart of Jesus was wounded for love of us: * O come, let us worship him.
sh now these things	The Most Sacred Heart of Jesus Ben	Now these things * were done, that the Scripture should be fulfilled which saith: They shall look on him whom they pierced.
sh but when they	The Most Sacred Heart of Jesus Mag at EP2	But when they came to Jesus, * and saw that he was dead already, they brake not his legs;. but one of the soldiers with a spear pierced his side, and forthwith came there out blood and water.
apostles for they shall	Common of Apostles Mag at EP1	For they shall deliver you up * to the councils, and they will scourge you in the synagogues, and ye shall be brought before governors and kings for my sake, for a testimony against them and the Gentiles.
apostles inv	Common of Apostles Inv	The Lord, the King of Apostles: * O come, let us worship him.
apostles ye which have	Common of Apostles Ben	Ye which have forsaken all * and followed me, shall receive an hundredfold, and shall inherit everlasting life.
apostles be ye valiant	Common of Apostles Mag at EP2	Be ye valiant * in the warfare, fight against the old serpent: and ye shall receive an everlasting kingdom.
t2 and all israel	The Second Sunday after Trinity Mag at EP1	And all Israel knew * from. Dan even to Beersheba, that Samuel was ordained a prophet of the Lord.
t2 a certain man	The Second Sunday after Trinity Ben	A certain man * made a great supper, and bade many; and he sent his servants at supper time to say to them that were bidden: Come, for all things are now ready, alleluia.
t2 go out quickly	The Second Sunday after Trinity Mag at EP2	Go out quickly into the streets and lanes of the city; and compel the poor and the maimed, the halt and the blind, to come in, that my house may be filled, alleluia.
doctor anthony	Saint Anthony of Padua Mag at EP2	O teacher most excellent, * O light of holy Church, O blessed Anthony of Padua, lover of the divine law, intercede for us unto the Son of God.
t3 ye mountains	The Third Sunday after Trinity Mag at EP1	Ye mountains of Gilboa, * let there be neither rain nor dew upon you; for there the shield of the mighty is vilely cast away, the shield of Saul, as though he had not been anointed with oil. How are the mighty fallen in the midst of the battle! Jonathan was slain upon your high places. Saul and Jonathan were lovely and pleasant in their lives, and in their death they were not divided.
t3 what man	The Third Sunday after Trinity Ben	What man among you * having an hundred sheep, if he lose one of them, doth not leave the ninety and nine in the wilderness, and go after that which is lost, until he find it? alleluia.
t3 what woman	The Third Sunday after Trinity Mag at EP2	What woman * having ten pieces of silver, if she lose one piece, doth not light a candle, and sweep the house, and seek diligently, until she find it?
njb when zacharias	The Nativity of Saint John the Baptist Mag at EP1	When Zacharias * went into the temple, there appeared unto him the Angel Gabriel, standing on the right side of the altar of incense.
njb inv	The Nativity of Saint John the Baptist Inv	The Lord, the King of the forerunner: * O come, let us worship him.
njb the mouth	The Nativity of Saint John the Baptist Ben	The mouth of Zacharias * was opened, and he prophesied, saying: Blessed be the God of Israel.
njb the child	The Nativity of Saint John the Baptist Mag at EP2	The Child * who is born unto us, is more than a Prophet; for this is he of whom the Saviour saith: Among all those born of women there hath not arisen a greater than John the Baptist.
t4 and david said	The Fourth Sunday after Trinity Mag at EP1	And David said * unto the Lord, when he saw the Angel that smote the people: It is I that have sinned, I that have done perversely; but these which are the sheep, what have they done?
t4 be ye therefore	The Fourth Sunday after Trinity Ben	Be ye therefore merciful, * even as your Father also is merciful, saith the Lord.
t4 judge not	The Fourth Sunday after Trinity Mag at EP2	Judge not, * that ye be not judged: for with what judgement ye judge, ye shall be judged, saith the Lord.
peter and paul today	Saints Peter and Paul Mag at EP2	Today * Simon Peter ascended the scaffold of the cross, alleluia; today the keybearer of heaven went with joy unto Christ, today the Apostle Paul, the light of the world, bowed his head, and for the name of Christ was crowned with martyrdom, alleluia.
t5 so david	The Fifth Sunday after Trinity Mag at EP1	So David prevailed * over the Philistine with a sling and a stone, in the name of the Lord.
t5 and jesus	The Fifth Sunday after Trinity Ben	And Jesus entered * into a ship and sat down and taught the people.
t5 master we have toiled	The Fifth Sunday after Trinity Mag at EP2	Master, * we have toiled all the night, and have taken nothing; nevertheless, at thy word I will let down the net.
elizabeth inv	Saint Elizabeth of Portugal Inv	Let us praise our God: * Whom blessed Elizabeth of Portugal confessed.
t6 i am distressed	The Sixth Sunday after Trinity Mag at EP1	I am distressed * for thee, my brother Jonathan; thy love to me was wonderful, passing the love of women; as a mother loveth her only son, so have I loved thee. The bow of Jonathan was never turned back, neither swerved his shield from the battle, and his spear returned not empty.
t6 ye have heard	The Sixth Sunday after Trinity Ben	Ye have heard * that it was said by them of old time: Thou shalt not kill, and whosoever shall kill shall be in danger of the judgement.
t6 if thou bring	The Sixth Sunday after Trinity Mag at EP2	If thou bring thy gift * to the altar, and rememberest that thy brother hath ought against thee, leave there thy gift before the altar, and go thy way; first be reconciled to thy brother, and then come and offer thy gift, alleluia.
doctor bonaventure	Saint Bonaventure Mag at EP2	O teacher most excellent, * O light of holy Church, O blessed Bonaventure, lover of the divine law, intercede for us unto the Son of God.
t7 but king david	The Seventh Sunday after Trinity Mag at EP1	But King David * covered his head, and mourned for Absalom, saying: O my son Absalom, O my son Absalom; would God I had died for thee, O my son Absalom!
t7 when a great	The Seventh Sunday after Trinity Ben	When a great multitude * was with Jesus, and had nothing to eat, he called his disciples and said unto them: I have compassion on the multitude, because they have now been with me three days; and have nothing to eat, alleluia.
t7 i have compassion	The Seventh Sunday after Trinity Mag at EP2	I have compassion * on the multitude, because they have now been with me three days, and have nothing to eat; and if I send them away fasting, they will faint by the way, alleluia.
doctor lawrence	Saint Lawrence of Brindisi Mag at EP2	O teacher most excellent, * O light of holy Church, O blessed Lawrence of Brindisi, lover of the divine law, intercede for us unto the Son of God.
mary mag a woman	Saint Mary Magdalene Mag at EP1	A woman * in the city, which was a sinner, when she knew that Jesus sat at meat in the house of Simon the leper, brought an alabaster box of ointment, and stood behind at the feet of Jesus, and began to wash his feet with tears, and did wipe them with the hairs of her head; and kissed his feet, and anointed them with the ointment.
mary mag inv	Saint Mary Magdalene Inv	For the conversion of Mary Magdalene: * Let us praise our God.
mary mag mary anointed	Saint Mary Magdalene Ben	Mary * anointed the feet of Jesus and wiped them with her hair, and the house was filled with the fragrance of the ointment.
mary mag a woman in the city	Saint Mary Magdalene Mag at EP2	A woman * in the city, which was a sinner, brought an alabaster box of ointment, and stood behind at the Lord's feet, and began to wash his feet with her tears, and wiped them with the hair of her head.
t8 wisdom crieth	The Eighth Sunday after Trinity Mag at EP1	Wisdom crieth aloud * in the broad places: Whosoever loveth wisdom, let him turn in hither, and he shall find her; and when he hath found her, happy is he if he hold her fast.
t8 beware ye	The Eighth Sunday after Trinity Ben	Beware ye * of false prophets, which come unto you in sheep's clothing, but inwardly they are ravening wolves; ye shall know them by their fruits.
t8 a good tree	The Eighth Sunday after Trinity Mag at EP2	A good tree * cannot bring forth evil fruit, neither can a corrupt tree bring forth good fruit. Every tree which bringeth not forth good fruit is hewn down, and cast into the fire, alleluia.
joachim and anne inv	Saints Joachim and Anne Inv	Christ the King, the Son of David: * O come, let us worship.
joachim and anne i am	Saints Joachim and Anne Ben	I am the God * of your fathers, saith the Lord; I have seen the affliction of my people, and have heard their cry, and I am come to deliver them, alleluia.
joachim and anne the glorious	Saints Joachim and Anne Mag at EP2	The glorious stock of Jesse * produced a beautiful branch, from which has sprung a flower of wondrous fragrance.
martha inv	Saints Martha, Mary and Lazarus Inv	The Lord, the King of all that confess him, O come, let us worship.
martha watch and pray	Saints Martha, Mary and Lazarus Ben	Watch and pray * for blessed is that servant whom his Lord when he cometh shall find so doing.
martha since the	Saints Martha, Mary and Lazarus Mag at EP2	Since the beginning of the world * men have not heard, nor perceived by the ear, neither hath the eye seen, any God beside thee, who worketh for them that wait upon him.
doctor peter	Saint Peter Chrysologus Mag at EP2	O teacher most excellent, * O light of holy Church, O blessed Peter Chrysologus, lover of the divine law, intercede for us unto the Son of God.
t9 i beseech	The Ninth Sunday after Trinity Mag at EP1	I beseech thee, O Lord, * remember now how I have walked before thee in truth and with a perfect heart, and have done that which is good in thy sight.
t9 the lord said	The Ninth Sunday after Trinity Ben	The lord  said unto his steward: * What is this I hear of thee? Give an account of thy stewardship, alleluia.
t9 what shall i do	The Ninth Sunday after Trinity Mag at EP2	What shall I do, * seeing my master taketh from-me the stewardship? I cannot dig, to beg I am ashamed; I am resolved what to do, that when I am removed from the stewardship, they may receive me into to their houses.
doctor alphonsus	Saint Alphonsus Liguori Mag at EP2	O teacher most excellent, * O light of holy Church, O blessed Alphonsus Liguori, lover of the divine law, intercede for us unto the Son of God.
transfiguration christ jesus	The Transfiguration of the Lord Mag at EP1	Christ Jesus, * the brightness of the Father and the express image of his substance, upholding all things by the word of his power, making atonement for our sins, today on the lofty mountain deigned to appear in his glory.
transfiguration inv	The Transfiguration of the Lord Inv	Christ, the most high King of glory: * O come, let us worship him.
transfiguration and behold	The Transfiguration of the Lord Ben	And behold, * a voice out of the cloud, which said: This is my beloved Son, in whom I am well pleased; hear ye him, alleluia.
transfiguration and when	The Transfiguration of the Lord Mag at EP2	And when the disciples * heard it, they fell on their faces, and were sore afraid; and Jesus came and touched them, and said: Arise, and be not afraid, alleluia.
smotc let us	Saint Mary of the Cross Mag at EP1	Let us honour Saint Mary of the Cross, * who, hearing the quiet voice of the Lord, ran courageously the race set before her.
smotc forsaking	Saint Mary of the Cross Ben	Forsaking worldly comforts, Saint Mary of the Cross, thy words and life remind us: * we are but travellers here.
smotc inspired	Saint Mary of the Cross Mag at EP2	Inspired by the holy Virgin, Saint Mary of the Cross, * let us listen in our hearts to the whisperings of our God.
t10 go not	The Tenth Sunday after Trinity Mag at EP1	Go not * after other gods to serve them.
t10 when jesus	The Tenth Sunday after Trinity Ben	When Jesus drew nigh  * to Jerusalem, he beheld the city and wept over it, saying: O that thou hadst known, even thou; for the days shall come unto thee, when they shall compass thee about, and shall keep thee in on every side, and I shall lay thee even with the ground; forasmuch as thou knewest not the time of thy visitation, alleluia.
t10 it is written	The Tenth Sunday after Trinity Mag at EP2	It is written: * My house shall be called the house of prayer for all nations; but ye have made it a den of thieves. And he was daily with them, teaching in the temple.
assump o virgin	The Assumption of the Blessed Virgin Mary Mag at EP1	O Virgin most prudent, * whither goest thou, shining forth like the dawn? O daughter of Sion, thou art all fair and gracious, fair as the moon, clear as the sun.
assump inv	The Assumption of the Blessed Virgin Mary Inv	O come, let us worship the King of kings: * Today hath he taken his Virgin Mother to heaven above.
assump who is she	The Assumption of the Blessed Virgin Mary Ben	Who is she * that ascendeth as the dawning of the morning, fair as the moon, clear as the sun, and terrible as an army of banners?
assump today	The Assumption of the Blessed Virgin Mary Mag at EP2	Today * Mary the Virgin is taken up into heaven: rejoice, for with Christ she reigneth for eyer.
t11 son of man	The Eleventh Sunday after Trinity Mag at EP1	Son of man, * I send thee to the children of Israel, to a rebellious nation.
t11 standing afar	The Eleventh Sunday after Trinity Ben	Standing afar off, * the publican would not so much as lift up his eyes unto heaven; but smote upon his breast, saying: God be merciful to me a sinner.
t11 this man	The Eleventh Sunday after Trinity Mag at EP2	This man went down * justified rather than the other; for every one that exalteth himself shall be abased, and he that humbleth himself shall be exalted.
doctor bernard	Saint Bernard Mag at EP2	O teacher most excellent, * O light of holy Church, O blessed Bernard, lover of the divine law, intercede for us unto the Son of God.
t12 i will shake	The Twelfth Sunday after Trinity Mag at EP1	I will shake the heavens * and the earth; and I will destroy the strength of the kingdom of the heathen.
t12 when the lord	The Twelfth Sunday after Trinity Ben	When the Lord had passed * through the coasts of Tyre, he made the deaf to hear, and the dumb to speak.
t12 he hath done	The Twelfth Sunday after Trinity Mag at EP2	He hath done all things well; * he maketh the deaf to hear, and the dumb to speak.
monica inv	Saint Monica Inv	Let us praise our God: * Whom blessed Monica  confessed.
doctor augustine	Saint Augustine of Hippo Mag at EP2	O teacher most excellent, * O light of holy Church, O blessed Augustine of Hippo, lover of the divine law, intercede for us unto the Son of God.
t13 i will stand	The Thirteenth Sunday after Trinity Mag at EP1	I will stand * upon my watch, and set me upon my tower.
t13 master what shall	The Thirteenth Sunday after Trinity Ben	Master, * what shall I do to inherit eternal life? He said unto him: What is written in the law? Thou shalt love the Lord thy God with all thy heart, alleluia.
t13 a certain man	The Thirteenth Sunday after Trinity Mag at EP2	A certain man * went down from Jerusalem to Jericho, and fell among thieves, which stripped him of his raiment, and wounded him, and departed, leaving him half dead.
bsjb herod sent	The Passion of Saint John the Baptist Ben	Herod sent an executioner * and commanded the head of John to be cut off in the prison. And when his disciples heard it, they came and took up his corpse, and laid it in a tomb.
bsjb the unbelieving king	The Passion of Saint John the Baptist Mag at EP2	The unbelieving king * sent his wicked servants, and commanded that the head of John the Baptist be cut off.
olsc the blessed	Our Lady of the Southern Cross Mag at EP1	The Blessed Virgin Mary, enthroned in heaven beyond the stars, ever rejoiceth in God her Saviour.
olsc our lady	Our Lady of the Southern Cross Ben	Our Lady of the Southern Cross, thou didst bring forth the dayspring from on high; * guide those, who sit in darkness and the shadow of death, unto the radiance of thy most blessed Son: Christ our God.
olsc we turn	Our Lady of the Southern Cross Mag at EP2	We turn to thee, Our Lady of the Southern Cross, whom Christ Crucified gave as Mother to all the faithful: * God hath regarded thy lowliness, and rightly we call thee blessed.
t14 blessed be	The Fourteenth Sunday after Trinity Mag at EP1	Blessed be the name * of God for ever; for wisdom and might are his.
t14 as jesus	The Fourteenth Sunday after Trinity Ben	As Jesus entered * into a certain village, there went out to meet him ten men that were lepers; and they stood afar off, and lifted up their voices, saying: Jesus, Master, have mercy upon us.
t14 but one	The Fourteenth Sunday after Trinity Mag at EP2	But one of them, * when he saw that he was cleansed, turned back, and with a loud voice glorified God, alleluia.
nativity bvm let us celebrate	The Nativity of the Blessed Virgin Mary Mag at EP1	Let us celebrate * the most honourable birth of the glorious Virgin Mary, who both attained to the dignity of motherhood, and suffered no loss of virgin modesty.
nativity bvm inv	The Nativity of the Blessed Virgin Mary Inv	Let us celebrate the Nativity of the Virgin Mary: Let us worship her Son, Christ the Lord.
nativity bvm today let us	The Nativity of the Blessed Virgin Mary Ben	Today let us devoutly celebrate * the Nativity of God's most holy Mother, the ever-Virgin Mary; from whom the Son of the Highest proceeded, alleluia.
nativity bvm thy nativity	The Nativity of the Blessed Virgin Mary Mag at EP2	Thy Nativity, * O Virgin Mother of God, hath proclaimed joyful tidings unto all the world; for from thee arose the Sun of righteousness, even Christ our God; who, taking away the curse, hath bestowed a blessing, and despoiling death, hath given unto us life everlasting.
t15 blessed be	The Fifteenth Sunday after Trinity Mag at EP1	Blessed be the God * of Shadrach, Meshach and Abednego, who hath delivered his servants that trusted in him.
t15 take no	The Fifteenth Sunday after Trinity Ben	Take no thought for your life, * saying: What shall we eat, or what shall we drink? for your Father knoweth that ye have need of all these things, alleluia.
t15 but seek ye	The Fifteenth Sunday after Trinity Mag at EP2	But seek ye first * the kingdom of God, and his righteousness, and all these things shall be added unto you, alleluia.
doctor john	Saint John Chrysostom Mag at EP2	O teacher most excellent, * O light of holy Church, O blessed John of Avila, lover of the divine law, intercede for us unto the Son of God.
holy cross o cross	The Exaltation of the Holy Cross Mag at EP1	O Cross, * surpassing all the stars in splendour, world-renowned, justly dear to all Christian people, holiest of earth's treasures; which only wast counted worthy to bear the price of our redemption; sweetest wood and sweetest iron, sweetest weight is hung on thee; bring aid to this congregation, who are assembled to celebrate thy praises.
holy cross inv	The Exaltation of the Holy Cross Inv	Christ crucified is our King: *O come, let us worship him.
holy cross above all	The Exaltation of the Holy Cross Ben	Above all cedar trees * art thou noble. O Tree on which the life of the world hung, on which Christ triumphed, and death for evermore.
holy cross he the holy	The Exaltation of the Holy Cross Mag at EP2	He the holy Cross endured, * who destroyed the power of hell; he with strength did gird himself, on the third day to rise again, alleluia.
ember sept this kind	Ember Wednesday in September Ben	This kind * of evil spirit can come forth by nothing but by prayer and fasting.
sorrows inv	Our Lady of Sorrows Inv	Let us stand by the Cross with Mary the Mother of Jesus: * through whose soul a sword of sorrow passed.
ember sept a woman	Ember Friday in September Ben	A woman * in the city, which was a sinner, stood at the Lord's feet behind him, and began to wash his feet with tears, and did wipe them with the hairs of her head, and kissed his feet, and anointed them with ointment.
doctor robert	Saint Robert Bellarmine Mag at EP2	O teacher most excellent, * O light of holy Church, O blessed Robert Bellarmine, lover of the divine law, intercede for us unto the Son of God.
doctor hildegard	Saint Hildegard of Bingen Mag at EP2	O teacher most excellent, * O light of holy Church, O blessed Hildegard of Bingen, lover of the divine law, intercede for us unto the Son of God.
ember sept give light	Ember Saturday in September Ben	Give light, O Lord * to them that sit in darkness, and in the shadow of death, and guide our feet into the way of peace.
t16 the sun shone	The Sixteenth Sunday after Trinity Mag at EP1	The sun shone * upon the shields of gold, and the mountains glistened therewith; and yet the forces of the heathen were discomfited.
t16 jesus entered	The Sixteenth Sunday after Trinity Ben	Jesus entered * into a city which was called Nain; and behold there was a dead man carried out, the only son of his mother.
t16 a great prophet	The Sixteenth Sunday after Trinity Mag at EP2	A great Prophet * is risen up among us, and God hath visited his people.
dedication the lord hath	Our Lady of Walsingham Mag at EP1	The Lord hath hallowed * his tabernacle: this is the house of the Lord, wherein men may call upon his Name; whereof it is written: My Name shall be there, saith the Lord.
walsingham inv	Our Lady of Walsingham Inv	The Saviour of the world was conceived by the Holy Ghost in the house of the Virgin: * O come, let us worship him.
walsingham behold the tabernacle	Our Lady of Walsingham Ben	Behold, * the tabernacle of God is with men, and he will dwell with them, and they shall be his people, and God himself shall be with them, and be their God.
dedication o how	Our Lady of Walsingham Mag at EP2	O how dreadful * is this place: truly this is none other than the house of God and the gate of heaven.
t17 now job	The Seventeenth Sunday after Trinity Mag at EP1	Now when Job had heard * the words of the messengers, he endured with patience, saying: Shall we receive good at the Lord's hand, and shall  we not' receive evil also? In all this Job sinned not with his lips, neither charged God in any thing foolishly.
t17 as jesus	The Seventeenth Sunday after Trinity Ben	As Jesus went * into the house of one of the chief Pharisees to eat bread on the sabbath day, behold there was a certain man before him which had the dropsy; and he took him, and healed him, and let him go.
t17 when thou	The Seventeenth Sunday after Trinity Mag at EP2	When thou art bidden * to a wedding, sit down in the lowest room; that he that bade thee may say: Friend, go up higher. Then shalt thou have worship in the presence of them that sit at meat with thee, alleluia.
archangels inv	Saints Michael, Gabriel, and Raphael Inv	The Lord, the King of Archangels: * O come, let us worship him.
doctor jerome	Saint Jerome Mag at EP2	O teacher most excellent, * O light of holy Church, O blessed Jerome, lover of the divine law, intercede for us unto the Son of God.
doctor therese	Saint Th√©r√®se of the Child Jesus Mag at EP2	O teacher most excellent, * O light of holy Church, O blessed Theresa of the Child Jesus, lover of the divine law, intercede for us unto the Son of God.
angels inv	Holy Guardian Angels Inv	The Lord, the King of Angels: * O come, let us worship him.
hga the angel	Holy Guardian Angels Ben	The Angel that talked with me * came again, and waked me, as a man that is awakened out of sleep.
hga holy angels	Holy Guardian Angels Mag at EP2	Holy Angels, * our guardians, defend us in the day of battle, that we perish not in the fearful judgement.
t18 dominion and fear	The Eighteenth Sunday after Trinity Mag at EP1	Dominion and fear * are with him: he maketh peace in his high places.
t18 master which	The Eighteenth Sunday after Trinity Ben	Master * which is the great commandment of the law? And Jesus answered: Thou shalt love the Lord thy God with all thy heart, alleluia.
t18 what think ye	The Eighteenth Sunday after Trinity Mag at EP2	What think ye * of Christ? whose son is he? They say unto him: The Son of David. Jesus saith unto them: How then doth David in spirit call him Lord, saying: The Lord said unto my Lord, Sit thou on my right hand?
jhn this man	Saint John Henry Newman Ben	This man * sought not personal gain but gazes on our Saviour and our Lord.
jhn every scribe	Saint John Henry Newman Mag at EP2	Every scribe * which is instructed unto the kingdom of heaven is like unto a man that is an householder, which bringeth forth out of his treasure things new and old.
t19 where shall	The Nineteenth Sunday after Trinity Mag at EP1	Where shall wisdom be found, * and where is the place of understanding?
t19 jesus said	The Nineteenth Sunday after Trinity Ben	Jesus said * to the sick of the palsy: Son, be of good cheer, thy sins are forgiven thee.
t19 the sick	The Nineteenth Sunday after Trinity Mag at EP2	The sick of the palsy therefore * took up his bed, on which he lay, and glorified God: and all the people, when they saw it, gave praise to God.
doctor teresa	Saint Teresa of Jesus Mag at EP2	O teacher most excellent, * O light of holy Church, O blessed Theresa of Jesus, lover of the divine law, intercede for us unto the Son of God.
t20 wisdom hath	The Twentieth Sunday after Trinity Mag at EP1	Wisdom hath builded her house, * she hath hewn out her seven pillars, she hath subdued the nations, and hath trodden on the necks of the haughty in the might of her own strength.
t20 tell them	The Twentieth Sunday after Trinity Ben	Tell them which are bidden: * Behold, I have prepared my dinner, come unto the marriage, alleluia.
t20 but when	The Twentieth Sunday after Trinity Mag at EP2	But when the king came in * to see the guests, he saw there a man not having a wedding garment, and said unto him: Friend, how earnest thou in hither, not having a wedding garment?
t21 in the way	The Twenty-first Sunday after Trinity Mag at EP1	In the way of righteousness * is life, and in the pathway thereof there is no death.
t21 there was	The Twenty-first Sunday after Trinity Ben	There was a certain nobleman * whose son was sick at Capernaum; who, when he heard that Jesus was come into Galilee, went and besought him that he would come down and heal his son.
t21 so the father	The Twenty-first Sunday after Trinity Mag at EP2	So the father knew * that it was at the selfsame hour in the which Jesus said: Thy son liveth; and himself believed, and his whole house.
t22 all wisdom	The Twenty-second Sunday after Trinity Mag at EP1	All wisdom * proceedeth from the Lord, and was with him for all time, and is before the ages.
t22 the lord said	The Twenty-second Sunday after Trinity Ben	The lord said unto his servant: * Pay that thou owest. But that servant fell down and besought him, saying: Have patience with me, and I will pay thee all.
t22 thou wicked	The Twenty-second Sunday after Trinity Mag at EP2	Thou wicked servant, * I forgave thee all that debt, because thou desiredst me: shouldest not thou also have had compassion on thy fellow servant, even as I had pity on thee? alleluia.
as angels archangels	All Saints Mag at EP1	Angels, * Archangels, Thrones and Dominations, Principalities and powers, heavenly Virtues, Cherubim and Seraphim, Patriarchs and Prophets, holy Doctors of the law, Apostles, all Martyrs of Christ,. holy Confessors, Virgins of the Lord, Hermits and all Saints, intercede for us.
as inv	All Saints Inv	The Lord, the King of kings, O come let us worship him: For he is the crown of all the Saints.
as the glorious	All Saints Ben	The glorious company * of the Apostles praise thee; the goodly fellowship of the Prophets praise thee; the noble army of Martyrs praise thee; and all the Saints and elect with one voice acknowledge thee, O blessed Trinity, one God.
as o how glorious	All Saints Mag at EP2	O how glorious is the kingdom, * wherein all the Saints rejoice with Christ, and clothed in white robes follow the Lamb whithersoever he goeth!
t23 arm yourselves	The Twenty-third Sunday after Trinity Mag at EP1	Arm yourselves * and be ye men of valour, and be in readiness for the conflict; for it is better for us to perish in battle than to look upon the evils of our nation and our altars. As the will of God is in heaven, even so let him do.
t23 master we know	The Twenty-third Sunday after Trinity Ben	Master, * we know that thou art true, and teachest the way of God in truth, alleluia.
t23 render therefore	The Twenty-third Sunday after Trinity Mag at EP2	Render therefore * unto Caesar the things which are Caesar's; and unto God the things that are God's, alleluia.
dedication inv	Common of Dedication of a Church Inv	Holiness becometh the house of God: * Therein let us worship Christ her Bridegroom.
dedication zacchaeus	Common of Dedication of a Church Ben	Zacchaeus, * make haste and come down, for today I must abide at thy house: and he made haste and came down, and received him joyfully into his house. This day is salvation come to this house from the Lord.
doctor leo	Saint Leo the Great Mag at EP2	O teacher most excellent, * O light of holy Church, O blessed Leo the Great, lover of the divine law, intercede for us unto the Son of God.
t24 i dwell	The Twenty-fourth Sunday after Trinity Mag at EP1	I dwell * in the height above, and my throne is set in a cloudy pillar.
t24 for she said	The Twenty-fourth Sunday after Trinity Ben	For she said * within herself: If I may but touch the hem of his garment, I shall be whole.
t24 but jesus	The Twenty-fourth Sunday after Trinity Mag at EP2	But Jesus turned him about, * and when he saw her, he said: Daughter, be of good comfort, thy faith hath made thee whole, alleluia.
doctor albert	Saint Albert the Great Mag at EP2	O teacher most excellent, * O light of holy Church, O blessed Theresa of Jesus, lover of the divine law, intercede for us unto the Son of God.
margaret of scotland inv	Saint Margaret of Scotland Inv	Let us praise our God: * Whom blessed Margaret of Scotland confessed.
elizabeth of hungary inv	Saint Elizabeth of Hungary Inv	Let us praise our God: * Whom blessed Elizabeth of Hungary confessed.
ctk the lord god	Our Lord Jesus Christ, King of the Universe Mag at EP1	The Lord God shall give * unto him the throne of his father David: and he shall reign over the house of Jacob for ever, and of his kingdom there shall be no end, alleluia.
ctk inv	Our Lord Jesus Christ, King of the Universe Inv	Jesus Christ, the King of kings: * O come, let us worship him.
ctk unto god	Our Lord Jesus Christ, King of the Universe Ben	Unto God and his Father * hath he made us to be a kingdom; who is the first-begotten of the dead, and the Prince of the kings of the earth, alleluia.
ctk he hath	Our Lord Jesus Christ, King of the Universe Mag at EP2	He hath on his vesture * and on his thigh a name is written: King of kings and Lord of lords; to him be glory and dominion for ever and ever.
sba when jesus	Monday before Advent Ben	When Jesus lift up his eyes, * and saw a great company come unto him, he said unto Philip: Whence shall we buy bread, that these may eat? And this he said to prove him, for he himself knew what he would do.
sba then those	Monday before Advent Mag at EP2	Then those men, * beholding the miracle that Jesus did, glorified God, and said: Of a truth, this is the Saviour of the world.
ad1 behold the name	The First Sunday of Advent Mag at EP1	Behold, the name of the Lord cometh from afar, and his glory filleth all the earth.
ad1 the lord the king	The First Sunday of Advent Inv	The Lord, the King that draweth nigh, * O come, let us worship him.
ad1 the holy ghost	The First Sunday of Advent Ben	The Holy Ghost shall come down upon thee, Mary: fear not, thou shalt conceive in thy womb the Son of God, alleluia.
ad1 fear not mary	The First Sunday of Advent Mag at EP2	Fear not, Mary, * thou hast found favour with the Lord; behold, thou shalt conceive and bear a Son, alleluia.
ad1 the lord the king ferias	Monday following Advent I Inv	The Lord, the King that draweth nigh, * O come, let us worship him.
ad1 the angel	Monday following Advent I Ben	The Angel of the Lord * brought the tidings to Mary, and she conceived by the Holy Ghost, alleluia.
ad1 lift up	Monday following Advent I Mag at EP2	Lift up thine eyes, * O Jerusalem, and see the King in his power; lo, thy Redeemer cometh to release thee from thy chain.
andrew on of the two	Saint Andrew Mag at EP1	One of the two * which followed the Lord was Andrew, Simon Peter's brother, alleluia.
ad1 before they	Tuesday following Advent I Ben	Before they came together, * Mary was found with child of the Holy Ghost.
ad1 see ye	Tuesday following Advent I Mag at EP2	Seek ye the Lord * while he may be found: call ye upon him while he is near, alleluia.
ad1 out of sion	Ember Wednesday in Advent Ben	Out of Sion * shall go forth the law: and the word of the Lord from Jerusalem.
ad1 there shall	Ember Wednesday in Advent Mag at EP2	There shall come * after me one that is mightier than I; the latchet of whose shoes I am not worthy to unloose.
ad1 blessed art	Thursday following Advent I Ben	Blessed art thou * among women, and blessed is the fruit of thy womb.
ad1 i will	Thursday following Advent I Mag at EP2	I will wait * upon the Lord my Saviour, and I will look for him when he is near, alleluia.
ad1 lo there	Ember Friday in Advent Ben	Lo, there cometh * one that is both God and man, of the house of David, to sit upon the throne, alleluia.
ad1 out of egypt	Ember Friday in Advent Mag at EP2	Out of Egypt * have I called my Son: he shall come to save his people.
ad1 sion be not	Ember Saturday in Advent Ben	Sion, be not dismayed, * for behold, thy God cometh, alleluia.
ad2 come o lord	The Second Sunday of Advent Mag at EP1	Come, O Lord, * and visit us in peace, that we may rejoice before thee with a perfect heart.
ad2 now when john	The Second Sunday of Advent Ben	Now when John * had heard in the prison the works of Christ, he sent two of his disciples, and asked him, saying: Art thou he that should come, or look we for another?
ad2 art thou he	The Second Sunday of Advent Mag at EP2	Art thou he that should come, * or look we for another? Tell John the things which ye have seen: the blind receive their sight, the dead are raised up, the poor have the gospel preached unto them, alleluia.
ad2 from heaven there	Monday following Advent II Ben	From heaven there cometh * the Lord the Ruler, and in his hand are honour and dominion.
ad2 behold the king	Monday following Advent II Mag at EP2	Behold the King cometh, * the Lord of the earth, and he shall take away the yoke of our captivity.
doctor ambrose	Saint Ambrose Mag at EP2	O teacher most excellent, * O light of holy Church, O blessed Ambrose, lover of the divine law, intercede for us unto the Son of God.
ad2 the lord shall	Tuesday following Advent II Ben	The Lord shall arise * upon thee, O Jerusalem, and his glory shall be seen upon thee.
ad2 the voice of	Tuesday following Advent II Mag at EP2	The voice of one crying * in the wilderness: Prepare ye the way of the Lord: make straight a highway for our God.
icbvm all generations	The Immaculate Conception of the Blessed Virgin Mary Mag at EP1	All generations shall call me blessed, for he that is mighty hath done to me great things, alleluia.
icbvm inv	The Immaculate Conception of the Blessed Virgin Mary Inv	Let us celebrate the Immaculate Conception of the Virgin Mary: * Let us worship her Son Christ our Lord.
icbvm today let us	The Immaculate Conception of the Blessed Virgin Mary Ben	Today let us devoutly celebrate * with due solemnity the Immaculate Conception of the most holy Mother of God, the ever-Virgin Mary; of whom the King of kings was born, alleluia.
icbvm let us celebrate	The Immaculate Conception of the Blessed Virgin Mary Mag at EP2	Let us celebrate * the worshipful and Immaculate Conception of the blessed and glorious Virgin Mary; whose lowliness the Lord regarded, when at the word of an Angel she conceived the world's Redeemer, alleluia.
ad2 behold i send	Wednesday following Advent II Ben	Behold, I send * my messenger, and he shall prepare thy way before thy face.
ad2 sion thou shalt	Wednesday following Advent II Mag at EP2	Sion, thou shalt be renewed, * and shalt see thy Righteousness, that is come unto thee.
ad2 thou o lord	Thursday following Advent II Ben	Thou, O Lord, * art he that should come, for whom we look, to save thy people.
ad2 he that cometh	Thursday following Advent II Mag at EP2	He that cometh after me * is preferred before me: the latchet: of whose shoes I am not worthy to unloose.
ad2 say to them	Friday following Advent II Ben	Say to them * that are of a fearful heart: Be strong, for behold, the Lord our God shall come.
ad2 o sing unto	Friday following Advent II Mag at EP2	O sing unto the Lord * a new song: and his praise to the ends of the earth.
ad3 before me	The Third Sunday of Advent Mag at EP1	Before me * there was no God formed, nor shall there be after me: for to me shall every knee be bowed, and me shall every tongue confess.
ad3 the lord is now nigh	The Third Sunday of Advent Inv	The Lord is now nigh at hand: * O come, let us worship him.
ad3 on the throne	The Third Sunday of Advent Ben	On the throne * of David, and over his kingdom, shall he reign for ever, alleluia.
ad3 blessed art thou	The Third Sunday of Advent Mag at EP2	Blessed art thou, Mary * who hast believed: there shall be performed in thee the things which were told thee from the Lord, alleluia.
ad3 there shall come	Monday following Advent III Ben	There shall come forth * a Rod from the stem of Jesse, and all the earth shall be filled with his glory: and all flesh shall see the salvation of God.
ad3 all generations	Monday following Advent III Mag at EP2	All generations * shall call me blessed, for God hath regarded his lowly handmaiden.
ad3 thou bethlehem	Tuesday following Advent III Ben	Thou, Bethlehem, * in and of Judah, shall not be the least; for out of thee shall come a Governor, that shall rule my people Israel.
ad3 awake awake arise	Tuesday following Advent III Mag at EP2	Awake, awake, * arise, O Jerusalem: loose the bands from off the neck, O captive daughter of Sion.
ad3 the angel gabriel	Wednesday following Advent III Ben	The Angel Gabriel * was sent to Mary, a Virgin espoused to Joseph.
ad3 behold the handmaid	Wednesday following Advent III Mag at EP2	Behold the handmaid of the Lord: * be it unto me according to thy word.
ad3 watch therefore	Thursday following Advent III Ben	Watch ye therefore * in your hearts: for the Lord our God is nigh at hand.
ad3 rejoice ye with	Thursday following Advent III Mag at EP2	Rejoice ye * with Jerusalem, and be glad with her, all ye that love her, for ever.
ad3 as soon as	Friday following Advent III Ben	As soon as the voice * of thy salutation sounded in mine ears, the babe leaped in my womb for joy, alleluia.
ad o wisdom	Friday following Advent III Mag at EP2	O Wisdom, * which camest out of the mouth of the Most High, and reachest from one end to another, mightily and sweetly ordering all things: come and teach us the way of prudence.
ad3 how shall this be	Saturday following Advent III Ben	How shall this be, * Angel of God, seeing I know not a man?  Hearken, O Virgin Mary: the Holy Ghost shall come upon thee, and the power of the Highest shall overshadow thee.
ad o adonai	Saturday following Advent III Mag at EP2	O Adonai, * and Leader of the house of Israel, who appearedst in the bush to Moses in a flame of fire, and gavest him the Law in Sinai: come and deliver us with an outstretched arm.
ad4 hail mary full	The Fourth Sunday of Advent Ben	Hail, Mary, * full of grace: the Lord is with thee, blessed art thou among women, alleluia.
ad o root of jesse	The Fourth Sunday of Advent Mag at EP2	O Root of Jesse, * which standest for an ensign of the peoples, at whom kings shall shut their mouths, to whom the Gentiles shall seek: come and deliver us, and tarry not.
ad4 thus says	Monday following Advent IV Ben	Thus saith the Lord: * Repent ye and turn again, because the kingdom of heaven is at hand, alleluia.
ad o key of david	Monday following Advent IV Mag at EP2	O Key of David, * and Sceptre of the house of Israel; that openest, and no man shutteth, and shuttest, and no man openeth: come and bring the prisoner out of the prison-house, and him that sitteth in darkness and the shadow of death.
ad be not afraid	Tuesday following Advent IV Ben	Be not afraid, * for on the fifth day our Lord will. come unto you.
ad o dayspring	Tuesday following Advent IV Mag at EP2	O Day-Spring, * Brightness of the Light everlasting and righteousness: come and enlighten him that sitteth in darkness and in the shadow of death.
ad4 i will place	Wednesday following Advent IV Ben	I will place salvation * in Sion, and my glory in Jerusalem, alleluia.
ad o king of the nations	Wednesday following Advent IV Mag at EP2	O King of the Nations, * and their Desire; the Corner-stone, who makest both one: come and save mankind, whom thou formedst of clay.
ad behold all things	Thursday following Advent IV Ben	Behold, all things are fulfilled * which were spoken by the Angel of the Virgin Mary.
ad o emmanuel	Thursday following Advent IV Mag at EP2	O Emmanuel, * our King and Lawgiver, the Desire of all nations and their Salvation: come and save us, O Lord our God.
ad the saviour of the world	Friday following Advent IV Ben	The Saviour of the world shall * arise as the sun: and shall come down into the Virgin's womb as the showers upon the grass.
nl or ever the sun	The Nativity of the Lord Mag at EP1	Or ever the sun * be risen in the heavens, ye shall see the King of kings proceeding from the Father, as a bridegroom out of his chamber.
nl glory to god	The Nativity of the Lord Ben	Glory to God in the highest, * and on earth peace, goodwill toward men, alleluia, alleluia.
nl today the christ is born	The Nativity of the Lord Mag at EP2	Today * the Christ is born: today hath a Saviour appeared: today on earth Angels are singing, Archangels rejoicing: today the righteous exult and say: Glory to God in the highest, alleluia.
hf christ the son of god	The Holy Family of Jesus, Mary and Joseph Inv	Christ the Son of God, who was made subject to Mary and Joseph: * O come, let us worship him.
hf enlighten us	The Holy Family of Jesus, Mary and Joseph Ben	Enlighten us, O Lord, * by the example of thy Family, and guide our feet into the way of peace.
hf but mary kept	The Holy Family of Jesus, Mary and Joseph Mag at EP2	But Mary * kept all these things, and pondered them in her heart.
stephen inv	Saint Stephen Inv	The newborn Christ, who on this day crowned blessed Stephen: * O come, let us worship him.
stephen but stephen	Saint Stephen Ben	But Stephen; * full of grace and power, did great wonders among the people.
stephen devout men	Saint Stephen Mag at EP2	Devout men carried Stephen * to his burial, and made great lamentation over him.
john this is that john	Saint John Ben	This is that John * which leaned on the Lord's bosom at supper; the blessed Apostle, unto whom were revealed the secrets of heaven.
john this saying	Saint John Mag at EP2	This saying * went out among the brethren, that that disciple should not die; yet Jesus said not: He shall not die; but: If I will that he tarry till I come, what is that to thee?
hi these are they	The Holy Innocents Ben	These are they * which were not defiled with women, for they are Virgins: and they follow the Lamb whithersoever he goeth.
hi many innocent	The Holy Innocents Mag at EP2	Many innocent children * were slain in Christ's behalf; by a ruthless tyrant were sucklings put to death; pure and unspotted, they follow the Lamb himself, and say without ceasing: Glory be to thee, O Lord.
doctor isidore	Saint Isidore Mag at EP2	O teacher most excellent, * O light of holy Church, O blessed Isidore, lover of the divine law, intercede for us unto the Son of God.
visit blessed art thou [easter]	The Visitation of the Blessed Virgin Mary Mag at EP1 with Alleluia	Blessed art thou, * O Mary, who hast believed: for there shall be a performance of those things which were told thee from the Lord, alleluia.
visit inv [easter]	The Visitation of the Blessed Virgin Mary Inv with Alleluia	Let us celebrate the Visitation of the Virgin Mary: * Let us worship her Son, Christ the Lord. Alleluia.
visit when elizabeth [easter]	The Visitation of the Blessed Virgin Mary Ben with Alleluia	When Elizabeth * heard the salutation of Mary, she cried with a loud voice, saying: Whence is this to me, that the Mother of my Lord should come to me? Alleluia.
visit all generations [easter]	The Visitation of the Blessed Virgin Mary Mag at EP2 with Alleluia	All generations * shall call me blessed, for God hath regarded the lowliness of his handmaiden, alleluia.
doctor ephrem [easter]	Saint Ephrem Mag at EP2 with Alleluia	O teacher most excellent, * O light of holy Church, O blessed Ephrem, lover of the divine law, intercede for us unto the Son of God. Alleluia.
doctor anthony [easter]	Saint Anthony of Padua Mag at EP2 with Alleluia	O teacher most excellent, * O light of holy Church, O blessed Anthony of Padua, lover of the divine law, intercede for us unto the Son of God. Alleluia.
olhoc behold mary	Our Lady, Help of Christians Mag at EP1	Behold, Mary * was our hope, to whom we fled for help, that she might deliver us, and she. came to our aid.
olhoc to thee we cried	Our Lady, Help of Christians Ben	To thee * we cried, O holy Mother of God, and through thee came the help of the Lord to us.
olhoc holy mary help	Our Lady, Help of Christians Mag at EP2	Holy Mary, * help those in misery, strengthen the fainthearted, comfort the sorrowful, pray for the people, intercede for clerics, plead for all women vowed to God; may all feel thy help, whosoever implore thine aid.
doctor bede	Saint Bede the Venerable Mag at EP2	O teacher most excellent, * O light of holy Church, O blessed Bede the Venerable, lover of the divine law, intercede for us unto the Son of God.
annunciation the holy ghost [easter]	The Annunciation of the Lord Mag at EP1 with Alleluia	The Holy Ghost * shall come upon thee, and the power of the highest shall overshadow thee. Alleluia.
annunciation how shall this be [easter]	The Annunciation of the Lord Ben with Alleluia	How shall this be, *O Angel of God, seeing I know not a man? Hearken, O Virgin Mary: the Holy Ghost shall come upon thee, and the power of the highest shall overshadow thee. Alleluia.
annunciation the angel gabriel [easter]	The Annunciation of the Lord Mag at EP2 with Alleluia	The Angel Gabriel * spake unto Mary, saying: Hail, full of grace; the Lord is with thee: blessed art thou among women. Alleluia.
ad4 awake awake	Tuesday following Advent IV Ben	Awake, awake * put on strength, O arm of the Lord.
ad4 comfort ye	Thursday following Advent IV Ben	Comfort ye, comfort ye, * my people, saith the Lord your God.
doctor catherine	Saint Catherine of Siena Mag at EP2	O teacher most excellent, * O light of holy Church, O blessed Catherine of Siena, lover of the divine law, intercede for us unto the Son of God.
doctor athanasius	Saint Athanasius Mag at EP2	O teacher most excellent, * O light of holy Church, O blessed Athanasius, lover of the divine law, intercede for us unto the Son of God.
st george the saints through faith	Saint George Mag at EP1	The Saints through faith * subdued kingdoms, wrought righteousness, obtained promises.
ep5 master sowedst		Master * sowedst thou not good seed in thy field? Whence then hath it tares? And he said: An enemy has done this.
ep6 as the lightning		As the lightning * cometh out of the east, and shineth even to the west: so shall also the coming of the Son of man be.
t25 give peace		Give peace, O Lord, * in this our time; because there is non other that fighteth for us, but only thou, our God.
t26 let your song		Let your song rejoice * in his mercy, and be not ashamed of his peace.
dedication the lord hath
dedication inv		Holiness becometh the house of God: * Therein let us worship Christ her Bridegroom.
dedication zacchaeus		Zacchaeus, * make haste and come down, for today I must abide at thy house: and he made haste and came down, and received him joyfully into his house. This day is salvation come to this house from the Lord.
dedication o how		O how dreadful * is this place: truly this is none other than the house of God and the gate of heaven.
bvm all generations		All generations * Shall call me blessed, for God hath regarded his lowly handmaiden.
ep4 when jesus		And when Jesus was entered * into a ship, behold, there arose a great tempest in the sea: and his disciples came and awoke him, saying unto him: Master, save us, we perish:
ep4 master save		Master, * save us, we perish: speak the. word, O God, and make the storm to cease.
ep5 gather ye		Gather ye together * first the tares, and bind them in. bundles to burn them: but gather the wheat into my barn.
ep6 wheresoever		Wheresoever the carcass is, * there will the eagles be gathered together.
jaiet john the [easter]		John the Apostle, * being cast into a cualdron of boiling oil, by grace protected came forth unharmed, alleluia.
helena inv	Saint Helena Inv	Let us praise our God: * Whom blessed Helena confessed.
christmas eve inv		Today ye shall know that the Lord will come: * and in the morning ye shall see his glory.

`;

export const getFinalAntiphon = (
    id: string | undefined
): string | undefined => {
    return spreadsheet
        .split("\n")
        .map((row: string) => {
            return row.split("	");
        })
        .find((row: string[]) => row[0] === id)?.[2];
};

// TODO: check eastertide
